<template>
  <div id="dataset-manage-page">

    <!-- Title & Buttons -->
    <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
      <div class="inline-block text-2xl font-medium mr-4">{{$t('Datasets')}}</div>
      <div class="dropdown" data-placement="bottom-start">
        <button class="btn px-2 box text-gray-700 hover:bg-green-600 hover:text-white" @click="Modal('dataset-create-box')"><span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-4 h-4" /></span></button>
      </div>
      <button class="btn px-2 box text-gray-700 ml-1 hover:bg-blue-500 hover:text-white" @click="reset()"><span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span></button>
    </div>

    <!-- Filters -->
    <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-5 xl:mt-0" v-if="listOptions.value?.filter" style="min-width: 700px;">
      <div class="mx-auto text-gray-600" v-if="listOptions.value">
        <div class="px-10 py-3 bg-white rounded-full border" :class="listOptions.value.type || listOptions.value.trained ? 'pl-10' : ''">
          <select class="w-72 form-select p-2 cursor-pointer bg-white align-top" :disabled="Layout.loading.list?true:false"
            v-model="listOptions.value.type" :class="listOptions.value.type ? Layout.loading.list?'bg-blue-500':'bg-blue-500 text-white' : 'text-gray-600'">
            <option value="">{{$t('All types')}}</option>
            <option value="MULTICLASS">{{$t('Classification with a single label')}}</option>
            <option value="MULTILABEL">{{$t('Classification with a multiple labels')}}</option>
            <option value="imageObjectDetection">{{$t('Vision Object detection')}}</option>
          </select>
          <select class="w-44 form-select p-2 cursor-pointer ml-2 bg-white align-top" :disabled="Layout.loading.list?true:false"
            v-model="listOptions.value.trained" :class="listOptions.value.trained ? Layout.loading.list?'bg-blue-500':'bg-blue-500 text-white' : 'text-gray-600'">
            <option value="">{{$t('All')}}</option>
            <option :value="true">{{$t('Trained')}}</option>
          </select>  
          <select class="w-32 form-select p-2 cursor-pointer ml-2 text-gray-600 bg-white align-top" v-model="listOptions.value.order" :disabled="Layout.loading.list?true:false">
            <option value="dateDesc">{{$t('Date desc')}}</option>
            <option value="dateAsc">{{$t('Date asc')}}</option>
          </select> 
          <button class="btn px-1 box bg-gray-600 mt-0.5 ml-4 hover:bg-blue-500 text-white h-8" @click="reset()" title="Reset Filters" :class="listOptions.value.type || listOptions.value.trained ? '' : 'hidden'">
            <span class="w-5 h-5 flex items-center justify-center"><TrashIcon class="w-4 h-4" /></span>
          </button>
        </div>
      </div>
    </div>
    <div class="mt-5 w-full" v-if="listOptions.value?.filter && !Layout.loading.list"><div class="text-gray-600 text-center text-xs">{{$t('Showing')}} {{Object.keys(_datasets).length}} {{$t('datasets')}}</div></div>

    <!-- Loading -->
    <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center p-5 mt-10" v-if="Layout.loading.list">
        <div class="flex items-center justify-center"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-24" /></div> 
        <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <!-- List -->
    <div class="mt-6 grid grid-cols-12 gap-3 mb-60" v-if="!Layout.loading.list">
      <div v-for="(d) in _datasets" :key="d.id" class="col-span-12 md:col-span-6 lg:col-span-4 xxl:col-span-3">
        <div class="box hover:bg-gray-100 border-t-4 pb-2" :class="d.trained ? 'border-green-500' : (tagCounter && tagCounter[d.id] && !tagCounter[d.id].count) ? 'border-red-300' : ' border-gray-500'">
          <div class="flex flex-col lg:flex-row items-center p-5 border-b border-gray-200  h-52 lg:h-auto">
            <a @click="manageDataset(d)">
              <div class="w-16 h-16 xxl:w-20 xxl:h-20 image-fit lg:mr-1" v-if="d?.preview"><img :src="d?.preview" class="w-full h-full rounded-full zoom-in" /></div>
              <div class="w-16 h-16 xxl:w-20 xxl:h-20 image-fit lg:mr-1" v-else><img class="rounded-full border border-gray-300 zoom-in" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" /></div>
            </a>
            <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0 cursor-pointer" @click="manageDataset(d)">
              <a class="font-medium" v-if="d.name">{{d.name.slice(0, 35)}} <span v-if="(d.name.length>35)">...</span></a>
              <div class="text-gray-600 text-xs mt-0.5" v-if="d.typeName">{{ d.typeName }}</div>
              <div class="text-gray-600 text-xs mt-0.5" v-else-if="d.type">
                <span v-if="d.type=='MULTICLASS'">{{$t('Image classification with a single label')}}</span>
                <span v-else-if="d.type=='MULTILABEL'">{{$t('Image classification with multiple labels')}}</span>
                <span v-else-if="d.type=='imageObjectDetection'">{{$t('Vision Object Detection')}}</span>
              </div>
              <span v-if="d.createdAt" class="text-gray-600 text-xs mt-0.5 font-italic" style="font-size:11px">{{ this.helper.getTimestampDate(d.createdAt.toDate(),'date')}}</span>
              <div class="text-gray-600 text-xs mt-0,5" v-if="d.count">
                <ImageIcon class="w-4 h-4 mr-1 text-gray-600" /> <span class="align-middle">{{ this.helper.formatScore(d.count) }} {{$t('Images')}}</span>
              </div>
            </div>
            <div class="absolute right-0 top-0 mr-5 mt-3 dropdown">
              <a class="dropdown-toggle w-5 h-5 block" href="javascript:;" aria-expanded="false"><MoreHorizontalIcon class="w-5 h-5 text-gray-600 dark:text-gray-300"/></a>
              <div class="dropdown-menu w-40">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <a @click="manageDataset(d)" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"><Edit2Icon class="w-4 h-4 mr-2" /> {{ $t('Edit') }}</a>
                  <a @click="labeling(d)" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"><ImageIcon class="w-4 h-4 mr-2" /> {{ $t('Labeling') }}</a>
                  <a @click="Layout.dataset.delete = d;Modal('dataset-delete-box')" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md cursor-pointer"><TrashIcon class="w-4 h-4 mr-2" /> {{ $t('Delete') }}</a>
                </div>
              </div>
            </div>
            <div class="flex justify-end text-xs sm:mt-2 cursor-pointer" @click="manageDataset(d)"><div v-if="d?.trained" title="Trained" class="mt-1 lg:mr-4"><CodesandboxIcon class="w-8 h-8 mr-0 text-green-600" style="margin-right: 0 !important;" /></div></div>
          </div>
        </div>
      </div>
    </div>

    <!-- No results -->
    <div v-if="!Layout.loading.list && !Object.keys(_datasets).length" class="text-center justify-center mt-20 text-gray-600">
        <div>
          <SearchIcon class="w-12 h-12" /> <h2 class="text-lg truncate mt-2 font-medium">{{ $t('No results found') }}</h2>
          <div>{{ $t("Sorry, that filter combination has no results") }}</div>
          <div class="mt-2 text-blue-500 cursor-pointer" @click="reset()">{{$t('reset filters')}}</div> 
        </div>
    </div>

    <!-- Manage Dataset -->
    <div id="dataset-manage" class="modal overflow-y-auto" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static" :class="!Layout.dataset.manage || !Object.keys(Layout.dataset.manage).length ? 'hidden' : ''">
      <imageEditor :options="Layout.editor.options" ref="imageEditorComponent" />
      <div class="modal-dialog modal-xl" style="width: 1170px; max-width: 1170px; transform: none;">
        <div id="dataset-manage-body" style="max-width: 900px; margin-top: 1px; float: left" class="modal-content">
          <!-- <a v-if="Layout.dataset.manage" :href="'/datasets/'+ Layout.dataset.manage.id" target="_blank" class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer text-blue-600 pt-5 px-8 text-xs pr-20">{{$t('Open in a new window')}}</a>-->
          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal" @click="router.push('/datasets/')"><XIcon class="w-8 h-8" /></label>
          <div class="modal-body p-8 text-left" v-if="Layout.dataset.manage">
            <div class="flex flex-row items-center p-0 h-auto w-full">
              <div class="w-20 h-20 xxl:w-24 xxl:h-24 image-fit mr-1" v-if="Layout.dataset.manage?.preview"><img :src="Layout.dataset.manage?.preview" class="w-full h-full rounded-full zoom-in cursor-default" /></div>
              <div class="w-20 h-20 xxl:w-24 xxl:h-24 image-fit mr-1" v-else><img class="rounded-full border border-gray-300 zoom-in" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" /></div>
              <div class="ml-3 text-left mt-0" v-if="Layout.dataset.manage?.name">
                <a class="font-medium text-lg cursor-pointer" @click="edit()">{{Layout.dataset.manage?.name.slice(0, 35)}}<span v-if="(Layout.dataset.manage.name.length>35)">...</span> <Edit-3Icon class="w-4 h-4" /></a>
                <div class="text-gray-600 text-xs mt-0.5" v-if="Layout.dataset.manage.type">
                  <span v-if="Layout.dataset.manage.type=='MULTICLASS'">{{$t('Image classification with a single label')}}</span>
                  <span v-if="Layout.dataset.manage.type=='MULTILABEL'">{{$t('Image classification with multiple labels')}}</span>
                  <span v-else-if="Layout.dataset.manage.type=='imageObjectDetection'">{{$t('Vision Object Detection')}}</span>
                </div>
                <span v-if="Layout.dataset.manage.createdDate" class="text-gray-600 mt-0.5 font-italic" style="font-size:11px">{{ Layout.dataset.manage.createdDate }}</span>
              </div>
              <div class="flex justify-end ml-auto">
                <div id="rpBtnReloadDataset" class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600 mr-1" title="Reload" v-if="Layout.dataset.manage" @click="refreshMosaic();manageDataset(Layout.dataset.manage)"><Refresh-cwIcon class="w-5 h-5" /></div>
                <div class="w-12">
                  <div class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600 mr-1" title="Previous dataset" @click="prev()" v-if="Layout.dataset.idx && Object.keys(_datasets).length && Object.keys(_datasets).length>1"><Corner-up-leftIcon class="w-5 h-5" /></div>
                </div>
                <div class="w-16 ml-1">
                  <div class="btn bg-gray-100 zoom-in border py-1.5 px-3 text-gray-600" title="Next dataset" @click="next()" v-if="(Layout.dataset.idx+1)<Object.keys(_datasets).length && Object.keys(_datasets).length && Object.keys(_datasets).length>1">
                    <Corner-up-rightIcon class="w-5 h-5" />
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-2 mt-5">
              <div class="col-span-12">
                <div class="intro-y">
                  <div class="box py-2 bg-gray-100 px-20 border">
                    <div class="chat__tabs nav nav-tabs justify-center" role="tablist">
                      <a id="mdTabOverview" class="flex-1 py-2 mx-1 rounded-md text-center bg-white cursor-pointer border" @click="Layout.tab='overview';" :class="Layout.tab=='overview' ? 'border-blue-300 bg-blue-500 text-white' : 'text-gray-600'">{{$t('Overview')}}</a>
                      <a id="mdTabLabels" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" @click="Layout.tab='labels';" :class="Layout.tab=='labels'   ? 'active' : ''">{{$t('Tags')}}</a>
                      <a id="mdTabImages" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" @click="Layout.tab='images';" :class="Layout.tab=='images'   ? 'active' : ''">{{$t('Upload')}}</a>
                      <a id="mdTabModels" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" @click="Layout.tab='models';" :class="Layout.tab=='models'   ? 'active' : ''">{{$t('Models')}}</a>
                      <a id="mdTabTools" class="flex-1 py-2 mx-1 rounded-md text-center bg-white text-gray-600 cursor-pointer border" style="max-width: 50px" title="Copy" 
                        @click="Layout.tab='tools';" :class="Layout.tab=='tools'   ? 'active' : ''"><CopyIcon class="w-5 h-5" /></a>
                    </div>
                  </div>
                </div>
                <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="Layout.loading.manage">
                  <div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
                  <LoadingIcon icon="three-dots" class="w-8 h-8" />
                </div>
                <div class="tab-content mt-3" v-else>
                  <div id="overview" class="tab-pane" role="tabpanel" aria-labelledby="chats-tab" :class="Layout.tab=='overview' ? 'active' : ''">
                    <div class="grid grid-cols-12 gap-5 mt-5">
                      <div class="col-span-7">
                        <div class="box px-3 py-10 shadow-none border border-solid border-gray-200">
                          <div class="text-gray-700 px-5 font-medium">{{$t('Resume')}}</div>
                          <div class="text-xs text-gray-600 px-5 mt-2" v-if="Layout.dataset.manage.resumeTxt">{{ Layout.dataset.manage.resumeTxt?.resume }}</div>
                          <div class="text-gray-700 px-5 mt-5 font-medium">{{$t('A brief description of the goal')}}</div>
                          <div v-if="Layout.dataset.manage.description" class="text-xs text-gray-600 px-5 mt-2">{{ Layout.dataset.manage.description }}</div> 
                          <div v-else class="text-xs text-gray-600 px-5 mt-2">{{$t('Not defined')}}.</div>   
                          <div class="input-form ml-5">
                            <div class="flex justify-left text-xs mt-5">
                              <div class="px-3 py-2 zoom-in border bg-gray-300 text-gray-700 hover:bg-theme-6 hover:text-white cursor-pointer mr-2 display-inline" @click="edit()">
                                <Edit3Icon class="w-3 h-3" /> {{ $t('Edit') }}
                              </div>
                            </div>
                          </div>
                          <div v-if="Layout.dataset.manage.descriptionGPT">
                            {{ Layout.dataset.manage.descriptionGPT }}
                          </div>
                        </div>  
                      </div>
                      <div class="col-span-5 text-left">
                        <div v-if="Layout.dataset.manage?.tagsCounter">
                            <div class="box p-5 shadow-none border border-solid border-gray-200">
                              <div class="flex items-center border-b border-gray-200 pb-2 mb-5" v-if="Layout.dataset.manage?.datasetStatus">
                                <div><div class="text-gray-600">{{ $t('Status') }}</div></div>
                                <span class="text-green-600 ml-auto" v-if="!Layout.dataset.manage?.datasetStatus?.inProgress">{{ 'Ready' }}</span>
                                <span class="text-gray-600 ml-auto" v-if="Layout.dataset.manage?.datasetStatus?.inProgress"><LoadingIcon icon="three-dots" class="w-4 h-4" /></span>
                                <span class="text-yellow-500 ml-auto text-xs" v-if="Layout.dataset.manage?.datasetStatus?.inProgress">{{ Layout.dataset.manage?.datasetStatus?.action }}</span>
                              </div>
                              <div v-else>
                                  <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center pb-2">
                                    <div class="text-xs text-center">{{ $t('Analyzing the dataset') }}</div>
                                    <LoadingIcon icon="three-dots" class="w-8 h-8" />
                                  </div>
                              </div>
                              <div class="flex items-center border-b border-gray-200 pb-2">
                                <div><div class="text-gray-600">{{ $t('Images') }}</div><div class="mt-1 text-xs text-gray-500"><span class="mt-1 text-xs text-blue-400 cursor-pointer" @click="Layout.tab='images'">{{ $t('manage') }}</span> </div></div>
                                <span class="text-gray-600 ml-auto text-xl pb-2"><span v-if="Layout.dataset.manage?.tagsCounter?.count">{{ this.helper.formatScore(Layout.dataset.manage?.tagsCounter?.count) }}</span><span v-else>0</span></span>
                              </div>
                              <div class="flex items-center border-b border-gray-200 pb-2 pt-5">
                                <div><div class="text-gray-600">{{ $t('Tags') }}</div><div class="mt-1 text-xs text-blue-400 cursor-pointer" @click="Layout.tab='labels'">{{ $t('manage') }}</div></div>
                                <span v-if="Layout.dataset.manage.type=='MULTICLASS' && Layout.dataset.manage.tagsCounter?.tags && Object.keys(Layout.dataset.manage.tagsCounter.tags).length>1" class="text-gray-600 ml-auto text-xl pb-2">
                                  {{ Object.keys(Layout.dataset.manage?.tagsCounter?.tags).length }} 
                                </span>
                                <span v-else-if="Layout.dataset.manage.type=='MULTILABEL' && Layout.dataset.manage.tagsCounter?.tags && Object.keys(Layout.dataset.manage.tagsCounter.tags).length>1" class="text-gray-600 ml-auto text-xl pb-2">
                                  {{ Object.keys(Layout.dataset.manage?.tagsCounter?.tags).length }} 
                                </span>
                                <span v-else-if="Layout.dataset.manage.type=='imageObjectDetection' && Layout.dataset.manage.tagsCounter?.tagslabeled && Object.keys(Layout.dataset.manage.tagsCounter.tagslabeled).length" class="text-gray-600 ml-auto text-xl pb-2">
                                  {{ Object.keys(Layout.dataset.manage?.tagsCounter?.tagslabeled).length }}
                                </span>
                                <span v-else class="text-gray-600 ml-auto text-xl pb-2">0</span>
                              </div>
                              <div class="flex items-center pt-5">
                                <div><div class="text-gray-600">{{ $t('Models') }}</div><div class="mt-1 text-xs text-blue-400 cursor-pointer" @click="Layout.tab='models'">{{ $t('manage') }}</div></div>
                                <span class="text-gray-600 ml-auto text-xl pb-2" v-if="Layout.dataset.manage?.models?.count">{{ Layout.dataset.manage?.models?.count }}</span>
                                <span v-else class="text-gray-600 ml-auto text-xl pb-2" @click="Layout.tab='models'">0</span>
                              </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center p-10">
                              <div class="text-xs text-center">{{ $t('Analyzing the dataset') }}</div>
                              <LoadingIcon icon="three-dots" class="w-8 h-8" />
                            </div>
                        </div>

                      </div>
                    </div>
                  </div> 
                  <div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="Layout.tab=='images'">
                    <DropzoneComponent :dataset="Layout.dataset.manage.id" ref="DropzoneComponent" class="m-10 mt-5" />
                  </div>
                  <div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="Layout.tab=='labels'">
                    <DatasetTags :dataset="Layout.dataset.manage.id" ref="DatasetTags" />
                  </div>
                  <div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="Layout.tab=='models'">
                    <DsModels :dataset="Layout.dataset.manage.id" ref="DsModels" />
                  </div>
                  <div class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab" v-if="Layout.tab=='tools'">
                    <CopyDataset :dataset="Layout.dataset.manage.id" ref="CopyDataset" />
                  </div>
                </div>
                <div class="col-span-12 text-gray-600 mt-5 p-5 pl-0 font-italic text-xs border-t border-gray-200">
                  <span v-if="Layout.dataset.manage.updatedDate">{{$t('Dataset updated on')}} {{Layout.dataset.manage.updatedDate}}</span>
                  <span class="text-red-400 cursor-pointer pl-2" v-if="Layout.tab=='overview'" @click="Layout.dataset.delete = Layout.dataset.manage;Modal('dataset-delete-box')"><Trash2Icon class="w-4 h-4 mr-1" />{{$t('delete dataset')}}</span>
                  <span class="float-right text-gray-600 font-italic text-right text-xs">{{$t('Dataset')}} {{ Layout.dataset.idx+1 }} {{$t('of')}} {{ Object.keys(_datasets).length }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Create --> 
    <div id="dataset-create-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
          <div class="modal-content">
              <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-12 p-10 pt-5" v-if="Layout.loading.create">
                <div class="flex items-center justify-center mt-5"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-28" /></div>
                <LoadingIcon icon="three-dots" class="w-10 h-10" />
              </div>
              <div v-else>
                <div class="modal-header p-5 pt-3">
                  <h2 class="intro-y text-xl font-medium">{{ $t('New dataset') }}</h2>
                  <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2" data-dismiss="modal"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
                </div>
                <div class="modal-body mt-0 pt-0">
                  <div class="pr-1">
                    <div class="box px-0 py-3">
                        <form ref="newDataset" class="validate-form" @submit.prevent="addDataset('newDataset',$event)">
                          <div class="text-normal mb-2 font-bold">{{ $t('Dataset name') }}</div>
                          <div class="relative text-gray-700 dark:text-gray-300">
                            <input type="text" name="dsname" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10 " :value="'untitled_'" required />
                            <div class="text-xs pt-2">{{ $t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.</div>
                          </div>
                          <div class="text-normal mt-3 font-bold">{{ $t('Select the objective of your model') }}</div>
                          <select name="dstype" class="form-select mt-3 sm:mr-2 cursor-pointer bg-gray-200 w-full">
                            <option value="MULTICLASS">{{$t('Image classification with a single label')}}</option>
                            <option value="MULTILABEL">{{$t('Image classification with multiple labels')}}</option> 
                            <option value="imageObjectDetection">{{$t('Vision Object Detection')}}</option>
                          </select>
                          <button type="submit" class="btn btn-primary w-full mt-5 py-4 font-normal">{{$t('Create dataset')}}</button>
                        </form>
                      </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
    </div> 

    <!-- Delete dataset --> 
    <div id="dataset-delete-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
            <div class="modal-body p-5 text-center">
                <div class="p-5 px-0 text-center" v-if="Layout.dataset.delete">
                    <div class="text-xl">{{  Layout.dataset.delete.name }}</div>
                    <div class="text-base mt-2">{{ $t('Are you sure to delete this dataset') }}?</div>
                    <div class="text-xs mt-2" v-if="Layout.dataset.delete.trained">{{ $t('There are models associated with this dataset') }}</div>
                    <div class="text-gray-600 mt-2 text-xs">{{ $t('This action is irreversible') }}.</div>
                </div>
                <div class="px-5 pb-5 text-center">
                    <button type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1">{{ $t('Cancel') }}</button>
                    <button type="button" class="btn bg-red-500 text-white w-24" @click="deleteDataset()">{{ $t('Delete') }}</button>
                </div>
              </div>
          </div>
      </div>
    </div>

    <!-- Edit dataset description --> 
    <div id="dataset-edit-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
          <div class="modal-content p-5 pb-2" v-if="Layout.dataset.edit">
            <div class="modal-header p-5 pt-3 pb-2">
              <h2 class="intro-y text-xl font-medium">{{ $t('Dataset profile') }}</h2>
              <label class="form-label  :hover:text-blue-500 cursor-pointer float-right ml-auto" data-dismiss="modal"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
            </div>
            <div class="relative text-gray-700 dark:text-gray-300 px-5 pt-3">
              <div class="text-normal mb-2 font-bold">{{ $t('Name') }}</div>
              <input type="text" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10" v-model="Layout.dataset.edit.name"  />
              <div class="text-xs pt-2 text-gray-600 text-left">{{ $t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.</div>
            </div>
            <div class="relative text-gray-700 dark:text-gray-300 px-5 pt-5 pb-5">
              <div class="text-normal mb-2 font-bold">{{ $t('A brief description of the goal') }}</div>
              <textarea v-model="Layout.dataset.edit.description" class="form-control h-32 rounded border text-gray-900 p-2 bg-gray-200 text-gray-600" :style="'font-size: 13px'" :placeholder="$t('add a description of the dataset goal...')"></textarea>
            </div>
            <div class="px-5 pb-5 text-center">
              <button class="btn box bg-green-600 text-white hover:bg-green-800 px-12 py-2 font-normal  text-center" @click="save()">{{$t('SAVE')}}</button>
            </div>
          </div>
      </div>
    </div> 
   
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, reactive, watch } from "vue";
import { helper as $h } from "@/utils/helper";
import { useRouter } from "vue-router";
import cash from "cash-dom";
import * as rosepetalModel from "rosepetal-model";
import DatasetTags  from "@/components/dataset-tags/Main.vue" 
import DsModels from "@/components/manage-datasets-models/Main.vue";
import DropzoneComponent  from "@/components/dropzone/Main.vue";
import imageEditor  from "@/components/manage-image/Main.vue";
import CopyDataset from "@/components/manage-datasets/copy-dataset.vue";

export default defineComponent({
  components: {
    DatasetTags,
    DsModels,
    CopyDataset,
    DropzoneComponent,
    imageEditor,
  },
  created () {
    this.helper  = $h
  },  
  setup() {
    const Layout          =   ref({
                               tab:    "overview",
                               loading: { create: false , list: false, manage: false },
                               dataset: { manage: false , delete: false , idx: 0, mosaic: false, edit: false },
                               editor:  { options: {} },
                              })   

    const router          = useRouter();
    const datasets        = ref(false)
    const _datasets       = ref(false)
    const listOptions     = reactive({})
    
    const Modal = (m) => { if(cash("#"+m)){ cash("#"+m).modal("show"); /*cash(".main").removeClass("overflow-y-hidden")*/}}

    const manageDataset = async (d , tab = false, modal = true) => {
      let dsObj = Object.values(_datasets.value).findIndex(dataset => dataset.id === d.id)
      if(dsObj !== -1)Layout.value.dataset.idx = dsObj
      Layout.value.loading.manage                 = true
      if(modal)Modal('dataset-manage')
      Layout.value.editor.options                 = { 'format': 'mosaic', 'dataset': d.id, 'limit': 10 }
      //navigation
      let currentUrl   = window.location.pathname
      currentUrl       = currentUrl.replace("/", "");
      let _cDs         = currentUrl.toString().split('/')
      if(_cDs[0] === 'datasets' && _cDs[2]){ 
        if(Layout.value.dataset.manage.id){
            if(Layout.value.dataset.manage.id==d.id){
              router.push('/datasets/'+d.id+'/'+_cDs[2]); 
              Layout.value.editor.options.id = _cDs[2]
            }
        }else{
          router.push('/datasets/'+d.id+'/'+_cDs[2]); 
          Layout.value.editor.options.id = _cDs[2]
        }
      }else{ router.push('/datasets/'+d.id) }
      //navigation

      Layout.value.dataset.manage                 = {}
      Layout.value.tab                            = "overview"
      Layout.value.dataset.manage                 = d
      if(d.type)Layout.value.editor.options.type  = d.type
      Layout.value.loading.manage                 = false
      if(tab)Layout.value.tab                     = tab


      Layout.value.dataset.manage.tagsCounter     = await rosepetalModel.dataset.getTagsCounter(d.id, true, { dataset: d })
      if(document.getElementById("fixedMosaicBox") && document.getElementById("dataset-manage-body"))cash('#fixedMosaicBox').insertBefore(document.getElementById("dataset-manage-body"))
      
      Layout.value.dataset.manage.datasetStatus   = await rosepetalModel.dataset.getStatus(d.id) 
      Layout.value.dataset.manage.models          = await rosepetalModel.dataset.getModels(d.id) 

      await generateResume()
    }

    const generateResume = async () => { 
      Layout.value.dataset.manage.resumeTxt = await rosepetalModel.dataset.generateResume(Layout.value.dataset.manage.id, { tagsCounter: Layout.value.dataset.manage.tagsCounter, models: Layout.value.dataset.manage.models })
    }

    const deleteDataset = async () => { 
      cash("#dataset-delete-box").modal("hide"); 
      await rosepetalModel.dataset.delete(Layout.value.dataset.delete.id).then( async () => { 
        $h.NotificationTxt({ text: "Dataset successfully deleted"});
        await reset().then( async () => { router.push('/datasets') }) 
      })
    }

    const save = async () => {
      if(Layout.value.dataset.edit){
        cash('#dataset-edit-box').modal('hide') 
        Layout.value.loading.manage = true
        await rosepetalModel.dataset.update( Layout.value.dataset.edit.id, { name: Layout.value.dataset.edit.name, description: Layout.value.dataset.edit.description }).then( async () => { 
          $h.NotificationTxt({ text: "Dataset profile updated"});
          Layout.value.dataset.edit = {}
          await load().then( async () => { await manageDataset(_datasets.value[Layout.value.dataset.idx]) })
          Layout.value.loading.manage = false
         })
      }
    }

    const edit = async () => { Modal('dataset-edit-box'); Layout.value.dataset.edit = { id: Layout.value.dataset.manage?.id, name: Layout.value.dataset.manage?.name, description: Layout.value.dataset.manage?.description }}
    const reset = async () => { cash("#dataset-manage").modal("hide"); listOptions.value = { type: "", trained: "", limit: false, order: "dateDesc", preview: false, filter: true} }  
    const labeling = async (d) => { 
      manageDataset(d).then( () => { 
        setTimeout(() => { 
          if(document.getElementById('BtnDatasetLabeling')){  document.getElementById('BtnDatasetLabeling').click(); router.push('/datasets/'+d.id+'/labeling') }
        }, 200)
      })
    }  

    const prev = async () => {
      let idx = Layout.value.dataset.idx
      if((idx-1)>=0)idx--
      Layout.value.dataset.idx = idx
      await manageDataset(_datasets.value[idx])
    }

    const next = async () => {
      let idx = Layout.value.dataset.idx
      if((idx+1)<Object.keys(_datasets.value).length)idx++
      Layout.value.dataset.idx = idx
      await manageDataset(_datasets.value[idx])
    }

    const load = async () => {
      Layout.value.loading.list = true
      _datasets.value           =  await rosepetalModel.dataset.list(listOptions.value)
      Layout.value.loading.list = false

      if(router.currentRoute.value.params.id)await loadDataset(router.currentRoute.value.params.id)
      
      if(_datasets.value.length){
        for(let i=0; i<_datasets.value.length; i++){
          if(_datasets.value[i]){
            let ImageGetPreview = await rosepetalModel.dataset.getPreview(_datasets.value[i].id)
            if(ImageGetPreview.preview && _datasets.value[i])_datasets.value[i].preview = ImageGetPreview.preview
          }
        }/*
        for(let i=0; i<_datasets.value.length; i++){
          if(_datasets.value[i]){
            let ImageCounter    = await rosepetalModel.dataset.getTagsCounter(_datasets.value[i].id, true)
            if(ImageCounter.count)_datasets.value[i].count = ImageCounter.count
          }
        }*/
      }
        
    }

    watch( listOptions , async () => { 
      await load() 
    });

    onMounted( async () => {  
      await reset() 
      if(!router.currentRoute.value.params.id)router.push('/datasets/')
      cash("body").css("overflow-y", "hidden");
    });

    onUnmounted( async () => { 
      if(cash("#dataset-manage"))cash("#dataset-manage").remove();
      Layout.value.dataset.idx = 0
      cash("body").css("overflow-y", "scroll");
     });

    const loadDataset = async (datasetId) => {
      let dsObj= Object.values(_datasets.value).findIndex(dataset => dataset.id === datasetId)
      if(dsObj !== -1){
        Layout.value.dataset.idx = dsObj
        await manageDataset(_datasets.value[Layout.value.dataset.idx])
      }
    }

    window.onpopstate = async function() {
      let currentUrl   = window.location.pathname
      currentUrl       = currentUrl.replace("/", "");
      let _cDs         = currentUrl.toString().split('/')
      console.log('onpopstate')
      if(_cDs[0] && _cDs[0] === 'datasets'){
        if (!_cDs[1]){ cash('#dataset-manage,#modal-image-editor').modal('hide')  }//await reset()
        else if(_cDs.length==2){
          cash('#modal-image-editor').modal('hide'); Layout.value.editor.options.id = false
          if(_cDs[1] !== Layout.value.dataset.manage.id)await loadDataset(_cDs[1]);
        } 
        else console.log(_cDs)
      } 
    }

    return {
      Layout,
      router,
      _datasets,
      datasets,
      DatasetTags,
      manageDataset,
      deleteDataset,
      Modal,
      load,
      reset,
      listOptions,
      edit,
      save,
      labeling,
      next,
      prev
    };
  },
  methods:{
    refreshMosaic: async function(){ this.$refs['imageEditorComponent'].reLoad() },
    refreshDataset: async function(){ await this.manageDataset(this.Layout.dataset.manage) },
    goDataset: async function(ds){ await this.manageDataset(ds, false, false) },
    addDataset: async function(formID,event){
      if (event) event.preventDefault()
      this.Layout.loading.create = true
      if(this.$refs[formID].length){
        let displayName = "";
        let type = ""
        for (let i = 0; i < this.$refs[formID].length; i++) {  
          if(this.$refs[formID][i].name=="dsname")displayName = this.$refs[formID][i].value.toLowerCase();
          if(this.$refs[formID][i].name=="dstype")type = this.$refs[formID][i].value
        }  
        if(displayName)await rosepetalModel.dataset.create({ name: displayName, type: type }).then( async response => {
          if(response.dataset){
            await rosepetalModel.dataset.createVertex(response.dataset).then( async resp => {
              cash("#dataset-create-box").modal("hide");
              console.log('Dataset created: ', response, resp)
              this.Layout.loading.create = false
              await this.reset()
            });
          }
        });
      }
    },
  }
});
</script>
<style>
/*body{overflow: hidden}*/

#dataset-manage-page{position: relative;overflow-y: scroll;height: 100vh; -ms-overflow-style: none; scrollbar-width: none;}
#dataset-manage-page::-webkit-scrollbar{display: none;}

.rpalert1{ padding: 20px 15px 20px 15px !important; background-color: #fff8ec !important;}
.rpalert2{ padding: 10px 0 !important; background-color: #FFCDD2 !important;}
.rpalert3{ padding: 2px 0 !important; background-color: #fff8ec !important;}
.rpalert4{ padding: 2px 0 !important; background-color: #f2f2f2 !important;}
.rpalert5{ padding: 20px 15px 20px 15px !important; background-color: #fff !important; border-left: 3px solid #ff0000}
.rpalert5 .btn{ border: 1px solid #202020 !important}
#dataset-manage{ background-color: #f2f3f3 !important;}
#dataset-manage .modal-dialog{ min-width: 900px !important; }
#dataset-manage .nav.nav-tabs .active {
  --tw-border-opacity: 1;
  background-color: #1a73e8;
  border-color: #1a73e8;
  font-weight: 500;
  color: #fff;
}
</style>