import { _firebase as $_firebase } from "@/model/firebase";
import config from '../etc/rosepetal.json';

//COCO SSD
require('@tensorflow/tfjs-backend-cpu')
require('@tensorflow/tfjs-backend-webgl')
const tf  = require("@tensorflow/tfjs");
const cocoSsd   = require('@tensorflow-models/coco-ssd')

const functions = $_firebase.firebase().app().functions(config.firebaseRegion);

const ml = {
    async detect(img) {

        if (img.objApi=="vision") {
            let predictions = []
            const action = functions.httpsCallable('api/image/detect/image/' + img.gsUri.replace(/\//g, "--"));
            await action({}).then(async response => {
                if (response.data) {
                    predictions = []
                    response.data.forEach((p) => {
                        let bbp = p;
                        bbp.bbox[0] = bbp.bbox[0] * img.width  //left
                        bbp.bbox[1] = bbp.bbox[1] * img.height //top
                        bbp.bbox[2] = bbp.bbox[2] * img.width  //width
                        bbp.bbox[3] = bbp.bbox[3] * img.height //height
                        predictions.push(bbp)
                    })
                }
            }).catch(async (error) => { console.log(error) });
            return predictions
        }

        if (img.objApi == "coco") {
            if (!(img instanceof tf.Tensor)) img = tf.browser.fromPixels(img);
            const model = await cocoSsd.load().catch(async (error) => { console.log(error) });
            const predictions = await model.detect(img).catch(async (error) => { console.log(error) });
            console.log('detect ml', predictions)
            return predictions
        }

        
    },
};

const install = app => {
    app.config.globalProperties.$tags = ml;
};

export { install as default, ml as ml };
