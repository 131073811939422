<template>
  <div class="w-full">

     <!-- MOSAIC BOTTOM
     <div class="fixedMosaicBoxBottom rounded" v-if="props.options.format=='mosaicTag' && !layout.mosaic.min">
          <div class="w-full py-2 px-5 rounded border" v-if="layout?.mosaic?.images?.media?.length">
              <label class="form-label pt-2">{{$t('Example of the associated images to this tag')}}</label>
              <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer " @click="layout.mosaic.min=true" ><XIcon class="w-8 h-8 hover:text-blue-500" /></label>
              <div class="border-2 border-dashed rounded-md mt-1">
                  <div class="flex flex-wrap pl-3 pt-3 w-full fixedMosaicBoxContentBottom ml-1 mt-1" v-if="layout?.mosaic?.images?.media">
                     <div v-for="(i) in layout?.mosaic?.images?.media" :key="i" class="w-1/6 mb-3 mr-6 cursor-pointer zoom-in">
                        <div :id="'ThumbcanvasBox_'+props.options.format+'_'+i.id" class="bg-white mosaicCanvas cursor-pointer" :style="'width: 100% !important; overflow: hidden !important;'"
                         @click="editImage(i.id)"></div>
                        <Tippy tag="div" content="Remove?" class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-24 right-0 top-0 -mr-2 -mt-2" @click="layout.action.delete=i;modal('modal-delete-image-box')">
                            <xIcon class="w-4 h-4" />
                        </Tippy>
                        <div class="text-xs mt-1 ml-1" v-if="i?.createdDate">{{ i?.createdDate }}</div>  
                    </div>
                  </div>
              </div>
          </div>
     </div>
     /Mosaic tag bottom -->

     <!-- MOSAIC LEFT -->
     <div id="fixedMosaicBox" class="fixedMosaicBox" v-if="props.options.format=='mosaic'" >
        <div class="w-full py-3 px-3 rounded border"  :class="!layout.mosaic.min ? 'h-full' : ''">
            <div class="mb-1">
              <label class="form-label pt-0.5">{{$t('Last images')}}</label>
              <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="layout.mosaic.min=false"  v-if="layout.mosaic.min"><EyeIcon class="w-6 h-6 hover:text-blue-500" /></label>
              <label v-else class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" @click="layout.mosaic.min=true"><XIcon class="w-6 h-6" /></label>
              <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer mr-1.5" @click="labeling()" ><Maximize2Icon class="w-4 h-4" /></label>
            </div>
            <div class="grid grid-cols-12 w-full mb-1 p-3" v-if="!layout.mosaic.min">
              <div class="col-span-12 mb-1" v-if="layout.mosaic.dataset && layout.mosaic.dataset.type=='imageObjectDetection'">
                <select class="w-full form-select p-1 pl-2 cursor-pointer text-gray-600 mt-1 text-xs" v-model="layout.mosaic.orderObj" @change="loadMosaicImages({ filters: true })">
                  <option value="labeled" v-if="layout.dataset.tags && Object.keys(layout.dataset.tags).length">{{$t('Labeled')}}</option>
                  <option value="nolabel" >{{$t('Unclassified')}}</option>
                </select>
              </div>
              <div class="col-span-12" 
                :class="layout.mosaic.dataset && layout.mosaic.dataset.type=='imageObjectDetection' && layout.mosaic.orderObj=='nolabel' ? 'hidden' : ''">
                <select class="w-full form-select p-1 pl-2 cursor-pointer bg-white text-gray-600 text-xs" v-model="layout.mosaic.tagId" @change="loadMosaicImages({ filters: true })">
                  <option value="all">{{$t('All')}}</option>
                  <option v-for="(t) in layout.dataset.tags" :value="t.id" :key="t.id">{{ t.name }}</option>
                </select>
              </div>
              <div class="col-span-7">
                <select class="w-full form-select p-1 pl-2 cursor-pointer bg-white text-gray-600 mt-1 text-xs" v-model="layout.mosaic.order" @change="loadMosaicImages({ filters: true })">
                  <option value="created">{{$t('Created')}}</option>
                  <template v-if="layout.mosaic.dataset && layout.mosaic.dataset.type=='imageObjectDetection' && layout.mosaic.orderObj=='nolabel'"></template>
                  <template v-else><option value="updated">{{$t('Updated')}}</option></template>
                </select>
              </div>
              <div class="col-span-5 ml-1">
                <select class="w-full form-select p-1 pl-2 cursor-pointer bg-white text-gray-600 mt-1 text-xs" v-model="layout.mosaic.limit" @change="loadMosaicImages({ filters: true })">
                  <option v-for="i in 100" :value="i" :key="i">{{i}}</option>
                </select>
              </div>
            </div>
            <div class="border-2 border-dashed rounded-md mt-2" v-if="!layout.mosaic.min">
                <div class="flex flex-wrap pt-3 w-full fixedMosaicBoxContent">
                    <div v-if="layout?.mosaic?.loading" class="w-60 mx-auto">
                        <div class="w-full py-4"><div class="flex items-center justify-center mx-auto"><img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-12" /></div>
                        <div class="text-center text-xs text-gray-500">{{$t('Waiting images')}}</div>
                      </div>
                    </div>    
                    <div v-else-if="!layout?.mosaic?.images?.media?.length" class="text-center w-full">

                      <div class="mt-2 text-sm mb-3 text-gray-600">{{$t('No contains images')}}</div>
                      <div class="mt-2">
                        <button type="button" class="btn w-32 bg-gray-600 text-white zoom-in font-normal" @click="dsTabImages(); layout.mosaic.min=true" >{{ $t('Upload') }}</button>
                      </div>
                    </div>
                    <div v-else class="text-center ml-3 w-full">
                      <div v-for="(i) in layout?.mosaic?.images?.media" :key="i" class="relative mb-3 mr-4 cursor-pointer zoom-in">
                          <div :id="'ThumbcanvasBox_'+props.options.format+'_'+i.id" class="bg-white mosaicCanvas cursor-pointer"  @click="editImage(i.id)"></div> <!--:style="'width: 100% !important; overflow: hidden !important;'"-->
                          <Tippy tag="div" content="Remove?" class="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-24 right-0 top-0 -mr-2 -mt-2" @click="layout.action.delete=i;modal('modal-delete-image-box')">
                              <xIcon class="w-4 h-4" />
                          </Tippy>
                          <div class="text-xs mt-1 ml-1" v-if="layout.mosaic.order && layout.mosaic.order=='created' && i?.createdDate">{{ i?.createdDate }}</div>  
                          <div class="text-xs mt-1 ml-1" v-else-if="layout.mosaic.order && layout.mosaic.order=='updated'">
                            {{ i?.updatedDate ? i?.updatedDate : i?.createdDate }}
                          </div>
                          <!-- 
                          <div v-if="i?.createdDate" class="text-xs">i: {{ i?.createdDate }}</div>
                          <div v-if="i?.updatedDate" class="text-xs" >u: {{ i?.updatedDate }}</div>
                           -->
                      </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="mt-4 mb-4" v-if="layout.mosaic?.dataset?.id">
              <div class="py-2 rounded-md text-center bg-blue-500 text-white w-full cursor-pointer border" @click="setImagesUpdatedDate(layout.mosaic.dataset.id)">{{$t('Update updatedAt')}}</div>
            </div>
            -->
        </div>
    </div>

    <!-- LABELING BUTTON -->
    <button v-if="layout.mosaic?.dataset?.id && props.options.format=='mosaic'" id="BtnDatasetLabeling" type="button" class="btn w-24 bg-gray-100 text-gray-600 zoom-in font-normal py-1 mr-1 hidden" @click="labeling()">
      <ImageIcon class="w-6 h-6 mr-1" /> <span>{{$t('Labeling')}}</span>
    </button>
    
     <!-- MODAL DELETE IMAGE --> 
     <div id="modal-delete-image-box"  class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
              <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
              <div class="modal-body p-5 text-center">
                  <div class="p-5 px-2 text-center" v-if="layout.action.delete">
                      <div class="text-base mt-2">{{ $t('Are you sure to delete this image') }}?</div>
                      <div class="text-red-700 text-sm" v-if="layout.action.delete?.fileName">{{ layout.action.delete?.fileName[1] }}</div>  
                      <div class="text-xs mt-1" v-if="layout.action.delete?.tags && layout.action.delete?.tags.length">
                        {{ $t('Labeled') }}:
                        <span v-for="(tag, index) in layout.action.delete.tags" :key="index">
                          <span class="text-xs mt-1 mr-2" v-if="tag.tag.path">{{ tag.tag.path.toString().split('/').pop() }}</span>
                        </span>  
                      </div> 
                      <div v-else><div class="text-xs mt-1" v-if="layout.action.delete?.tagName && layout.action.delete?.tagName[3]!='0'">{{ $t('Labeled') }}: {{ layout.action.delete?.tagName[3] }}</div></div>  
                      <div class="text-xs mt-1" v-if="layout.action.delete?.set">{{ $t('Data division') }}: {{ layout.action.delete?.set }}</div>  
                      <div class="text-xs mt-1" v-if="layout.action.delete?.createdDate">{{ $t('Created') }}: {{ layout.action.delete?.createdDate }}</div>  
                      <!-- <img class="rounded-md w-1/2 mx-auto my-5" v-bind:src="'data:image/png;base64,'+layout.action.delete.img_base64_val" /> --> 
                      <div class="text-gray-600 mt-1">{{ $t('This action is irreversible') }}.</div>
                  </div>
                  <div class="px-5 pb-3 text-center">
                      <button type="button" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in" data-dismiss="modal" @click="editImage(layout.action.delete.id)">{{ $t('Edit') }}</button>
                      <button type="button" class="btn bg-red-700 text-white w-24 zoom-in" data-dismiss="modal" @click="deleteImage()">{{ $t('Delete') }}</button>
                  </div>
                </div>
            </div>
        </div>
     </div>

    <!-- MODAL COPY IMAGE --> 
    <div id="modal-copy-image-box"  class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-md">
          <div class="modal-content">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal" @click="layout.action.copy.to=false" v-if="!layout.action.copy.loading"><XIcon class="w-8 h-8" /></label>
            <div class="modal-body p-5 pt-0 text-center">
                  <div class="p-5 pt-3 px-2 text-left">
                  <div class="text-lg" v-if="!layout.action.copy.loading && !layout.action.copy.success">{{ $t('Make a copy of the image') }}</div>  
                    <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center" v-if="layout.action.copy.loading"> 
                      <div class="mt-8"><div class="flex items-center justify-center"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div></div>
                      <div class="w-8 h-8 ml-2 mt-1"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
                    </div>
                    <div v-else-if="layout.action.copy.success" class="text-center w-full">
                      <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-8"><img :src="require(`@/assets/images/rosepetal/icon/ok.png`)" class="w-20"></div>
                      <div class="text-center mt-5">{{ $t('Image copied successfully') }}</div>
                      <button type="button" class="btn btn-outline-secondary w-24 mt-5 mr-1 h-10 zoom-in" data-dismiss="modal" 
                      @click="layout.action.copy.to=false; modalClose('modal-copy-image-box'); goDataset(layout.action.copy.toDataset); editImage(layout.action.copy.toEdit)">{{ $t('Edit') }}</button>
                    </div>
                    <div v-else>
                      <div v-if="layout.action.copy.validate && layout.action.copy.error" class="text-red-700 text-sm mt-3 text-center">{{ layout.action.copy.error }}</div>
                      <div class="text-left mt-5 font-medium">{{ $t('Select destination') }}</div>
                      <select class="form-select mt-3 cursor-pointer bg-white w-full bg-gray-100" v-model="layout.action.copy.to">
                        <option :value="false">- {{ $t('Select the destination dataset') }} -</option>
                        <option v-for="(ds) in layout.datasets" :value="ds.id" :key="ds.id">{{ ds.name }} ({{ ds.createdDate }})</option>
                      </select>
                      <div class="pt-2 text-xs text-left pl-3" v-if="layout.action.copy.to">
                        {{ layout.datasets.filter(ds => ds.id == layout.action.copy.to)[0]?.typeName }}
                        <span>/ {{ $t('Created') }}: {{ layout.datasets.filter(ds => ds.id == layout.action.copy.to)[0]?.createdDate }}</span>
                      </div>
                      <div v-if="layout.action.copy.to && layout.action.copy?.toTags && Object.keys(layout.action.copy?.toTags).length">
                        <div class="text-left mt-3 font-medium">{{ $t('Tag') }}</div>
                        <select class="form-select mt-3 cursor-pointer bg-white w-full bg-gray-100" v-model="layout.action.copy.toTag">
                          <template v-if="layout.datasets.filter(ds => ds.id == layout.action.copy.to)[0]?.type=='MULTICLASS'">
                            <option v-for="(key, i) in Object.keys(layout.action.copy?.toTags)" :value="key" :key="i">
                              {{ layout.action.copy?.toTags[key].name}} ({{ layout.action.copy?.toTags[key].imageCounter ? layout.action.copy?.toTags[key].imageCounter : 0}})
                            </option>
                          </template>
                        <template v-else>
                          <option value="0">{{ $t('Unclassified')}} ({{ layout.action.copy?.toTags["0"].imageCounter ? layout.action.copy?.toTags["0"].imageCounter : 0 }})</option>
                        </template>
                        </select>
                        <template v-if="layout.datasets.filter(ds => ds.id == layout.action.copy.to)[0]?.type == 'MULTICLASS' && layout.action.edit.type=='MULTICLASS'">
                          <div class="text-left mt-3 font-medium">{{ $t('Labeling') }}</div>
                          <select class="form-select mt-3 sm:mr-2 cursor-pointer bg-white w-full bg-gray-100" v-model="layout.action.copy.toMasks">
                            <option :value="false">{{ $t('Not include the mask, only image')}}.</option>
                            <option :value="true">{{ $t('Include labeling masks')}}</option>
                          </select>
                        </template>
                      </div>
                    </div>
                </div>
                <div class="px-5 pb-3 text-center mt-1" v-if="!layout.action.copy.loading && !layout.action.copy.success">
                    <button type="button" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in" data-dismiss="modal">{{ $t('Cancel') }}</button>
                    <button type="button" class="btn bg-green-500 text-white w-24 mr-1 h-10 zoom-in"  @click="copyImage()" v-if="layout.action.copy.validate">{{ $t('Copy') }}</button>
                </div>
              </div>
          </div>
      </div>
    </div> 

    <!-- IMAGE EDIT -->
    <div id="modal-image-editor" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-sm modal-image-editor">
        <div class="modal-body p-0 bg-gray-200 h-full">
          <div class="bg-gray-500 h-full overflow-hidden" v-if="layout.action.edit">
            <div class="grid grid-cols-12 h-full">
              <div class="col-span-12 sm:col-span-7 lg:col-span-9 xxl:col-span-10">
                <template v-if="!layout.canvas.loading">
                  <div id="muticlass-image-mode-top" class="text-center editorTopTool"><span v-if="layout.canvas.drawing">{{ $t('Drawing mode')}}</span><span v-else>{{ $t('Position mode')}}</span></div>
                  <div id="editor-zoom-top" class="editorTopTool" v-if="layout.canvas.tools.zoom"><Zoom-inIcon class="w-4 h-4 mr-1" />{{ parseFloat(parseFloat(layout.canvas.tools.zoom)*100).toFixed(0) }}%</div>
                  <div id="muticlass-image-date-top" class="editorTopTool" v-if="layout.action.edit?.createdDate">{{ layout.action.edit?.createdDate }}</div>
                  <div id="muticlass-image-size-top" class="editorTopTool"  v-if="layout.editMap[layout.dataset.id] && layout.editMap[layout.dataset.id].nav?.current && layout.editMap[layout.dataset.id].nav?.current?.KB"><ImageIcon class="w-4 h-4 mr-1" />{{ layout.editMap[layout.dataset.id].nav?.current?.KB }} KB</div>
                  <div id="muticlass-image-name-top" class="editorTopTool">{{ layout.action.edit?.name }}</div>
                </template>
                <div id="canvasImageTools" class="px-3 py-3 canvasImageTools" :class="layout.canvas.loading ? 'hidden' : ''" :style="'position: fixed; cursor: move; z-index: 999; background-color: #f7f8f9;width: 180px;border-radius: 8px; box-shadow: #999 3px 3px 3px; top: 50px; left: 15px'">
                  <div class="grid grid-cols-12">
                    <div class="col-span-12 text-center text-xs pb-2 bg-gray-200 py-1 mb-1">
                      <span v-if="layout.action.edit.type=='MULTICLASS'">{{ $t('Single label') }}</span>
                      <span v-else-if="layout.action.edit.type=='MULTILABEL'">{{ $t('Multiple labels') }}</span>
                      <span v-else-if="layout.action.edit.type=='imageObjectDetection'">{{ $t('Object detection') }}</span>
                    </div>
                    <!-- CLASIFICATION TOOLS -->
                    <div class="col-span-12" v-if="layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL'">
                      <div class="grid grid-cols-12">
                        <div class="col-span-12 text-left mb-3">
                          <div class="grid grid-cols-12">
                            <div @click="layout.canvas.tools.modalBrush.type='move'; layout.canvas.drawing=false" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer" title="Move" :class="layout.canvas.tools.modalBrush.type=='move' ? 'bg-blue-300 rounded' : ''">
                              <img :src="require(`@/assets/images/rosepetal/icon/hand.png`)" class="w-6 ml-4" />
                            </div>
                            <div @click="layout.canvas.tools.modalBrush.type='line'; layout.canvas.drawing=true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer ml-1" title="Pencil" :class="layout.canvas.tools.modalBrush.type=='line' ? 'bg-blue-300 text-white rounded' : ''">
                              <img :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-6 ml-4" />
                            </div>
                            <div @click="layout.canvas.tools.modalBrush.type='erase'; layout.canvas.drawing=true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer mt-1" title="Erase" :class="layout.canvas.tools.modalBrush.type=='erase' ? 'bg-blue-300 text-white rounded' : ''">
                              <img :src="require(`@/assets/images/rosepetal/icon/erase.png`)" class="w-6 ml-4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-12" v-if="layout.action.edit.type=='imageObjectDetection'">
                      <div class="grid grid-cols-12">
                        <div class="col-span-12 text-left mb-1">
                          <div class="grid grid-cols-12 p-2">
                            <div @click="layout.canvas.tools.modalObjMove=false; layout.canvas.tools.modalObjBB=false; layout.canvas.drawing=false" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer rounded" title="Move"
                                :class="!layout.canvas.tools.modalObjBB && !layout.canvas.tools.modalObjMove ? 'bg-blue-300 rounded' : ''">
                              <img :src="require(`@/assets/images/rosepetal/icon/hand.png`)" class="w-6 ml-4" />
                            </div>
                            <div @click="layout.canvas.tools.modalObjMove=true;layout.canvas.tools.modalObjBB=false; layout.canvas.drawing=true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer ml-1 rounded" title="Move Bounding" 
                              :class="layout.canvas.tools.modalObjMove ? 'bg-blue-300' : ''">
                              <MoveIcon class="w-6" />
                            </div>
                            <div @click="layout.canvas.tools.modalObjBB=true;layout.canvas.tools.modalObjMove=false; layout.canvas.drawing=true" class="col-span-6 p-2 bg-gray-300 text-center cursor-pointer mt-1 rounded" title="Draw" 
                              :class="layout.canvas.tools.modalObjBB ? 'bg-blue-300' : 't'">
                              <img :src="require(`@/assets/images/rosepetal/icon/bounding.png`)" class="w-6 ml-4" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-12">
                      <div class="col-span-12  text-left" v-if="layout.canvas.drawing && (layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL')">
                        <div class="p-3 border rounded">
                          <div class="col-span-12 text-left">
                            <div class="pb-2 text-xs">{{ $t('Size')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalBrush.size}}px</span></div>
                            <Slider v-model="layout.canvas.tools.modalBrush.size" class="mb-1" :value="0" :min="1" :max="50" :step="1" :merge="1" :tooltips="false" :lazy="true" />
                          </div>
                          <div class="col-span-12 text-left mt-2" 
                              v-if="layout.canvas.tools.modalBrush.type=='line' && layout.action.edit.type=='MULTILABEL' && layout.dataset.tags && Object.keys(layout.dataset.tags).length">
                              <div class="pb-2 text-xs"> {{ $t('Tag')}}</div>
                              <div v-for="(t) in layout.dataset.tags" :key="t.id" class="inline-block cursor-pointer align-top" :title="t.name"  @click="layout.action.edit.tag = 'dataset/'+layout.dataset.id+'/tag/'+t.id; refreshPropsCanvas('brushSize')"  >  
                                <div class="rounded display-inline text-center mr-0.5 h-6 w-6" 
                                  :style="t.color ? 'background-color:'+t.color+'' : ''">
                                  <img :class="layout.action.edit?.tag?.path && layout.action.edit?.tag.path.toString().split('/')[3]==t.id ? '' : layout.action.edit.tag.toString().split('/').pop()==t.id ? '' : 'hidden'" 
                                    :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-3 inline-block align-top mt-1.5" />
                                </div> 
                              </div>
                          </div>
                          <div class="col-span-12 text-left mt-2" v-if="layout.canvas.tools.modalBrush.type=='line' && layout.action.edit.type=='MULTICLASS'">
                              <div class="pb-2 text-xs"> {{ $t('Tag')}}</div>
                              <div v-for="(t) in layout.dataset.tags" :key="t.id" class="inline-block cursor-pointer align-top" :title="t.name"  @click="layout.action.edit.tag = 'dataset/'+layout.dataset.id+'/tag/'+t.id; refreshPropsCanvas('brushSize')"  >  
                                <div class="rounded display-inline text-center mr-0.5 mb-0.5 h-6 w-6" 
                                  :style="t.color ? 'background-color:'+t.color+'' : ''">
                                  <img :class="layout.action.edit?.tag?.path && layout.action.edit?.tag.path.toString().split('/')[3]==t.id ? '' : layout.action.edit.tag.toString().split('/').pop()==t.id ? '' : 'hidden'" 
                                    :src="require(`@/assets/images/rosepetal/icon/pencil.png`)" class="w-3 inline-block align-top mt-1.5" />
                                </div> 
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-span-12 mt-1 text-left" v-if="layout.canvas.canvas?._objects?.length || layout.canvas.tools.modalBrush.type=='line'">
                        <div class="pb-1 text-xs pl-1">{{ $t('Labels')}}</div>
                        <div class="p-3 border rounded">
                          <div class="col-span-12" v-if="(layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL') && (layout.canvas.tools.modalBrush.type=='line' || layout.canvas.canvas?._objects?.length)">
                            <div class="pb-2 text-xs">{{ $t('Opacity')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.maskOpacity * 100}}%</span></div>
                            <Slider v-model="layout.canvas.tools.maskOpacity" class="mb-3" :min="0.1" :max="1" :step="0.1" :merge="0.1" :tooltips="false" :lazy="true" />
                          </div>
                          <div class="col-span-12" v-if="layout.action.edit.type=='imageObjectDetection' && layout.canvas.canvas?._objects?.length">
                            <div class="pb-2 text-xs">{{ $t('Opacity')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.bbOpacity * 100}}%</span></div>
                            <Slider v-model="layout.canvas.tools.bbOpacity" class="mb-3" :min="0.1" :max="1" :step="0.1" :merge="0.1" :tooltips="false" :lazy="true" />
                          </div>
                          <div class="col-span-12">
                            <span v-if="layout.action.edit.type=='MULTICLASS' || layout.action.edit.type=='MULTILABEL'">
                              <button type="button" class="btn bg-red-400 text-white mt-2 display-inline text-xs text-white px-4 zoom-in  w-full" @click="removeMask()" 
                              v-if="layout.canvas.canvas?._objects?.length"><TrashIcon class="w-4 h-4 text-white" /> {{ $t('Remove labels')}}</button>
                              <button type="button" class="btn bg-blue-500 text-white mt-1 display-inline text-xs text-white px-5 w-full zoom-in" @click="layout.canvas.tools.showMask=false; initCanvas()" 
                                v-if="layout.canvas.canvas?._objects?.length && layout.canvas.tools.showMask"><Eye-offIcon class="w-4 h-4 mr-2 text-white" /> {{ $t('Hide labels')}}</button>
                              <button type="button" class="btn bg-blue-500 text-white mt-1 display-inline text-xs text-white px-5 w-full zoom-in" @click="layout.canvas.tools.showMask=true; initCanvas()" 
                                v-if="layout.canvas.canvas?._objects?.length && !layout.canvas.tools.showMask"><EyeIcon class="w-4 h-4 mr-1 text-white" /> {{ $t('Show labels')}}</button>
                            </span>
                          </div> 
                        </div>
                      </div>
                      <div class="col-span-12 mt-1 text-left" v-if="layout.canvas.drawing && layout.action.edit.type=='imageObjectDetection'">
                        <div class="pb-1 text-xs pl-1">{{ $t('Auto detection')}}</div>
                        <div class="p-3 border rounded">
                          <div class="grid grid-cols-12">
                            <div class="col-span-8 text-left">
                              <div class="pb-2 text-xs">{{ $t('Auto detection')}}</div>
                              <CpuIcon class="w-5 h-5 mr-3 mt-0.5" style="vertical-align: top" />
                              <input class="form-check-switch drawing-mode-switch" type="checkbox" v-model="layout.canvas.tools.autoDetection"   />
                            </div>
                            <div class="col-span-4 text-left" v-if="layout.canvas.tools.autoLoading"> 
                              <div class="w-8 h-8 ml-2 mt-5"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
                            </div>
                            <div class="col-span-12 text-left mt-1">
                              <div class="pb-2 text-xs">{{ $t('Environment')}}</div>
                              <input v-model="layout.canvas.tools.modalDetection.api" class="form-check-input" type="radio" value="vision" /> <span style="vertical-align: top">{{ $t('Vision') }}</span>
                              <input v-model="layout.canvas.tools.modalDetection.api" class="form-check-input ml-4" type="radio" value="coco" /> <span style="vertical-align: top">{{ $t('Coco') }}</span> 
                            </div>
                            <div class="col-span-12 text-left mt-1">
                              <button id="object-ml-btn" type="button" class="btn w-full bg-theme-21 text-white mt-1 display-inline" @click="autoDetection()" title="Object detection" v-if="layout.canvas.drawing && layout.action.edit.type=='imageObjectDetection'">
                                <CpuIcon class="w-4 h-4 mr-1" /> {{ $t('Detect')}}
                              </button>
                            </div>
                          </div> 
                        </div>
                      </div>
                      <div class="col-span-12 mt-2 text-left">
                        <div class="pb-1 text-xs pl-1">{{ $t('Image')}}</div>
                        <div class="p-3 border rounded">
                          <div class="col-span-12 text-left" v-if="layout.canvas.tools.zoom">
                            <div class="pb-2 text-xs">{{ $t('Zoom')}} <span class="float-right text-gray-600">{{ parseFloat(parseFloat(layout.canvas.tools.zoom)*100).toFixed(0) }}%</span></div>
                            <Slider v-model="layout.canvas.tools.zoom" class="mb-3" :min="0.05" :max="2" :step="0.01" :merge="0.01" :tooltips="false" :lazy="true" @change="SliderZoom($event)" />
                          </div>
                          <div class="col-span-1 py-5 px-2 text-gray-600 text-center" v-if="layout.canvas.tools.filersLoading">
                            {{ $t('Image filters are being applied')}}
                            <div class="intro-x flex flex-col justify-center items-center"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div> 
                          </div>
                          <div v-else>
                            <div class="grid grid-cols-12">
                              <div class="col-span-12 text-left">
                                <div class="pb-2 text-xs">{{ $t('Brightness')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.brightness }}</span></div>
                                <Slider v-model="layout.canvas.tools.modalZoomProps.brightness" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                              </div>
                              <div class="col-span-12 text-left">
                                <div class="pb-2 text-xs">{{ $t('Contrast')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.contrast }}</span></div>
                                <Slider v-model="layout.canvas.tools.modalZoomProps.contrast" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                              </div>
                              <div class="col-span-12 text-left">
                                <div class="pb-2 text-xs">{{ $t('Saturation')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.saturation }}</span></div>
                                <Slider v-model="layout.canvas.tools.modalZoomProps.saturation" class="mb-3" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                              </div>
                              <div class="col-span-12 text-left">
                                <div class="pb-2 text-xs">{{ $t('Vibrance')}} <span class="float-right text-gray-600">{{ layout.canvas.tools.modalZoomProps.vibrance }}</span></div>
                                <Slider v-model="layout.canvas.tools.modalZoomProps.vibrance" class="mb-1" :value="0" :min="-1" :max="1" :step="0.003921" :merge="0.003921" :tooltips="false" :lazy="true" />
                              </div>
                            </div>
                            <div class="col-span-12 text-left mt-2" 
                              v-if="layout.canvas.imageFilters && (layout.canvas.imageFilters.brightness!=layout.canvas.tools.modalZoomProps.brightness || layout.canvas.imageFilters.contrast!=layout.canvas.tools.modalZoomProps.contrast || layout.canvas.imageFilters.saturation!=layout.canvas.tools.modalZoomProps.saturation || layout.canvas.imageFilters.vibrance!=layout.canvas.tools.modalZoomProps.vibrance)">
                              <button id="btnImageFilters" type="button" class="btn w-full bg-theme-21 text-white mt-1 display-inline" @click="layout.canvas.tools.filersLoading = true; backgroundFilters(false, true); layout.canvas.tools.filersLoading = false" >
                                {{ $t('Apply last filters')}}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-span-12">
                      <button type="button" class="btn bg-gray-600 text-white mt-3 display-inline zoom-in w-full" @click="resetPropsCanvas();initialCenterImage()" title="Reset filters">
                        <RotateCcwIcon class="w-4 h-4 mr-1" />{{ $t('Reset') }}
                      </button>
                    </div>
                  </div>
                </div> 
                <!-- CANVAS -->
                <div id="canvasBox" class="w-full bg-gray-500 h-full" :style="'overflow: hidden !important'"> 
                  <div v-if="layout.canvas.error" class="text-center mt-60 text-gray-700"><div class="mb-2"><Alert-circleIcon class="w-20 h-20 text-gray-700" /></div>{{ $t('Failed to load image') }}</div>
                  <div v-else>
                    <div class="flex items-center justify-center w-full h-full" :class="layout.canvas.loading ? '' : 'hidden'" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train3.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%; height: 100vh;`"></div> 
                    <div :class="layout.canvas.loading ? 'hidden' : ''">
                      <div id="canvasContent" :style="'width: '+layout.canvas.tools.modalZoom+'% !important;'+(layout.canvas.tools.modalZoom>100 ? 'margin-left: '+layout.canvas.tools.modalPosition.right+'% !important; margin-top: '+layout.canvas.tools.modalPosition.top+'% !important;' : 'margin-left: calc((100% - '+layout.canvas.tools.modalZoom+'%)/2) !important;')"></div>
                      <!-- <img id="modal-zoom-image" class="hidden" :src="layout.action.edit.gimage" :style="'width: 100%;'">-->
                    </div>
                  </div>
                </div>
                <!-- /CANVAS -->
              </div> 
              <div id="editorLeftPanel" class="col-span-12 sm:col-span-5 lg:col-span-3 xxl:col-span-2 overflow-y-auto bg-white">
                <div :style="'border-left: 1px solid #ddd'" :class="layout.canvas.loading ? 'hidden' : ''">
                  <div class="bg-theme-15 editorSaveTools">
                    <div class="editorSaveToolsContent">
                      <div class="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap justify-center items-center mt-0">
                        <button type="button" class="btn w-2/6 hover:bg-green-700 hover:text-white flex items-center text-sm py-3 text-ms editorSaveBtn" v-if="!layout.canvas.error"
                          :style="'border-radius: 0 !important; font-weight: normal'" @click="saveImage();">
                          <SaveIcon class="w-6 h-5 mr-3" /> Save
                        </button> 
                        <button id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error"  @click="downloadImage()"
                          class="btn w-1/6 text-gray-900 hover:bg-blue-700 hover:text-white flex items-center text-sm py-3 text-base editorSaveBtn">
                          <DownloadIcon class="w-6 h-5" />
                        </button>
                        <button id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="layout.action.delete=layout.action.edit;modal('modal-delete-image-box')"
                          class="btn w-1/6 text-gray-900 hover:bg-red-700 hover:text-white flex items-center text-sm py-3 text-base editorSaveBtn">
                          <Trash2Icon class="w-6 h-5" />
                        </button>
                        <button id="modal-canvas-close" type="button" :style="'border-radius: 0 !important'" v-if="!layout.canvas.error" @click="layout.helper.active=true"
                          class="btn w-1/6 text-gray-900 hover:bg-red-700 hover:text-white flex items-center text-sm py-3 text-base editorSaveBtn">
                          <Help-circleIcon class="w-6 h-5" />
                        </button>
                        <button id="modal-canvas-close" type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" 
                          class="btn text-gray-900 hover:bg-blue-500 hover:text-white flex items-center text-sm py-3 text-base editorSaveBtn" @click="exitEditor();refreshLabeling()" :class="layout.canvas.error ? 'w-full' : 'w-1/6'">
                          <Log-outIcon class="w-8 h-5" />
                        </button>
                      </div>
                      <div class="grid grid-cols-12" v-if="layout.dataset.id && layout.editMap[layout.dataset.id]">
                        <div class="col-span-12 text-center" v-if="layout.editor.navOpt.loading">
                          <div class="intro-x flex flex-col justify-center items-center"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div> 
                        </div>
                        <div v-else class="col-span-12">
                          <div class="grid grid-cols-12">
                            <div class="col-span-6 text-center py-2 border cursor-pointer hover:bg-blue-500 hover:text-white editorSaveBtn" :class="!layout.editMap[layout.dataset.id]?.nav?.next ? 'col-span-12' : 'col-span-6'"
                              v-if="layout.editMap[layout.dataset.id]?.nav?.prev" @click="editImage(layout.editMap[layout.dataset.id].nav.prev)">
                              <Skip-backIcon class="w-6 h-5" />
                            </div>
                            <div class="text-center py-2 border cursor-pointer hover:bg-blue-500 hover:text-white editorSaveBtn" :class="!layout.editMap[layout.dataset.id]?.nav?.prev ? 'col-span-12' : 'col-span-6'"
                              v-if="layout.editMap[layout.dataset.id]?.nav?.next" @click="editImage(layout.editMap[layout.dataset.id].nav.next)">
                              <Skip-forwardIcon class="w-6 h-5" />
                            </div>
                          </div>
                          <div class="col-span-12 text-center py-1 border text-xs" v-if="layout.editor.navOpt.order=='date' && !layout.editMap[layout.dataset.id]?.nav?.prev">{{ $t('Last image stored in the dataset') }}</div>
                          <div class="col-span-12 text-center py-1 border text-xs" v-else-if="layout.editor.navOpt.order=='date' && !layout.editMap[layout.dataset.id]?.nav?.next">{{ $t('First image stored in the dataset') }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="rpEditorMenu">
                      <ul>
                        <li id="eMnav" class="selected nav" @click="editorMenu('eMnav')"><span>{{ $t('Nav controls') }}</span><span class="arrow"></span></li>
                        <li class="menu-content eMnav sel">
                          <div class="col-span-12 text-left" v-if="layout.editor?.navOpt">
                              <div class="grid grid-cols-12">
                                <div class="col-span-6 text-left">
                                  <input type="radio" class="input border mr-2 cursor-pointer" value="date" v-model="layout.editor.navOpt.order"> 
                                  <span :class="layout.editor.navOpt.order=='date' ? 'text-gray-600' : ''">{{ $t('Created desc') }}</span>
                                </div>
                                <div class="col-span-6 text-left" v-if="layout.action.edit.type=='MULTICLASS'">
                                  <input type="radio" class="input border mr-2 cursor-pointer" value="tag" v-model="layout.editor.navOpt.order"> 
                                  <span :class="layout.editor.navOpt.order=='tag' ? 'text-gray-600' : ''">{{ $t('Tag') }}</span>
                                </div>
                                <div class="col-span-12 mt-2" v-if="layout.action.edit.type=='MULTICLASS' && layout.dataset?.tags && layout.editor.navOpt.order=='tag' && layout.editMap[layout.dataset.id]">
                                  <select v-model="layout.editMap[layout.dataset.id].nav.tag" class="form-select cursor-pointer py-2 text-gray-600 bg-white" @change="setNavEditor()">
                                    <option v-for="(tag,indx,c) in layout.dataset.tags" :value="indx" :key="c">{{ tag.name }} <span v-if="tag.imageCounter">({{ tag.imageCounter }})</span></option>
                                  </select>  
                                </div>  
                              </div> 
                          </div>
                        </li>
                        <li id="eMhistory" class="nav" @click="editorMenu('eMhistory')" v-if="layout.editMap[layout.dataset.id] && layout.editMap[layout.dataset.id].nav.log && Object.keys(layout.editMap[layout.dataset.id].nav.log).length>1"><span>{{ $t('History') }} </span><span class="arrow"></span></li>
                        <li class="menu-content eMhistory" v-if="layout.editMap[layout.dataset.id] && layout.editMap[layout.dataset.id].nav.log && Object.keys(layout.editMap[layout.dataset.id].nav.log).length>1">
                          <div class="w-full" style="height: 85px; width: 100%; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; overflow-x: hidden;">
                            <div style="width: 102px; margin:1px 5px 5px 0; display: block; float: left;" :style="index==layout.action.edit.id ? 'hidden' : ''"
                              v-for="(hImg,index) in layout.editMap[layout.dataset.id].nav.log" :key="hImg">
                              <img :src="hImg.image" class="w-full h-auto cursor-pointer hover:border-blue-500 zoom-in" @click="editImage(index)"  /> 
                                <div class="text-center text-xs">{{ hImg.date }}</div>
                            </div>
                          </div>
                        </li>
                        <li id="eMlabeling" class="selected nav" @click="editorMenu('eMlabeling')"><span>{{ $t('Labeling') }}</span><span class="arrow"></span></li>
                        <li class="menu-content eMlabeling sel">
                          <div v-if="layout.action.edit.type=='MULTICLASS'">
                            <div v-if="!Object.keys(layout.dataset.tags).length" class="py-2 text-center">{{ $t('Must add tags to the dataset to start labeling') }}</div>
                            <div class="text-left" :class="Object.keys(layout.dataset.tags).length>5 ? 'text-xs' : ''">
                                <div v-for="(t) in layout.dataset.tags" :key="t.id">  
                                  <div class="py-1 pl-1 hover:bg-gray-500 hover:text-white w-full text-left font-normal cursor-pointer"
                                    @click="layout.action.edit.tag = 'dataset/'+layout.dataset.id+'/tag/'+t.id; refreshPropsCanvas('brushSize')"  
                                    :class="layout.action.edit?.tag?.path && layout.action.edit?.tag.path.toString().split('/')[3]==t.id ? 'bg-gray-500 text-white' : layout.action.edit.tag.toString().split('/').pop()==t.id ? 'bg-gray-500 text-white' : 'text-gray-600'"> 
                                    <div class="inline-block align-middle"><div class="mr-1 rounded display-inline roundTagIcon h-6 w-6"  :style="t.color ? 'background-color:'+t.color+';border: 2px solid #fff' : ''"></div> </div>
                                    <div class="inline-block align-middle ml-1"><span class="text-left">{{ t.name}}</span></div>
                                  </div>
                                </div>
                            </div> 
                          </div>
                          <div v-if="layout.action.edit.type=='MULTILABEL'">
                              <div v-if="!Object.keys(layout.dataset.tags).length" class="py-2 text-center">{{ $t('Must add tags to the dataset to start labeling') }}</div>
                              <div class="mt-2" v-if="layout.canvas.canvas && layout.canvas.canvas._objects && Object.keys(layout.canvas.canvas._objects).length">
                                <div v-for="(t,index) in layout.canvas.canvas._objects" :key="t.type" class="mb-2">
                                  <div class="grid grid-cols-12 cursor-pointer" v-if="t.type=='path' && t.name!='cursorBrush'" @mouseover="highlightObject(index)" @mouseleave="highlightObject(index, true)"> <!--  -->
                                    <div class="col-span-1">
                                      <div class="text-xs w-8 h-8 rounded-full px-2 py-1 text-center text-gray-800 mt-1 border-none roundTagIcon" :style="'background-color: '+t.stroke+'; '">
                                        <div class="text-sm text-center pt-0.5 text-white" v-if="t.objid">{{ parseInt(t.objid.match(/(\d+)$/)[1]) }}</div>
                                      </div>
                                    </div>
                                    <div class="col-span-9">
                                      <select class="form-select bg-gray-200 cursor-pointer w-full py-2 ml-4" :class="t.name=='new' ? 'bg-theme-28 text-white' : ''" 
                                          @change="resetObjectIds({ objid: t.objid, tag: $event.target.value, stroke: layout.dataset.tags[$event.target.value].color })">
                                          <option v-for="(tag,indx,c) in layout.dataset.tags" :value="indx" :key="c" :selected="indx==t.name">{{ tag.name }}</option>
                                      </select>
                                      <div v-if="t.name=='new'" class="ml-2"><input type="text" class="w-full form-control py-2 px-2 border-transparent bg-theme-7 mt-2 ml-2"></div>
                                    </div>  
                                    <div class="col-span-2 text-gray-500 hover:text-theme-2"><TrashIcon class="w-8 h-8 ml-5 pt-1 trashIconTag" @click="removeObject(t.objid)" /></div> 
                                  </div>  
                                </div> 
                              </div>
                              <div v-else>
                                <div class="text-left text-base p-2 pt-0 text-gray-600 mt-1">
                                  <div class="t-3 text-sm mb-1 text-red-400">{{ $t('Image not labeled')}}</div>
                                  <div class="t-3 text-xs text-gray-600">{{ $t('Select the pencil tool and one tag for draw') }}.</div></div>
                              </div>  
                          </div>
                          <div v-if="layout.action.edit.type=='imageObjectDetection'">
                              <div class="mt-2" v-if="layout.canvas.canvas && layout.canvas.canvas._objects && Object.keys(layout.canvas.canvas._objects).length">
                                <div v-for="(t,index) in layout.canvas.canvas._objects" :key="t.type" class="mb-2">
                                  <div class="grid grid-cols-12 cursor-pointer" v-if="t.type=='rect'" @mouseover="highlightObject(index)" @mouseleave="highlightObject(index, true)">
                                    <div class="col-span-1">
                                      <div class="text-xs w-8 h-8 rounded-full px-2 py-1 text-center text-gray-800 mt-1 border-none roundTagIcon" :style="'background-color: '+t.stroke+'; '">
                                        <EyeIcon class="w-4 h-4 mt-1 text-white" /></div><CornerDownRightIcon class="w-8 h-8 ml-1 mt-2 text-gray-600" v-if="t.name=='new'"  />
                                    </div>
                                    <div class="col-span-9">
                                      <select class="form-select bg-gray-200 cursor-pointer w-full py-2 ml-4" :class="t.name=='new' ? 'bg-theme-28 text-white' : ''" @change="canvaSetObject($event,index)">
                                        <option v-for="(tag,indx,c) in layout.dataset.tags" :value="indx==0 ? 'new' : indx" :key="c" :selected="indx==t.name"> 
                                          {{ indx==0 ?  $t('New label'): layout.dataset.tags[indx] && layout.dataset.tags[indx].name ? layout.dataset.tags[indx].name : indx }} 
                                        </option>
                                      </select>
                                      <div v-if="t.name=='new'" class="ml-2"><input type="text" class="w-full form-control py-2 px-2 border-transparent bg-theme-7 mt-2 ml-2" @input="updCanvastObjectTxt($event,index)" value="Label name"></div>
                                    </div>  
                                    <div class="col-span-2 text-gray-500 hover:text-theme-2"><TrashIcon class="w-8 h-8 ml-5 pt-1 trashIconTag" @click="removeObject(t.objid)" /></div> 
                                  </div>  
                                </div> 
                              </div>
                              <div v-else>
                                <div class="text-left text-base p-2 pt-0 text-gray-600 mt-1">
                                  <div class="t-3 text-sm mb-1 text-red-400">{{ $t('Image not labeled')}}</div>
                                  <div class="t-3 text-xs text-gray-600">{{ $t('Add bounding boxes and labels')}}.<br />{{ $t('Use the mouse to draw a new box on the image') }}.</div></div>
                              </div>  
                          </div>
                        </li>
                        <li id="eMdivision" class="selected nav" @click="editorMenu('eMdivision')"><span>{{ $t('Division') }}</span><span class="arrow"></span></li>
                        <li class="menu-content eMdivision sel">
                          <select v-model="layout.action.edit.set" class="form-select cursor-pointer py-2 text-gray-600 bg-white px-4">
                            <template v-if="!layout.action.edit.set"><option :value="layout.action.edit.set">{{ $t('NOT DEFINED') }}</option></template>
                            <option value="TRAIN">{{ $t('TRAIN') }}</option>
                            <option value="TEST">{{ $t('TEST') }}</option>
                            <option value="VALIDATION">{{ $t('VALIDATION') }}</option>
                          </select>
                        </li>
                        <li id="eMcomments" class="nav" :class="layout.action.edit.comments ? 'selected' : ''" @click="editorMenu('eMcomments')"><span>{{ $t('Comments') }}</span><span class="arrow"></span></li>
                        <li class="menu-content eMcomments" :class="layout.action.edit.comments ? 'sel' : ''">
                          <div class="w-full">
                            <textarea id="object-image-comments" spellcheck="false" v-model="layout.action.edit.comments"
                              :style="'width: 100%;height:80px;padding: 10px; font-size: 14px; line-height: 16px; background-color: #f7f8f9; margin:0; color: #999'" placeholder="Comments..."></textarea>
                          </div> 
                        </li>
                        <li id="eMfile" class="selected nav" @click="editorMenu('eMfile')" v-if="layout.action.edit?.gimageObj"><span>{{ $t('File') }}</span><span class="arrow"></span></li>
                        <li class="menu-content eMfile sel" v-if="layout.action.edit?.gimageObj">
                          <div class="p-1">
                            <div>{{ $t('Type') }}: {{ layout.action.edit.gimageObj.meta.contentType }}</div>
                            <div>{{ $t('Url') }}: <a class="text-blue-500" :href="layout.action.edit.gimageObj.url" target="_blank">{{ layout.action.edit.gimageObj.meta.name }}</a></div>
                            <div>{{ $t('Bucket') }}: {{ layout.action.edit.gimageObj.meta.bucket }}</div>
                            <div v-if="layout.canvas.canvas && layout.canvas.canvas.size && Object.keys(layout.canvas.canvas.size).length">
                              {{ $t('Size') }}: {{ layout.canvas.canvas.size.width }} x {{ layout.canvas.canvas.size.height }} px
                            </div>
                            <div>{{ (layout.action.edit.gimageObj.meta.size / 1024).toFixed(0) }} KB ({{ (layout.action.edit.gimageObj.meta.size / (1024 * 1024)).toFixed(2) }} MB)</div>
                            <div class="my-1" v-if="layout.action.edit?.createdDate">{{ $t('Created')}}: <span class="text-gray-700">{{ layout.action.edit.createdDate }}</span></div>
                            <div v-if="layout.action.edit?.updatedDate && (layout.action.edit?.updatedDate!=layout.action.edit?.createdDate)">{{ $t('Updated')}}: <span class="text-blue-500">{{ layout.action.edit.updatedDate }}</span></div>
                            <div class="mt-2"><span class="text-blue-500 cursor-pointer" @click="modal('modal-copy-image-box')">{{ $t('Make a copy')}}</span></div>
                            <div class="mt-1"><span class="text-blue-500 cursor-pointer" @click="downloadImage()">{{ $t('Download')}}</span></div>
                          </div>
                        </li>
                        <!--
                        <li id="eMdegub"><span>Debug</span><span class="arrow"></span></li>
                        <li class="menu-content eMdegub">
                          <pre class="text-xs">{{ layout.editMap }}</pre>
                          <pre class="text-xs ml-5" v-if="layout.canvas.canvas._objects">{{ layout.canvas.canvas._objects}}</pre>
                        </li>
                        -->
                      </ul>
                  </div>
                </div>
                <div class="intro-y col-span-9 lg:col-span-9 flex flex-col justify-center items-center mt-20" :class="layout.canvas.loading ? '' : 'hidden'"><LoadingIcon icon="three-dots" class="w-12 h-12" /></div> 
              </div>
              <!--
              <div id="imgEditComments" class="cursor-pointer" >
                <div class="w-12 h-12 bg-gray-600 py-2 rounded-full position-fixed text-center text-white" @click="layout.canvas.commentsBox=true"><Align-leftIcon class="w-8 h-8 text-white"/></div>
                <Tippy tag="div" v-if="layout.action.edit.comments"
                  class="w-4 h-4 flex items-center justify-center absolute rounded-full text-white bg-green-500 right-2 top-0 -mr-2 -mt-1 text-xs"></Tippy>
              </div>
              <div v-if="layout.canvas.commentsBox" id="commentsBox" class="bg-white rounded -mt-8 ml-12 p-2" >
                  <label class="form-label float-right ml-auto text-gray-800 cursor-pointer pl-1"><XIcon class="w-8 h-8" @click="layout.canvas.commentsBox=false" /></label>
                  <div class="text-lg pl-2 pt-1"><Align-leftIcon class="w-8 h-8" /> {{ $t('Image comments')}}</div>
                  <div class="w-full pt-2">
                    <textarea id="object-image-comments" v-model="layout.action.edit.comments" :style="'width: 100%;height: 100px;padding: 10px; font-size: 14px; line-height: 16px; background-color: #fff; margin:0'" placeholder="Comments..."></textarea>
                  </div>
              </div>
              -->
            </div>
          </div>  
          <helperTour :type="layout.action.edit.type" v-if="layout.helper.active" />  
        </div>
      </div>
    </div>

    <!-- LABELING COMPONENT -->
    <div v-if="layout.labeling.active"><LabelingComponent :options="{ 'dataset': props.options.dataset, 'datasets': {} }" ref="LabelingComponent" /></div>

  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, onUnmounted} from "vue"; 
import * as rosepetalModel from "rosepetal-model";
import { helper as $h } from "@/utils/helper";
import { useRouter } from "vue-router";
import cash from "cash-dom";
import Slider from '@vueform/slider'
import { fabric } from 'fabric'
import { ml as $ml } from "@/model/ml";
import helperTour  from "@/components/manage-image/Helper.vue";
//import jsonpack from 'jsonpack';
import lzstring from 'lz-string';
import LabelingComponent  from "@/components/dataset-labeling/Main.vue";

export default defineComponent({
  props: {
      options: {
      type: Object,
      required: true
      }
  },
  components: {
      Slider,
      helperTour,
      LabelingComponent
  },
  setup(props) {

    var   canvasbox = false
    const router   = useRouter();
    const layout   = ref(
                          { 
                              mosaic:   { min: false, images: {}, tagId: false, limit: false, order: false, loading: true },
                              editor:   { src: false, menu: {}, navOpt: { order: 'date', loading: false } },
                              dataset:  {},
                              action:   { edit: false, delete: false, copy: { to: false } },
                              canvas:   { 
                                          loading: false, 
                                          error:   false, 
                                          drawing: false,
                                          tools:{
                                                  modalZoom:        100,
                                                  zoom:           false,
                                                  modalBrush:     { size: 30, type: "move" },
                                                  modalPosition:  { top:0, right:0},
                                                  modalZoomProps: { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 },
                                                  modalDetection: { api: "vision" },
                                                  modalObjBB:     false,
                                                  modalObjMove:   false,
                                                  showMask:       true,
                                                  panning:        false,  
                                                  autoDetection:  false,
                                                  autoLoading:    false,
                                                  maskOpacity:    0.5,
                                                  bbOpacity:      0.4,
                                                },
                                          canvasbox: false,  
                                          canvas: {},
                                          mlPredictions: [], 
                                          commentsBox: false,
                                          isDown: false,  
                                          imageFilters: false,
                                          eventListener: {}
                                        },
                              editMap:  {},
                              reload:   false,
                              datasets: [],
                              helper:   { active: false },
                              labeling: { active: false }
                          }
                        );

    const modal       = (m) => { cash("#"+m).modal("show"); cash(".main").removeClass("overflow-y-hidden")};
    const modalClose  = (m) => { cash("#"+m).modal("hide"); };  

    const editImage = async (imageId) => { 
      modal('modal-image-editor')
      let i                                 =  await rosepetalModel.image.get(imageId)
      layout.value.canvas.loading           = true
      layout.value.canvas.tools.showMask    = true
      layout.value.action.edit              = i
      let imgDsId                           = i.dataset.path.toString().split('/').pop(); 
      router.push('/datasets/'+imgDsId+'/'+imageId)
      if(!props.options.type){
        let imgDataset                      = await rosepetalModel.dataset.get(imgDsId)
        layout.value.action.edit.type       = imgDataset.type 
      }else layout.value.action.edit.type   = props.options.type
      layout.value.action.edit.gimageObj    = await rosepetalModel.image.getStorageUrl(i.uri)
      if(layout.value.action.edit.gimageObj.error)layout.value.canvas.error = true
      else layout.value.action.edit.gimage  = layout.value.action.edit.gimageObj.url ? layout.value.action.edit.gimageObj.url : false
      if(layout.value.action.edit.type=='MULTILABEL'){
        layout.value.dataset.tags     = await rosepetalModel.dataset.getTags(props.options.dataset, false)
        layout.value.action.edit.tag  = 'dataset/'+layout.value.dataset.id+'/tag/'+Object.keys(layout.value.dataset.tags)[0];
      }
      var existImageFilters = imageFiltersChanged(); if(existImageFilters)layout.value.canvas.imageFilters = layout.value.canvas.tools.modalZoomProps
      await setNavEditor() //nav
      await initCanvas({ init: true }) 
      setTimeout(async () => {
        if(layout.value.action.edit.type=='imageObjectDetection' && layout.value.canvas.tools.modalObjBB)await refreshPropsCanvas('objectType')
        else refreshPropsCanvas()
      }, 200);
      $h.dragElement(document.getElementById("canvasImageTools")); //draggable
      if(layout.value.canvas.imageFilters)setTimeout(async () => { if(document.getElementById('btnImageFilters'))document.getElementById('btnImageFilters').click(); }, 3000);  //image filters
      const loadNextImageCache = async () => {  //get next image in cache
        setTimeout(async () => {
          let _gUri    = await rosepetalModel.image.getStorageUri(layout.value.editMap[props.options.dataset]["nav"]["next"])
          let _gUrl    = await rosepetalModel.image.getStorageUrl(_gUri.uri)
          if(!layout.value.editMap[props.options.dataset]["nav"][_gUrl.url]){
            let imageb64 = await rosepetalModel.image.getb64(_gUrl.url)
            if(imageb64){
              layout.value.editMap[props.options.dataset]["nav"][_gUrl.url] = { b64: imageb64 }
              console.log('next image in cache',_gUrl.url)
            }
          }
        }, 250);
      }
      if(layout.value.editMap[props.options.dataset]["nav"]["next"])loadNextImageCache()
    }
    
    const imageFiltersChanged = () => {
      for (const key in layout.value.canvas.tools.modalZoomProps) {
        if (Object.prototype.hasOwnProperty.call(layout.value.canvas.tools.modalZoomProps, key) && layout.value.canvas.tools.modalZoomProps[key] !== 0)return true;
      }
      return false;
    };

    const setNavEditor = async () => {
      layout.value.editor.navOpt.loading = true
      if(layout.value.action.edit){
        if(!layout.value.editMap[props.options.dataset])layout.value.editMap[props.options.dataset] = { nav: { current: false, prev: false, next: false , tag: false, log: {} } }
        if(!layout.value.editMap[props.options.dataset]["nav"]["tag"] && layout.value.editor.navOpt.order=='tag'){
          let tagEdit  = layout.value.action.edit.tag.toString().split("/").pop()
          if($h.isObject(layout.value.action.edit.tag))tagEdit = layout.value.action.edit.tag.path.toString().split("/").pop()
          layout.value.editMap[props.options.dataset]["nav"]["tag"] = tagEdit
        }
        
        let _currentNav = { id: layout.value.action.edit.id}
        if(layout.value.action.edit.gimageObj){
          _currentNav.gimage = layout.value.action.edit.gimageObj.url
          _currentNav.KB     = (layout.value.action.edit.gimageObj.meta.size / 1024).toFixed(0)
          _currentNav.MB     = (layout.value.action.edit.gimageObj.meta.size / (1024 * 1024)).toFixed(2)
        }
        layout.value.editMap[props.options.dataset]["nav"]["current"] = _currentNav
        layout.value.editMap[props.options.dataset]["nav"]["prev"]    = false
        layout.value.editMap[props.options.dataset]["nav"]["next"]    = false
        if(!layout.value.editMap[props.options.dataset]["nav"]["log"][layout.value.action.edit.id]){
          layout.value.editMap[props.options.dataset]["nav"]["log"][layout.value.action.edit.id] = { image: 'data:image/png;base64,'+ layout.value.action.edit.imageData.toBase64(), date: new Date().toLocaleTimeString('es-ES')  }
        }else layout.value.editMap[props.options.dataset]["nav"]["log"][layout.value.action.edit.id].date = new Date().toLocaleTimeString('es-ES')
        
        //order log by date desc and maintain index with map
        let navControls = await rosepetalModel.image.navControls(layout.value.action.edit.id, { order: layout.value.editor.navOpt.order, tag: layout.value.editMap[layout.value.dataset.id].nav.tag } )
        layout.value.editMap[props.options.dataset]["nav"]["prev"]    = navControls.prev 
        layout.value.editMap[props.options.dataset]["nav"]["next"]    = navControls.next 
      }
      layout.value.editor.navOpt.loading = false
    };

    const initCanvas = async ( opt = false) => { 
      let clearCanvas = opt.init ? true : false
      if(!canvasbox){
        let canvaOpt = { selectionLineWidth: 2, centeredScaling: true, transparentCorners: true, selection: false }  
        if(layout.value.canvas.drawing && layout.value.action.edit.type=='imageObjectDetection'){ canvaOpt.perPixelTargetFind = true; canvaOpt.enableRetinaScaling = true; canvaOpt.imageSmoothingEnabled = true; }
        if(layout.value.canvas.drawing && (layout.value.action.edit.type=='MULTICLASS' || layout.value.action.edit.type=='MULTILABEL'))canvaOpt.isDrawingMode = true 
        cash("#canvasContent").html('<canvas id="c"></canvas>')
        canvasbox   = new fabric.Canvas('c',canvaOpt);
        clearCanvas = true
      }
      if(clearCanvas){
        //console.log('clearCanvas')
        canvasbox.clear();
        canvasbox.remove(canvasbox.getActiveObject());
        await canvasPreLoad()
        resetPropsCanvas() 
      }
      await canvasEvents()
      canvasbox.isDrawingMode = (layout.value.action.edit.type=='MULTICLASS' || layout.value.action.edit.type=='MULTILABEL') ? layout.value.canvas.drawing : false
      if(layout.value.action.edit.type=='imageObjectDetection'){canvasbox.uniformScaling = false; canvasbox.uniScaleTransform = false; canvasbox.centeredScaling = false }
      if(!layout.value.canvas.drawing){
        canvasbox.on('mouse:up',   async function(e){ if (e.defaultPrevented)return; layout.value.canvas.tools.panning = false; canvasbox.defaultCursor = "default"; syncCanvasboxObjects() });
        canvasbox.on('mouse:down', async function(e){ if (e.defaultPrevented)return; layout.value.canvas.tools.panning = true;   });
        canvasbox.on('mouse:move', async function(e){ if (e.defaultPrevented)return; canvasbox.defaultCursor = "grab"
          if (!layout.value.canvas.drawing && layout.value.canvas.tools.panning && e && e.e) {
              let delta = new fabric.Point(e.e.movementX, e.e.movementY);
              canvasbox.relativePan(delta); 
              syncCanvasboxObjects() 
          }
        });
      }
      canvasbox.off('mouse:wheel');
      canvasbox.on('mouse:wheel', throttle(function(ev) {
        if (ev.defaultPrevented) return;
        const delta = ev.e.deltaY > 0 ? -1 : 1; 
        const zoom          = canvasbox.getZoom();
        const zoomFactor    = 0.01; // cantidad de zoom por paso de rueda
        const newZoom       = zoom + delta * zoomFactor;
        const positiveZoom  = Math.max(newZoom, 0.05); // asegura que el nivel de zoom no sea negativo ni menor que 0.05
        const limitedZoom   = Math.min(positiveZoom, 2); // asegura que el nivel de zoom no sea mayor que 4
        if (limitedZoom !== zoom) {
          canvasbox.zoomToPoint(new fabric.Point(canvasbox.width / 2, canvasbox.height / 2), limitedZoom);
          layout.value.canvas.tools.zoom = limitedZoom;
          if(layout.value.canvas.tools.modalBrush.type=="line" || layout.value.canvas.tools.modalBrush.type=="erase"){
            const currentZoom = canvasbox.getZoom(); canvasbox.setZoom(currentZoom);
            let cursorSize    = canvasbox.freeDrawingBrush.width * currentZoom; /* */console.log('size del cusor (wheel)', cursorSize, "("+canvasbox.freeDrawingBrush.width+"*"+currentZoom+")")
            canvasbox.freeDrawingCursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;
          }
          canvasbox.renderAll();
        }
      }, 50));
      syncCanvasboxObjects()
      canvasbox.renderAll();
    }
    
    const throttle = (func, delay) => {
      let lastCall = 0;
      return (...args) => {
        const now = new Date().getTime();
        if (now - lastCall < delay)return;
        lastCall = now;
        return func(...args);
      };
    }

    const SliderZoom = (e) => {
      const zoom          = canvasbox.getZoom();
      if (e && e !== zoom) {
        canvasbox.zoomToPoint(new fabric.Point(canvasbox.width / 2, canvasbox.height / 2), e);
        layout.value.canvas.tools.zoom = e;

        if(layout.value.canvas.tools.modalBrush.type=="line" || layout.value.canvas.tools.modalBrush.type=="erase"){
          let cursorSize    = canvasbox.freeDrawingBrush.width * layout.value.canvas.tools.zoom; /* */console.log('size del cusor (sliderzoom)', cursorSize, "("+canvasbox.freeDrawingBrush.width+"*"+layout.value.canvas.tools.zoom+")")
          canvasbox.freeDrawingCursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;
        }

        canvasbox.renderAll();
      }
    }

    const canvasPreLoad  = async () => {
      /*console.log('mask blob',layout.value.action.edit?.mask)
      const decompressedJSON = lzstring.decompressFromUint8Array(layout.value.action.edit?.mask.toUint8Array());
      console.log('decompressedJSON',JSON.parse(decompressedJSON))*/
      
      //multiclass load from image database json
      if(layout.value.action.edit.type=='MULTICLASS'){
        if(layout.value.canvas.tools.showMask && layout.value.action.edit?.mask?.imageJson){  //load mask json
          let maskJSON = false

          /*/Compressed with jsonpack
          try { maskJSON = JSON.parse(layout.value.action.edit.mask.imageJson)
          } catch (error) { maskJSON = JSON.parse(jsonpack.unpack(layout.value.action.edit.mask.imageJson)); }*/

          //Compressed with lz-string
          try { maskJSON = JSON.parse(layout.value.action.edit.mask.imageJson)
          } catch (error) { 
            maskJSON = JSON.parse(lzstring.decompressFromUint8Array(layout.value.action.edit?.mask.imageJson.toUint8Array())) ;
          }

          if(maskJSON)
            canvasbox.loadFromJSON(maskJSON, function() {
              canvasbox.renderAll();
            }, function(o, object) {
              object.selectable = false;
            });
        }
      }

      //Multilabel objects load from image database tags param
      if(layout.value.action.edit.type=='MULTILABEL'){
        if(layout.value.canvas.tools.showMask && layout.value.action.edit?.mask?.imageJson){  //load mask json
          let maskJSON = false
          
          /*Compressed with jsonpack
          try { maskJSON = JSON.parse(layout.value.action.edit.mask.imageJson)
          } catch (error) { maskJSON = JSON.parse(jsonpack.unpack(layout.value.action.edit.mask.imageJson)); }*/
          
          try { maskJSON = JSON.parse(layout.value.action.edit.mask.imageJson)
          } catch (error) { //Compressed with lz-string
            maskJSON = JSON.parse(lzstring.decompressFromUint8Array(layout.value.action.edit?.mask.imageJson.toUint8Array())) ;
          }
          

          if(maskJSON)canvasbox.loadFromJSON(maskJSON, canvasbox.renderAll.bind(canvasbox) /*, function(o , obj){
            if(obj?.uniqueId)console.log('cargamos el objeto: ',obj.uniqueId, obj?.name)
          }*/);
        }
        canvasbox.renderAll();
      }
     
      //load backimage if exist in cache
      if(layout.value.editMap[layout.value.dataset.id]["nav"][layout.value.action.edit.gimage] && layout.value.editMap[layout.value.dataset.id]["nav"][layout.value.action.edit.gimage]["b64"]){
        layout.value.action.edit.gimage = layout.value.editMap[layout.value.dataset.id]["nav"][layout.value.action.edit.gimage]["b64"]
        console.log('estic')
      }

      //load background image
      console.log('load background image',layout.value.action.edit.gimage)
      fabric.Image.fromURL(layout.value.action.edit.gimage, async function (img) {   
        img.erasable   = false;
        canvasbox.setHeight(img.height); 
        canvasbox.setWidth(img.width);
        layout.value.canvas.backImageOriginalSize = { width: img.width, height: img.height, blobImage : img }
        console.log('set original size',layout.value.canvas.backImageOriginalSize)
        layout.value.canvas.canvas.size = { width: img.width, height: img.height }
        canvasbox.setBackgroundImage(img, canvasbox.renderAll.bind(canvasbox), {
            scaleX: canvasbox.width / img.width,
            scaleY: canvasbox.height / img.height,
            
        });
        //if(canvasbox.backgroundImage._originalElement)canvasbox.backgroundImage._originalElement.crossOrigin= "anonymous"
        console.log('set image background',canvasbox.backgroundImage)
        canvasbox.renderAll();

        //Vision objects load from image database tags param
        if(layout.value.action.edit.type=='imageObjectDetection'){
          if(layout.value.action.edit.tags){
            for(let index in layout.value.action.edit.tags){ 
              let savedObject = layout.value.action.edit.tags[index] 
              if(savedObject.type=="rect"){
                let tagName             = savedObject.tag.path.toString().split('/').pop()
                let _tagData            = await rosepetalModel.dataset.getTag(layout.value.action.edit.dataset.path.toString().split("/").pop(), tagName)
                var fillColor           = _tagData.color ? $h.hexToRgb(_tagData?.color, layout.value.canvas.tools.bbOpacity) : 'transparent';
                let RectOpt             = {
                                              left:           savedObject.x * img.width,
                                              top:            savedObject.y * img.height,
                                              originX:        'left',
                                              originY:        'top',
                                              width:          savedObject.w*img.width,
                                              height:         savedObject.h*img.height,
                                              fill:           fillColor,
                                              strokeWidth:    1,
                                              hasControls:    true,
                                              lockRotation:   true,
                                              lockScalingX:   false,
                                              lockScalingY:   false,
                                              flipX:          false,
                                              flipY:          false,
                                              selectable:     true,
                                              scalable:       true,
                                              lockScalingFlip: true
                                          }
                if(!layout.value.canvas.drawing || !layout.value.canvas.tools.modalObjMove){  RectOpt.selectable = false; RectOpt.hoverCursor = "default"; }
                let newObject           = new fabric.Rect(RectOpt);
                newObject.cornerSize    = 10;
                newObject.setControlsVisibility({ mt: true, mb: true, ml: true, mr: true, bl: false, br: false, tl: true, tr: true, mtr: false });
                if (tagName) {
                  let bbColor           = _tagData.color ? _tagData.color : 'ff0000'
                  let newObjectId       = index+tagName;
                  newObject.toObject    = (function(toObject) {
                    return function(propertiesToInclude) {
                      return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                        name: tagName,
                        objid: newObjectId
                      });
                    };
                  })(newObject.toObject); 
                  newObject.set({stroke: bbColor })
                  newObject.set({name: tagName })
                  newObject.set({objid: newObjectId })
                  canvasbox.add(newObject);
                  let TextOpt = {
                          fill:           bbColor,
                          left:           savedObject.x * img.width,
                          top:            ((savedObject.y * img.height)-28),
                          fontSize:       25,
                          padding:        5,
                          objParentId:    newObjectId,
                          hoverCursor:    "default",
                          selectable:     false,
                          hasControls:    false,
                      }
                  if(!layout.value.canvas.drawing || !layout.value.canvas.tools.modalObjMove){ TextOpt.selectable = false; TextOpt.hoverCursor = "default"; } 
                  var tagNameBox = new fabric.Text(tagName, TextOpt);
                  tagNameBox.toObject = (function(toObject) {
                    return function(propertiesToInclude) {
                      return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                        objParentId: newObjectId
                      });
                    };
                  })(newObject.toObject);     
                  tagNameBox.set({objParentId: newObjectId })
                  canvasbox.add(tagNameBox);
                }
              }
            }
          }
          canvasbox.renderAll();
          syncCanvasboxObjects() 
          if(layout.value.canvas.drawing && !Object.keys(layout.value.canvas.canvas._objects).length && layout.value.canvas.tools.autoDetection)autoDetection()
        }

        if(!layout.value.canvas.tools.zoom)
          initialCenterImage()
        else layout.value.canvas.loading = false

      }, { crossOrigin: 'anonymous' } );
    }

    const initialCenterImage  = async () => {
      var zoom  = 1
      if(canvasbox.width > canvasbox.height)zoom = canvasbox.width / canvasbox.height
      else zoom = canvasbox.height / canvasbox.width
      canvasbox.setZoom(zoom* 0.5)
      var viewportTransform = canvasbox.viewportTransform.slice();
      viewportTransform[4]  = (canvasbox.width - layout.value.canvas.backImageOriginalSize.width * (zoom * 0.5)) / 2; //currenImage.width
      viewportTransform[5]  = 5;
      canvasbox.setViewportTransform(viewportTransform);
      layout.value.canvas.tools.zoom = canvasbox.getZoom()
      layout.value.canvas.loading    = false
      canvasbox.renderAll();
      /*let currenImage = new Image();
      currenImage.onload = function() {
      currenImage.src = layout.value.action.edit.gimage;*/
    }

    const canvasEvents  = async () => {

      canvasbox.off('mouse:down')
      canvasbox.off('mouse:move')
      canvasbox.off('mouse:up')
      canvasbox.off('mouse:out')
      //canvasbox.off('mouse:wheel')
      canvasbox.off('path:created')

      if(layout.value.action.edit.type=='imageObjectDetection' && !layout.value.canvas.drawing){
        canvasbox.selectable    = false;
        canvasbox.scalable      = false;
        canvasbox.selection     = false;
      }

      if(layout.value.action.edit.type=='imageObjectDetection'){
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerColor        = 'blue';
        fabric.Object.prototype.cornerStyle        = 'circle';
      }

      let currentObjects        = canvasbox.getObjects()

      for(let index in currentObjects){ 
        if(!layout.value.canvas.drawing && currentObjects[index])currentObjects[index].set({selectable: false, hoverCursor: "default"});
        if(layout.value.action.edit.type=='imageObjectDetection'){
          if(currentObjects[index].type=='rect'){
            if(layout.value.canvas.drawing){
                currentObjects[index].set({
                                        selectable: layout.value.canvas.tools.modalObjMove, 
                                        hoverCursor: layout.value.canvas.tools.modalObjMove ? "move" : "default"
                                      }).setCoords(); 
            }
            currentObjects[index].set({fill: $h.hexToRgb(currentObjects[index].stroke, layout.value.canvas.tools.bbOpacity)}).setCoords(); 
          }                        
        }

        if(layout.value.action.edit.type=='MULTICLASS' || layout.value.action.edit.type=='MULTILABEL'){
          if(!layout.value.canvas.tools.showMask)currentObjects[index].opacity = 0
          else currentObjects[index].opacity = layout.value.canvas.tools.maskOpacity
        }
      }

      if(layout.value.action.edit.type=='imageObjectDetection'){
        //set limits to move objects
        canvasbox.on('object:moving', async function(e){
          var obj = e.target;
          if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width)return;
          obj.setCoords();
          if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
            obj.top = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
            obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
          }
          if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
            obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
            obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
          }
          if(obj.objid && canvasbox._objects){
            for(let robj in canvasbox._objects){  //remove parent object
              if(canvasbox._objects[robj].objParentId && canvasbox._objects[robj].objParentId==obj.objid){
                let subObj = canvasbox._objects[robj]
                subObj.setCoords();
                subObj.top  = obj.top - 30
                subObj.left = obj.left
              }
            }
            canvasbox.renderAll();
          }
          syncCanvasboxObjects()
        });
        //scaling 
        canvasbox.on('object:scaling', async function(e){
          var obj = e.target;
          if (!obj || obj.type !== 'rect') { return; }
          obj.setCoords();
          if(obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width)return;
          if(obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0){
            obj.top  = Math.max(obj.top, obj.top-obj.getBoundingRect().top);
            obj.left = Math.max(obj.left, obj.left-obj.getBoundingRect().left);
          }
          if(obj.getBoundingRect().top+obj.getBoundingRect().height  > obj.canvas.height || obj.getBoundingRect().left+obj.getBoundingRect().width  > obj.canvas.width){
            obj.top = Math.min(obj.top, obj.canvas.height-obj.getBoundingRect().height+obj.top-obj.getBoundingRect().top);
            obj.left = Math.min(obj.left, obj.canvas.width-obj.getBoundingRect().width+obj.left-obj.getBoundingRect().left);
          }
          var sX = obj.scaleX;
          var sY = obj.scaleY;
          obj.width *= sX;
          obj.height *= sY;
          obj.scaleX = 1;
          obj.scaleY = 1;
          obj.dirty = true;
          if(obj.objid && canvasbox._objects){
            for(let robj in canvasbox._objects){  //remove parent object
              if(canvasbox._objects[robj].objParentId && canvasbox._objects[robj].objParentId==obj.objid){
                let subObj = canvasbox._objects[robj]
                subObj.setCoords();
                subObj.top  = obj.top - 30
                subObj.left = obj.left
              }
            }
            canvasbox.renderAll();
          }
          syncCanvasboxObjects()
        });
      }

      syncCanvasboxObjects()
    }

    const syncCanvasboxObjects = () => {
      if(layout.value.action.edit.type=='imageObjectDetection' || layout.value.action.edit.type=='MULTICLASS'){
        layout.value.canvas.canvas._objects = {}
        let currentObjects                  = canvasbox.getObjects()
        let ObjectsExcludedCursor            = []
        for(let index in currentObjects){ 
          if(currentObjects[index].type!='circle')ObjectsExcludedCursor.push(currentObjects[index])
        }
        layout.value.canvas.canvas._objects = ObjectsExcludedCursor.length ? ObjectsExcludedCursor :  {} //canvasbox._objects ? canvasbox._objects :  {}
        canvasbox.renderAll();
      }
      if(layout.value.action.edit.type=='MULTILABEL'){
        layout.value.canvas.canvas._objects = {};
        let currentObjects = canvasbox.getObjects();
        let ObjectsExcludedCursor = [];
        let objidMap = new Map();
        for (let index in currentObjects) {
          let obj = currentObjects[index];
          if (obj.type !== 'circle'){
            if(!objidMap.has(obj.objid)) {
              ObjectsExcludedCursor.push(obj);
              objidMap.set(obj.objid, true);
            }else{ canvasbox.remove(obj); }
          }
        }
        layout.value.canvas.canvas._objects = ObjectsExcludedCursor.length ? ObjectsExcludedCursor : {};
      }
      canvasbox.renderAll();
    };

    const resetPropsCanvas = () => {
     layout.value.canvas.tools.modalZoom      = 100;
     layout.value.canvas.tools.modalPosition  = { top:0, right:0 };
     layout.value.canvas.tools.modalZoomProps = { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 }
     //layout.value.canvas.tools.modalBrush     = { size: 30, type: 'move' };
     //layout.value.canvas.drawing = false
    }

    const resetObjectIds = (update = false) => {
      let currentObjects  = canvasbox.getObjects()
      for(let c=0;c<currentObjects.length;c++){ 
        if(update && currentObjects[c].objid==update?.objid){
          currentObjects[c].name   = update.tag
          currentObjects[c].set({stroke: update.stroke}).setCoords(); 
        }
        currentObjects[c].objid = currentObjects[c].uniqueId = false 
      }
      for(let c=0;c<currentObjects.length;c++){ 
        currentObjects[c].objid    = currentObjects[c].uniqueId = getMultiObjId(currentObjects[c].name)
      }
      syncCanvasboxObjects()
      canvasbox.renderAll();
    }

    const getMultiObjId = (tagEdit) => {
      let newObjectId  = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";
      let _curObjects  = canvasbox.getObjects()
      for(let robj in _curObjects){
        if(_curObjects[robj].name && _curObjects[robj].name.toString().toLowerCase() == tagEdit.toString().toLowerCase()){
          let _objid = _curObjects[robj].objid.toString().split("-")
          if(_objid.length>1)newObjectId = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-" + parseInt(parseInt(_objid[2])+1)
        }
      }
      return newObjectId
    }

    const backgroundFilters = (type=false, cached = false) => {
      if(canvasbox.backgroundImage){
        if(cached){
          layout.value.canvas.tools.modalZoomProps            = { contrast: 0, brightness: 0, saturation: 0, vibrance: 0 }
          layout.value.canvas.tools.modalZoomProps.brightness = layout.value.canvas.imageFilters.brightness;
          layout.value.canvas.tools.modalZoomProps.contrast   = layout.value.canvas.imageFilters.contrast; 
          layout.value.canvas.tools.modalZoomProps.saturation = layout.value.canvas.imageFilters.saturation;
          layout.value.canvas.tools.modalZoomProps.vibrance   = layout.value.canvas.imageFilters.vibrance; 
        }
        //canvasbox.backgroundImage._originalElement.crossOrigin= "anonymous"
        for(let index in canvasbox.backgroundImage.filters){
          if(type=="saturation" && canvasbox.backgroundImage.filters[index].saturation)canvasbox.backgroundImage.filters.splice(index, 1); 
          if(type=="brightness" && canvasbox.backgroundImage.filters[index].brightness)canvasbox.backgroundImage.filters.splice(index, 1);
          if(type=="contrast" && canvasbox.backgroundImage.filters[index].contrast)canvasbox.backgroundImage.filters.splice(index, 1);  
          if(type=="vibrance" && canvasbox.backgroundImage.filters[index].vibrance)canvasbox.backgroundImage.filters.splice(index, 1); 
          if(!type)canvasbox.backgroundImage.filters.splice(index, 1); 
        }
        
        if(!type || type=="saturation")canvasbox.backgroundImage.filters.push(new fabric.Image.filters.Saturation({ saturation: parseFloat(layout.value.canvas.tools.modalZoomProps.saturation) }));
        if(!type || type=="brightness")canvasbox.backgroundImage.filters.push(new fabric.Image.filters.Brightness({ brightness: parseFloat(layout.value.canvas.tools.modalZoomProps.brightness) })); 
        if(!type || type=="contrast")canvasbox.backgroundImage.filters.push(new fabric.Image.filters.Contrast({ contrast: parseFloat(layout.value.canvas.tools.modalZoomProps.contrast) })); 
        if(!type || type=="vibrance")canvasbox.backgroundImage.filters.push(new fabric.Image.filters.Vibrance({ vibrance: parseFloat(layout.value.canvas.tools.modalZoomProps.vibrance) })); 
        //console.log(canvasbox.backgroundImage.filters)
        try {  
          canvasbox.backgroundImage.applyFilters();
          canvasbox.renderAll();
        } catch(e) { console.log('error: '+e) }  
      }
    }

    const refreshPropsCanvas = async (type=false) => {
      if(canvasbox.backgroundImage){
        console.log("refreshPropsCanvas",type)
        backgroundFilters(type)

        if((!type || type=="brushSize") && (layout.value.action.edit.type=='MULTICLASS' || layout.value.action.edit.type=='MULTILABEL') ){

          if(layout.value.action.edit.type=='MULTILABEL')await canvasEvents()

          let tagEdit  = layout.value.action.edit.tag.toString().split("/").pop()
          if($h.isObject(layout.value.action.edit.tag))tagEdit = layout.value.action.edit.tag.path.toString().split("/").pop()
          let _tagData = await rosepetalModel.dataset.getTag(layout.value.action.edit.dataset.path.toString().split("/").pop(),tagEdit)
          
          //PENCIL
          if(layout.value.canvas.tools.modalBrush.type=="line"){ 
            canvasbox.freeDrawingBrush         = new fabric.PencilBrush(canvasbox);
            canvasbox.freeDrawingBrush.color   = $h.hexToRgb(_tagData?.color, layout.value.canvas.tools.maskOpacity)
            canvasbox.freeDrawingBrush.width   = layout.value.canvas.tools.modalBrush.size * canvasbox.getZoom() //layout.value.canvas.tools.modalBrush.size; 
            canvasbox.freeDrawingBrush.opacity = layout.value.canvas.tools.maskOpacity; 
            canvasbox.freeDrawingBrush.createPath = function(pathData) {
              //const currentZoom = canvasbox.getZoom(); canvasbox.setZoom(currentZoom);
              //canvasbox.freeDrawingBrush.width   = Math.round(layout.value.canvas.tools.modalBrush.size * currentZoom) 
              var path = new fabric.Path(pathData, {
                fill:            null,
                stroke:          _tagData?.color,
                strokeWidth:     canvasbox.freeDrawingBrush.width,
                strokeLineCap:   'round',
                strokeLineJoin:  'round',
                opacity:         layout.value.canvas.tools.maskOpacity, 
                strokeUniform:   true,
                paintFirst:      'fill',
                erasable:        true,
              });
              console.log('stroke with del path dibujado', path.strokeWidth, path)
              path.shadow = null
              path.set('erasable', true);
              if(layout.value.action.edit.type=='MULTILABEL'){
                let newObjectId  =  getMultiObjId(tagEdit)
                /*
                let newObjectId  = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-1";
                let _curObjects  = canvasbox.getObjects()
                for(let robj in _curObjects){
                  if(_curObjects[robj].name && _curObjects[robj].name.toString().toLowerCase() == tagEdit.toString().toLowerCase()){
                    let _objid = _curObjects[robj].objid.toString().split("-")
                    if(_objid.length>1)newObjectId = "MULTI-" + tagEdit.toString().replace(/ /g, '_').replace(/-/g, '_') + "-" + parseInt(parseInt(_objid[2])+1)
                  }
                }
                */
                path.toObject = (function(toObject) {
                  return function() {
                    return fabric.util.object.extend(toObject.call(this ), {
                      name:     tagEdit,
                      objid:    newObjectId,
                      uniqueId: newObjectId
                    });
                  };
                })(path.toObject);

                path.initialize = (function(_super) {
                  return function(path, options) {
                    _super.call(this, path, options);
                    this.name     = options && options.name ? options.name : '';
                    this.objid    = options && options.objid ? options.objid : '';
                    this.uniqueId = options && options.uniqueId ? options.uniqueId : '';
                  };
                })(path.initialize);

                path.name     = tagEdit;
                path.objid    = newObjectId;
                path.uniqueId = newObjectId;
                path.set('uniqueId', newObjectId);
              }
              canvasbox.bringToFront(path);
              syncCanvasboxObjects()
              return path;
            }
          }

          //CUSTOM CURSOR  
          if(layout.value.canvas.tools.modalBrush.type=="line"){
            //let cursorSize              = Math.round(layout.value.canvas.tools.modalBrush.size / layout.value.canvas.tools.zoom * currentZoom);
            let cursorSize                = layout.value.canvas.tools.modalBrush.size * canvasbox.getZoom(); console.log('size del cusor (initial)', cursorSize)
            canvasbox.freeDrawingCursor = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="${canvasbox.freeDrawingBrush.color}"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;
          }

          /*if(layout.value.canvas.tools.modalBrush.type=="line"){
            let _currentObjects = canvasbox.getObjects()
            var haveCursorZoom  = {}
            var haveStroke      = false
            for(let robj in _currentObjects){ 
              if(_currentObjects[robj].name && _currentObjects[robj].name=="cursorBrush"){ 
                if(_currentObjects[robj].zoomX)haveCursorZoom.zoomX = _currentObjects[robj].zoomX
                if(_currentObjects[robj].zoomY)haveCursorZoom.zoomY = _currentObjects[robj].zoomY
                if(_currentObjects[robj].stroke)haveStroke = _currentObjects[robj].stroke
                console.log('Eliminamos el cursor a canvasbox', _currentObjects[robj]); 
                canvasbox.remove(_currentObjects[robj]);  
              }  
            }
            console.log('No tenemos cursor, lo añadimos!')
            canvasbox.freeDrawingCursor = 'none'
            let newPointerCursor = { 
              left: 0, 
              top: 0, 
              radius:  canvasbox.freeDrawingBrush.width / 2, 
              fill:    canvasbox.freeDrawingBrush.color, 
              stroke:  null, 
              opacity: layout.value.canvas.tools.maskOpacity ? layout.value.canvas.tools.maskOpacity : 1, 
              originX: 'center',
              originY: 'center', 
              name: 'cursorBrush',
              hoverCursor: 'pointer',
              erasable: false,
            }
            if(Object.keys(haveCursorZoom).length){
              newPointerCursor.zoomX = haveCursorZoom.zoomX
              newPointerCursor.zoomY = haveCursorZoom.zoomY
            }
            if(haveStroke){
              console.log('el cursor anterior tenia stroke', haveStroke)
              newPointerCursor.stroke = haveStroke
            }
            var mousecursor = new fabric.Circle(newPointerCursor);
            if(haveStroke)mousecursor.set('stroke', haveStroke)
            console.log('Añadimos el cursor a canvasbox!',mousecursor)

            canvasbox.add(mousecursor);
            canvasbox.bringToFront(mousecursor);

            canvasbox.off('mouse:move')
            canvasbox.off('mouse:out')

            //let originalOpacity = mousecursor.opacity;
            canvasbox.on('mouse:move', function(obj) {
              mousecursor.set({ visible: true });
              //console.log('Movemos el cursor!', mousecursor)
              let pointer = canvasbox.getPointer(obj.e)
              mousecursor.set({
                left: pointer.x, 
                top: pointer.y, 
                hoverCursor: 'pointer',
                radius: canvasbox.freeDrawingBrush.width / 2,
                fill: canvasbox.freeDrawingBrush.color, 
                //stroke: null, 
                //opacity: layout.value.canvas.tools.maskOpacity, 
              });
             
              mousecursor.set({ opacity: 1 });
              let _currentObjects = canvasbox.getObjects()
              for(let robj in _currentObjects){ 
                if(_currentObjects[robj].name && _currentObjects[robj].name=="cursorBrush"){ 
                  _currentObjects[robj].opacity = 1;
                  _currentObjects[robj].set({ opacity: 1 });
                }  
              }
              
              canvasbox.bringToFront(mousecursor);
              canvasbox.renderAll();
            });
            canvasbox.on('mouse:out', function() {
              console.log('aparcamos el cursor!',mousecursor)
              //mousecursor.set({left: -100, top: -100,});
              mousecursor.set({ opacity: 0 });
              canvasbox.renderAll();
            });
            canvasbox.renderAll();
          }*/
          
          //ERASE
          if (layout.value.canvas.tools.modalBrush.type === "erase") {
            canvasbox.isDrawingMode          = true;
            canvasbox.freeDrawingBrush       = new fabric.EraserBrush(canvasbox);
            canvasbox.preserveObjectStacking = false;
            canvasbox.freeDrawingBrush.width = layout.value.canvas.tools.modalBrush.size;
            canvasbox.freeDrawingBrush.color = 'transparent';
            let eraserOpacity                = 0.4
            let cursorSize                   = layout.value.canvas.tools.modalBrush.size * canvasbox.getZoom(); //Math.round(canvasbox.freeDrawingBrush.width / layout.value.canvas.tools.zoom * canvasbox.getZoom());
            canvasbox.freeDrawingCursor      = `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="${cursorSize}" height="${cursorSize}"><circle cx="${cursorSize/2}" cy="${cursorSize/2}" r="${cursorSize/2}" fill="rgba(0, 0, 0, ${eraserOpacity})"/></svg>') ${cursorSize/2} ${cursorSize/2}, auto`;
            
            canvasbox.freeDrawingBrush.globalCompositeOperation = 'destination-out';
            canvasbox.freeDrawingBrush.id = 'erasure';
            canvasbox.renderAll();
            canvasbox.on('path:created', function(e) {
              if (e.path.id === 'erasure') {
                e.path.globalCompositeOperation = 'destination-out';
                canvasbox.renderAll();
              }
            });
          }

          //UNIFY COLOR AND OPACITY
          let currentObjects  = canvasbox.getObjects()
          for(let index in currentObjects){ 
            if(layout.value.action.edit.type=='MULTICLASS'){ 
              currentObjects[index].set( { stroke: _tagData?.color, shadow: null, fill: null, opacity: layout.value.canvas.tools.maskOpacity })
            }
            currentObjects[index].set( { opacity: layout.value.canvas.tools.maskOpacity })
          } 
          canvasbox.freeDrawingBrush.width = layout.value.canvas.tools.modalBrush.size;
        }
        if((!type || type=="objectType") && layout.value.action.edit.type=='imageObjectDetection'){ await canvasEvents(); mouseBoundingDraw() }
        canvasbox.renderAll();
      }else{ 
       console.log('canvas not back image')
       setTimeout(async () => { /*console.log('reRender');*/ await refreshPropsCanvas(type); if(canvasbox)canvasbox.renderAll(); }, 2000);
      }
    }

    const mouseBoundingDraw = () => {
      //mouse drawing
      if(layout.value.canvas.tools.modalObjMove && layout.value.canvas.drawing)canvasbox.defaultCursor = "move"
      if(layout.value.canvas.tools.modalObjBB && layout.value.canvas.drawing){
        canvasbox.defaultCursor = "crosshair"
        canvasbox.selectable    = true;
        canvasbox.scalable      = true;
        canvasbox.selection     = true;
        var rect, origX, origY, tagNameBox; 
        canvasbox.on('mouse:down', async function(o){
          if(!o.target){ 
            var pointer = canvasbox.getPointer(o.e);
            origX = pointer.x;
            origY = pointer.y;
            layout.value.canvas.isDown = true;
            rect = new fabric.Rect({
                left: origX,
                top: origY,
                originX: 'left',
                originY: 'top',
                width: pointer.x-origX,
                height: pointer.y-origY,
                angle: 0,
                fill: $h.hexToRgb("#ff0000", layout.value.canvas.tools.bbOpacity),
                strokeWidth: 1,
                stroke: "#ff0000",
                hasControls:    true,
                lockRotation:   true,
                lockScalingX:   false,
                lockScalingY:   false,
                flipX:          false,
                flipY:          false,
                selectable:     true,
                scalable:       true,
                lockScalingFlip: true,
            });
            highlightObject(canvasbox.getObjects().length)
            rect.cornerSize = 10;
            rect.setControlsVisibility({
              mt: true,
              mb: true,
              ml: true,
              mr: true,
              bl: false,
              br: false,
              tl: true,
              tr: true,
              mtr: false
            });
            let newObjectId = 'MB'+Math.random();
            rect.toObject = (function(toObject) {
              return function(propertiesToInclude) {
                return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                  name: 'new', //Layout.value.canvas.tags && Layout.value.canvas.tags[0].id ? Layout.value.canvas.tags[0].id : ''
                  objid: newObjectId
                });
              };
            })(rect.toObject);
            rect.set({name: 'new' })
            rect.set({objid: newObjectId })
            if(layout.value.canvas.drawing && layout.value.canvas.tools.modalObjBB)canvasbox.add(rect);
            tagNameBox = new fabric.Text('Label name', {
                        fill: 'red',
                        left: origX,
                        top: origY-26,
                        fontSize: 23,
                        padding: 5,
                        objParentId: newObjectId,
                        selectable: false,
                        hoverCursor: "default",
                        hasControls: false
                    });
            if(layout.value.canvas.drawing && layout.value.canvas.tools.modalObjBB)canvasbox.add(tagNameBox);        
            tagNameBox.toObject = (function(toObject) {
              return function(propertiesToInclude) {
                return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                  objParentId: newObjectId
                });
              };
            })(tagNameBox.toObject);     
            tagNameBox.set({objParentId: newObjectId })   
            syncCanvasboxObjects()             
            }
        });
        canvasbox.on('mouse:move', async function(o){
            if (!layout.value.canvas.isDown)return;
            var pointer = canvasbox.getPointer(o.e);  
            if(layout.value.canvas.tools.modalObjBB){
              clearCrosshairLines()
              var lineX = new fabric.Line([0, Math.abs(pointer.y), canvasbox.width, Math.abs(pointer.y)], { stroke: 'red', strokeWidth: 1, selectable: false, crossline: true }); canvasbox.add(lineX);
              var lineY = new fabric.Line([Math.abs(pointer.x), 0, Math.abs(pointer.x), canvasbox.height], { stroke: 'red', strokeWidth: 1, selectable: false, crossline: true }); canvasbox.add(lineY);
              canvasbox.renderAll();
            }
            if(origX>pointer.x)rect.set({ left: Math.abs(pointer.x) });
            if(origY>pointer.y)rect.set({ top: Math.abs(pointer.y) });
            rect.set({ width: Math.abs(origX - pointer.x) });
            rect.set({ height: Math.abs(origY - pointer.y) });
            canvasbox.renderAll();
            syncCanvasboxObjects()
        });
        canvasbox.on('mouse:up', async function(){ 
          layout.value.canvas.isDown = false;  
          highlightObject(canvasbox.getObjects().length,true);  
          clearCrosshairLines()
          canvasbox.renderAll();
        });
        canvasbox.on('mouse:out', async function(){ clearCrosshairLines() });
      }
    }

    const clearCrosshairLines = () => {
      for (var i = 0; i < canvasbox.getObjects().length; i++) {
        if(canvasbox.getObjects()[i]){
          var obj = canvasbox.getObjects()[i];
          if (obj.crossline)canvasbox.remove(obj);
        }
      }
      canvasbox.renderAll();
    }

    const removeMask = async () => {
      if (confirm('Are you sure you want to remove all masks?')) {
        let currentObjects  = canvasbox.getObjects()
        for(let c=0;c<currentObjects.length;c++){ if(currentObjects[c].type!='circle')canvasbox.remove(currentObjects[c]); }
        syncCanvasboxObjects()
      }
    }

    const saveImage = async () => {

      syncCanvasboxObjects() 
      let currentObjects            = canvasbox.getObjects()
      layout.value.canvas.loading   = true
      var saveError                 = false
      var updatedMask               = false

      for(let c=0;c<currentObjects.length;c++){ 
        currentObjects[c].set({ opacity: 1 }) 
        if(currentObjects[c].type!='rect' && currentObjects[c].type!='path')canvasbox.remove(currentObjects[c]); //delete all object no rect or path
      }

      if(layout.value.action.edit.type=='MULTICLASS' || layout.value.action.edit.type=='MULTILABEL'){
        canvasbox.setBackgroundImage(null);
        let canvasJSON = '{}'
        if(layout.value.action.edit.type=='MULTICLASS')canvasJSON = canvasbox.toJSON();
        if(layout.value.action.edit.type=='MULTILABEL')canvasJSON = canvasbox.toJSON(['name', 'objid', 'uniqueId']);

        let tagEdit         = layout.value.action.edit.tag.toString().split("/").pop()
        if($h.isObject(layout.value.action.edit.tag))tagEdit = layout.value.action.edit.tag.path.toString().split("/").pop()
        let _tagData        = await rosepetalModel.dataset.getTag(layout.value.dataset.id, tagEdit)
        if(Object.keys(currentObjects).length && canvasJSON){
           updatedMask = await rosepetalModel.image.updateMask(layout.value.action.edit.id, { imageJson: JSON.stringify(canvasJSON), width: canvasbox.width, height: canvasbox.height, color: _tagData?.color ? _tagData?.color : "#FB48C4" })
           if(updatedMask.error)saveError = true
        }else{
          updatedMask = await rosepetalModel.image.updateMask(layout.value.action.edit.id, {})
          if(updatedMask.error)saveError = true
        } 

        if(layout.value.action.edit.type=='MULTICLASS')await rosepetalModel.image.setTag(layout.value.action.edit.id, layout.value.dataset.id, tagEdit)
          
        if(layout.value.action.edit.type=='MULTILABEL'){
          var multiTags          = {}
          let multiObjs          = [] 
          let multiObjsContained = []
          if(Object.keys(layout.value.canvas.canvas._objects).length){
            for(let i = 0; i < Object.keys(layout.value.canvas.canvas._objects).length; i++) {
              if(!multiTags[layout.value.canvas.canvas._objects[i].objid] && layout.value.canvas.canvas._objects[i].name){
                var multiObjTag = rosepetalModel._firebase.firestore().collection("dataset").doc(layout.value.dataset.id.toString()).collection('tag').doc(layout.value.canvas.canvas._objects[i].name.toString())
                let newPathOpt  = {
                  type:         layout.value.canvas.canvas._objects[i].type,
                  tag:          multiObjTag,
                  name:         layout.value.canvas.canvas._objects[i].name,
                  objid:        layout.value.canvas.canvas._objects[i].objid,
                  uniqueId:     layout.value.canvas.canvas._objects[i].uniqueId,
                }
                multiObjs.push(newPathOpt)
                multiObjsContained.push(multiObjTag)
              }
            }
          }
          await rosepetalModel.image.setMultilabelTags(layout.value.action.edit.id, multiObjs, multiObjsContained)
        }
      }

      if(layout.value.action.edit.type=='imageObjectDetection'){
        let objectTags      = [] 
        for(let index in currentObjects){ 
            if(currentObjects[index].type=="rect"){
              let tagNameUpd = currentObjects[index].name.toString()
              if(currentObjects[index].objid){
                for(let sobj in currentObjects){ 
                  if(currentObjects[sobj].objParentId && currentObjects[sobj].objParentId==currentObjects[index].objid){
                    tagNameUpd = currentObjects[sobj].text.toString()
                  }
                }
              }
              let newtagRef = rosepetalModel._firebase.firestore().collection("dataset").doc(layout.value.dataset.id.toString()).collection('tag').doc(tagNameUpd)
              let saveBd = {
                tag: newtagRef,
                type: currentObjects[index].type,
                h: currentObjects[index].height / layout.value.canvas.backImageOriginalSize.height,
                w: currentObjects[index].width / layout.value.canvas.backImageOriginalSize.width,
                x: currentObjects[index].left / layout.value.canvas.backImageOriginalSize.width,
                y: currentObjects[index].top / layout.value.canvas.backImageOriginalSize.height

               /*h: currentObjects[index].height / imgsize.height,
                w: currentObjects[index].width / imgsize.width,
                x: currentObjects[index].left / imgsize.width,
                y: currentObjects[index].top / imgsize.height,*/
              }
              if(layout.value.dataset.tags && !layout.value.dataset.tags[tagNameUpd])
                await rosepetalModel.dataset.createTag(layout.value.dataset.id, { tag: tagNameUpd.toString(), name: tagNameUpd.toString(), unclassified: false, imageCounter: Number(0) })
              objectTags.push(saveBd)
            }
          }
          await rosepetalModel.image.setObjectTags(layout.value.action.edit.id, objectTags)
          layout.value.dataset.tags = await rosepetalModel.dataset.getTags(props.options.dataset)
        /*fabric.Image.fromURL(layout.value.action.edit.gimage, async function (imgsize) { 
         
        }) */
      } 

      if(!saveError){
        await rosepetalModel.image.setSet(layout.value.action.edit.id, layout.value.action.edit.set)
        await rosepetalModel.image.setComments(layout.value.action.edit.id, layout.value.action.edit.comments ? layout.value.action.edit.comments : '')
        $h.NotificationTxt({ text: "Successfully image saved", position: "center" });
        if(props.options.format=='mosaic')await loadMosaicImages()
        if(layout.value.editMap[layout.value.dataset.id]?.nav?.next)editImage(layout.value.editMap[layout.value.dataset.id].nav.next)
        else{
          modalClose('modal-image-editor')
          await exitEditor();
        }
      }else{
        $h.NotificationTxt({ text: "Failed to save image", position: "center" });
        //fabric.Image.fromURL(layout.value.action.edit.gimage, async function (img) {   
          //layout.value.backImageOriginalSize.blobImage.erasable   = false;
          canvasbox.setHeight(layout.value.canvas.backImageOriginalSize.blobImage.height); 
          canvasbox.setWidth(layout.value.canvas.backImageOriginalSize.blobImage.width);
          canvasbox.setBackgroundImage(layout.value.canvas.backImageOriginalSize.blobImage, canvasbox.renderAll.bind(canvasbox), {
              scaleX: canvasbox.width / layout.value.canvas.backImageOriginalSize.blobImage.width,
              scaleY: canvasbox.height / layout.value.canvas.backImageOriginalSize.blobImage.height,
              crossOrigin: 'anonymous'
          });
          canvasbox.backgroundImage._originalElement.crossOrigin= "anonymous"
          canvasbox.renderAll();
          layout.value.canvas.loading   = false
          /*img.erasable   = false;
          canvasbox.setHeight(img.height); 
          canvasbox.setWidth(img.width);
          canvasbox.setBackgroundImage(img, canvasbox.renderAll.bind(canvasbox), {
              scaleX: canvasbox.width / img.width,
              scaleY: canvasbox.height / img.height,
              crossOrigin: 'anonymous'
          });
          canvasbox.backgroundImage._originalElement.crossOrigin= "anonymous"
          canvasbox.renderAll();
          layout.value.canvas.loading   = false*/
        //})
        
      }
    }

    const deleteImage = async () => { 
      modalClose('modal-image-editor')
      await rosepetalModel.image.delete(layout.value.action.delete.id)
      $h.NotificationTxt({ text: "Successfully image deleted", position: "center" });
      if(props.options.format=='mosaic')await loadMosaicImages()
    }

    const thumb = async (image) => { 
      if(cash("#ThumbcanvasBox_"+props.options.format+"_"+image.id)){
        cash("#ThumbcanvasBox_"+props.options.format+"_"+image.id).html('<canvas id="thumbcanvas_'+props.options.format+"_"+image.id+'"></canvas>')
        let thumbCanvas = new fabric.Canvas('thumbcanvas_'+props.options.format+"_"+image.id, {selection: false, defaultCursor: 'pointer'});
        fabric.Image.fromURL('data:image/png;base64,'+image.img_base64_val, async function (img) { 
            thumbCanvas.setHeight(img.height); 
            thumbCanvas.setWidth(img.width);
            thumbCanvas.setBackgroundImage(img, thumbCanvas.renderAll.bind(thumbCanvas), { scaleX: thumbCanvas.width / img.width, scaleY: thumbCanvas.height / img.height });
            thumbCanvas.renderAll();
            
            //load mask json
            if(image?.mask?.imageJson){
                var json = false //JSON.parse(image.mask.imageJson)
              
                try { json = JSON.parse(image.mask.imageJson)
                } catch (error) {  //Compressed with lz-string
                  json = JSON.parse(lzstring.decompressFromUint8Array(image.mask.imageJson.toUint8Array())) ;
                }
                thumbCanvas.loadFromJSON(json, function() { thumbCanvas.setBackgroundImage(img, thumbCanvas.renderAll.bind(thumbCanvas), { scaleX: thumbCanvas.width / img.width, scaleY: thumbCanvas.height / img.height }); thumbCanvas.renderAll(); }, 
                function(o, object) {
                  object.selectable = false;
                  var widthRatio    = thumbCanvas.width / image?.mask?.width;
                  var heightRatio   = thumbCanvas.height / image?.mask?.height;
                  var scaleFactor   = Math.min(widthRatio, heightRatio);
                  object.set({
                    strokeWidth: object.strokeWidth * scaleFactor,
                    scaleX: object.scaleX * scaleFactor,
                    scaleY: object.scaleY * scaleFactor,
                    left: object.left * scaleFactor,
                    top: object.top * scaleFactor,
                    opacity: 0.8,
                    selectable: false,
                    hoverCursor: "default"
                  });
                  thumbCanvas.renderAll();
              });
            }   

            //load boundingBox
            if(image.tags){
                for(let index in image.tags){ 
                    let savedObject = image.tags[index] 
                    if(savedObject.type=="rect"){
                      let RectOpt = {
                          left:     savedObject.x * img.width,
                          top:      savedObject.y * img.height,
                          originX:  'left',
                          originY:  'top',
                          width:    savedObject.w*img.width,
                          height:   savedObject.h*img.height,
                          angle:    0,
                          fill:     'transparent',
                          strokeWidth: 1,
                          transparentCorners: false,
                      }
                      RectOpt.selectable    = false;   
                      let newObject         = new fabric.Rect(RectOpt);
                      let tagName           = savedObject.tag.path.toString().split('/').pop()
                      let _tagData          = await rosepetalModel.dataset.getTag(layout.value.dataset.id, tagName)
                      if (tagName) {
                        let bbColor = _tagData.color ? _tagData.color : 'ff0000'
                        let newObjectId    = index+tagName;
                        newObject.toObject = (function(toObject) {
                          return function(propertiesToInclude) {
                            return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                              name: tagName,
                              objid: newObjectId
                            });
                          };
                        })(newObject.toObject); 
                        newObject.set({stroke: bbColor })
                        newObject.set({name: tagName })
                        newObject.set({objid: newObjectId })
                        thumbCanvas.add(newObject);
                        let TextOpt = {
                                fill: bbColor,
                                left: savedObject.x * img.width,
                                top: ((savedObject.y * img.height)-28),
                                fontSize: 20,
                                padding: 5,
                                objParentId: newObjectId,
                                hoverCursor: "default"
                            }
                        TextOpt.selectable = false;   
                        var tagNameBox = new fabric.Text(tagName, TextOpt);
                        tagNameBox.toObject = (function(toObject) {
                          return function(propertiesToInclude) {
                            return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                              objParentId: newObjectId
                            });
                          };
                        })(newObject.toObject);     
                        tagNameBox.set({objParentId: newObjectId })
                        thumbCanvas.add(tagNameBox);
                      }
                    }
                  }
            }

            //change canvas height adjust image
            let newWidth         = 195;
            let newHeight        = (img.height / img.width) * newWidth;
            let mosaicCanvases   = document.getElementsByClassName("mosaicCanvas");
            let canvasContainers = [];
            for (var i = 0; i < mosaicCanvases.length; i++) {  canvasContainers = canvasContainers.concat(Array.from(mosaicCanvases[i].getElementsByClassName("canvas-container"))); }
            if(canvasContainers.length)for(i = 0; i < canvasContainers.length; i++) { canvasContainers[i].style.height = newHeight+"px"; }
            if(cash(".fixedMosaicBoxBottom"))cash(".fixedMosaicBoxBottom").css('height',(newHeight+110)+"px")  

        });
        thumbCanvas.renderAll();
      }
    }

    const autoDetection = async () => {
        layout.value.canvas.mlPredictions = []
        const pic0        = new Image();
        pic0.crossOrigin  = "anonymous"
        pic0.setAttribute('crossOrigin', 'anonymous');
        pic0.src         = layout.value.action.edit.gimage
        pic0.onload = async () => {
          layout.value.canvas.tools.autoLoading = true
          pic0.objApi = layout.value.canvas.tools.modalDetection.api
          pic0.gsUri  = layout.value.action.edit.uri
          await $ml.detect(pic0).then( (predictions) => { 
            //if(predictions.class)console.log(predictions.class)
            layout.value.canvas.mlPredictions = predictions; 
            predictions.forEach(prediction => {
              let newObjectId = 'MB'+Math.random();
              let newPredictionObject = new fabric.Rect({
                left: prediction.bbox[0],
                top: prediction.bbox[1],
                originX: 'left',
                originY: 'top',
                width: prediction.bbox[2],
                height: prediction.bbox[3],
                angle: 0,
                fill: $h.hexToRgb("#ff0000", layout.value.canvas.tools.bbOpacity),
                strokeWidth: 1,
                stroke: "#ff0000", 
                transparentCorners: false,
                hasControls:    true,
                lockRotation:   true,
                lockScalingX:   false,
                lockScalingY:   false,
                flipX:          false,
                flipY:          false,
                selectable:     true,
                scalable:       true,
                centeredScaling: false,
                lockScalingFlip: true,
              }) 
              newPredictionObject.cornerSize = 10;
              newPredictionObject.setControlsVisibility({
                mt: true,
                mb: true,
                ml: true,
                mr: true,
                bl: false,
                br: false,
                tl: true,
                tr: true,
                mtr: false
              });
              canvasbox.add(newPredictionObject);
              canvasbox._objects[canvasbox.getObjects().length-1].toObject = (function(toObject) {
                                                                                return function(propertiesToInclude) {
                                                                                  return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                                                                                    name:'new',
                                                                                    objid:newObjectId
                                                                                  });
                                                                                };
                                                                              })(canvasbox._objects[canvasbox.getObjects().length-1].toObject);
                                                                              canvasbox._objects[canvasbox.getObjects().length-1].set({name: 'new' })
                                                                              canvasbox._objects[canvasbox.getObjects().length-1].set({objid: newObjectId })
              var tagNameBox = new fabric.Text('Label name', { //prediction.class
                          fill: 'red',
                          left: prediction.bbox[0],
                          top: prediction.bbox[1]-26,
                          fontSize: 23,
                          padding: 5,
                          objParentId: newObjectId,
                          selectable:  false,
                          hoverCursor: "default"
                      });
              tagNameBox.toObject = (function(toObject) {
                return function(propertiesToInclude) {
                  return fabric.util.object.extend(toObject.call(this, "toDatalessObject", propertiesToInclude), {
                    objParentId: newObjectId
                  });
                };
              })(tagNameBox.toObject);     
              tagNameBox.set({objParentId: newObjectId })       
              canvasbox.add(tagNameBox);
              syncCanvasboxObjects()
              canvasbox.renderAll();
            })
            layout.value.canvas.tools.autoLoading = false
            syncCanvasboxObjects()
            canvasbox.renderAll();
          }).catch( async (error) => {  console.log(error) });
        }
        syncCanvasboxObjects()
    }

    const removeObject = async (objectItemId) => {
      if(canvasbox._objects){
        for(let robj in canvasbox._objects){  //remove parent object
          if(canvasbox._objects[robj].objParentId && canvasbox._objects[robj].objParentId==objectItemId){
            var TXTobject = canvasbox.item(robj);
            canvasbox.remove(TXTobject);
          }
        }
        for(let robj in canvasbox._objects){  //remove main object
          if(canvasbox._objects[robj].objid && canvasbox._objects[robj].objid==objectItemId){
            var mainObject = canvasbox.item(robj);
            canvasbox.remove(mainObject);
          }
        }
        highlightObject(canvasbox.getObjects().length,true)
        canvasbox.renderAll();
      }
      syncCanvasboxObjects()
      if(layout.value.action.edit.type=='MULTILABEL')
        setTimeout(async () => { 
          resetObjectIds()
        }, 500);
    }

    const updCanvastObjectTxt = async (event,objectItem) => {
      if(canvasbox._objects[objectItem]){
        if(canvasbox._objects[objectItem].objid){
          for(let icobj in canvasbox._objects){ 
            if(canvasbox._objects[icobj].objParentId && canvasbox._objects[icobj].objParentId==canvasbox._objects[objectItem].objid){
              canvasbox._objects[icobj].text = event.target.value;
              canvasbox.renderAll();
            }
          }
        }
      } 
      syncCanvasboxObjects()
    }
    
    const canvaSetObject = async (event,objectItem) => {
        let tc = layout.value.dataset.tags[event.target.value] ? layout.value.dataset.tags[event.target.value].color : '#ff0000'
        if(event.target.value=='new')tc = "#ff0000"
        canvasbox._objects[objectItem].set({stroke: tc, fill: $h.hexToRgb(tc, layout.value.canvas.tools.bbOpacity)  })  
        canvasbox._objects[objectItem].toObject = (function(toObject) {
                return function(propertiesToInclude) {
                  return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
                    name: event.target.value
                  });
                };
              })(canvasbox._objects[objectItem].toObject);
              canvasbox._objects[objectItem].set({name: event.target.value })   
        if(canvasbox._objects[objectItem]){
        if(canvasbox._objects[objectItem].objid){
          for(let icobj in canvasbox._objects){ 
            if(canvasbox._objects[icobj].objParentId && canvasbox._objects[icobj].objParentId==canvasbox._objects[objectItem].objid){
              canvasbox._objects[icobj].text = canvasbox._objects[objectItem].name
              canvasbox._objects[icobj].set({fill: canvasbox._objects[objectItem].stroke }) 
              break
            }
          }
        }
        canvasbox.renderAll();
      }
      syncCanvasboxObjects()
    }
    
    const highlightObject = async (objectItem,leave = false) => {
      if(canvasbox._objects){

        var hightOpacity = layout.value.canvas.tools.maskOpacity
        if(layout.value.action.edit.type=='imageObjectDetection')hightOpacity = layout.value.canvas.tools.bbOpacity
        
        for(let index in canvasbox._objects){ 
          if(index!=objectItem)canvasbox._objects[index].set({opacity: 0})
          if(leave)canvasbox._objects[index].set({opacity: hightOpacity })
        }

        if(canvasbox._objects[objectItem]){
          if(canvasbox._objects[objectItem].objid){
            for(let icobj in canvasbox._objects){ 
              if(layout.value.action.edit.type=='imageObjectDetection'){
                if(canvasbox._objects[icobj].objParentId && canvasbox._objects[icobj].objParentId==canvasbox._objects[objectItem].objid){
                  canvasbox._objects[icobj].set({opacity: hightOpacity})
                }
              }
              if(layout.value.action.edit.type=='MULTILABEL'){
                if(canvasbox._objects[icobj].objid && canvasbox._objects[icobj].objid==canvasbox._objects[objectItem].objid){
                  canvasbox._objects[icobj].set({opacity: hightOpacity})
                }
              }
            }
          }
        } 
        canvasbox.renderAll();
      }
      syncCanvasboxObjects()
    }

    const loadMosaicImages = async (opt={}) => {
      layout.value.mosaic.images  = {}
      layout.value.mosaic.loading = true
      layout.value.mosaic.dataset = await rosepetalModel.dataset.get(props.options.dataset)
      layout.value.dataset.tags   = await rosepetalModel.dataset.getTags(props.options.dataset, layout.value.mosaic.dataset.type=='imageObjectDetection' ? false :true )
      let imagesFilters           = { datasetID: props.options.dataset }
      if(opt.filters){
        imagesFilters.objByTag = layout.value.mosaic.tagId
        imagesFilters.limit    = layout.value.mosaic.limit
      }else{
        if(props.options?.tagId){
          imagesFilters.tagId        = props.options.tagId
          imagesFilters.objByTag     = layout.value.mosaic.tagId 
        }else layout.value.mosaic.tagId  = 'all' 
        if(props.options?.limit)imagesFilters.limit    = props.options.limit
        layout.value.mosaic.limit    = props.options?.limit ? props.options.limit : 10
        layout.value.mosaic.order    = (layout.value.mosaic.dataset.type=='imageObjectDetection' ||  layout.value.mosaic.dataset.type=='MULTILABEL') ? 'created' : 'updated' 
        layout.value.mosaic.orderObj = 'nolabel' 
      }
      if(layout.value.mosaic.dataset.type=='imageObjectDetection' && layout.value.mosaic.orderObj== 'nolabel')layout.value.mosaic.order = 'created'
      if(layout.value.mosaic.order=="updated")imagesFilters.order = { order: "updatedAt", type: "desc"  }
      if(layout.value.mosaic.dataset.type=='imageObjectDetection')imagesFilters.objtagsType = layout.value.mosaic.orderObj
      layout.value.mosaic.images  = await rosepetalModel.dataset.getImages(imagesFilters)
      /*if(layout.value.mosaic.dataset.type=='imageObjectDetection'){
        layout.value.mosaic.images.media  = await rosepetalModel.image.sortImagesByDate(layout.value.mosaic.images.media , layout.value.mosaic.order+'Date' )
      }*/
      setTimeout(async () => { for(let i in layout.value.mosaic.images.media){ if(layout.value.mosaic.images.media[i])await thumb(layout.value.mosaic.images.media[i]) } }, 30);
      if(document.getElementById('BtnDatasetLabeling'))cash('#BtnDatasetLabeling').insertBefore('#rpBtnReloadDataset').removeClass('hidden')
      layout.value.mosaic.loading = false;
    }

    const copyImage = async () => {
      layout.value.action.copy.error = false
      if(layout.value.action.edit.id && layout.value.action.copy?.to){
        layout.value.action.copy.loading = true
        let copy = await rosepetalModel.image.copy(layout.value.action.edit.id, { dataset: layout.value.action.copy.to, tag: layout.value.action.copy.toTag, mask: layout.value.action.copy.toMasks })
        if(!copy.error){
          layout.value.action.copy.toEdit     = copy.id
          layout.value.action.copy.toDataset  = await rosepetalModel.dataset.get(layout.value.action.copy.to)
          layout.value.action.copy.loading    = false
          layout.value.action.copy.success    = true
        }else{ layout.value.action.copy.error  = copy.error; layout.value.action.copy.loading = false}
      }
    }

    const labeling = async () => { layout.value.labeling.active = true }

    const downloadImage = async () => {
      let download = await rosepetalModel.image.download(layout.value.action.edit.id)
      if(download.message)$h.NotificationTxt({ text: download.message, position: "center" });
    }

    const editorMenu = (navId) => {
      let menuItem = document.querySelector('#' + navId);
      if(menuItem.classList.contains('selected')){
        menuItem.classList.remove('selected');
        if (menuItem.id && document.querySelector('.' + menuItem.id) && document.querySelector('.' + menuItem.id).classList.contains('sel')) {
          document.querySelector('.' + menuItem.id).classList.remove('sel');
        }
      }else{
        menuItem.classList.add('selected');
        if (menuItem.id && document.querySelector('.' + menuItem.id)) {
          document.querySelector('.' + menuItem.id).classList.add('sel');
        }
      }
    }

    const load = async (opt={}) => { 
      if(props.options.id){
        if(props.options.id=='labeling')labeling()
        else editImage(props.options.id)
      }
      layout.value.dataset.id     = props.options.dataset
      layout.value.datasets       = await rosepetalModel.dataset.list()
      layout.value.dataset.tags   = await rosepetalModel.dataset.getTags(props.options.dataset)
      if(props.options.format=='mosaic' || props.options.format=='mosaicTag')await loadMosaicImages(opt)
      
    }

    const mnt = async () => { 
      await load()
      /*let dsRef             =  rosepetalModel._firebase.firestore().collection("dataset").doc(props.options.dataset.toString())
      setTimeout(async () => { 
        layout.value.listener  = rosepetalModel._firebase.firestore().collection('image').where('dataset', '==', dsRef).onSnapshot((snapshot) => {
              const changes = snapshot.docChanges();
              if(props.options.format=='mosaic')
                changes.forEach(async (change) => { 
                  let reloadMosaic = false;
                  if(change.type=='removed')reloadMosaic = true
                  if(change.type=='added'  && !change?._delegate?.doc?.metadata?.fromCache && change?._delegate?.doc?.metadata?.hasPendingWrites)reloadMosaic = true //&& change?._delegate?.newIndex==0
                  if(reloadMosaic)await loadMosaicImages()
              }) 
            });
       }, 5000);*/
    }

    const cleanEditor = async () => {
      layout.value.canvas.tools.zoom = false
      layout.value.action.edit.gimage      = false
      if(layout.value?.listener)layout.value.listener()
      if(canvasbox){
        canvasbox.dispose()
        canvasbox = false
      }
      await loadMosaicImages({ filters: true})
    };

    const exitEditor = async () => { 
      await cleanEditor()
      if(document.getElementById('BtnLabelingReload')){
        setTimeout(async () => { document.getElementById('BtnLabelingReload').click(); }, 5);
        router.push('/datasets/'+props.options.dataset+'/labeling') 
      }else router.push('/datasets/'+props.options.dataset); 
     };

    const linkToBrowser = () => { router.push({ name: 'side-menu-data', params: { datasetID: props.options.dataset } });  };

    /*DEV TOOLS*/
    const setImagesUpdatedDate = async  () => { 
      let action = await rosepetalModel.dataset.setImagesUpdatedDate(props.options.dataset)
      if(!action.error)console.log('Images updated date setted Ok ',action.updateCount)
    }
    /**/

    onMounted( async () => { 
      await mnt()
    });

    onUnmounted( async () => {
      await cleanEditor()
    });
  
    watch(() => layout.value.mosaic.min, async () => { if(!layout.value.mosaic.min)await load() });
    watch(() => props.options.dataset, async () => { await load() });
    watch(() => props.options.id, async () => { await load() });
    watch(() => layout.value.reload, async () => { await load() });
    watch(() => layout.value.canvas.drawing, async () => { 
      //if(layout.value.canvas.drawing && layout.value.action.edit.type=='MULTICLASS'){ layout.value.canvas.tools.modalBrush.type='move'; }
      //if(layout.value.canvas.drawing && layout.value.action.edit.type=='imageObjectDetection'){ layout.value.canvas.tools.modalObjMove = true; layout.value.canvas.tools.modalObjBB = false; }
      await initCanvas();
    });
    watch(() => layout.value.canvas.tools.modalZoomProps.saturation, async () => { await refreshPropsCanvas('saturation') });
    watch(() => layout.value.canvas.tools.modalZoomProps.brightness, async () => { await refreshPropsCanvas('brightness') });
    watch(() => layout.value.canvas.tools.modalZoomProps.contrast, async () => { await refreshPropsCanvas('contrast') });
    watch(() => layout.value.canvas.tools.modalZoomProps.vibrance, async () => { await refreshPropsCanvas('vibrance') });
    watch(() => layout.value.canvas.tools.modalBrush.size, async () => { await refreshPropsCanvas('brushSize') });
    watch(() => layout.value.canvas.tools.modalBrush.type, async () => { await refreshPropsCanvas('brushSize') });
    watch(() => layout.value.canvas.tools.maskOpacity, async () => { await refreshPropsCanvas('brushSize') });
    watch(() => layout.value.canvas.tools.bbOpacity, async () => { await refreshPropsCanvas('objectType') });
    watch(() => layout.value.canvas.tools.modalObjBB, async () => { await refreshPropsCanvas('objectType'); });
    watch(() => layout.value.editor.navOpt.order, async () => { await setNavEditor() });

    watch(() => layout.value.action.copy.to, async () => { 
      layout.value.action.copy.toTags     = {}
      layout.value.action.copy.toMasks    = false
      layout.value.action.copy.success    = false
      if(layout.value.action.copy.to){
        layout.value.action.copy.toTags   = await rosepetalModel.dataset.getTags(layout.value.action.copy.to)
        if(Object.keys(layout.value.action.copy.toTags).length){
          layout.value.action.copy.toTag          = Object.keys(layout.value.action.copy.toTags)[0];
          layout.value.action.copy.validate = true
        }else layout.value.action.copy.validate = false
      }else{
        layout.value.action.copy.toTags   = []
        layout.value.action.copy.validate = false
      }
     });
    
    return {
      props,
      router,
      layout,
      modal,
      modalClose,
      editImage,
      saveImage,
      deleteImage,
      initCanvas,
      resetPropsCanvas,
      initialCenterImage,
      refreshPropsCanvas,
      canvaSetObject,
      highlightObject,
      removeObject,
      updCanvastObjectTxt,
      autoDetection,
      removeMask,
      exitEditor,
      downloadImage,
      linkToBrowser,
      resetObjectIds,
      setNavEditor,
      imageFiltersChanged,
      backgroundFilters,
      loadMosaicImages,
      setImagesUpdatedDate,
      editorMenu,
      copyImage,
      labeling,
      SliderZoom
    };
  },
  methods: {
      back: async function () { this.props.options.format = 'mosaic' },
      reLoad: async function () { this.layout.mosaic.loading = true; this.layout.reload = this.layout.reload ? false : true; },
      closeHelper: async function () { this.layout.helper.active = false; },
      drawingStatus: async function (newStat) { this.layout.canvas.drawing = newStat; },
      refreshLabeling: async function () { if(this.props.options?.labeling)this.$parent.refreshLabeling() },
      dsTabImages() { if(document.getElementById('mdTabImages'))document.getElementById('mdTabImages').click();},
      goDataset: async function (ds) { if(this.$parent?.goDataset)this.$parent.goDataset(ds) },
      hideLabeling: async function(){ this.layout.labeling.active = false; },
      goEditImage: async function (id) { this.editImage(id) },
  }
});
</script>
<style>

.fixedMosaicBox{ display: block; float: left; width: 250px; background-color: #fff; z-index:99;overflow: hidden; margin-right: 15px; --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);} /*position: fixed; */
.fixedMosaicBoxContent{ height: 500px; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; }
.fixedMosaicBoxContent::-webkit-scrollbar { display: none; }
.mosaicCanvas .canvas-container{ width: 195px !important; cursor: pointer !important;height: 195px }
/*.fixedMosaicBoxBottom{ width: 800px; position: fixed; left: 0; bottom: 20px; left: calc(50% - 405px); height: 100PX; background-color: #fff; z-index:99;overflow: hidden;}
.fixedMosaicBoxContentBottom{ width: 200px; -ms-overflow-style: none; scrollbar-width: none; overflow-x: scroll; }
.fixedMosaicBoxContentBottom::-webkit-scrollbar { display: none; }*/


.fullSizeEditor{ position: fixed; left: 0; top: 0; width: 100%; height: 100%; background-color: #fff; z-index:999999;overflow: hidden;}
.fullSizeEditor img{ width: 100%; height: 100%; object-fit: contain; }
.modal-image-editor{ width: 100% !important;min-width: 600px; margin: 0 !important; height: 100% !important;}
.modal-image-editor .modal-content{ height: 100% !important;}
#canvasContent {display: flex;justify-content: center; width: 100%;}
#canvasContent .canvas-container{ width: 100% !important;}
#imgEditComments{display: block;position: fixed;left: 20px;bottom: 20px;}
#commentsBox{position: fixed; top: calc( 50% - 180px );left: calc( 50% - 300px );margin: 0;width: 500px;min-height: 180px;}
#muticlass-image-mode-top{ width: 100px;display: block;position: fixed;top: 0; left: 5px;font-size: 12px;background-color: rgba(0,0,0,0.4) ;padding: 0 10px;opacity: 0.7;z-index: 999; color: #fff; height: 22px;}
#editor-zoom-top{display: block;position: fixed;top: 0; left: 106px;font-size: 12px;background-color: rgba(0,0,0,0.4);padding: 0 5px;opacity: 0.7;z-index: 999;color: #fff; height: 22px; width: 80px; text-align: center;}
#muticlass-image-date-top{display: block;position: fixed;top: 0; left: 188px;font-size: 12px;background-color: #fff;padding: 0 10px;opacity: 0.7;z-index: 999; height: 22px;}
#muticlass-image-size-top{width: 95px;display: block;position: fixed;top: 0; left: 323px;font-size: 12px;background-color: #fff;padding: 0 10px;opacity: 0.7;z-index: 999; height: 22px; text-align: center;}
#muticlass-image-name-top{display: block;position: fixed;top: 0; left: 420px;font-size: 12px;background-color: #fff;padding: 0 10px;opacity: 0.7;z-index: 999; height: 22px;}

#editorLeftPanel{-ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;}
#applyFiltersLoading{ z-index: 999;position: fixed;width: 100%;background-color: rgba(0,0,0,0.9);height: 100vh;margin: 0;padding-top: 10%;}

.rpEditorMenu {  width: 100%;font-size: 13px; }
.rpEditorMenu li { background-color: #f1f5f8; width: 100%; cursor: pointer; padding: 10px; margin-bottom: 1px;display: block; }
.rpEditorMenu ul li:hover { background-color: #f1f5f8 }
.rpEditorMenu ul li.selected { background-color: #f1f5f8;}
.rpEditorMenu ul li .arrow {display: block;float: right;width: 0;height: 0;border-top: 8px solid transparent;border-bottom: 7px solid transparent;border-left: 7px solid #ccc; margin: 2px 8px 0 0;}
.rpEditorMenu ul li.selected .arrow{ transform: rotate(90deg);}
.rpEditorMenu ul li.menu-content { background-color: #fff; padding: 10px 15px; font-size: 12px; display: none; cursor: default;}
.rpEditorMenu ul li.menu-content.sel{ display: block; }

</style>

