import { _firebase as $_firebase } from "@/model/firebase";
//import { tag as $tag } from "@/model/tag";
import { helper as $h } from "@/utils/helper";
import moment from 'moment';
import config from '../etc/rosepetal.json';

const functions = $_firebase.firebase().app().functions(config.firebaseRegion);

const images = {
    async get(doc=false,dates=false){
        if(doc){
            let mon = $_firebase.firestore().collection('image').doc(doc)
            const snapshot = await mon.get(); let item = snapshot.data(); if(item)item.id = snapshot.id
            return item;
        }else{
            let images = []
            //let tags   = await $tag.init();
            let set    = $_firebase.firestore().collection('image')
            if(dates){
                if(dates[0]){ let i = moment(dates[0]+" 00:00:00", 'DD/MM/YYYY H:i:s').valueOf(); set = set.where('date', '>=', i); }
                if(dates[1]){ let e = moment(dates[1]+" 23:59:59", 'DD/MM/YYYY H:i:s').valueOf(); set = set.where('date', '<=', e); }
            }
            set  = set.orderBy("date", "desc")
            await set.get().then(snapshot => { snapshot.forEach(async doc => { 
                let item = doc.data();
                let img_base64_val  = null
                if(item.imageData && item.imageData._delegate._byteString.binaryString)img_base64_val = btoa(item.imageData._delegate._byteString.binaryString);
                /*let date = new Date(item.date);*/
                //let tagName = tags[item.tag]
                
                let imgData = { 
                            id:      doc.id, 
                            tag:     'Undefined', // item.tag, 
                            tagname: 'Undefined', //tagName,
                            name:    item.name, 
                            date:    $h.getTimestampDate(item.date),
                            seconds: item.date,
                            image:   "",
                            image_data: img_base64_val, 
                            prediction: item.prediction,
                            monitor: 'Undefined'
                        }

                if(item.dataset && item.dataset.id)imgData.dataset  = item.dataset.id
                if(item.tag.id)imgData.tagname = item.tag.id=="0" ? 'Unclassified' : item.tag.id 
                if(item.tag && item.tag.color)imgData.tagColor      = item.tag.color
                if(item.monitor && item.monitor.id)imgData.monitor  = item.monitor.id.toString()
                if(item.predictionTime)imgData.predictionTime     = item.predictionTime
                if(item.uri)imgData.uri = item.uri
                //console.log(imgData)
                images.push(imgData);
            }); });
            return images
        }
    },
    async getUri(doc){
        let i = $_firebase.firestore().collection('image').doc(doc)
        const snapshot = await i.get(); let item = snapshot.data(); if(item)item.id = snapshot.id
        return item && item.uri ? item.uri : false;
    },
    async getb64(gsUri) {
        let image = []
        const action = functions.httpsCallable('api/image/getb64/image/' + gsUri.replace(/\//g, "--"));
        await action({}).then(async response => {
            if (response.data) image.push(response.data)
        }).catch(async (error) => { console.log(error) });
        return image
    },
    async deleteStorage(uri){
        let imageUri = uri.replace(config.modelBucket + "/", "").replace(/\//g, "--")
        const action = functions.httpsCallable('api/image/delete/image/'+imageUri);
        await action({}).then(() => { }).catch(async (error) => { console.log(error) });
    },
    async byDateRange(dateRange){
        return await this.get(false,dateRange);
    },
    async updateSet(doc, set) {
        await $_firebase.firestore().collection("image").doc(doc.toString()).update({ "set": set });
    },
    async updateMask(doc, mask) {
        await $_firebase.firestore().collection("image").doc(doc.toString()).update({ "mask": mask });
    },
    async updateTag(Tag){
        let tagRef  = await $_firebase.firestore().collection("dataset").doc(Tag.datasetID.toString()).collection('tag').doc(Tag.tagID.toString())
        Tag.data = { tag: tagRef, updatedAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp() }
        await $_firebase.firestore().collection("image").doc(Tag.id.toString()).update(Tag.data);
    },
    async byRef(collection, doc){
        let tagRef  = await $_firebase.firestore().collection(collection).doc(doc.toString())
        const snapshot = await tagRef.get(); let item = snapshot.data(); if(item)item.id = snapshot.id
        return item;
    },
    async setObjectTags(doc, tags, comments = "") {
        console.log('setObjectTags: ', doc.toString() , tags, comments)
        await $_firebase.firestore().collection('image').doc(doc.toString()).update({ "tags": tags, "comments": comments });
    },
    getObjectTags(t) {
        if (t && t.length) {
            let tagsArr = []
            for (let obt in t) {
                if (t[obt].tag) {
                    let tagRef  = t[obt].tag.path.toString().split('/')
                    let tagName = false
                    if (tagRef[tagRef.length-1])tagName = tagRef[tagRef.length-1]
                    if (tagName) {
                        if (!tagsArr[tagName]) { tagsArr[tagName] = 1 } else { tagsArr[tagName]++; }
                        //console.log(tagName, t[obt].type, t[obt].x, t[obt].y, t[obt].w, t[obt].h)
                    }
                }
            }
            /*
            //t = JSON.stringify(t)
            //let tagsArr = []
            JSON.parse(t,async (key, value) => {
                console.log(key,value)
                if (key) {
                    let tagData = false;
                    if (key == "tag")tagData = await this.byRef('dataset', value)
                    console.log(tagData)
                    let tagbb = JSON.parse(value)
                    for (let i in tagbb) {
                        if (i == "name") { if (!tagsArr[tagbb[i]]) { tagsArr[tagbb[i]] = 1 } else { tagsArr[tagbb[i]]++; } }
                    }
                }
            });
            */
            if (Object.keys(tagsArr).length) {
                let tagsArrList = ' '
                for (let objectTag in tagsArr) { tagsArrList += ' ' + objectTag + '(' + tagsArr[objectTag] + '),' }
                return tagsArrList.slice(0, -1)
            } else {
                return 'not labeled'
            }
        } else {
            return 'not labeled'
        }
    },
};

const install = app => {
  app.config.globalProperties.$images = images;
};

export { install as default, images as image };
