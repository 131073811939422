<template>
    <div>
        <div class="modal-body px-0 pt-0 text-left mt-6" v-if="layout.dataset">
          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0 pt-0 copyingFull" v-if="layout.copying">
            <div class="w-full text-center" style="background-color: #f1f2f1">
              <div v-if="layout.error">
                <div>{{ $t('An error occurred while copying the dataset') }}.</div>
                <div class="py-2 font-medium">{{ layout.error }}</div>
                <button type="button" class="btn bg-blue-500 text-white w-24 h-10 mt-3 zoom-in" @click="layout.copying = false;">{{ $t('Back') }}</button>
              </div>
              <div v-else>
                <div class="text-lg mb-8" style="font-size: 20px !important">
                  <span v-if="layout.success">{{ $t('Dataset copied successfully') }}</span><span v-else>{{ $t('Copying dataset') }}</span>
                </div>
                <div class="mb-10 text-left w-52 mx-auto">
                  <div class="my-2">
                    <div class="w-6 h-6 mr-3 inline-block align-middle" v-if="!layout.copying.dataset"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
                    <img v-else :src="require(`@/assets/images/rosepetal/icon/ok.png`)" class="w-6 mr-3 inline-block" /> 
                      {{ $t('Dataset') }} 
                  </div>
                  <div class="my-2">
                    <div class="w-6 h-6 mr-3 inline-block align-middle" v-if="layout.copying.tags!=layout.resume?.resume?.tagsCount"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
                    <img v-else :src="require(`@/assets/images/rosepetal/icon/ok.png`)" class="w-6 mr-3 inline-block" /> 
                    {{ $t('Tags') }} <span v-if="layout.resume?.resume?.tagsCount">({{ layout.copying.tags+"/"+layout.resume?.resume?.tagsCount }})</span> 
                  </div>
                  <div class="my-2">
                    <div class="w-6 h-6 mr-3 inline-block align-middle" v-if="layout.copying.images!=layout.resume?.resume?.imagesCount"><LoadingIcon icon="three-dots" class="w-8 h-8" /></div>
                    <img v-else :src="require(`@/assets/images/rosepetal/icon/ok.png`)" class="w-6 mr-3 inline-block" /> 
                    {{ $t('Images') }} <span v-if="layout.resume?.resume?.imagesCount">({{ layout.resume?.resume?.imagesCount }})</span> 
                    <div v-if="layout.copying.images==layout.resume?.resume?.imagesCount" class="ml-10 font-bold text-xs mt-3">
                      {{ $t('The copying of images to your new dataset has started, in a few minutes you will have all') }}.
                    </div>
                  </div>
                </div>
                <div v-if="layout.success">
                  <button type="button" class="btn bg-blue-500 text-white w-24 h-10 zoom-in" @click="goCopyDataset(layout.copying.dataset);">{{ $t('Manage') }}</button>
                </div>
                <div v-else>
                  <div class="text-xs mt-2 text-gray-700" style="font-size: 14px !important">{{$t("This process can take a few minutes")}}</div>
                  <span class="text-base text-red-600" style="line-height: 25px; font-size: 16px">{{$t("Please do not close your browser")}}</span>
                </div>
              </div>
              
            </div>
          </div>
          <div v-else class="grid chat grid-cols-12 gap-2 mt-0">
            <div :class="layout.resume.resume ? 'col-span-8' : 'col-span-12'">
              <div class="text-gray-700 px-5">
                <div class="grid chat grid-cols-12">
                  <div :class="!layout.resume.resume ? 'col-span-7' : 'col-span-12'">
                    <div class="text-gray-700">
                      <div class="text-normal mb-2 font-medium">{{ $t('Name') }} *</div>
                      <input type="text" class="form-control py-2 px-4 border-gray-200 text-gray-600 bg-gray-200" v-model="layout.dataset.copy.name"  />
                    </div>
                  </div>
                  <div :class="!layout.resume.resume ? 'col-span-5 ml-5' : 'col-span-12 mt-4'">
                    <div class="text-gray-700">
                      <div class="text-normal mb-2 font-medium">{{ $t('Type') }} *</div>
                      <select name="dstype" class="form-select cursor-pointer bg-white w-full border-gray-200 text-gray-600  bg-gray-200" v-model="layout.dataset.copy.type">
                        <option value="MULTICLASS">{{$t('Image classification with a single label')}}</option>
                        <option value="MULTILABEL">{{$t('Image classification with multiple labels')}}</option> 
                        <option value="imageObjectDetection">{{$t('Vision Object Detection')}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="mt-5">
                    <div class="text-normal mb-4 font-medium mt-3">{{ $t('Select the tags to include in the new dataset') }}:</div>
                    <table class="table table--sm text-xs mb-5 w-full table-layout" v-if="layout.dataset.copy.tagsCounter?.tags && layout.dataset.copy.tags">
                      <template v-if="layout.dataset.copy.dataset.type=='MULTICLASS' && layout.dataset.copy.tagsCounter.tags">
                        <tr class="bg-gray-100 h-8">
                            <td class="border w-12"></td>
                            <td class="border w-32">{{ $t('Tag') }}</td>
                            <td class="border w-40" :class="!layout.resume.resume ? 'hidden' : ''">{{ $t('New tag name') }}</td>
                            <td class="border w-20 text-center">{{ $t('Images') }}</td>
                        </tr>
                        <tr v-for="(c,t) in layout.dataset.copy.tagsCounter.tags" :key="t" class="bg-white hover:bg-blue-100 cursor-pointer">
                            <td class="border text-center"><input type="checkbox" class="form-check-input copyTag" :value="t" @change="resume()" v-if="c"  :class="layout.resume.loading ? 'hidden' : ''" /></td>
                            <td class="border text-left" v-if="layout.dataset.copy.tags[t]">{{ layout.dataset.copy.tags[t].name }}</td>
                            <td class="border text-left" :class="!layout.resume.resume ? 'hidden' : ''">
                              <template v-if="t!='0'">
                                <input v-if="layout.resume.resume.tags && layout.resume.resume.tags[t]" type="text" class="form-control py-2 px-4 text-xs bg-gray-100" v-model="layout.resume.resume.tags[t].name" />
                              </template>
                              <template v-else-if="layout.resume.resume.tags && layout.resume.resume.tags[t]">{{ layout.resume.resume.tags[t].name }}</template>
                            </td>
                            <td class="border text-center" >{{ c }}</td>
                        </tr>
                      </template>
                      <template v-if="(layout.dataset.copy.dataset.type=='imageObjectDetection' || layout.dataset.copy.dataset.type=='MULTILABEL') && layout.dataset.copy.tagsCounter.tagslabeled">
                        <tr>
                          <td class="border w-14"><input type="checkbox" class="form-check-input copyTag" :value="'0'" @change="resume()"  /></td>
                          <td class="border text-left" colspan="2">{{ $t('All images') }}</td>
                          <td class="border text-center">{{ layout.dataset.copy.tagsCounter.count }}</td>
                        </tr>
                      </template>
                    </table>
                </div>
              </div>
            </div>
            <div class="col-span-4" v-if="layout.resume.resume">
              <div class="bg-gray-100 p-5 rounded">
                <div v-if="layout.resume.loading"><div class="intro-y w-full py-4"><div class="flex items-center justify-center mx-10"><img :src="require(`@/assets/images/rosepetal/icon/loading2.gif`)" class="w-12" /></div></div></div>
                <div v-else-if="layout.resume.resume && layout.resume.validate" class="text-gray-600">
                  <div class="text-lg mb-1 text-gray-800">{{ $t('Resume') }}</div>
                  <div class="text-sm mb-1">{{ $t('Tags') }}</div>
                  <div class="text-xs mb-1 ml-2" v-for="(c,t) in layout.resume.resume?.tags" :key="t">
                    {{ c.name ? c.name.slice(0, 30)  : layout.dataset.copy.tags[t] ? layout.dataset.copy.tags[t].name.slice(0, 30) : t.slice(0, 30)}} 
                    <span class="float-right">{{ c.count }}</span>
                  </div>
                  <div class="text-sm pt-2">{{ $t('Total of images') }}: <span class="float-right font-bold" v-if="layout.resume.resume?.imagesCount">{{ layout.resume.resume?.imagesCount }}</span></div>
                </div>  
                <div v-else class="text-center">
                  <div class="flex items-center justify-center mx-10"><img :src="require(`@/assets/images/rosepetal/icon/copy.png`)" class="w-20" style="opacity: 0.2;" /></div>
                  <div class="text-sm mt-3">{{ $t('Copy Dataset') }}</div>
                </div>
                <div  v-if="layout.resume.validate && !layout.resume.loading">
                  <select class="form-select mt-3 sm:mr-2 cursor-pointer bg-white w-full bg-gray-100 text-xs">
                    <option :value="false">{{ $t('Not include the mask, only image')}}</option>
                  </select>
                </div>
                <div class="mt-5" v-if="layout.resume.validate && !layout.resume.loading">
                  <button type="button" @click="copyDataset()" class="btn w-full bg-green-600 text-white zoom-in font-normal">{{ $t('Make a copy') }}</button> 
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted , ref } from "vue";
import * as rosepetalModel from "rosepetal-model";
export default defineComponent({
  props: {
    dataset: {
      type: String,
      required: true
    }
  },
  created () {
    this.rosepetalModel = rosepetalModel
  },  

  setup(props) {
    
    const layout   = ref({
                          dataset:   { copy: { method: "all" , tags: {}, tagsCounter: {} } },
                          resume:    { validate: false , loading: false, resume: false },
                          copying:   false,
                      })

    const copyDataset = async () => {
      layout.value.error   = false
      layout.value.success = false
      layout.value.copying = { dataset: false, tags: 0, images: 0 }
      //copy dataset
      await rosepetalModel.dataset.create({ name: layout.value.dataset.copy.name, type: layout.value.dataset.copy.type, description: "Copied from "+layout.value.dataset.copy.dataset.name }).then( async response => {
        if(response.dataset){
          await rosepetalModel.dataset.createVertex(response.dataset).then( async resp => {
            if(!resp.error){
              layout.value.copying.dataset = response.dataset

              let tags        = {}
              let copyImages  = {}
              let _promises   = []
              let promises    = []

              for(let t in layout.value.resume.resume.tags){
                if(t!="0"){
                  if(!tags[layout.value.resume.resume.tags[t].name])tags[layout.value.resume.resume.tags[t].name] = [t]
                  else tags[layout.value.resume.resume.tags[t].name].push(t)
                }else{
                  let _promise = new Promise((resolve, reject) => {
                                      rosepetalModel.dataset.getImages({ datasetID: props.dataset , objByTag: t.toString() }).then( async _tagImages => {
                                        if(_tagImages.media)copyImages[t] = _tagImages.media
                                        else reject()
                                        layout.value.copying.tags   = 1
                                        resolve()
                                      })
                                    })
                  _promises.push(_promise) 
                } 
              }

              Promise.all(_promises).then(() => {
                for(let _tag in tags){
                  //console.log('+ create tag',_tag.toString(), tags[_tag])
                  let promiseTag = new Promise((resolve, reject) => {
                    rosepetalModel.dataset.createTag(response.dataset, { tag: _tag.toString(), name: _tag.toString(), unclassified: false }).then( async _createdTag => {
                      if(_createdTag.error)reject(_createdTag.error)
                      else resolve()
                    });
                  })
                  promises.push(promiseTag) 

                  if(!layout.value.copying.tags)layout.value.copying.tags = tags[_tag].length
                  else layout.value.copying.tags += tags[_tag].length
                  tags[_tag].forEach( _tId => {
                    let promise = new Promise((resolve, reject) => {
                      rosepetalModel.dataset.getImages({ datasetID: props.dataset , objByTag: _tId.toString() }).then( async _tagImages => {
                        if(_tagImages.media){
                          if(!copyImages[_tag.toString()])copyImages[_tag.toString()] = _tagImages.media
                          else copyImages[_tag.toString()] = copyImages[_tag.toString()].concat(_tagImages.media)
                        }else reject()
                        resolve()
                      })
                    })
                    promises.push(promise) 
                  })
                }

                Promise.all(promises).then(async () => {
                  let _datasetReqCopy = { images: {} }
                  for(let i in copyImages){
                    if(copyImages[i].length){
                      for (let j = 0; j < copyImages[i].length; j++) {
                        //console.log('------ copy to tag:',i , 'image', copyImages[i][j].id)
                        if(!layout.value.copying.images)layout.value.copying.images = 1
                        else layout.value.copying.images++
                        _datasetReqCopy.images[copyImages[i][j].id.toString().replace(/'/g,"")] = i 
                      }
                    }
                  }

                  if(Object.keys(_datasetReqCopy.images).length){
                    let responseCopy = await rosepetalModel.dataset.createCopyOperation(response.dataset ,{ images: JSON.stringify(_datasetReqCopy.images) })
                    if(!responseCopy.error)layout.value.success = true
                    else layout.value.error = responseCopy.error
                  }

                })
            })

            }else layout.value.error  = resp.error
          });
        }else layout.value.error  = response.error
      });
    }

    const resume = async () => {
      layout.value.resume.loading  = true
      layout.value.resume.validate = false
      let resume                   = { tags: {}, tagsCount: 0, imagesCount: 0 }   
      let checkTags                = document.querySelectorAll('.copyTag:checked');  
      checkTags.forEach(checkbox => {
        resume.tags[checkbox.value] = { count: layout.value.dataset.copy.tags[checkbox.value].imageCounter, name: layout.value.dataset.copy.tags[checkbox.value].name }
        resume.imagesCount          = resume.imagesCount + layout.value.dataset.copy.tags[checkbox.value].imageCounter
        resume.tagsCount++
      }) 
      if(!resume.imagesCount){
        layout.value.resume.resume   = false
        layout.value.resume.loading  = false
      }else{
        setTimeout(() => {
          layout.value.resume.loading  = false
          layout.value.resume.resume   = resume
          layout.value.resume.validate = true
        }, 1000);
      }
    }

    onMounted( async () => {
      layout.value.datasetOrigin               = props.dataset
      layout.value.dataset.copy.dataset        = await rosepetalModel.dataset.get(props.dataset)
      layout.value.dataset.copy.name           = layout.value.dataset.copy.dataset.name + "_copy"
      layout.value.dataset.copy.type           = layout.value.dataset.copy.dataset.type
      layout.value.dataset.copy.tags           = await rosepetalModel.dataset.getTags(layout.value.dataset.copy.dataset.id)
      layout.value.dataset.copy.tagsCounter    = await rosepetalModel.dataset.getTagsCounter(layout.value.dataset.copy.dataset.id, true)
    });

    return{
        layout,
        resume,
        copyDataset
    }
  },
  methods: {
      goCopyDataset: async function (d) { 
        if(this.$parent?.goDataset){
          let ds = await rosepetalModel.dataset.get(d)
          await this.$parent.goDataset(ds)
          await this.$parent.refreshDataset() 
        }
      },
  }
});
</script>

<style>
.copyTag {
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none;
  width: 22px !important; 
  height: 22px !important;
  border-radius: 4px;
  border: 2px solid #ccc; 
  margin: 0 ; 
  background-color: #ccc;
}

.copyTag:checked {
  background-color: #2ea272 !important; 
  border-color: #2ea272 !important; 
}

.copyTag:checked::before {
  content: '\2714'; 
  display: block;
  width: 16px;
  height: 16px;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 16px;
}
.copyingFull{ position: fixed !important; left: 0 !important; top: 0 !important; z-index: 999 !important; width: 100% !important; height: 100vh !important; background-color: #f1f2f1; }
</style>