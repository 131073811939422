
import { _firebase as $_firebase } from "@/model/firebase";
const functions = $_firebase.functions(); 

const others = {
    async httpsCallable(a = false) {
      let resp = {} 
      if(a){
        const action = functions.httpsCallable(a);
        await action({}).then(response => {resp = response }).catch((error) => { resp.error = error });
      }else{ resp.error = "action is required" }
      return resp
    },
    getApiMethods(opt = false) {
      let methods = [
        { 
          id:       "create-dataset", 
          name:     "create",  
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Create a new dataset", 
          default:     "The dataset will be created in the database",
          props: { 
            params: { 
                      options:   "Object / name: string (*), type: string MULTICLASS or imageObjectDetection (*), description: string", 
                    },
            required: ["options"]
          } 
        },
        { 
          id:       "create-dataset-vertex", 
          name:     "createVertex",  
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Validate to train, create selected dataset in Vertex Ai platform", 
          important:   "Requires internal API connection",
          default:     "By default, the system incorporates the dataset identifier in Vertex to the stored dataset information (autmol)",
          props: { 
            params: { 
                      datasetId:   "string", 
                    },
            required: ["datasetId"]
          } 
        },
        { 
          id:       "create-dataset-aws", 
          name:     "createAws",  
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Validate to train, upload the selected dataset images to S3 and create the project in Lookout vision", 
          default:     "By default, the system incorporates the project identifier in Lookout to the stored dataset information (aws)",
          important:   "Requires internal API connection, Once the upload has started, do not close your browser",
          props: { 
            params: { 
                      datasetId:   "string", 
                    },
            required: ["datasetId"]
          } 
        },
        { 
          id:       "list", 
          name:     "list", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get the list of project datasets.",
          default:     "Default order by created date desc",
          props: { 
            params: { 
                      type:        "MULTICLASS or imageObjectDetection", 
                      vertex:      "boolean (default false) Returns only vertex dataset", 
                      trained:     "boolean (default false) Returns only trained dataset", 
                      preview:     "boolean (default false) Get an example image of the dataset",   
                      search:      "string (default '') Search by name",
                      limit:       "integer"
                    }
          }  
        }, 
        { 
          id:       "get", 
          name:     "get", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get data from a dataset by id",
          props: { 
            params: { 
                      datasetId:  "string",
                      options:    "Object / models: boolean, tagsCounter: boolean, dataDivision: boolean, preview: boolean, ref: boolean"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "dataset-getvertex", 
          name:     "getVertex", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get the information of the dataset in vertex",
          important:   "Requires internal API connection",
          props: { 
            params: { 
                      datasetId:  "string"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "dataset-getaws", 
          name:     "getAws", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get the information of the dataset in Lookout Vision",
          important:   "Requires internal API connection",
          props: { 
            params: { 
                      datasetId:  "string"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "update-dataset", 
          name:     "update", 
          model:    "dataset", 
          source:   "model", 
          run:      false, 
          description: "Update or add any parameter to the dataset such as name or description",
          props: { 
            params: { 
                      datasetId:  "string",
                      tag:        "Object / name: string, description: string",
                    },
            required: ["datasetId", "tag"]
          }  
        }, 
        { 
          id:       "create-tag", 
          name:     "createTag", 
          model:    "dataset", 
          source:   "model", 
          run:      false, 
          description: "Create a new tag associated with the dataset",
          default:     "Apply string-to-color in case of not having a defined color in tag object parameter",
          props: { 
            params: { 
                      datasetId:  "string",
                      tag:        "Object / tag: string (*), name: string (*), description: string (*), imageCounter: integer (*), unclassified: boolean (*), color: string #RGB (*)",
                    },
            required: ["datasetId","tag"]
          }  
        }, 
        { 
          id:       "update-tag", 
          name:     "updateTag", 
          model:    "dataset", 
          source:   "model", 
          run:      false, 
          description: "Update or add any parameter to the tag such as name, color, description ...",
          props: { 
            params: { 
                      tag:  "Object / dataset: dataset Id (*), id: tag Id (*), data: Object (*)",
                    },
            required: ["tag"]
          }  
        }, 
        { 
          id:       "get-tags", 
          name:     "getTags", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get the list of associated tags",
          default:     "Apply string-to-color in case of not having a defined color",
          props: { 
            params: { 
                      datasetId:    "string",
                      unclassified: "Boolean (Default true)"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "get-tag", 
          name:     "getTag", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get tag data by Id",
          props: { 
            params: { 
                      datasetId:    "string",
                      tagId:    "string",
                    },
            required: ["datasetId","tagId"]
          }  
        }, 
        { 
          id:       "get-tags-counter", 
          name:     "getTagsCounter", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get a summary of the elements that are included in your dataset by tag",
          props: { 
            params: { 
                      datasetId:    "string",
                      unclassified: "Boolean (Default true)"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "get-images", 
          name:     "getImages", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get all the images of the dataset through filters. Default order by created date desc",
          props: { 
            params: { 
                      datasetId:     "string",
                      objDivision:   "string (TEST, VALIDATION or TRAIN) / Does not allow the use of a paginator", 
                      objtagsType:   "string (labeled or nolabel)",
                      objByTag:      "string (Tag_ID)",
                      pagination:    "Boolean",
                      perPage:       "Integer",
                      paginationQry: "Object (Paginator instance generated by the first request)",
                      action:        "string (init, first, prev, next or last)",
                      resume:        "Boolean (Get the list of images in reduced format) / Does not allow the use of a paginator"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "get-models", 
          name:     "getModels", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get trained models from dataset",
          props: { 
            params: { 
                      datasetId:    "string",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "get-status", 
          name:     "getStatus", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Check if the dataset is importing or training",
          optionals:   "Requires internal API connection",
          default:     "Automatically updates the local identifier of the model once the training is finished",
          props: { 
            params: { 
                      datasetId:    "string",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "get-dataset-last-log", 
          name:     "getLastLog", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Get the last log of a dataset",
          props: { 
            params: { 
                      datasetId:    "string",
                      opt:          "Object / preview: Boolean (Default false), byDate: Boolean (Default false)",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "csv", 
          name:     "getCsv", 
          model:    "dataset", 
          source:   "API", 
          path:     "api/dataset/DATASETID/csv",
          run:      true, 
          description: "Generates a csv for importing images to Vertex",
          optionals:   "Optional values % for test and validation as parameters ?validation=&test=",
          default:     "By default generate csv with auto % for training, test and validation",
          props: { 
            params: { 
                      datasetId:    "string",
                      test:         "integer",
                      validation:   "integer"
                    },
           required: ["datasetId"]
          }  
        }, 
        { 
          id:       "datadivision", 
          name:     "getDataDivision", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Obtain the current separation in test, validation and entry images of your dataset",
          props: { 
            params: { 
                      datasetId:    "string",
                      onlyLabeled:  "Boolean (Default false)",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "set-random-datadivision", 
          name:     "setRandDataDivision", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Randomly assigns a test, train or validation set to all images in the dataset",
          important: "This action is irreversible",
          props: { 
            params: { 
                      datasetId:    "string",
                      onlyLabeled:  "Boolean (Default false)",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "validate", 
          name:     "validateToTrain", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Validate the dataset for training",
          optionals:   "Requires minimal images and data division by set",
          props: { 
            params: { 
                      datasetId:    "string"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "dataset-normalAnomaly", 
          name:     "normalAnomaly", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Dataset tags division by normal or anomaly",
          props: { 
            params: { 
                      datasetId:  "string",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "upload-dataset-image", 
          name:     "uploadImage", 
          model:    "dataset", 
          source:   "model", 
          run:      false, 
          description: "Upload a single image to the dataset",
          props: { 
            params: { 
                      datasetId:  "string",
                      image:      "Buffer",
                      name:       "string",
                      tagId:      "string",
                      uid:        "string"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "upload-dataset-zip", 
          name:     "uploadZip", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Upload a zip file with the images to the dataset"
        }, 
        { 
          id:       "dataset-import-vertex", 
          name:     "uploadStorage", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Generates a csv, upload all dataset images to Vertex and create new annotation set for training",
          important:   "Requires internal API connection",
          default:     "By default generate csv with auto % for training, test and validation",
          response:    "Will obtain an operation identifier to track it", 
          props: { 
            params: { 
                      datasetId: "string",
                      options:   "Object / test: integer, validation: integer",
                    },
           required: ["datasetId"]
          }  
        }, 
        { 
          id:       "dataset-upload-s3", 
          name:     "uploadS3", 
          model:    "dataset", 
          source:   "model", 
          run:      false, 
          description:"Upload all dataset images to S3 bucket",
          default:    "Will be located in the datasets directory within the project directory",
          important:  "Requires internal API connection, Once the upload has started, do not close your browser",
          props: { 
            params: { 
                      datasetId: "string",
                    },
           required: ["datasetId"]
          }  
        }, 
        { 
          id:       "dataset-download", 
          name:     "downloadZip", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Download all images of the dataset in zip format",
          default:    "The download will start automatically",
          important:  "Once the download has started, do not close your browser",
          props: { 
            params: { 
                      datasetId: "string"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "train", 
          name:     "train", 
          model:    "dataset", 
          source:   "API", 
          path:     "api/dataset/model/NEW_MODEL_NAME-MULTICLASS",
          run:      false, 
          description: "Train a new model",
          optionals:   "A prior validation of the dataset is performed",
          default:     "Default auto %",
          important:   "Each training model entails added expenses",
          props: { 
            description: "As a single parameter separated by -",
            params: { 
                      datasetId:        "string",
                      modelName:        "string",
                      trainBudget:      "number / The budget expressed in milli node hours, that is, the value of 1000 in this field means 1 node hour /  Minimum is 1000 (1 hour)",
                      type:             "MULTICLASS or imageObjectDetection", 
                      annotationSetId:  "string"
                    },
            required: ["datasetId","modelName","trainBudget","type"]
          }  
        }, 
        { 
          id:       "delete", 
          name:     "delete", 
          model:    "dataset", 
          source:   "model", 
          run:      true, 
          description: "Delete a dataset by id",
          important: "This action is irreversible",
          props: { 
            params: { 
                      datasetId:    "string"
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "get-image", 
          name:     "get", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Get all the information related to the image",
          props: { 
            params: { 
                      imageId:  "string"
                    },
             required: ["imageId"]
          }  
        }, 
        { 
          id:       "get-image-set", 
          name:     "getSet", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Get image associated data division",
          important: "If the set is not defined in the image, returns PREDETERMINED",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "set-image-set", 
          name:     "setSet", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Set image associated data division",
          props: { 
            params: { 
                      imageId:  "string",
                      set:      "string (TEST, VALIDATION or TRAIN)"
                    },
            required: ["imageId", "set"]
          }  
        }, 
        { 
          id:       "get-image-tags", 
          name:     "getTags", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Get image associated tags",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "set-image-tags", 
          name:     "setTags", 
          model:    "image", 
          source:   "model", 
          run:      false, 
          description: "Set image associated tags",
          props: { 
            params: { 
                      imageId:  "string",
                      tags:     "string (tag name) or Array of objects { tag, w, h, x, y, type }"
                    },
            required: ["imageId","tags"]
          }  
        }, 
        { 
          id:       "remove-image-tags", 
          name:     "removeTags", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Remove all tags linked to the image",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "get-storage-uri", 
          name:     "getStorageUri", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Get the GS_URI path",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "get-storage-url", 
          name:     "getStorageUrl", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Get the url of the image from its location in gs",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "get-image-comments", 
          name:     "getComments", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description: "Get the comments on the image",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "set-image-comments", 
          name:     "setComments", 
          model:    "image", 
          source:   "model", 
          run:      false, 
          description: "Set the comments on the image",
          props: { 
            params: { 
                      imageId:  "string",
                      comments: "string"
                    },
            required: ["imageId","comments"]
          }  
        }, 
        { 
          id:       "get-image-dimensions", 
          name:     "getDimensions", 
          model:    "image", 
          source:   "model", 
          run:      false, 
          description: "Get the height and width dimensions of the image",
          props: { 
            params: { 
                      url:  "string (https or base64 image url)"
                    },
            required: ["url"]
          }  
        },
        { 
          id:       "download-image", 
          name:     "download", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description:   "Download the original image",
          props: { 
            params: { 
                      imageId:  "string (imageId) or Array of imageId"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "preview-image64", 
          name:     "previewB64", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description:   "Get image preview in base64",
          props: { 
            params: { 
                      imageId:  "string (imageId) or Array of imageId"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "preview-image", 
          name:     "preview", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description:   "Preview image with fabricJs",
          props: { 
            params: { 
                      imageId:  "string",
                      options:  "Object / storage: boolean, tags: boolean, width: integer, height: integer"
                    },
            required: ["imageId"]
          },
          code: [
                  { title: "Install and import the fabricJS library in the project", code: 'npm i fabric' },
                  { code: "import { fabric } from 'fabric'" },
                  { 
                    title: "Add HTML5 <canvas> tag element to your document and personalize your identifier", 
                    code: '<canvas id="YOUR_CANVAS_ID"></canvas>'
                  },
                  { 
                    title: "Optional. Define the preview display options", 
                    code: 'let options = { storage: true }'
                  },
                  { 
                    title: "Instance preview method", 
                    code: 'let response = await $image.preview(IMAGE_ID, options)' 
                  },
                  { 
                    title: "Instance fabricJS and add copy the init code", 
                    code: 'let preview = new fabric.Canvas("YOUR_CANVAS_ID", response.options)'
                  },
                  { 
                    code: 'fabric.Image.fromURL(response.canvas.image, function (img) { preview.setWidth(response.canvas.size.width); preview.setHeight(response.canvas.size.height); preview.setBackgroundImage(img, preview.renderAll.bind(preview), { scaleX: preview.width / img.width, scaleY: preview.height / img.height }); for (var t = 0; t < response.canvas.objects.bbox.length; t++) { if(response.canvas.objects.bbox[t])preview.add(response.canvas.objects.bbox[t]); } for (var b = 0; b < response.canvas.objects.text.length; b++) { if(response.canvas.objects.text[b])preview.add(response.canvas.objects.text[b]); }preview.renderAll()});'
                  }
                ]  
        }, 
        { 
          id:       "get-image-detect", 
          name:     "detect", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description:   "Automatically detects bounding box objects in the image",
          props: { 
            params: { 
                      imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "get-image-vision-detect", 
          name:     "detectOnline", 
          model:    "image", 
          source:   "API", 
          path:     "api/image/detect/image/GS_URI",
          run:      true, 
          description:   "Automatically detects bounding box objects in the image",
          important:     "Each online prediction to vision may entail additional costs",
          props: { 
            params: { 
                      gs_uri:  "string / Make sure to replace '/' with '--' when sending the URI parameter"
                    },
            required: ["gs_uri"]
          }  
        }, 
        { 
          id:       "get-image-coco-detect", 
          name:     "detectCoco", 
          model:    "image", 
          source:   "model", 
          run:      true, 
          description:   "Automatically detects bounding box objects in the image",
          props: { 
            params: { 
                      url:  "string"
                    },
            required: ["url"]
          }  
        }, 
        { 
          id:       "get-imageb64", 
          name:     "getB64", 
          model:    "image", 
          source:   "API", 
          path:     "api/image/getb64/image/GS_URI",
          run:      true, 
          description: "Get the gs image in base 64",
          important:   "Make sure to replace '/' with '--' when sending the URI parameter",
          props: { 
            params: { 
                      gs_uri:  "string / gs path"
                    },
            required: ["gs_uri"]
          }  
        }, 
        { 
          id:       "delete-image", 
          name:     "delete", 
          model:    "image", 
          source:   "model", 
          run:      false, 
          description: "Delete an image",
          important: "This action is irreversible",
          props: { 
            params: { 
                       imageId:  "string"
                    },
            required: ["imageId"]
          }  
        }, 
        { 
          id:       "delete-gs-image", 
          name:     "deleteStorage", 
          model:    "image", 
          source:   "API", 
          path:     "api/image/delete/image/GS_URI",
          run:      false, 
          description: "Delete an image from gs",
          important:   "Make sure to replace '/' with '--' when sending the URI parameter",
          props: { 
            description: "This action is irreversible",
            params: { 
                      gs_uri:  "string / gs path"
                    },
            required: ["gs_uri"]
          }  
        }, 
        { 
          id:       "model-create", 
          name:     "create", 
          model:    "model", 
          source:   "model", 
          run:      true, 
          description: "Create a new model by dataset",
          props: { 
            params: { 
                      datasetId:    "string",
                      modelName:    "string",
                      modelData:    "Object / Use this object to include additional information in the model"
                    },
            required: ["datasetId", "modelName"]
          }  
        }, 
        { 
          id:       "model-list", 
          name:     "list", 
          model:    "model", 
          source:   "model", 
          run:      true, 
          description: "Get the list of models",
          dafault:     "Default order by created date desc",
          props: { 
            params: { 
                      options: "Object / export: boolean (Returns only exported models), vertex: boolean (Default false - Returns only vertex dataset), limit: integer "
                    }
          }  
        }, 
        { 
          id:       "model-get", 
          name:     "get", 
          model:    "model", 
          source:   "model", 
          run:      true, 
          description: "Get the model data",
          props: { 
            params: { 
                      modelId: "string"
                    },
            required: ["modelId"]
          }  
        }, 
        { 
          id:       "model-update", 
          name:     "update", 
          model:    "model", 
          source:   "model", 
          run:      false, 
          description: "Update model data",
          props: { 
            params: { 
                      modelId: "string",
                      data:    "Object"
                    },
            required: ["modelId"]
          }  
        },
        { 
          id:       "model-export-url", 
          name:     "getExportUrl", 
          model:    "model", 
          source:   "model", 
          run:      true, 
          description: "Get model export api Url",
          props: { 
            params: { 
                    modelId:  "string"
                    },
            required: ["modelId"]
          }  
        },
        { 
          id:       "model-export", 
          name:     "export", 
          model:    "model", 
          source:   "model",
          run:      true, 
          description:  "Export the model to a gs bucket and save PB file",
          optionals:    "Requires internal API connection",
          props: { 
            params: { 
                    modelId:  "string"
                    },
            required: ["modelId"]
          }  
        },  
        { 
          id:       "model-export-api", 
          name:     "exportStorage", 
          model:    "model", 
          source:   "API", 
          path:     "api/model/export/model_id/MODELID-BUCKET", 
          run:      true, 
          description:  "Export the model to a gs bucket",
          important:    "Make sure to replace '/' with '--' when sending the URI parameter",
          props: { 
            params: { 
                    modelId:  "string / AutoMl model Id",
                    bucket:   "string / gs address"
                    },
            required: ["modelId"]
          }  
        }, 
        { 
          id:       "get-evaluation", 
          name:     "getEvaluations", 
          model:    "model", 
          source:   "model",
          run:      true, 
          description:  "Get model evaluation list",
          optionals:    "Requires internal API connection",
          props: { 
            params: { 
                    modelId:  "string"
                    },
            required: ["modelId"]
          }  
        },   
        { 
          id:       "render-evaluation", 
          name:     "renderEvaluations", 
          model:    "model", 
          source:   "model",
          run:      true, 
          description:  "Get in HTML format the most relevant information of the model evaluation",
          important:    "For render evaluation use <div v-html='RENDER_RESPONSE'></div>",
          optionals:    "Requires internal API connection",
          props: { 
            params: { 
                    modelId:  "string"
                    },
            required: ["modelId"]
          }  
        },   
        { 
          id:       "get-event", 
          name:     "get", 
          model:    "event", 
          source:   "model", 
          run:      true, 
          description: "Get the list of events or the data of an event",
          props: { 
            params: { 
                      api:        "Boolean", 
                      ui:         "Boolean", 
                      type:       "string", 
                      dataset:    "string", 
                      isCritical: "Boolean (Return only errors)", 
                      limit:      "Integer", 
                      last:       "Boolean  (Return only last event)",
                      preview:    "Boolean (Return previews images)",
                      byDate:     "Boolean (Return group events by date)",
                    }
          }  
        }, 
        { 
          id:       "save-event", 
          name:     "saveEvent", 
          model:    "event", 
          source:   "model", 
          run:      false, 
          description: "Save a new event",
          props: { 
            params: { 
                      name:     "string / Format example: dataset.import, dataset.training, model.update, model.delete ...",
                      payload:  "Object / The data linked to the event you want to save"
                    },
            required: ["name","payload"]
          }  
        }, 
        { 
          id:       "update-event", 
          name:     "updateEvent", 
          model:    "event", 
          source:   "model", 
          run:      false, 
          description: "Update event data",
          props: { 
            params: { 
                      eventId:  "string / Event ID",
                      payload:  "Object / With the parameters 'status' or 'discard' as Boolean to update"
                    },
            required: ["eventId","payload"]
          }  
        }, 
        { 
          id:       "discard-event", 
          name:     "discard", 
          model:    "event", 
          source:   "model", 
          run:      false, 
          description: "Mark the event as read and dismisses the error",
          props: { 
            description: "This action is irreversible",
            params: { 
                      eventId:  "string / Event ID"
                    },
            required: ["eventId"]
          }  
        },
        { 
          id:       "delete-event", 
          name:     "deleteEvent", 
          model:    "event", 
          source:   "model", 
          run:      false, 
          description: "Delete a event by id",
          props: { 
            description: "This action is irreversible",
            params: { 
                      eventId:  "string / Event ID"
                    },
            required: ["eventId"]
          }  
        },
        { 
          id:       "get-event-types", 
          name:     "getEventTypes", 
          model:    "event", 
          source:   "model", 
          run:      true, 
          description: "Get full event names list or by type",
          props: { 
            params: { 
                      type:  "string"
                    },
          }  
        },
        { 
          id:       "get-vertex-config", 
          name:     "getConfig", 
          model:    "vertex", 
          source:   "model", 
          run:      true, 
          description: "Get Vertex project configuration"
        }, 
        { 
          id:       "create-vertex-dataset", 
          name:     "createDataset", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/create/dataset/",
          run:      false, 
          description: "Create a new empty dataset",
          props: { 
            description: "As a single parameter separated by NAME-TYPE",
            params: { 
                      name:        "string",
                      type:        "string MULTICLASS or imageObjectDetection",
                    },
            required: ["name","type"]
          }  
        }, 
        { 
          id:       "get-vertex-dataset-list", 
          name:     "getDatasets", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/datasets",
          run:      true, 
          description: "Get the list of vertex datasets"
        }, 
        { 
          id:       "get-vertex-dataset", 
          name:     "getDataset", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/datasets/dataset_id/AUTOMLID",
          run:      true, 
          description: "Get the information of the dataset in vertex",
          props: { 
            params: { 
                      automlId:  "string"
                    },
            required: ["automlId"]
          }  
        }, 
        { 
          id:       "get-vertex-dataset-annotation-set-list", 
          name:     "getDatasetAnnotationSetList", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/annotationset/dataset_id/AUTOMLID",
          run:      true, 
          description: "Get a list of the annotation sets created on the dataset",
          props: { 
            params: { 
                      automlId:  "string"
                    },
            required: ["automlId"]
          }  
        }, 
        { 
          id:       "import-vertex-dataset", 
          name:     "importDataset", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/import/dataset/",
          run:      false, 
          description: "Import all images to the vertex dataset and create a new annotation set",
          important: "Use replace '/' for '!!-' in the CSV Path parameter",
          props: { 
            description: "As a single parameter separated by CSVPATH-|-AUTOMLID-|-TYPE",
            params: { 
                      csvPath:   "string / gs path",
                      automlId:  "string",
                      type:      "string MULTICLASS or imageObjectDetection",
                    },
            required: ["csvPath","automlId","type"]
          }  
        }, 
        { 
          id:       "get-vertex-model-list", 
          name:     "getModels", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/list",
          run:      true, 
          description: "Get the list of vertex models"
        }, 
        { 
          id:       "get-vertex-model", 
          name:     "getModel", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/get/model_id/MODELID",
          run:      true, 
          description: "Get the information of the model in vertex",
          props: { 
            params: { 
                      modelId:  "string / AutoML ID"
                    },
            required: ["modelId"]
          }  
        }, 
        { 
          id:       "get-vertex-dataitems", 
          name:     "getDataItems", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/dataitems/dataset_id/AUTOMLID",
          run:      true, 
          description: "Get the list of images included in the vertex dataset",
          props: { 
            params: { 
                      automlId:  "string"
                    },
            required: ["automlId"]
          }  
        }, 
        { 
          id:       "get-vertex-dataitem-annotation", 
          name:     "getDataItemAnnotation", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/annotations/dataitem/DATAITEM_ID",
          run:      true, 
          description: "Get the annotations on an image in vertex",
          props: { 
            params: { 
                      dataitemId:  "string"
                    },
            required: ["dataitemId"]
          }  
        }, 
        { 
          id:       "get-operation", 
          name:     "getOperation", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/operation/status/OPERATION_NAME",
          run:      true, 
          description: "Get the status of an operation",
          props: { 
            params: { 
                      name:  "string"
                    },
            required: ["name"]
          }  
        }, 
        { 
          id:       "get-pipeline", 
          name:     "getTrainingPipeline", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/trainingpipeline/status/PIPELINE_NAME",
          run:      true, 
          description: "Get the status of an training pipeline",
          props: { 
            params: { 
                      name:  "string"
                    },
            required: ["name"]
          }  
        }, 
        { 
          id:       "get-model-evaluations", 
          name:     "getModelEvaluations", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/evaluations/model_id/AUTOMLID",
          run:      true, 
          description: "Get the model evaluation list",
          props: { 
            params: { 
                      automlId:  "string"
                    },
            required: ["automlId"]
          }  
        }, 
        { 
          id:       "get-model-evaluation", 
          name:     "getModelEvaluation", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/evaluation/evaluation_id/EVALUATION_NAME",
          run:      true, 
          description: "Get the model evaluation data",
          props: { 
            params: { 
                      name:  "string"
                    },
            required: ["name"]
          }  
        }, 
        { 
          id:       "get-model-evaluation-slices", 
          name:     "getModelEvaluationSlices", 
          model:    "vertex", 
          source:   "API", 
          path:     "api/model/evaluationslices/evaluation_id/EVALUATION_NAME",
          run:      true, 
          description: "Get a list of all the evaluation slices for the evaluation",
          props: { 
            params: { 
                      name:  "string"
                    },
            required: ["name"]
          }  
        }, 
        { 
          id:       "aws-get-config", 
          name:     "getConfig", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Get AWS project configuration",
        }, 
        { 
          id:       "aws-create-dataset", 
          name:     "createDataset", 
          model:    "aws", 
          source:   "model", 
          run:      false, 
          description: "Creates a new dataset in an Amazon Lookout for Vision project",
          props: { 
            params: { 
                      datasetType:  "string / The type of the dataset. Specify train for a training dataset. Specify test for a test dataset",
                      projectName:  "string / The name of the project in which you want to create a dataset"
                    },
            required: ["datasetType", "projectName"]
          } 
        }, 
        { 
          id:       "aws-create-project", 
          name:     "createProject", 
          model:    "aws", 
          source:   "model", 
          run:      false, 
          description: "Create a new Lookout for Vision project",
          props: { 
            params: { 
                      projectName:  "string"
                    },
            required: ["projectName"]
          } 
        },  
        { 
          id:       "aws-get-bucket-cors", 
          name:     "getBucketCors", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Get bucket CORS" 
        }, 
        { 
          id:       "aws-get-bucket-acl", 
          name:     "getBucketAcl", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Get bucket ACL",
          default: "An S3 ACL is a sub-resource that's attached to every S3 bucket and object. It defines which AWS accounts or groups are granted access and the type of access"
        }, 
        { 
          id:       "aws-bucket-list-objects", 
          name:     "getBucketObjects", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "List bucket objects content"
        },
        { 
          id:       "aws-uploads3", 
          name:     "uploadS3", 
          model:    "aws", 
          source:   "model", 
          run:      false, 
          description: "Upload dataset files to S3 Bucket",
          props: { 
            params: { 
                      datasetId:  "string",
                    },
            required: ["datasetId"]
          }  
        }, 
        { 
          id:       "aws-list-projects", 
          name:     "listProjects", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Get AWS project list"
        }, 
        { 
          id:       "aws-describe-dataset", 
          name:     "getDataset", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Describe an Amazon Lookout for Vision dataset",
          props: { 
            params: { 
                      projectName:  "string / The name of the project in which you want to describe"
                    },
            required: ["projectName"]
          } 
        }, 
        { 
          id:       "aws-list-dataset-entries", 
          name:     "getDatasetEntries", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Lists the JSON Lines within a dataset. An Amazon Lookout for Vision JSON Line contains the anomaly information for a single image",
          props: { 
            params: { 
                      projectName:  "string / The name of the project in which you want to describe"
                    },
            required: ["projectName"]
          } 
        }, 
        { 
          id:       "aws-describe-project", 
          name:     "getProject", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Describe an Amazon Lookout for Vision project",
          props: { 
            params: { 
                      projectName:  "string / The name of the project in which you want to describe"
                    },
            required: ["datasetType", "projectName"]
          } 
        }, 
        { 
          id:       "aws-get-object", 
          name:     "getObject", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Retrieves object data from Amazon S3",
          props: { 
            params: { 
                      key:         "string",
                      renderHtml:  "Boolean (Default false, returns a stream if true) / Optional: send value 64 returns a base64 encoded string"
                    },
            required: ["key"]
          }  
        }, 
        { 
          id:       "aws-get-object-attributes", 
          name:     "getObjectAttributes", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Retrieves object attributes from Amazon S3",
          props: { 
            params: { 
                      key:  "string"
                    },
            required: ["key"]
          }  
        }, 
        { 
          id:       "aws-delete-object", 
          name:     "deleteObject", 
          model:    "aws", 
          source:   "model", 
          run:      false, 
          description: "Delete S3 file by file key Id",
          props: { 
            params: { 
                      key:  "string"
                    },
            required: ["key"]
          }  
        }, 
        { 
          id:       "aws-list-models", 
          name:     "listModels", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Lists the versions of a model in an Amazon Lookout for Vision project.",
          props: { 
            params: { 
                      projectName:  "string / The name of the project"
                    },
            required: ["projectName"]
          }  
        }, 
        { 
          id:       "aws-get-model", 
          name:     "getModel", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Describes a version of an Amazon Lookout for Vision model",
          props: { 
            params: { 
                      projectName:  "string / The name of the project",
                      modelVersion: "Integer / The version of the model that you want to describe: minimum length of 1 and maximum length of 10. By default return latest"
                    },
            required: ["projectName"]
          }  
        }, 
        { 
          id:       "aws-get-model-pakaging-jobs", 
          name:     "getModelPackagingJobs", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Use to get the active deployments from a device",
          props: { 
            params: { 
                      projectName:  "string / The name of the project",
                    },
            required: ["projectName"]
          }  
        }, 
        { 
          id:       "aws-get-model-pakaging-job", 
          name:     "getModelPackagingJob", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Use to get the active deployment information",
          props: { 
            params: { 
                      projectName:  "string / The name of the project",
                      jobName:      "string",
                    },
            required: ["projectName", "jobName"]
          }  
        },
        { 
          id:       "aws-create-model", 
          name:     "createModel", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Creates a new version of a model within an Amazon Lookout for Vision project",
          props: { 
            params: { 
                      projectName:  "string / The name of the project",
                    },
            required: ["projectName"]
          }  
        }, 
        { 
          id:       "aws-get-model-evaluation", 
          name:     "getEvaluation", 
          model:    "aws", 
          source:   "model", 
          run:      true, 
          description: "Get model evaluation",
          props: { 
            params: { 
                      projectName:  "string / The name of the project",
                      modelVersion: "Integer / The version of the model that you want to describe: minimum length of 1 and maximum length of 10. By default return latest"
                    },
            required: ["projectName"]
          }  
        },
        { 
          id:       "get-node-config-controller", 
          name:     "getNodeConfig", 
          model:    "controller", 
          source:   "model", 
          run:      true, 
          description: "Get the current connection configuration with Node-RED",
        }, 
        { 
          id:       "get-status-controller", 
          name:     "getStatus", 
          model:    "controller", 
          source:   "model", 
          run:      true, 
          description: "Check controller status",
        }, 
        { 
          id:       "get-menu-controller", 
          name:     "getNavMenu", 
          model:    "controller", 
          source:   "model", 
          run:      true, 
          description: "Get controller current navigation menu",
        }, 
        { 
          id:       "load-page-controller", 
          name:     "loadPage", 
          model:    "controller", 
          source:   "model", 
          run:      true, 
          description: "Load controller selected page in a iframe",
          optionals:   "The controller model gives you a list of pages",
          props: { 
            params: { 
                      url:       "string",
                    },
            required: ["url"]
          }
        }, 
        { 
          id:       "get-config", 
          name:     "getConfig", 
          model:    "project", 
          source:   "model", 
          run:      true, 
          description: "Get current project configuration",
        }, 
        { 
          id:       "get-api-host", 
          name:     "getApiHost", 
          model:    "project", 
          source:   "model", 
          run:      true, 
          description: "Get current host for api requests",
        }, 
        { 
          id:       "get-project-users", 
          name:     "getUsers", 
          model:    "project", 
          source:   "model", 
          run:      true, 
          description: "Get a list of users linked to the project",
          important:   "Requires internal API connection",
          props: { 
            params: { 
              roleId:  "Integer / 1: user, 2: admin, 3: superadmin",
              uid:     "string / User Id"
                    },
          }
        }, 
        { 
          id:       "get-users-list", 
          name:     "list", 
          model:    "user", 
          source:   "API", 
          path:     "api/user/list",
          run:      true, 
          description: "Get a list of users"
        }, 
        { 
          id:       "create-user", 
          name:     "create", 
          model:    "user", 
          source:   "API", 
          path:     "api/user/create",
          run:      false, 
          description: "Create a new user",
          props: { 
            params: { 
              data:  "Object / displayName: string (*), email: string (*), password: string (*)"
                    },
            required: ["data"]
          }
        }, 
        { 
          id:       "search-user", 
          name:     "search", 
          model:    "user", 
          source:   "API", 
          path:     "api/user/search/",
          run:      false, 
          description: "Get user data by user Id",
          props: { 
            params: { 
              uid:  "string / User Id"
                    },
            required: ["uid"]
          }
        },
        { 
          id:       "update-user-data", 
          name:     "update", 
          model:    "user", 
          source:   "API", 
          path:     "api/user/update",
          run:      false, 
          description: "Update user data",
          props: { 
            params: { 
              data:  "Object / uid: string (*), displayName: string"
                    },
            required: ["data"]
          }
        }, 
        { 
          id:       "delete-user", 
          name:     "delete", 
          model:    "user", 
          source:   "API", 
          path:     "api/user/delete",
          run:      false, 
          description: "Delete user",
          important: "This action is irreversible",
          props: { 
            params: { 
              uid:  "string / User Id"
                    },
            required: ["uid"]
          }
        }, 
        { 
          id:       "get-api-request", 
          name:     "httpsCallable", 
          model:    "other", 
          source:   "model", 
          run:      false, 
          description: "Make an Api request",
          props: { 
            params: { 
                    action:  "string"
                    },
            required: ["action"]
          } ,
        }, 
        { 
          id:       "get-icons", 
          name:     "getIcons", 
          model:    "other", 
          source:   "model", 
          run:      true, 
          description: "Get theme icon list"
        }, 
      ]
      if(opt.model || opt.source){
        let filterMethods = []
        for (var i = 0; i < methods.length; i++) {
          let pushMethod = true
          if(opt.model && methods[i].model && methods[i].model!=opt.model)pushMethod = false
          if(opt.source && opt.source!='all' && methods[i].source && methods[i].source!=opt.source)pushMethod = false
          if(pushMethod)filterMethods.push(methods[i])
        }
        return filterMethods
      }
      if(opt.models){
        let models = {}; 
        for (var m = 0; m < methods.length; m++) {
          if(!models[methods[m].model]){ models[methods[m].model]= 1
          }else{ models[methods[m].model]++; }
        }
        return models
      }
      return methods
    },
    getIcons(){ 
      let resp    = { error: false }
      resp.icons  = ['ActivityIcon','AlignJustifyIcon','BoxIcon','BookmarkIcon','CpuIcon','GithubIcon','ClockIcon','DatabaseIcon','Edit3Icon','EditIcon','FlagIcon','GridIcon','HashIcon','ShoppingCartIcon','ToggleLeftIcon','PieChartIcon','TerminalIcon','TrendingUpIcon','TriangleIcon','UploadCloudIcon','UserIcon','UsersIcon']
      return resp
    },
    hi(){ 

      console.log(
       '\n    ;;;    ;;;   ;;;   ;;  \n'+
          '    ;;;    ;;;         ;;  \n'+
          '    ;;;    ;;;   ;;;   ;;  \n'+
          '    ;;;;;;;;;;   ;;;   ;;  \n'+
          '    ;;;    ;;;   ;;;   ;;  \n'+
          '    ;;;    ;;;   ;;;       \n'+       
          '    :;;    ;;;   ;;;   ;;  \n'+
        '\n%c  ® ROSEPETAL AIS 2023\n\n'
          ,
          'color: #306090;font-size:13px',
      );
    }
  };
  
  const install = app => {
    app.config.globalProperties.$mother = others;
  };
  
  export { install as default, others as other };
  