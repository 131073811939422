<template>
  <div>
    <nav id="menuController" class="top-nav pt-0 mt-0 pl-0">
      <ul>
        <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Information <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/dashboard')">Dashboard</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/metrics/index.html')">Metrics</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/logs.html')">Logs</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/hwinfo.html')">Hardware</div></a></li>
          </ul>
         </li>
         <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Tools <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="load(':4040')">IDE</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/navigator/index.html')">File Manager</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/terminal.html')">Terminal</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/power')">Power</div></a></li>
          </ul>
        </li>
        <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Services <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/services.html')">Services</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/podman/index.html')">Containers</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/machines/index.html')">Virtual Machines</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/updates/index.html')">Updates</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/packages/apt')">APT Packages</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/packages/pip')">PIP Packages</div></a></li>
          </ul>
        </li>
        <li>
          <a class="top-menu pt-0 mt-0">
            <div class="top-menu__title">Settings <ChevronDownIcon class="top-menu__sub-icon" /></div>
          </a>
          <ul>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/system/index.html')">System</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/storage/index.html')">Storage</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/network/index.html')">Network</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/')">DHCP status</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/dhcp_leases')">DHCP leases</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/dhcp_config')">DHCP config</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadDH('/dhcp_start_stop_restart')">DHCP start/stop</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadCP('/users/index.html')">Users</div></a></li>
            <li><a class="top-menu top-submenu"><div class="top-menu__title" @click="loadAJ('/view/datetime')">Datetime</div></a></li>
            
          </ul>
        </li>
      </ul>
    </nav>  
  </div>
</template>
<script>
import { defineComponent,onMounted, computed, ref, onUnmounted} from "vue"; 
import cash from "cash-dom";
import { useStore } from "@/store";
import config from '../../etc/rosepetal.json';
import { view as $v } from "@/model/view";

const nodeConfig = config.nodeConfig;

export default defineComponent({

  setup() {

    const store            = useStore();   
    const User             = computed(() => store.state.main.User);
    const ctrlMenu         = ref([])
    const cockpitAuth      = ref()

    const loadAJ = (url) => {  
      document.getElementById("controllerFrame").style.display = 'block';
      //console.log(nodeConfig.host+"/"+url+"?uid="+User.value.uid)
      //document.getElementById("controllerFrame").src=nodeConfig.host+"/"+url; 
      load("/ajenti"+url+"?uid="+User.value.uid); 
    };

    function load(url) { 
      document.getElementById("controllerFrame").src=nodeConfig.host+url; 
    }

    function loadCP(url) {
      var auth   = cockpitAuth.value.user+":"+cockpitAuth.value.password 
      var xhr = new XMLHttpRequest();
      xhr.open("GET", nodeConfig.host+"/cockpitp/cockpit/login", false);
      xhr.setRequestHeader("Authorization", "Basic " + btoa(auth));  
      xhr.send();
      load ("/cockpitp/cockpit/@localhost"+url); 
    }

    function loadDH(url) { 
      load(":4041"+url); 
    }

    onMounted( async () => { 

      cockpitAuth.value = await $v.getSettings('cockpit');
      
      cash(".content").before(cash("#menuController"))
      cash(".content").addClass("hidden");
      cash("#topBarControllerIframe,#menuController").css("display", "block");
      cash(".dashboxItem,.side-nav").addClass("hidden");
      cash(".top-bar-node-menu").removeClass("hidden");
      cash(".top-bar-node-menu-side-menu-controller").addClass("text-primary-4 top-bar-node-menu-current");
      if(cash("#controllerFrame").attr("src")=="about:blank")loadAJ('/view/dashboard')

    });

    onUnmounted( async () => { 
     cash(".content").removeClass("hidden");
     cash("#topBarControllerIframe,#menuController").css("display", "none");
     cash(".dashboxItem,.side-nav").removeClass("hidden");
     cash(".top-bar-node-menu").addClass("hidden");
     cash(".top-bar-node-menu-current").removeClass("text-primary-4 top-bar-node-menu-current");
    });

    return {
      ctrlMenu,
      loadAJ,
      loadCP,
      loadDH,
      load
    }
  }

});
</script>

<style scoped>
  #menuController{ width: 40%; margin: 0 30%}

  #menuController li ul{ padding: 20px 0;}
  #menuController .top-menu{ height:45px !important; }
  #menuController .top-submenu{ height:23px !important;  }
  #menuController .top-menu__title{ font-weight: normal !important;  font-size: 12px; cursor: pointer }
  #menuController .top-menu__title:hover{ 
    --tw-text-opacity: 1;
    color: rgba(145, 199, 20, var(--tw-text-opacity));
   }
  #menuController .top-nav > ul > li:hover{ background: none !important;}
</style>