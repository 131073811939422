//import firebase from 'firebase/app'

import { _firebase as $_firebase } from "@/model/firebase";
import * as rosepetalModel from "rosepetal-model";
//import config from '../etc/rosepetal.json';

//const axios = require('axios').default;
//const nodeConfig = config.nodeConfig;

const layouts = {
    async getMainMenu(){
      let mon                 = $_firebase.firestore().collection('view').doc('layout')
      const snapshot          = await mon.get();
      let item                = snapshot.data(); item.id = snapshot.id
      let mainMenu            = JSON.parse(item.main_menu)
      let ctrStatus           = await rosepetalModel.controller.getStatus() 
      if(!ctrStatus || ctrStatus.error){
       let mainMenuByStatus   = []
       let disableMenus       = ['side-menu-controller','side-menu-flow','side-menu-node','side-menu-node']
        mainMenu.forEach( m => {
          if(m.pageName && !disableMenus.includes(m.pageName))mainMenuByStatus.push(m)
          if(m=="devider")mainMenuByStatus.push(m)
        });
        mainMenu = mainMenuByStatus
      }
      return mainMenu;
    },
    /*
    async checkControllerStatus(){
      let status = false
      await axios.get(nodeConfig.host+'/nodered/flow/global', {}, { headers: {} }) 
                 .then(response => {
                  if(response.status && response.status==200)status = true;
                 })
                 .catch(error => {
                  console.log(error);
                 });
      return status
    },*/
};

const install = app => {
  app.config.globalProperties.$layouts = layouts;
};

export { install as default, layouts as layout };
