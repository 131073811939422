<template>
  <div>

    <!-- Model list -->
    <div class="grid chat grid-cols-12 gap-0" v-if="!curModel" >
      <div class="col-span-12 flex flex-col">
        <div class="grid chat grid-cols-12 gap-3 mt-3">
          <div v-for="m in localModelsMenu" :key="m.id" class="intro-y" @click="curModel=m" :class="localModelsMenu.length==1 ? 'col-span-12' : 'col-span-6'">
            <div class="box px-4 py-4 flex items-center zoom-in shadow-none border border-gray-300 hover:bg-gray-100">
              <div class="mr-auto">
                <div class="font-medium truncate text-gray-700">{{ m.id.toString().substring(0, 35) }}<span v-if="m.id.toString().length>35">...</span></div>
                <div class="text-gray-600 text-xs mt-0.5">{{ m.created }}</div>
                
              </div>
              <div v-if="!m?.automl && m?.trainOperation?.operationId" class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal text-gray-500"><LoadingIcon icon="puff" class="w-24 h-24" /></div>
              <div v-else class="py-0.5 px-4 rounded-full text-xs cursor-pointer font-normal border border-gray-400 text-gray-500">{{ m.aws ? "Anomaly detection" : "Supervised" }}</div>
              <!-- <div class="py-1 px-4 rounded-full text-xs bg-green-600 text-white cursor-pointer font-normal">100%</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 flex flex-col mt-5" v-if="Dataset.localModels.length">
        <div class="box px-3 py-5 shadow-none border border-solid border-gray-200">
          <div class="text-center">
            <span class="text-xs text-gray-500">{{$t('Keep fine-tuning the precision')}}</span>
            <div class="text-center mt-2"><button type="button" @click="modal('modal-trainBox')" class="btn bg-green-600 text-white py-3 px-8">{{ $t('Train a new model') }}</button></div>  
            <div class="mt-3 text-gray-500 text-center">
              {{$t('If there are fewer Images, inaccurate accuracy and recall scores are likely to be generated')}}.<br />
              {{$t('Assign the maximum number of images to each tag before training')}}. 
            </div> 
          </div>
        </div>    
      </div>
    </div>  
    <!-- /Model list -->

    <div class="grid chat grid-cols-12">
      <div class="col-span-12" v-if="Layout.loading">
        <div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
        <div class="intro-y flex flex-col justify-center items-center mb-5"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div>
      </div>
      <div class="col-span-12" v-else>

        <div class="pb-2 text-left">

          <!-- No trained --> 
          <div class="text-center" v-if="!Dataset.localModels.length">
            <span class="text-xs text-gray-500">{{$t('Has not been trained')}}</span>
            <div class="font-medium text-lg text-gray-700">{{$t('Train a new Model')}}</div>
            <div class="mt-1 text-gray-500 text-center">
              {{$t('If there are fewer Images, inaccurate accuracy and recall scores are likely to be generated')}}.<br />
              {{$t('Assign the maximum number of images to each tag before training')}}. 
            </div> 
            <div class="text-center mt-2"><button type="button" @click="modal('modal-trainBox')" class="btn bg-green-600 text-white py-3 mt-2 px-8">{{ $t('Train a new model') }}</button></div>  
          </div>
          <!-- /No trained --> 
          <div class="w-full" v-else>

            <!-- Header -->
            <div class="grid chat grid-cols-12 gap-5 py-2 mt-2" v-if="curModel">
              <div class="col-span-12 text-left">
                
                <span class="text-xl truncate text-gray-700">
                  {{ curModel?.db?.id }}
                  <span class="py-0.5 px-2 rounded-full text-xs font-normal ml-2 align-top border border-gray-400 text-gray-500">{{ curModel?.db?.aws ? "Semi-supervised" : "Supervised" }}</span>
                </span>

                <span class="py-2 px-6 rounded-full text-xs font-normal align-top text-center cursor-pointer float-right ml-auto bg-gray-700 text-white"
                      @click="curModel=false;">{{ $t('Back') }}</span>

                <span class="py-2 px-3 rounded-full text-xs font-normal align-top text-center cursor-pointer float-right ml-auto bg-gray-500 hover:bg-red-500 text-white mr-2" @click="modal('model-delete-box')"><Trash2Icon class="w-4 h-4" /></span>

                <span class="py-2 px-2 rounded-full text-xs font-normal align-middle border text-center w-20 cursor-pointer float-right ml-auto mr-2 border-green-200 text-green-500" 
                  v-if="curModel?.db?.automl && curModel.vertexEvaluation?.evaluations && curModel.vertexEvaluation?.evaluations[0]?.model?.eligibleAsBaseModel" @click="modal('modal-trainBox')">{{ $t('Retrain') }}</span>
                <span class="py-2 px-2 rounded-full text-xs font-normal align-middle border text-center w-20 cursor-pointer float-right ml-auto mr-2 border-green-200 text-green-500" 
                  v-else-if="curModel?.db?.aws" @click="modal('modal-trainBox')">{{ $t('Retrain') }}</span>
                
                <span v-if="curModel?.db?.aws && curModel?.awsEvaluation?.manifestResume && !semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading" @click="loadEvaluationObjects(curModel?.awsEvaluation?.manifest)" 
                      class="py-2 px-2 rounded-full text-xs font-normal align-middle border border-blue-200 text-blue-500 w-48 text-center cursor-pointer hover:bg-blue-500 hover:text-white float-right ml-auto mr-2">
                      {{ $t('View evaluation images') }} ({{ curModel.awsEvaluation.manifestResume?.totalTestImages }}) 
                </span>
                <span v-else-if="curModel?.db?.aws" @click="semiSupervisedEvaluationObjects=[]"
                      class="py-2 px-2 rounded-full text-xs font-normal align-middle border text-center w-48 float-right ml-auto mr-2"
                      :class="semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading ? 'border-blue-200 text-blue-500 hover:bg-blue-500 hover:text-white cursor-pointer' : 'border-gray-200 text-gray-500 cursor-not-allowed'">
                      {{ $t('View Metrics') }}
                </span>
              </div>
            </div>

            <!-- Table settings -->
            <div class="col-span-12" v-if="curModel.db" >
              <table class="table table--sm text-xs text-left">
                <tbody>
                    <!-- Global --> 
                    <tr v-if="curModel.db.description">
                      <td class="border"><span>{{$t('description')}}</span></td>
                      <td class="border"><span>{{  curModel.db.description }}</span></td>
                    </tr>
                  </tbody>
              </table>
            </div>

            <!-- training supervised -->
            <div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="!curModel?.db?.automl && curModel?.db?.trainOperation?.operationId"
                :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
                <span v-if="curModel?.db?.trainOperation?.operationId">{{ $t('Supervised classification model is training, please wait ...') }}</span>
                <!--<div class="display-block ml-2 text-xs" v-if="curModel?.db?.trainOperation?.operationId">{{ $t('RP') }} {{ curModel?.db?.trainOperation?.operationId }}</div>-->
                <span class="py-2 px-6 rounded-full text-xs font-normal align-middle text-center cursor-pointer text-gray-700 bg-white ml-3" style="z-index: 99999;position: relative;" @click="curModel=false;">{{ $t('Back') }}</span>
              </div>
            <!-- /training -->

            <!-- training semi-supervised -->
              <div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="!curModel?.db?.trainOperation?.operationId && curModel?.awsModel?.response?.ModelDescription?.Status=='TRAINING'"
                :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
                <span>{{ $t('Anomaly detection model is training, please wait ...') }}</span>
                <!--<div class="display-block ml-2 text-xs" v-if="curModel?.db?.trainOperation?.operationId">{{ $t('RP') }} {{ curModel?.db?.trainOperation?.operationId }}</div>-->
                <span class="py-2 px-6 rounded-full text-xs font-normal align-middle text-center cursor-pointer text-gray-700 bg-white ml-3" style="z-index: 99999;position: relative;" @click="curModel=false;">{{ $t('Back') }}</span>
              </div>
            <!-- /training -->


            <!-- load model error -->
            <div v-if="curModel?.errorLoad">
                <div class="text-left mt-5 text-gray-800 py-3 pl-5 mb-3">
                  <div>{{  $t('An error occurred while retrieving model information')  }}.</div>
                  <div class="py-3 text-red-600 text-xs">{{  curModel?.errorLoad  }}</div>
                </div>
            </div>
            <!-- load model error -->

            <!-- Supervised --> 
            <div>
              <div class="grid chat grid-cols-12 gap-6" v-if="curModel?.db?.automl">
                <div class="col-span-5 text-center mt-8">
                  <div class="flex flex-col justify-center items-center" v-if="!curModel?.vertexEvaluation?.render">
                    <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-if="curModel?.vertexEvaluation?.error"><div class="mt-3 text-gray-600 text-xs">{{  curModel?.vertexEvaluation?.error  }}</div></div>
                    <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center" v-else><LoadingIcon icon="puff" class="w-20 h-20" /></div>
                  </div>
                  <div v-else>
                    <div class="relative" v-if="curModel.vertexEvaluation?.evaluations[0]">
                      <ReportDonutChart2 :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
                                                                   labels: [ "Train", "validation" ],
                                                                   datasets: [ {
                                                                        data: [ 
                                                                                curModel.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount, 
                                                                                curModel.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount
                                                                              ],
                                                                        backgroundColor: [ "#333333", "#1d8102" ]
                                                                      }
                                                                    ]
                                                                  }' />
                      <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                        <div class="text-xl xxl:text-2xl font-medium">
                          {{ 
                          parseInt(curModel.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount)+parseInt(curModel.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount) + parseInt(curModel.vertexEvaluation?.evaluations[0]?.evaluatedCount) 
                          }}
                        </div>
                        <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
                      </div>
                    </div>
                    <div class="mx-auto w-10/12 xxl:w-2/3 mt-3" v-if="curModel.vertexEvaluation?.evaluations[0]">
                      <div class="flex items-center mt-2">
                        <div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #333333"></div>
                        <span class="truncate">{{$t('Train')}}</span>
                        <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                        <span class="font-medium xl:ml-auto">{{ curModel.vertexEvaluation?.evaluations[0]?.model.trainingDataItemsCount  }}</span>
                      </div>
                      <div class="flex items-center mt-2">
                        <div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
                        <span class="truncate">{{$t('Validation')}}</span>
                        <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                        <span class="font-medium xl:ml-auto">{{ curModel.vertexEvaluation?.evaluations[0]?.model.validationDataItemsCount  }}</span>
                      </div>
                      <div class="flex items-center mt-2" v-if="curModel.vertexEvaluation?.evaluations[0]?.evaluatedCount">
                        <div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
                        <span class="truncate">{{$t('Test')}}</span>
                        <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                        <span class="font-medium xl:ml-auto">{{ curModel.vertexEvaluation?.evaluations[0]?.evaluatedCount  }}</span>
                      </div>
                    </div>
                  </div>  
                </div>
                <div class="col-span-7 pb-0 text-left mt-5">
                  <table class="table table--sm text-xs text-left">
                    <tbody>
                        <!-- 
                        <tr v-if="curModel.db?.automl">
                          <td class="border w-52"><span>{{$t('Identifier')}}</span></td>
                          <td class="border"><span>{{  curModel.db.automl }}</span></td>
                        </tr>
                         --> 
                        <tr v-if="curModel.db.automl">
                          <td class="border"><span>{{$t('Storage bucket')}}</span></td>
                          <td class="border bg-gray-100" v-if="curModel.db?.savedModel">
                            <div class="copyButton cursor-pointer" @click="copyToClipboard(curModel.db.savedModel)" :data-clipboard-text="curModel.db.savedModel">
                              <span class="text-green-700">{{$t('model saved')}} <CopyIcon class="w-4 h-4 ml-2 hover:text-blue-900" /></span>
                            </div>
                          </td>
                          <td class="border bg-gray-100" v-else>
                            <div v-if="Layout.loading" class="w-10 h-10"><LoadingIcon icon="three-dots" class="w-12 h-12" /></div>
                            <!-- <div class="text-left mt-1">{{$t('Destination')}}: <span class="font-bold text-gray-700">{{modelBucket ? modelBucket : ''}}</span></div> --> 
                            <button v-else type="button" @click="exportModelToBucket()" class="btn bg-theme-14 text-white my-2 font-normal zoom-in">{{ $t('Export to Storage Bucket') }}</button>
                          </td>
                        </tr>
                        <tr v-if="curModel.vertexEvaluation?.evaluations && curModel.vertexEvaluation?.evaluations[0]?.model?.versionId">
                          <td class="border"><span>{{$t('Version')}}</span></td>
                          <td class="border bg-gray-100"><span>{{ curModel.vertexEvaluation.evaluations[0].model.versionId }}</span></td>
                        </tr>
                        <tr v-if="curModel.vertexEvaluation?.evaluations && curModel.vertexEvaluation?.evaluations[0]?.model?.training?.budgetMilliNodeHours">
                          <td class="border"><span>{{$t('Budget')}}</span></td>
                          <td class="border bg-gray-100"><span>{{ curModel.vertexEvaluation.evaluations[0].model.training.budgetMilliNodeHours }} {{$t('milliNodeHours')}}</span></td>
                        </tr>
                        <tr v-if="curModel.vertexEvaluation?.evaluations && curModel.vertexEvaluation?.evaluations[0]?.model?.training?.costMilliNodeHours">
                          <td class="border"><span>{{$t('Cost')}}</span></td>
                          <td class="border bg-gray-100"><span>{{ curModel.vertexEvaluation.evaluations[0].model.training.costMilliNodeHours }} {{$t('milliNodeHours')}}</span></td>
                        </tr>
                        <tr v-if="curModel.vertexEvaluation?.evaluations && curModel.vertexEvaluation?.evaluations[0]?.model?.training?.stopReason">
                          <td class="border"><span>{{$t('Stop reason')}}</span></td>
                          <td class="border bg-gray-100"><span :class="curModel.vertexEvaluation.evaluations[0].model.training.stopReason=='MODEL_CONVERGED' ? 'text-green-500' : 'text-blue-300'">{{ curModel.vertexEvaluation.evaluations[0].model.training.stopReason }}</span></td>
                        </tr>
                        <tr v-if="curModel?.vertex?.createTime">
                          <td class="border"><span>{{$t('Created')}}</span></td>
                          <td class="border bg-gray-100"><span>{{ this.helper.getFbDate(curModel.vertex.createTime) }}</span></td>
                        </tr>
                      </tbody>
                  </table> 
                </div>
                <div class="col-span-12" v-if="curModel?.vertexEvaluation?.render">
                  <div v-html="curModel.vertexEvaluation.render"></div>
                </div>
              </div>
            </div>

            <!-- Semi-supervised --> 
            <table class="table table--sm text-xs text-left mt-5" v-if="curModel.db?.aws && !semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading">
              <tbody>
                <tr v-if="curModel.db?.aws">
                  <td class="border w-52"><span>{{$t('Identifier')}}</span></td>
                  <td class="border"><span>{{ curModel.db.aws }}</span></td>
                </tr>
                <tr v-if="curModel.db?.aws && (curModel.db.ModelVersion || curModel?.awsModel?.response?.ModelDescription?.ModelVersion)">
                  <td class="border"><span>{{$t('Version')}}</span></td>
                  <td class="border">
                    <span v-if="curModel.db.ModelVersion">{{  curModel.db.ModelVersion }}</span>
                    <span v-if="curModel?.awsModel?.response?.ModelDescription?.ModelVersion!=curModel.db.ModelVersion">({{ curModel?.awsModel?.response?.ModelDescription?.ModelVersion }})</span>
                  </td>
                </tr>
                <tr v-if="curModel?.awsModel && curModel?.awsModel?.response?.ModelDescription?.Status && curModel?.awsModel?.response?.ModelDescription?.Status!='TRAINING_FAILED'">
                  <td class="border">{{$t('Packages')}}</td>
                  <td class="border text-left" v-if="curModel.packageJobs?.response?.ModelPackagingJobs.length">
                    <div v-for="job in curModel.packageJobs?.response?.ModelPackagingJobs" :key="job" class=" mt-3 mb-3">
                      <span class="py-1 px-5 rounded-full text-xs cursor-pointer mr-2" 
                        :class="job.Status=='SUCCEEDED'?'bg-green-600 text-white':'bg-gray-300 text-gray-600'"
                        @click="curModel.packageJob=job.JobName; modal('modal-semi-supervised-packageJob')">{{ job.JobName }}</span> 
                    </div>
                  </td>
                  <td class="border" v-else>
                    <div class="text-left mt-1">{{$t('The model does not packaging jobs')}}.</div>
                    <button type="button" class="btn bg-gray-600 font-normal text-white mt-2">{{ $t('Create new package') }}</button>
                  </td>
                </tr>
                <tr v-if="curModel?.awsModel?.response?.ModelDescription?.Status">
                  <td class="border"><span>{{$t('Status')}}</span></td>
                  <td class="border" :class="curModel?.awsModel?.response?.ModelDescription?.Status=='TRAINING_FAILED' ? 'text-red-500' : ''">
                    <span>{{  curModel?.awsModel?.response?.ModelDescription?.Status }}</span>
                    <span v-if="curModel?.awsModel?.response?.ModelDescription?.StatusMessage" class="pl-3">{{  curModel?.awsModel?.response?.ModelDescription?.StatusMessage }}</span>
                  </td>
                </tr>
                <tr v-if="curModel?.awsModel?.response?.ModelDescription?.CreationTimestamp">
                  <td class="border"><span>{{$t('Created')}}</span></td>
                  <td class="border"><span>{{  curModel?.awsModel?.response?.ModelDescription?.CreationTimestamp }}</span></td>
                </tr>
              </tbody>
            </table>  
            <div class="grid chat grid-cols-12 gap-6 mt-3" v-if="curModel?.db?.aws">
              <div class="col-span-12 p-0">
                <div class="grid chat grid-cols-12 gap-5 py-1">
                  <div class="col-span-12 text-left" v-if="curModel?.awsEvaluation && curModel?.awsEvaluation?.status!='error'">
                    <div class="text-center">
                      <div v-if="curModel.awsEvaluation?.loading">
                        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0">
                          <div class="flex items-center justify-center mt-5">  
                            <img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" />
                          </div>
                          <LoadingIcon icon="three-dots" class="w-12 h-12" />
                        </div>
                      </div>  
                      <div v-if="!semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading">
                        <div class="grid chat grid-cols-12 gap-0 py-2 px-2 text-gray-600">
                          <div class="col-span-12 text-gray-600 text-center mb-2">
                            <h2 class="text-xl truncate">{{ $t('Model performance metrics') }}</h2>
                            <div class="text-gray-600 text-xs font-normal text-center mt-2">{{$t('Use model performance metrics to find the strengths and weaknesses of the model')}}.</div>
                          </div>
                          <div class="col-span-4 text-center mx-auto mt-5">
                            <div class="relative">
                              <div class="w-72"><ReportDonutChart2 :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' /></div>
                              <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                <div class="text-xl xxl:text-2xl font-medium">{{ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0)  }}%</div>
                                <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Precision')}}</div>
                              </div>
                            </div>
                            <div class="mx-auto w-f xxl:w-3/4 mt-5">
                              <div class="flex items-center">
                                <span class="mr-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</span>
                                <span class="truncate">{{$t('anomalies were correct of')}}</span>
                              </div>
                              <div class="flex items-center mt-1">
                                <span class="mr-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.total }}</span>
                                <span class="truncate">{{$t('total predictions')}}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-span-4 text-center mx-auto mt-5">
                            <div class="relative">
                              <div class="w-72">
                                <ReportDonutChart2 :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' />
                              </div>
                              <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                <div class="text-xl xxl:text-2xl font-medium">
                                  {{ 
                                  ((((curModel.awsEvaluation.manifestResume?.anomaly?.predictions+curModel.awsEvaluation.manifestResume?.normal?.predictions)-(curModel.awsEvaluation.manifestResume?.anomaly?.falsePositive+curModel.awsEvaluation.manifestResume?.normal?.falseNegative))/curModel.awsEvaluation.manifestResume?.totalTestImages)*100).toFixed(0) 
                                  }}%</div>
                                <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('F1 Score')}}</div>
                              </div>
                            </div>
                            <div class="mx-auto w-full mt-5 text-center">
                              <span class="truncate">{{$t('The overall performance')}}</span>
                            </div>
                          </div>
                          <div class="col-span-4 text-center mx-auto mt-5">
                            <div class="relative">
                              <div class="w-72">
                                <ReportDonutChart2  :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' />
                              </div>
                              <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                <div class="text-xl xxl:text-2xl font-medium">{{ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.predictions)*100).toFixed(0)  }}%</div>
                                <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Recovery')}}</div>
                              </div>
                            </div>
                            <div class="mx-auto w-f xxl:w-4/4 mt-5">
                              <div class="overflow-hidden">
                                <div class="flex items-center">
                                  <span class="truncate pl-5">{{$t('were correct')}}</span>
                                  <span class="mr-2 ml-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</span>
                                  <span class="truncate text-xs">{{$t('anomalies predicted of')}}</span>
                                </div>
                                <div class="flex items-center mt-1">
                                  <span class="mr-2 font-bold pl-5">{{ curModel.awsEvaluation.manifestResume?.anomaly?.predictions }}</span>
                                  <span class="truncate">{{$t('total anomalies')}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-span-12 mt-5 text-gray-600 text-center">
                            <h2 class="text-xl truncate">{{ $t('About test results') }}</h2>
                          </div>
                          <div class="col-span-6 text-left mt-5 p-5 border ml-2 rounded mr-1">
                            <h2 class="text-base font-medium truncate">{{ $t('Predicted as normal') }}</h2>
                            <div class="grid chat grid-cols-12 gap-0 py-1 text-gray-600">
                              <div class="col-span-6">
                                <div class="h-10 font-medium">{{ $t('Correct') }} <span class="text-xs font-normal">({{ $t('true negative') }})</span></div>
                                <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.normal?.trueNegative }}</h2>
                              </div>
                              <div class="col-span-6">
                                <div class="h-10 font-medium">{{ $t('Incorrect') }} <span class="text-xs font-normal">({{ $t('false negative') }})</span></div>
                                <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.normal?.falseNegative }}</h2>
                              </div>
                            </div>   
                          </div>
                          <div class="col-span-6 text-left mt-5 p-5 border mr-2 rounded" style="border-left: none;">
                            <h2 class="text-base font-medium truncate">{{ $t('Predicted as anomaly') }}</h2>
                            <div class="grid chat grid-cols-12 gap-0 py-1 text-gray-600">
                              <div class="col-span-6">
                                <div class="h-10 font-medium">{{ $t('Correct') }} <span class="text-xs font-normal">({{ $t('true positive') }})</span></div>
                                <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</h2>
                              </div>
                              <div class="col-span-6">
                                <div class="h-10 font-medium">{{ $t('Incorrect') }} <span class="text-xs font-normal">({{ $t('false positive') }})</span></div>
                                <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.anomaly?.falsePositive }}</h2>
                              </div>
                            </div>   
                          </div>
                        </div> 
                      </div>
                      <div class="grid chat grid-cols-12 gap-2 text-gray-600 mt-0" v-else>
                        <div class="col-span-12" v-if="curModel?.awsEvaluation?.order && !curModel.awsEvaluation?.loading">
                          <select class="form-select bg-white cursor-pointer w-44 mt-0" v-model="curModel.awsEvaluation.filterPrediction" @change="loadEvaluationObjects(curModel?.awsEvaluation?.manifest)">
                              <option value="all">{{$t('All prediction')}}</option>
                              <option value="correct">{{$t('Correct')}}</option>
                              <option value="incorrect">{{$t('Incorrect')}}</option>
                          </select>
                          <select class="form-select bg-white cursor-pointer w-60 mt-0 ml-2" v-model="curModel.awsEvaluation.order" @change="loadEvaluationObjects(curModel?.awsEvaluation?.manifest)">
                              <option value="asc">{{$t('Lowest confidence')}}</option>
                              <option value="desc">{{$t('Highest confidence')}}</option>
                          </select>
                          <div style="display: inline-block" class="w-44 ml-5"><Slider v-model="curModel.awsEvaluation.sizeObject" :min="25" :max="75" :step="25" :merge="25" :tooltips="false" :lazy="true" /></div>
                          <div v-if="!semiSupervisedEvaluationObjects[0]?.empty" class="text-xs text-center pt-3 pb-2">
                            - {{$t('Showing')}} {{ semiSupervisedEvaluationObjects?.length }} {{$t('of')}} {{ curModel.awsEvaluation.manifestResume?.totalTestImages }} {{$t('test images')}} - 
                          </div>
                        </div>
                        <div v-if="semiSupervisedEvaluationObjects[0]?.empty" class="col-span-12 py-20">- {{$t('No results')}} -</div> 
                        <div v-else class="col-span-12 text-gray-600 text-center">
                          <div v-for="(t,indx) in semiSupervisedEvaluationObjects" :key="indx" class="relative inline-block rounded border border-gray-200 mb-2"
                            :style="curModel.awsEvaluation.sizeObject ? 'float: left !important; width: '+((curModel.awsEvaluation.sizeObject==25 ? 33 : curModel.awsEvaluation.sizeObject==75 ? 100 : curModel.awsEvaluation.sizeObject)-0.5)+'% !important; margin-right: 0.5% !important' : ''">
                            <div class="pt-3 px-2 pb-10 rounded border" style="background-color: #fff">
                              <div class="text-lg mb-3 truncate">{{ semiSupervisedEvaluationObjects[indx]?.obj['source-ref'].toString().split("/").pop() }}</div>
                              <img class="w-full rounded" :src="semiSupervisedEvaluationObjects[indx]?.imageSrc?.renderSrc" />
                              <div v-if="curModel?.awsEvaluation?.manifestResume">
                                <div class="pt-3 text-left font-medium" :class="curModel.awsEvaluation.sizeObject!=25 ? 'px-2' : ''">
                                    <span v-if="semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label']==semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label-detected']">
                                      <img :src="require(`@/assets/images/rosepetal/icon/tick.png`)" class="w-5" style="display: inline" /> <span class="text-green-700 align-middle font-normal">{{$t('Correct')}}</span> 
                                    </span>
                                    <span v-else><X-circleIcon  class="text-red-600 w-5 h-5" /> <span class="text-red-700">{{$t('Incorrect')}}</span></span>
                                    <span class="float-right ml-aut text-gray-600 text-center">
                                      <span class="text-xs text-gray-500">{{$t('Confidence')}}</span><br />
                                      <span class="text-lg">{{ (semiSupervisedEvaluationObjects[indx].obj['anomaly-label-detected-metadata']['confidence']*100).toFixed(1) }}%</span>
                                    </span>
                                    <span class="float-right ml-aut text-gray-600 text-center mr-8">
                                      <span class="text-xs text-gray-500">{{$t('Prediction')}}</span><br />
                                      {{ semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label-detected-metadata']['class-name'] }}
                                    </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div v-else-if="curModel?.awsEvaluation?.status=='error'" class="col-span-12 text-left text-xs"><div class="text-center">- {{ curModel.awsEvaluation.error }} - </div></div>
                  <div class="col-span-12 text-center text-lg">{{$t('Training data')}}</div>
                  <div class="col-span-5 text-center mr-5">
                    <div class="relative">
                      <ReportDonutChart2 :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
                                                                   labels: [ "Normal", "Anomaly" ],
                                                                   datasets: [ {
                                                                        data: [ curModel?.response.dataset.test.DatasetDescription.ImageStats["Normal"], curModel.response.dataset.test.DatasetDescription.ImageStats["Anomaly"] ],
                                                                        backgroundColor: [ "#1d8102", "#d13212" ]
                                                                      }
                                                                    ]
                                                                  }' />
                      <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                        <div class="text-xl xxl:text-2xl font-medium">{{ this.helper.formatScore(curModel?.response.dataset.train.DatasetDescription.ImageStats["Total"]+curModel.response.dataset.test.DatasetDescription.ImageStats["Total"]) }}</div>
                        <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
                      </div>
                    </div>
                    <div class="mx-auto w-10/12 xxl:w-2/3 mt-8">
                      <div class="flex items-center">
                        <div class="w-2 h-2 bg-theme-26 rounded-full mr-3" style="background-color: #1d8102"></div>
                        <span class="truncate">{{$t('Normal')}}</span>
                        <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                        <span class="font-medium xl:ml-auto">
                            {{ ((curModel.response.dataset.test.DatasetDescription.ImageStats["Normal"]/curModel.response.dataset.test.DatasetDescription.ImageStats["Total"])*100).toFixed(2)  }}%
                        </span>
                      </div>
                      <div class="flex items-center mt-2">
                        <div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #d13212"></div>
                        <span class="truncate">{{$t('Anomaly')}}</span>
                        <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                        <span class="font-medium xl:ml-auto">
                            {{ ((curModel.response.dataset.test.DatasetDescription.ImageStats["Anomaly"]/curModel.response.dataset.test.DatasetDescription.ImageStats["Total"])*100).toFixed(2)  }}%
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-span-7 pb-0 text-left">
                    <table class="table table--sm text-xs text-center">
                      <tbody>
                          <tr>
                            <td></td>
                            <td class="border bg-gray-100"><span>{{$t('TEST')}}</span></td>
                            <td class="border bg-gray-100"><span>{{$t('TRAIN')}}</span></td>
                          </tr>
                          <tr v-for="(t,indx) in curModel.response.dataset.test.DatasetDescription.ImageStats" :key="indx">
                            <td class="border p-2 bg-gray-100">{{ indx }}</td>
                            <td class="border p-2"><span>{{ t }}</span></td>
                            <td class="border p-2" v-if="curModel.response.dataset.train.DatasetDescription.ImageStats[indx]"><span>{{ curModel.response.dataset.train.DatasetDescription.ImageStats[indx] }}</span></td>
                          </tr>
                        </tbody>
                    </table>
                    <div v-if="curModel.db && curModel.db.tagMap" class="pt-5">
                      <div class="text-sm text-gray-600">{{$t('Label Map')}}</div>
                      <div class="grid chat grid-cols-12 gap-1 py-2  text-gray-600">
                          <div class="col-span-6 text-left">
                            <table class="table table--sm text-xs">
                              <tbody>
                                  <tr><td class="border text-center text-green-700"><span>{{$t('NORMAL')}}</span></td></tr>
                                  <tr v-for="(t,indx) in JSON.parse(curModel.db.tagMap)['normal']" :key="indx">
                                    <td class="border bg-gray-100 text-center"><span>{{ t }}</span></td>
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                          <div class="col-span-6 text-left">
                            <table class="table table--sm text-xs">
                              <tbody>
                                  <tr><td class="border text-center text-red-700"><span>{{$t('ANOMALY')}}</span></td></tr>
                                  <tr v-for="(t,indx) in JSON.parse(curModel.db.tagMap)['anomaly']" :key="indx">
                                    <td class="border bg-gray-100 text-center"><span>{{ t }}</span></td>
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                       </div> 
                    </div>
                  </div>
                </div>  
              </div>
              <!-- Modal packageJob
              <div id="modal-semi-supervised-packageJob" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-ms" v-if="curModel.packageJobData">
                  <div class="modal-content">
                      <div class="modal-body p-10 text-left">
                        <div class="intro-y flex items-center h-6">
                          <h2 class="text-xl font-medium truncate">{{ $t('Model Packaging Job Details') }}</h2>
                          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" data-dismiss="modal"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
                        </div>
                        <div class="grid chat grid-cols-12 gap-6">
                          <div class="col-span-12 pb-0 text-left">
                              <h2 class="text-base truncate py-3">{{ $t('Package Job') }}</h2>
                              <table class="table table--sm text-xs text-left">
                                <tbody>
                                    <tr><td class="border text-left w-40"><span>{{$t('Job Name')}}</span></td><td class="border bg-gray-300"><span class="font-medium text-base">{{ curModel.packageJobData.jobName }}</span></td></tr>
                                    <tr><td class="border"><span>{{$t('ProjectName')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ProjectName }}</span></td></tr>
                                    <tr><td class="border"><span>{{$t('ModelVersion')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelVersion }}</span></td></tr>
                                    <tr><td class="border"><span>{{$t('Status')}}</span></td><td class="border">
                                      <div>{{ curModel.packageJobData.response?.ModelPackagingDescription?.Status }}</div>
                                      <div class="mt-2">{{ curModel.packageJobData.response?.ModelPackagingDescription?.StatusMessage }}</div>
                                    </td></tr>
                                    <tr><td class="border"><span>{{$t('CreationTimestamp')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.CreationTimestamp }}</span></td></tr>
                                  </tbody>
                              </table>
                              <div class="mt-3" v-if="curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass">
                                <h2 class="text-base truncate py-3">{{ $t('Configuration') }}</h2>
                                <table class="table table--sm text-xs text-left">
                                  <tbody>
                                    <tr><td class="border w-40"><span>{{$t('Method')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingMethod }}</span></td></tr>
                                    <tr><td class="border"><span>{{$t('Compiler Options')}}</span></td><td class="border"><span><pre>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.CompilerOptions }}</pre></span></td></tr>
                                    <tr>
                                        <td class="border"><span>{{$t('Target Platform')}}</span></td>
                                        <td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.TargetPlatform?.Os }} - {{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.TargetPlatform?.Arch }}</span></td>
                                    </tr>
                                    <tr>
                                        <td class="border"><span>{{$t('S3 Output Location')}}</span></td>
                                        <td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.S3OutputLocation?.Prefix+curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.S3OutputLocation?.Bucket }}</span></td>
                                    </tr>
                                    <tr><td class="border w-40"><span>{{$t('Component Name')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.ComponentName }}</span></td></tr>
                                    <tr><td class="border"><span>{{$t('Component Version')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.ComponentVersion }}</span></td></tr>
                                    <tr><td class="border"><span>{{$t('Component Description')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingConfiguration?.Greengrass?.ComponentDescription }}</span></td></tr>
                                </tbody>
                                </table>
                                <h2 class="text-base truncate py-3 mt-3">{{ $t('Output Details') }}</h2>
                                <table class="table table--sm text-xs text-left">
                                  <tbody>
                                    <tr><td class="border w-40"><span>{{$t('Component Name')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingOutputDetails?.Greengrass?.ComponentName }}</span></td></tr>
                                    <tr>
                                        <td class="border" colspan="2"><div>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingOutputDetails?.Greengrass?.ComponentVersionArn }}</div></td>
                                    </tr>
                                    <tr><td class="border"><span>{{$t('Component Version')}}</span></td><td class="border"><span>{{ curModel.packageJobData.response?.ModelPackagingDescription?.ModelPackagingOutputDetails?.Greengrass?.ComponentVersion }}</span></td></tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>    
                        </div>  
                      </div>
                  </div>
                </div>
              </div> --> 
              <!-- Modal semi-supervised details 
              <div id="modal-semi-supervised-details" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog modal-xl">
                  <div class="modal-content">
                      <div class="modal-body p-5 pb-10 text-left">
                        <div class="intro-y flex items-center h-6">
                          <h2 class="text-xl font-medium truncate">{{ $t('Model training dataset') }}</h2>
                          <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" data-dismiss="modal"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
                        </div>
                        <span class="text-gray-600 text-xs font-normal text-left">{{ curModel.db.id }}</span>
                        <div class="grid chat grid-cols-12 gap-0 py-2 px-2 text-gray-600">
                          <div class="col-span-6 text-center mx-auto mt-8">
                            <div class="relative">
                              <ReportDonutChart2 :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
                                                                                      labels: [ "Normal", "Anomaly" ],
                                                                                      datasets: [ {
                                                                                          data: [ curModel.response.dataset.test.DatasetDescription.ImageStats["Normal"], curModel.response.dataset.test.DatasetDescription.ImageStats["Anomaly"] ],
                                                                                          backgroundColor: [ "#1d8102", "#d13212" ]
                                                                                        }
                                                                                      ]
                                                                                    }' />
                              <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                <div class="text-xl xxl:text-2xl font-medium">{{ curModel.response.dataset.train.DatasetDescription.ImageStats["Total"]+curModel.response.dataset.test.DatasetDescription.ImageStats["Total"] }}</div>
                                <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
                              </div>
                            </div>
                            <div class="mx-auto w-10/12 xxl:w-2/3 mt-8">
                              <div class="flex items-center">
                                <div class="w-2 h-2 bg-theme-26 rounded-full mr-3" style="background-color: #1d8102"></div>
                                <span class="truncate">{{$t('Normal')}}</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                <span class="font-medium xl:ml-auto">
                                    {{ ((curModel.response.dataset.test.DatasetDescription.ImageStats["Normal"]/curModel.response.dataset.test.DatasetDescription.ImageStats["Total"])*100).toFixed(2)  }}%
                                </span>
                              </div>
                              <div class="flex items-center mt-2">
                                <div class="w-2 h-2 bg-theme-35 rounded-full mr-3" style="background-color: #d13212"></div>
                                <span class="truncate">{{$t('Anomaly')}}</span>
                                <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                <span class="font-medium xl:ml-auto">
                                    {{ ((curModel.response.dataset.test.DatasetDescription.ImageStats["Anomaly"]/curModel.response.dataset.test.DatasetDescription.ImageStats["Total"])*100).toFixed(2)  }}%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-span-6 pb-0 text-left">
                            <div class="text-sm text-gray-600">{{$t('Data division')}}</div>
                            <table class="table table--sm text-xs text-center">
                              <tbody>
                                  <tr>
                                    <td></td>
                                    <td class="border"><span>{{$t('TEST')}}</span></td>
                                    <td class="border"><span>{{$t('TRAIN')}}</span></td>
                                  </tr>
                                  <tr v-for="(t,indx) in curModel.response.dataset.test.DatasetDescription.ImageStats" :key="indx">
                                    <td class="border p-2">{{ indx }}</td>
                                    <td class="border bg-gray-100 p-2"><span>{{ t }}</span></td>
                                    <td class="border bg-gray-100 p-2" v-if="curModel.response.dataset.train.DatasetDescription.ImageStats[indx]"><span>{{ curModel.response.dataset.train.DatasetDescription.ImageStats[indx] }}</span></td>
                                  </tr>
                                </tbody>
                            </table>
                            <div v-if="curModel.db && curModel.db.tagMap" class="pt-5">
                              <div class="text-sm text-gray-600">{{$t('Label Map')}}</div>
                              <div class="grid chat grid-cols-12 gap-1 py-2  text-gray-600">
                                  <div class="col-span-6 text-left">
                                    <table class="table table--sm text-xs">
                                      <tbody>
                                          <tr><td class="border text-center"><span>{{$t('NORMAL')}}</span></td></tr>
                                          <tr v-for="(t,indx) in JSON.parse(curModel.db.tagMap)['normal']" :key="indx">
                                            <td class="border bg-gray-100 text-center"><span>{{ t }}</span></td>
                                          </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                  <div class="col-span-6 text-left">
                                    <table class="table table--sm text-xs">
                                      <tbody>
                                          <tr><td class="border text-center"><span>{{$t('ANOMALY')}}</span></td></tr>
                                          <tr v-for="(t,indx) in JSON.parse(curModel.db.tagMap)['anomaly']" :key="indx">
                                            <td class="border bg-gray-100 text-center"><span>{{ t }}</span></td>
                                          </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                </div> 
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                </div>
              </div>
              --> 
              <!-- Modal evaluation semi-supervised
              <div class="col-span-12" v-if="curModel?.awsEvaluation && curModel?.awsEvaluation?.status!='error'">
                <div id="modal-semi-supervised-evaluation" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
                  <div class="modal-dialog modal-xl">
                    <div class="modal-content p-8" :style="semiSupervisedEvaluationObjects?.length ? 'width: 100% !important; margin: 0 !important; background-color: #f2f3f3 !important' : ''">
                      <div class="intro-y flex items-center h-8">
                        <h2 class="text-2xl font-medium truncate mr-8">{{ curModel.db.id }}</h2>
                        <button v-if="!semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading" type="button" @click="loadEvaluationObjects(curModel?.awsEvaluation?.manifest)" class="btn bg-dark-3 text-white py-1 font-normal mt-1 float-right ml-auto mr-8 px-8 zoom-in">
                          <imageIcon class="mr-2 w-5 h-5" /> <span v-if="curModel?.awsEvaluation?.manifestResume" class="pr-1 text-base">{{ curModel.awsEvaluation.manifestResume?.totalTestImages }}</span> 
                        </button>
                        <button v-else type="button" @click="semiSupervisedEvaluationObjects=[]" :disabled="semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading ? false : true" :class="semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading ? '' : 'disabledButton'" 
                        class="btn bg-dark-3 text-white py-1.5 font-normal mt-1 float-right ml-auto mr-8 px-8 zoom-in"><Corner-up-leftIcon class="mr-2 w-5 h-5" />{{ $t('Metrics') }}</button>
                        <label class="form-label :hover:text-blue-500 cursor-pointer pt-2 pr-1" data-dismiss="modal"><XIcon class="w-10 h-10 text-gray-600 hover:text-gray-900 zoom-in" /></label>
                      </div>
                      <span class="text-gray-600 text-sm font-normal text-left">{{ $t('Model Evaluation') }}</span>
                      <div class="modal-body p-0 text-center">
                        <div v-if="curModel.awsEvaluation?.loading">
                          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-0">
                            <div class="flex items-center justify-center mt-5">  
                              <img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" />
                            </div>
                            <LoadingIcon icon="three-dots" class="w-12 h-12" />
                          </div>
                        </div>  
                        <div v-if="!semiSupervisedEvaluationObjects?.length && !curModel.awsEvaluation?.loading">
                          <div class="grid chat grid-cols-12 gap-0 py-2 px-2 text-gray-600">
                            <div class="col-span-12 text-gray-600 text-center mt-3">
                              <h2 class="text-xl truncate">{{ $t('Model performance metrics') }}</h2>
                              <div class="text-gray-600 text-xs font-normal text-center mt-2">{{$t('Use model performance metrics to find the strengths and weaknesses of the model')}}.</div>
                            </div>
                            <div class="col-span-4 text-center mx-auto mt-5">
                              <div class="relative">
                                <div class="w-72"><ReportDonutChart2 :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' /></div>
                                <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                  <div class="text-xl xxl:text-2xl font-medium">{{ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0)  }}%</div>
                                  <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Precision')}}</div>
                                </div>
                              </div>
                              <div class="mx-auto w-f xxl:w-3/4 mt-5">
                                <div class="flex items-center">
                                  <span class="mr-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</span>
                                  <span class="truncate">{{$t('anomalías eran correctas de')}}</span>
                                </div>
                                <div class="flex items-center mt-1">
                                  <span class="mr-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.total }}</span>
                                  <span class="truncate">{{$t('predicciones totales')}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-span-4 text-center mx-auto mt-5">
                              <div class="relative">
                                <div class="w-72">
                                  <ReportDonutChart2 :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' />
                                </div>
                                <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                  <div class="text-xl xxl:text-2xl font-medium">{{ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0)  }}%</div>
                                  <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('F1 Score')}}</div>
                                </div>
                              </div>
                              <div class="mx-auto w-full mt-5 text-center">
                                <span class="truncate">{{$t('El rendimiento general del modelo')}}</span>
                              </div>
                            </div>
                            <div class="col-span-4 text-center mx-auto mt-5">
                              <div class="relative">
                                <div class="w-72">
                                  <ReportDonutChart2  :options="{ legend: { display: false }, cutoutPercentage: 80 }" :data='{ labels: [ "Precision" ],datasets: [ { data: [ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0) ], backgroundColor: [ "#1d8102" ]}] }' />
                                </div>
                                <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                  <div class="text-xl xxl:text-2xl font-medium">{{ ((curModel.awsEvaluation.manifestResume?.anomaly?.truePositive/curModel.awsEvaluation.manifestResume?.anomaly?.total)*100).toFixed(0)  }}%</div>
                                  <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Recovery')}}</div>
                                </div>
                              </div>
                              <div class="mx-auto w-f xxl:w-3/4 mt-5">
                                <div class="flex items-center">
                                  <span class="truncate">{{$t('Se predijeron')}}</span>
                                  <span class="mr-2 ml-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</span>
                                  <span class="truncate">{{$t('anomalías de')}}</span>
                                </div>
                                <div class="flex items-center mt-1">
                                  <span class="mr-2 font-bold">{{ curModel.awsEvaluation.manifestResume?.anomaly?.total }}</span>
                                  <span class="truncate">{{$t('predicciones totales')}}</span>
                                </div>
                              </div>
                            </div>
                            <div class="col-span-12 mt-5 text-gray-600 text-center">
                              <h2 class="text-xl truncate">{{ $t('General information about test results') }}</h2>
                            </div>
                            <div class="col-span-6 text-left mt-5 p-5 border">
                              <h2 class="text-base font-medium truncate">{{ $t('Predicted as normal') }}</h2>
                              <div class="grid chat grid-cols-12 gap-0 py-1 text-gray-600">
                                <div class="col-span-6">
                                  <div class="h-10 font-medium">{{ $t('Correct') }} <span class="text-xs font-normal">({{ $t('true negative') }})</span></div>
                                  <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.normal?.trueNegative }}</h2>
                                </div>
                                <div class="col-span-6">
                                  <div class="h-10 font-medium">{{ $t('Incorrect') }} <span class="text-xs font-normal">({{ $t('false negative') }})</span></div>
                                  <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.normal?.falseNegative }}</h2>
                                </div>
                              </div>   
                            </div>
                            <div class="col-span-6 text-left mt-5 p-5 border" style="border-left: none;">
                              <h2 class="text-base font-medium truncate">{{ $t('Predicted as anomaly') }}</h2>
                              <div class="grid chat grid-cols-12 gap-0 py-1 text-gray-600">
                                <div class="col-span-6">
                                  <div class="h-10 font-medium">{{ $t('Correct') }} <span class="text-xs font-normal">({{ $t('true positive') }})</span></div>
                                  <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.anomaly?.truePositive }}</h2>
                                </div>
                                <div class="col-span-6">
                                  <div class="h-10 font-medium">{{ $t('Incorrect') }} <span class="text-xs font-normal">({{ $t('false positive') }})</span></div>
                                  <h2 class="text-5xl font-medium truncate px-5">{{ curModel.awsEvaluation.manifestResume?.anomaly?.falsePositive }}</h2>
                                </div>
                              </div>   
                            </div>
                          </div> 
                        </div>
                        <div class="grid chat grid-cols-12 gap-2 text-gray-600" v-else>
                          <div class="col-span-12 mt-3" v-if="curModel?.awsEvaluation?.order && !curModel.awsEvaluation?.loading">
                            <select class="form-select bg-white cursor-pointer w-44 mt-0" v-model="curModel.awsEvaluation.filterPrediction" @change="loadEvaluationObjects(curModel?.awsEvaluation?.manifest)">
                                <option value="all">{{$t('All prediction')}}</option>
                                <option value="correct">{{$t('Correct')}}</option>
                                <option value="incorrect">{{$t('Incorrect')}}</option>
                            </select>
                            <select class="form-select bg-white cursor-pointer w-60 mt-0 ml-2" v-model="curModel.awsEvaluation.order" @change="loadEvaluationObjects(curModel?.awsEvaluation?.manifest)">
                                <option value="asc">{{$t('Lowest confidence')}}</option>
                                <option value="desc">{{$t('Highest confidence')}}</option>
                            </select>
                            <div style="display: inline-block" class="w-44 ml-5"><Slider v-model="curModel.awsEvaluation.sizeObject" :min="25" :max="75" :step="25" :merge="25" :tooltips="false" :lazy="true" /></div>
                            <div v-if="!semiSupervisedEvaluationObjects[0]?.empty" class="text-xs text-center pt-3 pb-2">
                              - {{$t('Showing')}} {{ semiSupervisedEvaluationObjects?.length }} {{$t('of')}} {{ curModel.awsEvaluation.manifestResume?.totalTestImages }} {{$t('test images')}} - 
                            </div>
                          </div>
                          <div v-if="semiSupervisedEvaluationObjects[0]?.empty" class="col-span-12 py-20">- {{$t('No results')}} -</div> 
                          <div v-else class="col-span-12 text-gray-600 text-center">
                            <div v-for="(t,indx) in semiSupervisedEvaluationObjects" :key="indx" class="relative inline-block rounded border border-gray-300 mb-2"
                              :style="curModel.awsEvaluation.sizeObject ? 'float: left !important; width: '+((curModel.awsEvaluation.sizeObject==25 ? 33 : curModel.awsEvaluation.sizeObject==75 ? 100 : curModel.awsEvaluation.sizeObject)-0.5)+'% !important; margin-right: 0.5% !important' : ''">
                              <div class="pt-3 px-2 pb-10 rounded border" style="background-color: #fff">
                                <div class="text-lg mb-3 truncate">{{ semiSupervisedEvaluationObjects[indx]?.obj['source-ref'].toString().split("/").pop() }}</div>
                                <img class="w-full rounded" :src="semiSupervisedEvaluationObjects[indx]?.imageSrc?.renderSrc" />
                                <div v-if="curModel?.awsEvaluation?.manifestResume">
                                  <div class="pt-3 text-left font-medium" :class="curModel.awsEvaluation.sizeObject!=25 ? 'px-3' : ''">
                                      <span v-if="semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label']==semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label-detected']">
                                        <img :src="require(`@/assets/images/rosepetal/icon/tick.png`)" class="w-5" style="display: inline" /> <span class="text-green-700">{{$t('Correct')}}</span> 
                                      </span>
                                      <span v-else><X-circleIcon  class="text-red-600 w-5 h-5" /> <span class="text-red-700">{{$t('Incorrect')}}</span></span>
                                      <span class="float-right ml-aut text-gray-600 text-center">
                                        <span class="text-xs text-gray-500">{{$t('Confidence')}}</span><br />
                                        <span class="text-lg">{{ (semiSupervisedEvaluationObjects[indx].obj['anomaly-label-detected-metadata']['confidence']*100).toFixed(1) }}%</span>
                                      </span>
                                      <span class="float-right ml-aut text-gray-600 text-center mr-8">
                                        <span class="text-xs text-gray-500">{{$t('Prediction')}}</span><br />
                                        {{ semiSupervisedEvaluationObjects[indx]?.obj['anomaly-label-detected-metadata']['class-name'] }}
                                      </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               --> 
            </div>

          </div>
        </div> 
      </div> 
      <!-- 
      <div class="col-span-12 text-xs" v-if="curModel">
        {{ curModel }}
      </div> 
      --> 
    </div>  

    <!-- MODALS -->

        <!-- Delete model --> 
        <div id="model-delete-box"  class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div class="modal-dialog modal-md">
              <div class="modal-content">
                <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
                <div class="modal-body p-5 text-center">
                    <div class="p-5 px-2 text-center" v-if="curModel?.db">
                        <div class="text-base mt-5">{{ $t('Are you sure to delete this model') }}?</div>
                        <div class="text-red-700 text-base mt-1">{{curModel.db.id}}</div>  
                        <div class="text-gray-600 mt-1">{{ $t('This action is irreversible') }}.</div>
                    </div>
                    <div class="px-5 pb-3 text-center">
                        <button id="dismiss-modal-delete" type="button" data-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1 h-10 zoom-in">{{ $t('Cancel') }}</button>
                        <button type="button" class="btn bg-red-700 text-white w-24 zoom-in" @click="deleteModel()">{{ $t('Delete') }}</button>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <!-- /Delete --> 

        <!-- trainBox --> 
        <div id="modal-trainBox" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div class="modal-dialog modal-xl">
            <div class="modal-content pt-5">
              <h2 class="text-2xl font-medium text-center pb-4">{{ $t('Select a goal you want to achieve with the trained model') }}</h2>
              <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer" data-dismiss="modal"><XIcon class="w-8 h-8" /></label>
              <div class="modal-body py-0 px-5 text-center pb-5">
                  <div class="grid chat grid-cols-12 gap-5 py-2 px-2 text-gray-600">
                    <div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-5 text-left">
                      <img :src="require(`@/assets/images/rosepetal/icon/supervised.png`)" class="w-1/2 mx-auto" />
                      <div class="text-lg mb-3 font-medium text-center text-gray-900">{{ $t('Supervised classification') }}</div>
                      <div class="pb-5 text-center">
                          <button type="button" @click=" modalClose('modal-trainBox');modalTrainSupervised()" class="btn w-44 bg-green-600 text-white zoom-in font-normal" v-if="Dataset && Dataset?.data?.type && Dataset?.data?.type!='MULTILABEL'">{{ $t('Create') }}</button> 
                          <button type="button" v-else class="btn w-44 bg-gray-600 text-gray-500 cursor-default disabledButton font-normal">{{ $t('Unavailable') }}</button> 
                      </div>
                      <div class="text-xs text-center">
                        {{ $t('Trained with labeled data, where the correct output is known') }}. {{ $t('The goal is to learn a function that can generalize to predict the desired output on new data') }}.
                      </div>
                      <!--
                      <div class="text-xs text-gray-600 mt-42 bg-white p-3 rounded" v-if="Dataset && Dataset.TagsCounter || Dataset && (Dataset.data.type=='imageObjectDetection' && Dataset.TagsObj && Dataset.TagsObj.labeled)">
                        <div>{{ $t('You must previously upload your images for training') }}.</div>
                        <button type="button" @click="modalClose('modal-trainBox'); Import()" class="btn btn-dark text-white py-2 mt-3 w-full" >{{ $t('Upload') }}</button>
                      </div> 
                      -->
                    </div>
                    <div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-5 text-left">
                      <img :src="require(`@/assets/images/rosepetal/icon/semi-supervised.png`)" class="w-1/2 mx-auto" />
                      <div class="text-lg mb-3 font-medium text-center text-gray-900">{{ $t('Anomaly detection') }}</div>
                      <div class="pb-5 text-center">
                          <button type="button" @click="modalClose('modal-trainBox'); modalTrainSemiSupervised()" class="btn w-44 bg-green-600 text-white zoom-in font-normal" v-if="Dataset && Dataset?.data?.type && (Dataset?.data?.type=='MULTICLASS')">{{ $t('Create') }}</button> 
                          <button type="button" v-else class="btn w-44 bg-gray-600 text-gray-500 cursor-default disabledButton font-normal">{{ $t('Unavailable') }}</button> 
                      </div>
                      <div class="text-xs text-center">
                        {{ $t('Trained with partially labeled data, where some correct outputs are not available') }}. {{ $t('Are useful when fully labeled data is not available or costly to obtain') }}.
                      </div>
                    </div>
                    <div class="col-span-6 xl:col-span-4 bg-gray-200 rounded p-5 text-left">
                      <img :src="require(`@/assets/images/rosepetal/icon/unsupervised.png`)" class="w-1/2 mx-auto" />
                      <div class="text-lg mb-3 font-medium text-center text-gray-900">{{ $t('One-class classification') }} </div>
                      <div class="pb-5 text-center">
                          <button type="button" class="btn w-44 bg-gray-600 text-gray-500 cursor-default disabledButton font-normal">{{ $t('Unavailable') }}</button> 
                      </div>
                      <div class="text-xs text-center">
                        {{ $t('Is a type of machine learning in which the algorithms are provided with data that does not contain any labels or explicit instructions on what to do with it') }}.<br /><br />
                      </div>
                    </div>
                  </div>    
                </div>
              </div>
          </div>
        </div>
        <!-- /trainBox --> 

        <!-- Train Semi-supervised AWS model --> 
        <div id="model-semi-supervised-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static">
          <div class="modal-dialog modal-xl" style="min-width: 550px;">
              <div class="modal-content">
                <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer p-5" data-dismiss="modal"><XIcon class="w-8 h-8" v-if="Dataset && !Dataset.trainSemiSupervisedAction && !Dataset.trainSemiSupervisedError"  /></label>
                <div class="modal-body p-2 text-center" v-if="Dataset">
                    <div class="grid chat grid-cols-12">
                      <div class="col-span-12 mt-5 mb-2" v-if="!Dataset.trainSemiSupervisedSuccess">
                          <div class="text-center">
                            <div class="text-center"><div class="text-2xl font-medium">{{ $t('Train a new anomaly detection model') }}</div></div>
                            <div class="text-gray-700">{{$t('Use this dataset and annotation set to train a new machine learning model')}}</div> 
                          </div>
                      </div>
                      <div class="col-span-12">
                        <div v-if="Dataset.trainSemiSupervisedError">
                            <div class="text-left mt-5 text-gray-800 pb-3 pl-5 mb-3">
                              <div>{{  $t('An error has occurred that prevents training from starting')  }}.</div>
                              <div class="py-2 text-red-600">{{ Dataset.trainSemiSupervisedError.toString().replace("Amazon Lookout for Vision","")  }}</div>
                              <button type="button" class="btn bg-theme-15 text-gray-800 w-24 mr-l zoom-in py-2 mt-3" @click="Dataset.trainSemiSupervisedError=false">{{ $t('Back') }}</button>
                            </div>
                        </div>
                        <div v-else class="grid chat grid-cols-12 mx-10">
                            <div class="col-span-12 mt-2" v-if="!Dataset.trainSemiSupervisedAction && semiSupervisedTagMap?.resume?.errors?.length">
                              <div class="text-left w-full p-5 rounded border my-3">
                                <div class="mr-5 mb-2">{{$t('Now the dataset cannot be training, check requirements for train')}}.</div>
                                <span class="pr-3 text-red-400" ><AlertCircleIcon class="w-4 h-4" /> <span class="ml-1">{{semiSupervisedTagMap?.resume?.errors.length}} {{$t('Requirements')}}</span></span>
                                <div class="grid chat grid-cols-12 mt-3 text-xs">
                                    <div class="col-span-12" v-if="semiSupervisedTagMap?.resume?.errors?.length">
                                      <div class="w-full text-truncate font-italic" v-for="(e) in semiSupervisedTagMap?.resume?.errors" v-bind:value="wd" v-bind:key="e">{{e}}</div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-span-12" v-else>
                              <div  v-if="!semiSupervisedTagMap?.resume?.total">
                                <div class="text-center mb-3 border rounded py-5 mt-3">
                                  <div class="grid grid-cols-12 gap-1">
                                    <div class="col-span-2 text-right"><Mouse-pointerIcon class="w-14 h-14 text-gray-600" /></div>
                                    <div class="col-span-9 text-left">
                                      <div class="text-sm text-gray-600 ml-5 mt-1">
                                        {{ $t('Drag and drop the labels to classify in the data set as normal or anomaly') }}.<br />
                                        {{ $t('Add anomaly labels only if you want your model to find anomalous areas') }}.
                                      </div>
                                    </div>
                                  </div>  
                                </div>
                              </div>
                            </div> 
                            <div class="col-span-12" v-if="!Dataset.trainSemiSupervisedAction">
                              <div id="trainingSummayBox" class="grid chat grid-cols-12 gap-0 mt-1 bg-gray-100" v-if="semiSupervisedTagMap?.resume?.total">
                                <div class="col-span-12"><div class="text-xl pt-3">{{ $t('Summary') }}</div></div>
                                <div class="col-span-12">
                                  <div class="grid chat grid-cols-12 gap-1 py-5 px-2 pt-0 text-gray-600" v-if="semiSupervisedTagMap?.resume">
                                    <div class="col-span-6 text-center px-5">
                                      {{ $t('Training') }} 
                                      <div class="text-2xl text-gray-700">{{  this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.resume?.train) }}</div>
                                      <div class="text-xs text-left pl-2 mt-1">
                                        <div class="float-left w-1/2">{{ $t('Normal') }}</div>
                                        <div class="float-right w-1/2 text-right pr-3">{{ this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.normal?.train) }}</div>
                                        <div class="float-left w-1/2 mt-1">{{ $t('Anomaly') }}</div>
                                        <div class="float-right w-1/2 text-right pr-3 mt-1">{{ this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.anomaly?.train) }}</div>
                                      </div> 
                                    </div> 
                                    <div class="col-span-6 text-center px-5 border-l pl-2">
                                      {{ $t('Test') }} 
                                      <div class="text-2xl text-gray-700">{{  this.helper.formatScore(semiSupervisedTagMap?.resume?.division?.resume?.test) }}</div>
                                      <div class="text-xs text-left pl-2 mt-1">
                                        <div class="float-left w-1/2">{{ $t('Normal') }}</div>
                                        <div class="float-right w-1/2 text-right pr-3">{{ this.helper.formatScore((semiSupervisedTagMap?.resume?.division?.normal?.test+semiSupervisedTagMap?.resume?.division?.normal?.validation)) }}</div>
                                        <div class="float-left w-1/2 mt-1">{{ $t('Anomaly') }}</div>
                                        <div class="float-right w-1/2 text-right pr-3 mt-1">{{ this.helper.formatScore((semiSupervisedTagMap?.resume?.division?.anomaly?.test+semiSupervisedTagMap?.resume?.division?.anomaly?.validation)) }}</div>
                                      </div> 
                                    </div> 
                                  </div>
                                </div>
                                <div class="col-span-12" v-if="!Dataset.trainSemiSupervisedError && !semiSupervisedTagMap?.resume?.errors?.length">
                                  <div class="text-green-600 text-center px-10 pb-4">{{ $t('Now the dataset is ready to be trained with') }} <span class="text-xl px-1">{{ this.helper.formatScore(semiSupervisedTagMap?.resume?.total) }}</span> {{ $t('labeled images') }}.</div>
                                  <button type="button" class="btn bg-green-500 text-white w-44 mr-1 zoom-in py-2 mb-5 text-lg" @click="trainSemiSupervised()">{{ $t('Continue') }}</button>
                                </div>
                              </div>
                              <div class="grid grid-cols-12 gap-1 mb-10">
                                <div class="col-span-4 pt-14 rounded overflow-hidden mt-11">
                                  <div class="bg-gray-300 h-full px-3">
                                    <draggable class="list-group bg-gray-300 h-full pt-5 mt-20 rounded pb-5" :list="semiSupervisedTagMap.labels" group="people" @change="refreshTagMap" v-if="Object.keys(Dataset?.tags).length">
                                      <template #item="{ element }"><div class="list-group-item bg-theme-2 text-white text-center mb-1 cursor-pointer p-3 mx-2 text-xs rounded truncate" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div></template>
                                    </draggable>
                                    <div v-else class="pt-5">{{ $t('Dataset does not contain tags') }} <div class="text-blue-500 text-xs text-center cursor-pointer" @click="manageTags()">{{ $t('create tags') }}</div></div>
                                  </div>
                                </div>
                                <div class="col-span-4 overflow-hidden text-green-600">
                                  <div class="text-lg font-medium text-center pt-2">{{ $t('Normal') }}</div>
                                  <div class="text-2xl font-medium text-center mb-2 text-gray-600 mb-8" style="margin-bottom: 56px;">
                                    <span v-if="semiSupervisedTagMap?.resume?.normal">{{ this.helper.formatScore(semiSupervisedTagMap.resume.normal) }}</span><span v-else>0</span>
                                    <div class="mx-5 text-base mt-2">
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Test')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.normal?.test">{{ semiSupervisedTagMap?.resume?.division?.normal?.test }}</span><span v-else>0</span></span>
                                      </div>
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Validation')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.normal?.validation">{{ semiSupervisedTagMap?.resume?.division?.normal?.validation }}</span><span v-else>0</span></span>
                                      </div>
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Train')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.normal?.train">{{ semiSupervisedTagMap?.resume?.division?.normal?.train }}</span><span v-else>0</span></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="bg-green-100 h-full px-3" style="margin-top: 20px;">
                                    <draggable class="list-group h-full pt-5 pb-10" :list="semiSupervisedTagMap.normal" group="people" @change="refreshTagMap">
                                      <template #item="{ element }">
                                        <div class="list-group-item bg-green-500 text-white text-center mb-1 cursor-pointer p-3 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div>
                                      </template>
                                    </draggable>
                                  </div>
                                </div>
                                <div class="col-span-4 rounded overflow-hidden text-red-400">
                                  <div class="text-lg font-medium text-center pt-2">{{ $t('Anomaly') }}</div>
                                  <div class="text-2xl font-medium text-center mb-2 text-gray-600">
                                    <span v-if="semiSupervisedTagMap?.resume?.anomaly">{{ this.helper.formatScore(semiSupervisedTagMap.resume.anomaly) }}</span><span v-else>0</span>
                                    <div class="mx-5 text-base mt-2">
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Test')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.anomaly?.test">{{ semiSupervisedTagMap?.resume?.division?.anomaly?.test }}</span><span v-else>0</span></span>
                                      </div>
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Validation')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.anomaly?.validation">{{ semiSupervisedTagMap?.resume?.division?.anomaly?.validation }}</span><span v-else>0</span></span>
                                      </div>
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Train')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.division?.anomaly?.train">{{ semiSupervisedTagMap?.resume?.division?.anomaly?.train }}</span><span v-else>0</span></span>
                                      </div>
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Mask')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><span v-if="semiSupervisedTagMap?.resume?.anomalyMask">{{ semiSupervisedTagMap?.resume?.anomalyMask }}</span><span v-else>0</span></span>
                                      </div>
                                      <div class="flex items-center">
                                        <span class="truncate">{{$t('Use mask for training')}}</span><div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                        <span class="font-medium xl:ml-auto"><input type="checkbox" class="cursor-pointer rounded" v-model="semiSupervisedTagMapUseMask"></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="bg-red-100 h-full px-3">
                                    <draggable class="list-group bg-red-100 pt-5 h-full pb-10" :list="semiSupervisedTagMap.anomaly" group="people" @change="refreshTagMap">
                                      <template #item="{ element }">
                                        <div class="list-group-item bg-red-400 text-white text-center mb-1 cursor-pointer p-2 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">
                                          <div class="font-medium">{{ element.name }}</div>
                                          <input type="text" class="w-full py-2 px-3 border-transparent bg-white text-gray-900 mt-2" v-model="element.anomalyLabel" :placeholder="'Anomaly label'" @change="refreshTagMap" />
                                        </div>
                                      </template>
                                    </draggable>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                        <div v-if="Dataset && Dataset.trainSemiSupervisedAction">
                          <div v-if="Dataset && Dataset.trainSemiSupervisedSuccess">
                            <div class="text-lg font-medium text-green-600 mt-5">{{ Dataset.trainSemiSupervisedSuccess }}</div>
                            <div class="text-base">{{ Dataset.trainSemiSupervisedAction }}</div>
                            <div class="px-5 pb-5 text-center mt-5" v-if="Dataset"><button type="button" data-dismiss="modal" class="btn text-white bg-green-500 w-32" @click="refreshBtn()">{{ $t('Ok') }}</button></div>
                          </div> 
                          <div class="intro-y col-span-12 lg:col-span-12 flex flex-col justify-center items-center mt-5 pt-0" v-if="Dataset && !Dataset.trainSemiSupervisedSuccess">
                            <div class="w-full text-center pt-8" style="background-color: #f1f2f1">
                              <div class="text-lg">{{ Dataset.trainSemiSupervisedAction }}</div>
                              <div class="mt-3">{{$t("This process can take several minutes")}}</div>
                              <span class="text-base text-red-600 font-bold">{{$t("Please do not close your browser")}}</span>
                            </div>
                            <div class="w-full text-center py-24" :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/loading.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`"></div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <!-- /Train Semi-supervised AWS model --> 

        <!-- Train Supervised vertex model --> 
        <div id="model-supervised-box" class="modal" tabindex="-1" aria-hidden="true" data-keyboard="false" data-backdrop="static" :style="trainVertex.training ? 'z-index: 99999;position: relative;' : ''">
          <div class="modal-dialog modal-xl" style="min-width: 550px;">
            <div class="modal-content p-5">
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer p-5" data-dismiss="modal"><XIcon class="w-8 h-8" v-if="trainVertex.setup || trainVertex.training"  /></label>
            <div class="modal-body py-2 px-0 text-center" v-if="Dataset">
              <div class="grid chat grid-cols-12">
                <div class="col-span-12" v-if="Layout.loading">
                  <div class="flex items-center justify-center mt-10"><img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" class="w-20" /></div>
                  <div class="intro-y flex flex-col justify-center items-center mb-5"><LoadingIcon icon="three-dots" class="w-10 h-10" /></div>
                </div>
                <div class="col-span-12" v-else>
                  <div class="grid chat grid-cols-12 px-10">
                    <div class="col-span-12 mt-2">
                      <div class="grid chat grid-cols-12" v-if="trainVertex.setup">
                        <div class="col-span-12">
                            <div class="text-center">
                              <div class="text-center"><div class="text-2xl font-medium">{{ $t('Train a new supervised classification model') }}</div></div>
                              <div class="text-gray-700">{{$t('Use this dataset and annotation set to train a new machine learning model')}}</div> 
                            </div>
                        </div>

                        <div class="col-span-12 mt-2" v-if="Dataset?.validateToTrain && !Dataset?.validateToTrain?.validated && trainVertex?.tagMap && supervisedTagMap.selected && Object.keys(supervisedTagMap.selected).length">
                            <div class="text-left w-full p-5 rounded border my-3">
                              <div class="mr-5 mb-3">{{$t('Now the dataset cannot be training, check requirements and warnings for train')}}.</div>
                              <div class="grid chat grid-cols-12 mt-5 text-xs">
                                  <div class="col-span-7" v-if="(Dataset.validateToTrain.errors && Dataset.validateToTrain.errors.length)">
                                    <div class="pr-3 text-red-400 text-sm mb-5" v-if="Dataset.validateToTrain.errors.length"><AlertCircleIcon class="w-4 h-4" /> {{Dataset.validateToTrain.errors.length}} {{$t('Requirements')}}</div>
                                    <div class="w-full text-truncate font-italic" v-for="(k) in Dataset.validateToTrain.errors" :key="k"> {{k}}</div>  
                                  </div>
                                  <div class="col-span-5" v-if="(Dataset.validateToTrain.warnings && Dataset.validateToTrain.warnings.length)" >
                                    <div v-if="Dataset.validateToTrain.warnings.length" class="text-sm mb-5"><AlertTriangleIcon class="w-4 h-4" /> {{Dataset.validateToTrain.warnings.length}} {{$t('Warnings')}}</div>
                                    <div class="w-full text-truncate font-italic" v-for="(k) in Dataset.validateToTrain.warnings" :key="k">{{k}}</div> 
                                  </div>
                              </div>
                              <div class="mt-5 text-xs"><span class="text-blue-500 cursor-pointer" @click="manageTags()">Manage tags</span> | <span class="text-blue-500 cursor-pointer" @click="linkToBrowser()">Labeling</span></div>
                            </div>
                            <!--<pre class="text-xs text-left mt-32" v-if="Dataset.validateToTrain">{{Dataset.validateToTrain}}</pre>-->
                        </div>

                        <div class="col-span-12 mt-2">
                            <div class="text-left">
                              <div class="mb-3 ">{{ $t('Model name') }} *</div>
                              <input id="train-name" type="text" :maxlength="32" v-model="trainVertex.modelName" class="form-control py-3 px-4 border-transparent bg-gray-200 pr-10" required   />
                              <div class="text-xs pt-2 text-center">{{ $t('Use up to 32 characters, which can include letters, numbers, and underscores') }}.</div>
                            </div>
                        </div>

                        <!-- Create tag Map Supervised @change="refreshTagMap" -->
                        <div class="col-span-12 mt-5">
                          <div class="text-left">{{ $t('Select the tags you want to include in the training') }} *</div>
                          <div class="grid grid-cols-12 gap-1" v-if="supervisedTagMap">
                            <div class="col-span-6 rounded overflow-hidden">
                              <div class="bg-gray-300 h-full px-3">
                                <draggable class="list-group bg-gray-300 h-full pt-3 mt-5 rounded pb-3" :list="supervisedTagMap.labels" group="people" @change="refreshTagMapSupervised" v-if="Object.keys(Dataset?.tags).length">
                                  <template #item="{ element }"><div class="list-group-item bg-theme-2 text-white text-center mb-1 cursor-pointer p-2 mx-2 text-xs rounded truncate" :class="{ 'not-draggable': !enabled }">{{ element.name }}</div></template>
                                </draggable>
                                <div v-else class="pt-5 mb-5">{{ $t('Dataset does not contain tags') }} <div class="text-blue-500 text-xs text-center cursor-pointer" @click="manageTags()">{{ $t('create tags') }}</div></div>
                              </div>
                            </div>
                            <div class="col-span-6 overflow-hidden" >
                              <div class="bg-green-100 h-full px-3">
                                <draggable class="list-group h-full pt-5 pb-10" :list="supervisedTagMap.selected" group="people" >
                                  <template #item="{ element }">
                                    <div class="list-group-item bg-green-500 text-white text-center mb-1 cursor-pointer p-2 mx-1 text-xs rounded truncate font-medium" :class="{ 'not-draggable': !enabled }">
                                      <div class="font-medium">{{ element.name }}</div>
                                      <input type="text" class="w-full py-2 px-3 border-transparent bg-white text-gray-900 mt-2" v-model="element.vertexLabel" :placeholder="'Label'" @change="refreshTagMapSupervised" />
                                    </div>
                                  </template>
                                </draggable>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- END Create tag Map Supervised>
                        <div class="col-span-12 mt-3"><pre class="text-left text-xs">{{ trainVertex }}</pre></div>
                        <div class="col-span-12 mt-3"><pre class="text-left text-xs">{{ Dataset.vertexDivision }}</pre></div>
                        <div class="col-span-12 mt-3"><pre class="text-left text-xs">{{ Dataset.validateToTrain }}</pre></div>-->
                        

                        <div class="col-span-12 mt-5" v-if="supervisedTagMap?.selected && Object.keys(supervisedTagMap?.selected).length && trainVertex?.tagMap">
                          <div class="grid chat grid-cols-12">
                            <div class="col-span-12">
                              <div class="mb-3 ">{{ $t('The train budget of creating this model') }} *</div>
                                <div v-if="Dataset.data.type!='imageObjectDetection'" class="text-xs mb-5">
                                    <span>{{$t('Recommended')}}</span> <span class="py-1 px-4 rounded-full text-xs bg-gray-400 text-gray-600 cursor-pointer px-5 ml-2">{{ trainVertex.modelTrainBudgetObjMin+" "+$t('node hours')}}</span>
                                </div> 
                              <div class="relative text-gray-700 dark:text-gray-300 px-5">
                                <Slider v-model="trainVertex.modelTrainBudget" 
                                        :min="Dataset.data.type=='imageObjectDetection' ? trainVertex.modelTrainBudgetObjMin : trainVertex.modelTrainBudgetMin" 
                                        :max="Dataset.data.type=='imageObjectDetection' ? trainVertex.modelTrainBudgetObjMax : trainVertex.modelTrainBudgetMax" 
                                        :step="trainVertex.modelTrainBudgetStep" 
                                        :merge="trainVertex.modelTrainBudgetStep" />
                                <div class="text-xs pt-2 mb-2">
                                  {{$t('The budget for the creation of this model, expressed in milli node hours, that is, the value of 1000 in this field means 1 node hour')}}.
                                  <div class="font-bold">{{$t('24.000 represents one day in wall time')}}.</div>
                                </div>
                                
                                <!-- 
                                <div class="text-xs pt-1" v-else> 
                                  <span class="pt-5">{{$t('Recommended')}}</span>: 
                                  <span class="py-1 px-4 rounded-full text-xs bg-theme-23 cursor-pointer">
                                    <span v-if="Dataset.TagsCounter<1000">3</span>
                                    <span v-if="Dataset.TagsCounter>1000 && Dataset.TagsCounter<10000">4-6</span>
                                    <span v-if="Dataset.TagsCounter>10000">8-24</span>
                                    <span class="pl-1">{{$t('node hours')}}</span>
                                  </span>
                                </div>
                                -->   
                              </div>
                            </div>
                            <div class="col-span-12 mt-3">
                              <div class="text-left">
                                <div class="text-left">
                                  <div class="pb-5 text-center">
                                    <input v-model="trainVertex.method" class="form-check-input mr-1" type="radio" value="labeled" style="vertical-align: middle" /> <span class="font-medium" :class="trainVertex.method=='labeled' ? 'text-blue-500' : ''">{{ $t('Current labeled') }}</span>
                                    <input v-model="trainVertex.method" class="form-check-input ml-10 mr-1" type="radio" value="auto" style="vertical-align: middle" /> <span class="font-medium" :class="trainVertex.method=='auto' ? 'text-blue-500' : ''">{{ $t('Auto assignment') }}</span> 
                                  </div>
                                  <div v-if="trainVertex.method=='labeled'">
                                    <div class="grid chat grid-cols-12 mb-10">
                                      <div class="col-span-5">
                                        <div v-if="Dataset.vertexDivision" class="p-5 pt-0">
                                          <div class="relative">
                                            <ReportDonutChart2 :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
                                                                                          labels: [ "Train", "Test", "validation", "Predetermined" ],
                                                                                          datasets: [ {
                                                                                              data: [ 
                                                                                                      Dataset.vertexDivision?.train, Dataset.vertexDivision?.test, Dataset.vertexDivision?.validation, Dataset.vertexDivision?.predetermined
                                                                                                      
                                                                                                    ],
                                                                                              backgroundColor: [ "#333333", "#1d8102", "#09491b", "#b7b7b7" ]
                                                                                            }
                                                                                          ]
                                                                                        }' />
                                            <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                              <div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertexDivision?.total) }}</div>
                                              <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-span-7">
                                        <div class="text-left text-xs mx-12">
                                          <div class="mb-2">{{ $t('Data division') }} *</div>
                                          {{ $t('Your datasets will be manually split into training, validation, and test sets with the following ratios') }}: 
                                        </div>
                                        <div class="mx-auto w-10/12 xxl:w-2/3 mt-5">
                                          <div class="flex items-center">
                                            <div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
                                            <span class="truncate">{{$t('Train')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                                {{ Dataset.vertexDivision?.train  }} ({{ ((Dataset.vertexDivision?.train*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
                                            </span>
                                          </div>
                                          <div class="flex items-center mt-2">
                                            <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
                                            <span class="truncate">{{$t('Test')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                                {{ Dataset.vertexDivision?.test }} ({{ ((Dataset.vertexDivision?.test*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
                                            </span>
                                          </div>
                                          <div class="flex items-center mt-2">
                                            <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
                                            <span class="truncate">{{$t('Validation')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                                {{ Dataset.vertexDivision?.validation }} ({{ ((Dataset.vertexDivision?.validation*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
                                            </span>
                                          </div>
                                          <div class="flex items-center mt-2" v-if="Dataset.vertexDivision">
                                            <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #b7b7b7"></div>
                                            <span class="truncate">{{$t('Predetermined')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                                {{ Dataset.vertexDivision?.predetermined }} ({{ ((Dataset.vertexDivision?.predetermined*100)/Dataset.vertexDivision?.total).toFixed(0) }}%)
                                            </span>
                                          </div>
                                        </div>
                                        <div class="text-center text-xs mt-5">
                                          {{ $t('You can configure the set by image through') }} <span class="text-blue-600 cursor pointer" @click="linkToBrowser()">{{ $t('data labeling') }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="trainVertex.method=='auto'">
                                    <div class="grid chat grid-cols-12">
                                      <div class="col-span-5 mb-10">
                                        <div v-if="Dataset.vertexDivision" class="p-5 pt-0">
                                          <div class="relative">
                                            <ReportDonutChart2 :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
                                                                                          labels: [ "Train", "Test", "validation" ],
                                                                                          datasets: [ {
                                                                                              data: [ trainVertex.train, trainVertex.test, trainVertex.validation],
                                                                                              backgroundColor: [ "#333333", "#1d8102", "#09491b", "#b7b7b7" ]
                                                                                            }
                                                                                          ]
                                                                                        }' />
                                            <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0">
                                              <div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertexDivision?.total) }}</div>
                                              <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="mx-auto w-10/12 xxl:w-2/3">
                                          <div class="flex items-center">
                                            <div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
                                            <span class="truncate">{{$t('Train')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                              {{ ((trainVertex.train*Dataset.vertexDivision?.total)/100).toFixed(0)}}
                                            </span>
                                          </div>
                                          <div class="flex items-center mt-2">
                                            <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
                                            <span class="truncate">{{$t('Test')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                              {{ ((trainVertex.test*Dataset.vertexDivision?.total)/100).toFixed(0)}}
                                            </span>
                                          </div>
                                          <div class="flex items-center mt-2">
                                            <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
                                            <span class="truncate">{{$t('Validation')}}</span>
                                            <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                            <span class="font-medium xl:ml-auto">
                                              {{ ((trainVertex.validation*Dataset.vertexDivision?.total)/100).toFixed(0)}} 
                                            </span>
                                          </div>
                                        </div>
                                      </div> 
                                      <div class="col-span-7">
                                        <div class="text-left text-xs mx-12"><div class="mb-2">{{ $t('Data division') }} *</div> {{ $t('Your dataset will be automatically randomized and split into training, validation, and test sets with the following ratios') }}:</div>
                                        <div class="text-left text-xs mx-12 bg-red-200 text-gray-800 py-3 mt-5 pl-5" v-if="trainVertex?.autoDivisionAlert"><AlertTriangleIcon class="w-4 h-4 mr-1" /> {{$t('The division of data must add up to 100%')}}. </div>
                                        <div class="grid chat grid-cols-12 mx-20 my-2 mt-8">
                                        <div class="col-span-12 text-left mb-4">
                                          <div class="mb-2">{{ $t('Train') }} <span v-if="trainVertex.train" class="ml-auto float-right text-xs">{{trainVertex.train}}%</span></div>
                                          <Slider v-model="trainVertex.train" :min="50" :max="100" :step="1" :merge="1" :tooltips="false" :lazy="true" />
                                        </div>
                                        <div class="col-span-12 text-left mb-4">
                                          <div class="mb-2">{{ $t('Test') }}  <span v-if="trainVertex.test" class="ml-auto float-right text-xs">{{trainVertex.test}}%</span></div>
                                          <Slider v-model="trainVertex.test" :min="0" :max="50" :step="1" :merge="1" :tooltips="false" :lazy="true" />
                                        </div>
                                        <div class="col-span-12 text-left mb-4">
                                          <div class="mb-2">{{ $t('Validation') }} <span v-if="trainVertex.validation" class="ml-auto float-right text-xs">{{trainVertex.validation}}%</span></div>
                                          <Slider v-model="trainVertex.validation" :min="0" :max="50" :step="1" :merge="1" :tooltips="false" :lazy="true" />
                                        </div>
                                      </div>
                                      </div> 
                                    </div>  
                                  </div>  
                                </div>   
                              </div> 
                            </div>  
                            <div class="col-span-12 text-center mt-5" v-if="Dataset?.validateToTrain?.validated && !trainVertex?.autoDivisionAlert"><button type="button" class="btn bg-theme-15 w-44 mr-1 zoom-in py-4" @click="trainSupervised()">{{ $t('Continue') }}</button></div>
                          </div>
                        </div>
                      </div> 
                      <div v-else>
                        <div class="col-span-12">
                            <div v-if="trainVertex.error">
                                <div class="text-left mt-5 text-gray-800 py-3 pl-5 mb-3">
                                  <div>{{  $t('An error has occurred that prevents training from starting')  }}.</div>
                                  <div class="py-3 text-red-600">{{ trainVertex.error }}</div>
                                  <button type="button" class="btn bg-theme-15 text-gray-800 w-32 mr-l zoom-in py-2 mt-5" @click="trainVertex.setup=true; trainVertex.error=false">{{ $t('Back') }}</button>
                                </div>
                            </div>
                            <div v-else class="grid chat grid-cols-12 gap-0">
                              <div class="col-span-12">
                                <div class="intro-y flex flex-col justify-center items-center  text-center my-20 mx-10">
                                  <LoadingIcon icon="oval" class="w-20 h-20 mb-5" /><br />
                                  {{$t('Synchronizing and creating the model')}}.<br /> 
                                  {{$t('This process can take several minutes')}}.<br /> 
                                  <span class="text-theme-5 text-base">{{$t("Don't close your browser")}}</span>
                                </div>
                              </div>  
                              <!-- muestra el estado de la division de los datos en vertex y lo va refrescando mientras se esta importando 
                              <div class="col-span-5 mt-2 mb-5" v-if="Dataset.vertex">
                                <div class="relative">
                                  <ReportDonutChart2 :height="190" :options="{ legend: { display: false }, cutoutPercentage: 70  }" :data='{
                                                                                labels: [ "Train", "validation" ],
                                                                                datasets: [ {
                                                                                    data: [ 
                                                                                            Dataset.vertex?.division?.training, Dataset.vertex?.division?.test, Dataset.vertex?.division?.validation
                                                                                            
                                                                                          ],
                                                                                    backgroundColor: [ "#333333", "#1d8102", "#09491b" ]
                                                                                  }
                                                                                ]
                                                                              }' />
                                  
                                  <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0" v-if="!Dataset.vertex?.exampleCount">
                                    <div class="text-xl xxl:text-2xl font-medium">{{ parseInt(Dataset.vertex?.exampleCount ) }}</div>
                                    <div class="text-gray-600 dark:text-gray-600 mt-0.5">{{$t('Images')}}</div>
                                  </div>
                                  <div class="flex flex-col justify-center items-center absolute w-full h-full top-0 left-0" v-else>
                                    <LoadingIcon icon="oval" class="w-20 h-20" /><br />
                                  </div>


                                  

                                </div>
                                <div class="mx-auto w-10/12 xxl:w-2/3 mt-8">
                                  <div class="flex items-center" v-if="Dataset.vertex?.division?.training">
                                    <div class="w-3 h-3 bg-theme-26 rounded-full mr-3" style="background-color: #333333"></div>
                                    <span class="truncate">{{$t('Train')}}</span>
                                    <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                    <span class="font-medium xl:ml-auto">
                                        {{ Dataset.vertex?.division?.training  }} <span v-if="Dataset.vertex?.exampleCount">({{ ((Dataset.vertex?.division?.training*100)/Dataset.vertex?.exampleCount).toFixed(0) }}%)</span>
                                    </span>
                                  </div>
                                  <div class="flex items-center mt-2" v-if="Dataset.vertex?.division?.test">
                                    <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #1d8102"></div>
                                    <span class="truncate">{{$t('Test')}}</span>
                                    <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                    <span class="font-medium xl:ml-auto">
                                        {{ Dataset.vertex?.division?.test  }} <span v-if="Dataset.vertex?.exampleCount">({{ ((Dataset.vertex?.division?.test*100)/Dataset.vertex?.exampleCount).toFixed(0) }}%)</span>
                                    </span>
                                  </div>
                                  <div class="flex items-center mt-2" v-if="Dataset.vertex?.division?.validation">
                                    <div class="w-3 h-3 bg-theme-35 rounded-full mr-3" style="background-color: #09491b"></div>
                                    <span class="truncate">{{$t('Validation')}}</span>
                                    <div class="h-px flex-1 border border-r border-dashed border-gray-400 mx-2 mt-3"></div>
                                    <span class="font-medium xl:ml-auto">
                                        {{ Dataset.vertex?.division?.validation  }} <span v-if="Dataset.vertex?.exampleCount">({{ ((Dataset.vertex?.division?.validation*100)/Dataset.vertex?.exampleCount).toFixed(0) }}%)</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              --> 
                            </div>
                        </div> 
                      </div> 
                      <div class="fixed top-0 left-0 w-full h-full bg-gray-200 text-center p-20 flex items-center justify-center text-white" v-if="trainVertex.training" 
                      :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/train.gif`)}); background-position: center; background-size: cover; background-repeat: no-repeat; height: 100%;`" style="z-index:999">
                        <div class="text-center">{{ $t('Model is training, please wait ...') }}</div> <div @click="trainVertex =false; refreshBtn()" class="ml-5 bg-green-500 text-white py-2 px-5 rounded cursor-pointer">{{ $t('Continue') }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        </div>
        <!-- /Train Supervised vertex model --> 

   <!-- /MODALS -->
   </div>

</template>

<script>
import { defineComponent, onMounted , ref,  watch  } from "vue";
import { helper as $h } from "@/utils/helper";
//import { model as $model } from "@/model/model";
import { dataset as $dataset } from "@/model/db/dataset";
//import { event as $event } from "@/model/events";
import { useRouter } from "vue-router";
//import { useStore } from "@/store";
import cash from "cash-dom";
import config from '@/etc/rosepetal.json';
import draggable from "vuedraggable";
import ClipboardJS from 'clipboard';

import * as rosepetalModel from "rosepetal-model";
import Slider from '@vueform/slider'
import ReportDonutChart2 from "@/components/report-donut-chart-2/Main.vue";

export default defineComponent({
  props: {
    dataset: {
      type: String,
      required: true
    }
  },
  components: {
      Slider,
      ReportDonutChart2,
      draggable
  },
  created () {
    this.helper         = $h
    this.rosepetalModel = rosepetalModel
  },  

  setup(props) {
    const modelBucket        = config.modelBucket;
    const router             = useRouter();
    const Layout             = ref({ loading: true, wizard: { curStep: 1 }, modalImport: false })
    const curDataset         = ref()
    const Dataset            = ref({ data: {}, tags: {}, TagsCounter: {}, localModels: [], selectedModel: false})
    const curModel           = ref({})
    //const DatasetTags      = ref({});
    const DatasetTagsObj     = ref({});
    const localModelsMenu    = ref([]);
    const supervisedTagMap                = ref({})
    const semiSupervisedTagMap            = ref({})
    const semiSupervisedTagMapUseMask     = ref(true)
    const semiSupervisedEvaluationObjects = ref([])
    const clipboard                       = ref()
    const trainVertexInCourse             = ref(false)

    const trainVertex        = ref({
                                      method: "auto",
                                      modelName: 'model_undefined',
                                      test: 10,
                                      validation: 10,
                                      train: 80, 
                                      modelTrainBudget: 3000, 
                                      modelTrainBudgetMin: 1000,
                                      modelTrainBudgetObjMin: 1000,
                                      modelTrainBudgetStep: 1000,
                                      modelTrainBudgetMax: 24000, 
                                      modelTrainBudgetObjMax: 30000,
                                      setup: true, 
                                   })
    
    const Train              = ref({ csv: { 
                                      method: "auto",
                                      test: 10,
                                      validation: 10,
                                      train: 80,
                                      modelTrainBudget: 3000, 
                                      modelTrainBudgetMin: 1000,
                                      modelTrainBudgetObjMin: 1000,
                                      modelTrainBudgetStep: 1000,
                                      modelTrainBudgetMax: 24000, 
                                      modelTrainBudgetObjMax: 30000,
                                      modelName: 'model_undefined',
                                      modelAnnotationSet: false,
                                      minTagImages: 10, 
                                      minTagCounter: false, 
                                      gs: false, 
                                      error: false, 
                                      loading: false, 
                                      lastImport: {} 
                                    },
                                    train: {
                                      loading: false, 
                                      lastTraining: {},
                                      annotationSetList: {}
                                    }
                                })

    const modal       = (m) => { cash("#"+m).modal("show"); cash(".main").removeClass("overflow-y-hidden")};
    const modalClose  = (m) => { cash("#"+m).modal("hide"); };
    const modalRemove = (m) => { cash("#"+m).remove(); };

    const getDatasetModels = async () => {
      Layout.value.loading       = true
      let _dsModels              = await rosepetalModel.dataset.getModels(curDataset.value)
      Dataset.value.localModels  = _dsModels?.models ? _dsModels?.models : false
      clipboard.value            = new ClipboardJS(".copyButton");
      //if(Dataset.value.localModels.length && !Dataset.value.selectedModel)Dataset.value.selectedModel = Dataset.value.localModels[0].id
      /*for (let ds in Dataset.value.localModels) { 
        if(Dataset.value.localModels[ds].automl){
          curModel.value     = await rosepetalModel.dataset.getVertex(curDataset.value)
          curModel.value.db  = Dataset.value.localModels[ds]
          clipboard.value    = new ClipboardJS(".copyButton");
        }
        if(Dataset.value.localModels[ds].aws){
          curModel.value                 = await rosepetalModel.aws.getDataset(Dataset.value.localModels[ds].aws)  
          curModel.value.awsModel        = await rosepetalModel.aws.getModel(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion) 
          Layout.value.loading = false 
          //curModel.value.awsEvaluation   = await rosepetalModel.aws.getEvaluation(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion) 
          //curModel.value.packageJobs     = await rosepetalModel.aws.getModelPackagingJobs(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion) 
          curModel.value.db              = Dataset.value.localModels[ds]
        }
      }*/
      Layout.value.loading = false 
    }

    const getDataset = async () => {
      Dataset.value.data            = await rosepetalModel.dataset.get(curDataset.value, { tagsCounter: false });
      Dataset.value.tags            = await $dataset.getTags(curDataset.value, false);
      await getDatasetModels()

      /*
      Dataset.value.data            = await $dataset.get(curDataset.value); 
      Dataset.value.data.setCounter = false; 
      Dataset.value.data.setCounter = await $dataset.getSetCounter(curDataset.value, true, Dataset.value.data.type);
      Train.value.csv.lastImport  = {}
      var nowdate                 = new Date()
      Train.value.csv.modelName   = Dataset.value.data.name+"_"+nowdate.getTime()
      Train.value.csv.modelName   = Train.value.csv.modelName.slice(0, 32);
      if(Dataset.value.data.type=="imageObjectDetection"){
        Train.value.csv.modelTrainBudget  =  Train.value.csv.modelTrainBudgetObjMin
        Dataset.value.TagsObj             = await $dataset.getTagsObjectDetection(curDataset.value)
        if(Dataset.value.TagsObj.labeled && Dataset.value.TagsObj.labeled>=Train.value.csv.minTagImages)Train.value.csv.minTagCounter = true;
      }
      Dataset.value.tags          = await $dataset.getTags(curDataset.value, false);
      Dataset.value.TagsCounter   = await $dataset.getTotalTagsCounter(Dataset.value.tags);
      if(Dataset.value.data.type && Dataset.value.data.type=='imageObjectDetection')DatasetTagsObj.value  = await $dataset.getTagsObjectDetection(Dataset.value.data.id)
      if(Dataset.value.data.automl)Dataset.value.data.automldata = await $dataset.getVertexDataset(Dataset.value.data, false, true)
      await getDatasetModels()
      if(Dataset.value.data.type!="imageObjectDetection"){
        if(Object.keys(Dataset.value.tags).length){
            let haveMin = true
            for (const dstag of Object.values(Dataset.value.tags)) {  if(!dstag.imageCounter || (dstag.imageCounter<Train.value.csv.minTagImages))haveMin = false  }
            Train.value.csv.minTagCounter = haveMin;
        } 
      }
      */
    }

    const exportModelToBucket = async () => { 
      Layout.value.loading = true
      if(curModel.value?.db?.id){
        await rosepetalModel.model.export(curModel.value.db.id, { updateModel: true }).then(async response =>  {
          if(response?.savedModel)curModel.value.db.savedModel = response?.savedModel
        })
      }
      /*
      if(curModel.value){
        console.log(curModel.value)
        let modelId = curModel.value.name.toString().split('/').pop() 
        await $event.saveEvent('model.export', { uid: useStore().state.main.User.uid, model: modelId }, false)
        await $dataset.exportModel(modelId).then(async response =>  {
            if(response.name){
              await $event.saveEvent('model.export.response', { uid: useStore().state.main.User.uid, model: modelId, operation: response.name, response: JSON.stringify(response)  }, false)
              if(response.metadata.outputInfo.artifactOutputUri)
                  await $dataset.modelUpdate(curModel.value.displayName, { savedModel: response.metadata.outputInfo.artifactOutputUri+"/saved_model.pb" } ) 
            }
            await getDatasetModels()
        })
      }
      */
      Layout.value.loading = false
    }

    const deleteModel = async () => {
      cash("#model-delete-box").modal("hide")
      if(Dataset.value.selectedModel)await $dataset.modelUpdate(Dataset.value.selectedModel,{ deleted : Boolean(true) })
      await this.refreshBtn() 
    }

    const modalTrainSupervised = async () => {
      modal("model-supervised-box")
      Layout.value.loading          = true
      supervisedTagMap.value        =  { "labels":  [], "selected":  [], "resume": {} }
      if(Object.keys(Dataset.value.tags).length)
          for (const dstag of Object.values(Dataset.value.tags)) {  
            if(!dstag.unclassified)
            supervisedTagMap.value.labels.push({ 
                                                name:     dstag.name, 
                                                id:       dstag.id, 
                                                counter:  dstag.imageCounter, 
                                                color:    dstag.color, 
                                                division: await rosepetalModel.dataset.getDataDivision(curDataset.value, true, dstag.id), 
                                                mask:     await rosepetalModel.dataset.getMaskCounter(curDataset.value, dstag.id), 
                                              })   
              
          }
      trainVertex.value.modelName   = Dataset.value.data.name.toString() 
      Layout.value.loading          = false
    }
    
    const initTrainSupervised = async () => {
      if(!trainVertexInCourse.value){
        await rosepetalModel.dataset.getDatasetAnnotationSetList(Dataset.value.data.automl).then(async response =>  {
          if(response?.status && response?.status=="success"){
            trainVertex.value.annotationSetId  = response?.last
            //create model
            await rosepetalModel.model.create(curDataset.value, trainVertex.value.modelName, { trainBudget: trainVertex.value.modelTrainBudget, annotationSetId:  trainVertex.value.annotationSetId, tagMap: JSON.stringify(trainVertex.value.tagMap)  } ).then(async response =>  {
              if(response?.status && response?.status=="success"){
                let trainSet = { 
                        datasetID:       Dataset.value.data.automl, 
                        type:            Dataset.value.data.type, 
                        modelName:       trainVertex.value.modelName, 
                        trainBudget:     trainVertex.value.modelTrainBudget,
                        annotationSetId: trainVertex.value.annotationSetId,
                        tagMap:          trainVertex.value.tagMap ? trainVertex.value.tagMap : false
                      }
                await rosepetalModel.dataset.train(trainSet).then(async responseTrain =>  {
                  if(responseTrain.status=='error')trainVertex.value.error = responseTrain.error
                  else{ 
                    trainVertex.value.trainOperation  = responseTrain
                    if(trainVertex.value.trainOperation.operationId){
                      await rosepetalModel.model.update(response.modelId, { trainOperation: { operationId: trainVertex.value.trainOperation.operationId, operationName: trainVertex.value.trainOperation.operationName, }  })
                      trainVertex.value.training = true
                    }
                  }
                })
              }else trainVertex.value.error = response?.error
            });
          }else trainVertex.value.error = response?.error
        });
      }
    }

    const trainSupervised = async () => {
      trainVertex.value.setup   = false
      //check dataset vertex status
      let intervId    = setInterval(async () => {
                          Dataset.value.vertex           = await rosepetalModel.dataset.getVertex(curDataset.value)
                          trainVertex.value.vertexCount  = Dataset.value.vertex?.response[0].exampleCount
                          if(trainVertex.value.importOperation)
                            await rosepetalModel.other.httpsCallable('api/model/operation/status/'+ trainVertex.value.storage?.operationName.replace(/\//g, "--")).then(async response =>  {
                              if(response.data)trainVertex.value.importOperation = response.data
                              if(response.data.done){
                                clearInterval(intervId)
                                await initTrainSupervised()
                                trainVertexInCourse.value = true
                              }
                            })
                          /*  
                          if(Dataset.value.vertex?.status=='error'){ console.log(Dataset.value.vertex?.error)
                          }else{
                            if(Dataset.value.vertex?.response[0])Dataset.value.vertex = Dataset.value.vertex?.response[0]
                            if(Dataset.value.vertex?.dataItems[0]){
                              Dataset.value.vertex.division = { training: 0, validation: 0, test: 0 }
                              for(const item of Dataset.value.vertex?.dataItems[0]){
                                if(item?.labels["aiplatform.googleapis.com/ml_use"]=='training')Dataset.value.vertex.division.training++
                                if(item?.labels["aiplatform.googleapis.com/ml_use"]=='validation')Dataset.value.vertex.division.validation++
                                if(item?.labels["aiplatform.googleapis.com/ml_use"]=='test')Dataset.value.vertex.division.test++
                              }
                            }
                          } 
                          */
                          if(Dataset.value.vertex?.status=='error' || trainVertex.value?.trainOperation)clearInterval(intervId)
                        }, 5000);

      //Upload storage
      await rosepetalModel.dataset.uploadStorage(curDataset.value, { test: trainVertex.value.test, validation: trainVertex.value.validation, tagMap: trainVertex.value.tagMap ? trainVertex.value.tagMap : false } ).then(async response =>  {
        if(response.status == "error")trainVertex.value.error = response.error
        else{ 
          trainVertex.value.storage = response; 
          if(trainVertex.value.storage?.status=='error')trainVertex.value.error = trainVertex.value.storage?.error
          else await rosepetalModel.other.httpsCallable('api/model/operation/status/'+ trainVertex.value.storage?.operationName.replace(/\//g, "--")).then(async response =>  { if(response.data)trainVertex.value.importOperation = response.data  })
        }
      })
    }


    const modalTrainSemiSupervised = async () => {
      cash("#model-semi-supervised-box").modal("show")
      cash(".main").removeClass("overflow-y-hidden")
      Dataset.value.trainSemiSupervisedAction = Dataset.value.trainSemiSupervisedError = Dataset.value.trainSemiSupervisedSuccess =  false
      semiSupervisedTagMap.value =  { "labels":  [], "normal":  [], "anomaly": [], "resume": {} }
      if(Object.keys(Dataset.value.tags).length)
          for (const dstag of Object.values(Dataset.value.tags)) {  
            if(!dstag.unclassified){
              let tagItem = { 
                              name:     dstag.name, 
                              id:       dstag.id, 
                              counter:  dstag.imageCounter, 
                              color:    dstag.color, 
                              division: await rosepetalModel.dataset.getDataDivision(curDataset.value, true, dstag.id), 
                              mask:     await rosepetalModel.dataset.getMaskCounter(curDataset.value, dstag.id), 
                            }
              if(tagItem?.division?.total)tagItem.counter = tagItem.division.total
              semiSupervisedTagMap.value.labels.push(tagItem)   
            }  
          }
    }

    const trainSemiSupervised = async () => {
      Dataset.value.trainSemiSupervisedError = false
      if(semiSupervisedTagMap.value){
        semiSupervisedTagMap.value.tagMap = { normal: [], anomaly: [], anomalyLabel: {}, useMask: false }
        if(semiSupervisedTagMapUseMask.value)semiSupervisedTagMap.value.tagMap.useMask = true
        for(const tag of semiSupervisedTagMap.value.normal){  semiSupervisedTagMap.value.tagMap.normal.push(tag.name.toString().toUpperCase()) }
        for(const tag of semiSupervisedTagMap.value.anomaly){  
          semiSupervisedTagMap.value.tagMap.anomaly.push(tag.name.toString().toUpperCase()) 
          if(tag.anomalyLabel){
            if(!semiSupervisedTagMap.value.tagMap.anomalyLabel[tag.anomalyLabel])semiSupervisedTagMap.value.tagMap.anomalyLabel[tag.anomalyLabel] = []
            semiSupervisedTagMap.value.tagMap.anomalyLabel[tag.anomalyLabel].push(tag.name.toString().toUpperCase())
          }
        }
      }
      Dataset.value.trainSemiSupervisedAction = "Step 1 of 2 - Uploading images, Please wait..."
      await rosepetalModel.dataset.createAws(curDataset.value, semiSupervisedTagMap.value.tagMap).then(async response =>  {
        if(response?.status && response?.status=="success"){
          Dataset.value.trainSemiSupervisedAction = response?.projectName
          let projectName = response?.projectName
          console.log(projectName)
          Dataset.value.trainSemiSupervisedAction = "Step 2 of 2 - Starting training, Please wait..."
          /*setTimeout( async () => { 
            Dataset.value.trainSemiSupervisedAction  = "When finished you will be able to see the evaluation of the new model from this same section."
            Dataset.value.trainSemiSupervisedSuccess = "The training has started successfully"
          }, 30000);   
          */
          setTimeout( async () => { 
            await rosepetalModel.aws.createModel(projectName).then(async createNewModel =>  {
              if(createNewModel?.status && createNewModel?.status=="success"){
                let modelName = 'model'
                let modelData = { aws: projectName, tagMap: JSON.stringify(semiSupervisedTagMap.value.tagMap) }
                if(createNewModel?.response?.ModelMetadata){
                  modelData.ModelArn     = createNewModel.response.ModelMetadata.ModelArn
                  modelData.ModelVersion = createNewModel.response.ModelMetadata.ModelVersion
                  if(modelData.ModelVersion)modelName += '-'+modelData.ModelVersion
                }
                await rosepetalModel.model.create(curDataset.value, modelName, modelData ).then(async response =>  {
                  if(response?.status && response?.status=="success"){
                    Dataset.value.trainSemiSupervisedAction  = "When finished you will be able to see the evaluation of the new model from this same section."
                    Dataset.value.trainSemiSupervisedSuccess = "The training has started successfully"
                  }else{
                    Dataset.value.trainSemiSupervisedAction = false
                    Dataset.value.trainSemiSupervisedError  = response?.error
                  }
                });
              }else{
                Dataset.value.trainSemiSupervisedAction = false
                Dataset.value.trainSemiSupervisedError  = createNewModel?.error
              }
            }); 
          }, 60000);   
        }else{
          Dataset.value.trainSemiSupervisedAction = false
          Dataset.value.trainSemiSupervisedError  = response?.error
        }
      });
    }

    const linkToBrowser = () => { 
      cash(".modal").modal("hide"); 
      Layout.value.modalImport = false; 
      router.push({ name: 'side-menu-data', params: { datasetID: curDataset.value } });  
    };

    onMounted( async () => {
      curModel.value   = false
      curDataset.value = props.dataset
      await getDataset();
      let _localModelsMenu  = await rosepetalModel.dataset.getModels(props.dataset) 
      localModelsMenu.value = _localModelsMenu.models
      await rosepetalModel.dataset.updateAutomlId(props.dataset) 
    });

    watch(() => curModel.value.packageJob, async () => { if(curModel.value.packageJob)curModel.value.packageJobData = await rosepetalModel.aws.getModelPackagingJob(curModel.value.projectName, curModel.value.packageJob)  });

    watch(() => curModel.value.id, async () => { 
      if(curModel.value.id){
        for (let ds in Dataset.value.localModels) { 
          if(curModel.value.id==Dataset.value.localModels[ds].id){
            Layout.value.loading       = true
            if(Dataset.value.localModels[ds].automl){
              curModel.value  = {}
              await rosepetalModel.model.getVertex(Dataset.value.localModels[ds].automl).then(async responseModel =>  {
                if(responseModel?.status=="success"){
                  curModel.value  = responseModel.data
                  let vertexDataset  = await rosepetalModel.dataset.getVertex(curDataset.value)
                  if(vertexDataset?.status=="success"){
                    curModel.value.vertex = vertexDataset.response[0]
                    if(curModel.value?.vertex?.dataItems &&curModel.value?.vertex?.dataItems[0]?.length){
                      curModel.value.vertex.dataDivision = { test: 0, training: 0, validation: 0 }
                      for(let i=0; i<curModel.value.vertex.dataItems[0].length; i++){ curModel.value.vertex.dataDivision[curModel.value.vertex.dataItems[0][i].labels["aiplatform.googleapis.com/ml_use"].toLowerCase()]++ }
                    }
                  }else{
                    curModel.value.errorLoad = ' 1- '+vertexDataset?.error
                  }  
                }else{
                  curModel.value.errorLoad = ' 2- '+responseModel?.error
                }
              })
              curModel.value.db                 = Dataset.value.localModels[ds]
              Layout.value.loading              = false  
              curModel.value.vertexEvaluation   = await rosepetalModel.model.renderEvaluations(curModel.value.db.id, { theme: "light", onlyMetrics: true, titles: false })
              clipboard.value                   = new ClipboardJS(".copyButton");
              
              /*console.log("evaluation",curModel.value.vertexEvaluation.evaluations[0])
              let chatUser                       =  "I have a dataset with these characteristics:"
              let datasetResume                  =  await rosepetalModel.dataset.generateResume(props.dataset)
              chatUser                          +=  datasetResume?.resume
              chatUser                          +=  "I have trained a new objects supervised model with vertex ai, marking the classification mask on each image using a bounding box or several depending on the image."
              chatUser                          +=  "For train this model I have used " + (parseInt(curModel.value.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount)+parseInt(curModel.value.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount) + parseInt(curModel.value.vertexEvaluation?.evaluations[0]?.evaluatedCount) ) + " total images, " 
              chatUser                          +=  curModel.value.vertexEvaluation?.evaluations[0]?.model.trainingDataItemsCount  +" for training, "
              chatUser                          +=  curModel.value.vertexEvaluation?.evaluations[0]?.model.validationDataItemsCount + " for validation and " 
              chatUser                          +=  curModel.value.vertexEvaluation?.evaluations[0]?.evaluatedCount +" for tests."
              chatUser                          +=  "These are the results of the vertex evaluation:" 
              chatUser                          +=  "training: budgetMilliNodeHours("+curModel.value.vertexEvaluation?.evaluations[0]?.model?.training.budgetMilliNodeHours+"), costMilliNodeHours ("+curModel.value.vertexEvaluation?.evaluations[0]?.model?.training.costMilliNodeHours+") and stopReason ("+curModel.value.vertexEvaluation?.evaluations[0]?.model?.training.stopReason+")"
              chatUser                          +=  ", type: "+ curModel.value.vertexEvaluation?.evaluations[0]?.model?.type
              chatUser                          +=  ", trainingAnnotationsCount: "+ curModel.value.vertexEvaluation?.evaluations[0]?.model?.trainingAnnotationsCount
              chatUser                          +=  ", trainingDataItemsCount: "+ curModel.value.vertexEvaluation?.evaluations[0]?.model?.trainingDataItemsCount
              chatUser                          +=  ", validationAnnotationsCount: "+ curModel.value.vertexEvaluation?.evaluations[0]?.model?.validationAnnotationsCount
              chatUser                          +=  ", validationDataItemsCount: "+ curModel.value.vertexEvaluation?.evaluations[0]?.model?.validationDataItemsCount
              chatUser                          +=  ", with " + Object.keys(curModel.value.vertexEvaluation?.evaluations[0]?.slices).length +" slices: "
              for(let ind in curModel.value.vertexEvaluation?.evaluations[0]?.slices){
                chatUser                      +=  ind +"("
                chatUser                      +=  "boundingBoxMeanAveragePrecision: "+ curModel.value.vertexEvaluation?.evaluations[0]?.slices[ind].boundingBoxMeanAveragePrecision
                chatUser                      +=  " and evaluatedBoundingBoxCount: "+ curModel.value.vertexEvaluation?.evaluations[0]?.slices[ind].evaluatedBoundingBoxCount + "), "
              }
              chatUser                          +=  "What is your evaluation?"
              let chatSystem = "You are chatting with the rosepetal assistant, you are going to be in charge of evaluating the models based on the information from the trained data set and the results obtained. It has to be a technical answer and as precise as possible for the objective of the evaluation, which is to improve the prediction model, to evaluate if another approach is required when training, if another type of training is recommended..."
              console.log('ChatUser',chatUser)
              console.log('ChatSystem',chatSystem)
              curModel.value.descriptionGPT      = await rosepetalModel.openai.chatWithGPT({ system: chatSystem, user: chatUser })*/
            }
            else if(Dataset.value.localModels[ds].aws){
              curModel.value                 = {}
              semiSupervisedEvaluationObjects.value = [] 
              Layout.value.loading           = true
              curModel.value                 = await rosepetalModel.aws.getDataset(Dataset.value.localModels[ds].aws)
              curModel.value.db              = Dataset.value.localModels[ds]
              Layout.value.loading           = false 
              curModel.value.awsModel        = await rosepetalModel.aws.getModel(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion) 
              curModel.value.packageJobs     = await rosepetalModel.aws.getModelPackagingJobs(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion) 
              curModel.value.awsEvaluation   = await rosepetalModel.aws.getEvaluation(Dataset.value.localModels[ds].aws, Dataset.value.localModels[ds].ModelVersion)
              //curModel.value.awsModel.response.ModelDescription.Status = 'TRAINING'
            }
            else{
              curModel.value = { db: Dataset.value.localModels[ds] }
              Layout.value.loading = false  
            }
            break
          }
        }
      }
     });

    watch(() => props.dataset, async () => {  curDataset.value = props.dataset; await getDataset(); });

    watch(() => trainVertex.value.method, async () => {  
      Dataset.value.validateToTrain = false; 
      let validateOpt = { noDivision: trainVertex.value.method=='auto' ? true : false }
      if(trainVertex.value.tagMap)validateOpt.tagMap = trainVertex.value.tagMap
      Dataset.value.validateToTrain = await rosepetalModel.dataset.validateToTrain(curDataset.value,validateOpt) 
    });

    const autoDivision = () => { if(trainVertex.value)trainVertex.value.autoDivisionAlert = trainVertex.value.test+trainVertex.value.train+trainVertex.value.validation!=100 ? true : false };

    watch(() => trainVertex.value.train, async () => { autoDivision() });
    watch(() => trainVertex.value.test, async () => { autoDivision() });
    watch(() => trainVertex.value.validation, async () => { autoDivision() });

    return {
      Layout,
      modal,
      modalClose,
      modalRemove,
      curModel,
      Train,
      exportModelToBucket,
      Dataset,
      modelBucket,
      getDatasetModels,
      deleteModel,
      linkToBrowser,
      modalTrainSupervised,
      trainSupervised,
      modalTrainSemiSupervised,
      trainSemiSupervised,
      localModelsMenu,
      DatasetTagsObj,
      supervisedTagMap,
      semiSupervisedTagMap,
      semiSupervisedEvaluationObjects,
      semiSupervisedTagMapUseMask,
      clipboard,
      trainVertex
    };
  },
  methods:{
    refreshBtn: async function() { this.modalRemove("model-semi-supervised-box"); this.modalRemove("model-supervised-box"); await this.$parent.manageDataset(this.Dataset.data, 'models'); },
    manageTags: async function() { this.modalRemove("model-semi-supervised-box"); this.modalRemove("model-supervised-box"); await this.$parent.manageDataset(this.Dataset.data, 'labels'); },
    refreshTagMap: async function() { //evt
      this.semiSupervisedTagMap.resume.division = { normal: { test: 0, validation:0, train: 0 }, anomaly: { test: 0, validation:0, train: 0 }, resume: { test: 0, train: 0 } }
      this.semiSupervisedTagMap.resume.normal   = 0
      if(this.semiSupervisedTagMap.normal.length){
        this.semiSupervisedTagMap.normal.forEach((tag) => {
          this.semiSupervisedTagMap.resume.normal += tag.counter
          this.semiSupervisedTagMap.resume.division.normal.test       += tag.division.test
          this.semiSupervisedTagMap.resume.division.normal.validation += tag.division.validation
          this.semiSupervisedTagMap.resume.division.normal.train      += tag.division.train
          this.semiSupervisedTagMap.resume.division.resume.test       += ( tag.division.test + tag.division.validation )
          this.semiSupervisedTagMap.resume.division.resume.train      += tag.division.train
        })
      }

      this.semiSupervisedTagMap.resume.anomaly        = 0
      this.semiSupervisedTagMap.resume.anomalyMask    = 0
      this.semiSupervisedTagMap.resume.anomalyLabels  = {}
      if(this.semiSupervisedTagMap.anomaly.length){
        this.semiSupervisedTagMap.anomaly.forEach((tag) => {
          this.semiSupervisedTagMap.resume.anomaly += tag.counter
          this.semiSupervisedTagMap.resume.division.anomaly.test       += tag.division.test
          this.semiSupervisedTagMap.resume.division.anomaly.validation += tag.division.validation
          this.semiSupervisedTagMap.resume.division.anomaly.train      += tag.division.train
          this.semiSupervisedTagMap.resume.division.resume.test        += ( tag.division.test + tag.division.validation )
          this.semiSupervisedTagMap.resume.division.resume.train       += tag.division.train
          if(tag.mask)this.semiSupervisedTagMap.resume.anomalyMask     += tag.mask
          if(tag?.anomalyLabel){
            if(this.semiSupervisedTagMap.resume.anomalyLabels[tag?.anomalyLabel]){ 
              this.semiSupervisedTagMap.resume.anomalyLabels[tag?.anomalyLabel].counter += tag.counter
              this.semiSupervisedTagMap.resume.anomalyLabels[tag?.anomalyLabel].mask    += tag.mask ? tag.mask : 0
            }else{  this.semiSupervisedTagMap.resume.anomalyLabels[tag?.anomalyLabel] = { counter: tag.counter, mask: tag.mask ? tag.mask : 0 } } 
            
          }
          //console.log(tag)
        })
      }

      this.semiSupervisedTagMap.resume.total   = this.semiSupervisedTagMap.resume.normal + this.semiSupervisedTagMap.resume.anomaly
      this.semiSupervisedTagMap.resume.errors  = []
      if(this.semiSupervisedTagMap.normal.length || this.semiSupervisedTagMap.anomaly.length){
        if(this.semiSupervisedTagMap.normal.length + this.semiSupervisedTagMap.anomaly.length < 2)this.semiSupervisedTagMap.resume.errors.push("You must select at least 2 classes")
        
        if(!this.semiSupervisedTagMap.normal.length){
          this.semiSupervisedTagMap.resume.errors.push("You must select at least 1 normal label")
        }else{
           if(this.semiSupervisedTagMap.resume.normal< 20){
            this.semiSupervisedTagMap.resume.errors.push("You must select at least 20 normal images")
          }else{
            if((this.semiSupervisedTagMap.resume.division.normal.test + this.semiSupervisedTagMap.resume.division.normal.validation) < 10)
            this.semiSupervisedTagMap.resume.errors.push("You must have at least 10 normal images counting the test and validation images. Now have only "+ (this.semiSupervisedTagMap.resume.division.normal.test + this.semiSupervisedTagMap.resume.division.normal.validation)+".")
          }
        }

        if(!this.semiSupervisedTagMap.anomaly.length){
          this.semiSupervisedTagMap.resume.errors.push("You must select at least 1 anomaly label")
        }else{
          if(this.semiSupervisedTagMap.resume.anomaly< 20){
            this.semiSupervisedTagMap.resume.errors.push("You must select at least 20 anomaly images")
          }else{
            if((this.semiSupervisedTagMap.resume.division.anomaly.test + this.semiSupervisedTagMap.resume.division.anomaly.validation) < 10){
              this.semiSupervisedTagMap.resume.errors.push("You must have at least 10 anomaly images counting the test and validation images. Now have only "+ (this.semiSupervisedTagMap.resume.division.anomaly.test + this.semiSupervisedTagMap.resume.division.anomaly.validation)+".")
            }else{
              if(this.semiSupervisedTagMapUseMask){
                if(Object.keys(this.semiSupervisedTagMap.resume.anomalyLabels).length){
                  for(let key in this.semiSupervisedTagMap.resume.anomalyLabels){
                    if(this.semiSupervisedTagMap.resume.anomalyLabels[key].counter < 20)this.semiSupervisedTagMap.resume.errors.push("Select at least 20 images by anomaly label "+key)
                    if(this.semiSupervisedTagMap.resume.anomalyLabels[key].mask < this.semiSupervisedTagMap.resume.anomalyLabels[key].counter){
                      let _e = "All images linked to an anomaly label must have a mask. "
                      _e += "Anomaly label: "+key+" have "+this.semiSupervisedTagMap.resume.anomalyLabels[key].mask+"/"+this.semiSupervisedTagMap.resume.anomalyLabels[key].counter+" images with annotated mask."
                      _e += " Use data labeling tool to add masks."
                      this.semiSupervisedTagMap.resume.errors.push(_e)
                    }
                  }
                }
              }
            }  
          }
        }
      }
      if(this.semiSupervisedTagMap.resume.total)$h.dragElement(document.getElementById("trainingSummayBox"));
    },
    refreshTagMapSupervised: async function() {
      this.trainVertex.tagMap = {  total: 0, resume: {division: { test: 0, validation: 0, train: 0 }}, tags: {}, errors: [] }
      var getObjDivTagMap = { tags: {} }
      if(this.supervisedTagMap?.selected && Object.keys(this.supervisedTagMap.selected).length){
        this.supervisedTagMap.selected.forEach( async (tag) => {
          if(this.Dataset.data.type=='MULTICLASS')this.trainVertex.tagMap.total += tag.counter
          this.trainVertex.tagMap.resume.division.test       += tag.division.test
          this.trainVertex.tagMap.resume.division.validation += tag.division.validation
          this.trainVertex.tagMap.resume.division.train      += tag.division.train
          let _tagName                                        = tag.vertexLabel ? tag.vertexLabel : tag.id

          if(!this.trainVertex.tagMap.tags[_tagName])this.trainVertex.tagMap.tags[_tagName] = { total: 0, tags: { count: 0 } } 
          this.trainVertex.tagMap.tags[_tagName]["tags"][tag.id] = {  }
          if(this.Dataset.data.type=='MULTICLASS')this.trainVertex.tagMap.tags[_tagName]["tags"][tag.id]["count"]           = tag.counter
          
          this.trainVertex.tagMap.tags[_tagName]["tags"]["count"]++
          if(this.Dataset.data.type=='MULTICLASS')this.trainVertex.tagMap.tags[_tagName].total += tag.counter

          if(this.Dataset.data.type=='imageObjectDetection'){
            let _objMap = { total: 0, tags: { [tag.id]: { count: 0 } } }
            getObjDivTagMap.tags[tag.id] = _objMap
            let _objDiv = await this.rosepetalModel.dataset.getDataDivision(this.Dataset.data.id, true, false, { tags: { [tag.id]: _objMap }})
            this.trainVertex.tagMap.tags[_tagName]["tags"][tag.id]["count"]   = _objDiv.total
            this.trainVertex.tagMap.tags[_tagName].total                      += _objDiv.total
            this.trainVertex.tagMap.tags[_tagName]["tags"][tag.id]["division"] = { total: _objDiv.total, test: _objDiv.test, validation: _objDiv.validation, train: _objDiv.train, predetermined: _objDiv.predetermined }
          }
        })
        if(this.Dataset.data.type=='imageObjectDetection'){
          var objectsDivision                      = await this.rosepetalModel.dataset.getDataDivision(this.Dataset.data.id, true, false, getObjDivTagMap)
          this.trainVertex.tagMap.total            = objectsDivision.total
          this.trainVertex.tagMap.resume.division  = { test: objectsDivision.test, validation: objectsDivision.validation, train: objectsDivision.train }
        }
      }
      this.Dataset.vertexDivision  = await this.rosepetalModel.dataset.getDataDivision(this.Dataset.data.id, true, false, this.trainVertex.tagMap)
      let validationOpt = { noDivision: this.trainVertex.method=='auto' ? true : false, tagMap: this.trainVertex.tagMap}
      if(this.Dataset.data.type=='imageObjectDetection')validationOpt.labeledImages = true
      this.Dataset.validateToTrain = await this.rosepetalModel.dataset.validateToTrain(this.Dataset.data.id, validationOpt)
    },
    loadEvaluationObjects: async function(objs) { 

      this.curModel.awsEvaluation.loading           = true
      if(!this.curModel.awsEvaluation.sizeObject)this.curModel.awsEvaluation.sizeObject = 50
      this.semiSupervisedEvaluationObjects          = []
      this.curModel.awsEvaluation.order             = this.curModel.awsEvaluation.order ? this.curModel.awsEvaluation.order : "asc"
      this.curModel.awsEvaluation.filterPrediction  = this.curModel.awsEvaluation.filterPrediction ? this.curModel.awsEvaluation.filterPrediction : "all"
      
      if(!this.curModel.awsEvaluation.oriAwsTestObjects){ //get aws Objects
        this.curModel.awsEvaluation.oriAwsTestObjects = []
        for(let i=0; i<objs.length; i++){ if(objs[i].key)this.curModel.awsEvaluation.oriAwsTestObjects.push({ imageSrc: await rosepetalModel.aws.getObject(objs[i].key, true), obj: objs[i]  }) }
      }
      
      let modelTestObj = this.curModel.awsEvaluation.oriAwsTestObjects
      
      if(modelTestObj.length){

        //filter prediction correct/incorrect
        let filterObjects = []
        for(let i=0; i<modelTestObj.length; i++){
          let addImage = true
          let awsObj   = modelTestObj[i]
          if(awsObj?.obj && this.curModel.awsEvaluation.filterPrediction!="all"){ //
            if(this.curModel.awsEvaluation.filterPrediction=="correct"   && awsObj?.obj['anomaly-label']!=awsObj?.obj['anomaly-label-detected'])addImage = false
            if(this.curModel.awsEvaluation.filterPrediction=="incorrect" && awsObj?.obj['anomaly-label']==awsObj?.obj['anomaly-label-detected']){ addImage = false; filterObjects.push({ empty: true}) }
          }
          if(addImage)filterObjects.push(awsObj)
        } 
        modelTestObj = filterObjects

        //order
        if(!modelTestObj[0]['empty']){
          if(this.curModel.awsEvaluation.order=="asc")modelTestObj.sort((a, b) => a.obj['anomaly-label-detected-metadata']['confidence'] - b.obj['anomaly-label-detected-metadata']['confidence']);
          else modelTestObj.sort((a, b) => b.obj['anomaly-label-detected-metadata']['confidence'] - a.obj['anomaly-label-detected-metadata']['confidence']);
        }
      }

      this.semiSupervisedEvaluationObjects = modelTestObj
      
      this.curModel.awsEvaluation.loading = false
    },
    copyToClipboard: async function() { 
      this.clipboard.on('success', () => { $h.NotificationTxt({ text: "Storage copied to clipboard ", position: "center" });  });
      this.clipboard.on('error', () => {   $h.NotificationTxt({ text: "Storage could not copy text", position: "center" });  });
    },
  }
});
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>
.selConfidence{ padding: 10px !important; background-color: #fff8ec !important;}
.overflow-hidden{ overflow: hidden !important; }
.disabledButton {
    cursor: not-allowed !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
#model-supervised-box, #model-semi-supervised-box{ background-color: #f2f3f3 !important;}
#mdodel-supervised-box .modal-dialog, #model-semi-supervised-box .modal-dialog{ min-width: 900px !important; }
#model-supervised-box .nav.nav-tabs .active,  #model-semi-supervised-box .nav.nav-tabs .active{
  --tw-border-opacity: 1;
  background-color: #1a73e8;
  border-color: #1a73e8;
  font-weight: 500;
  color: #fff;
}
#trainingSummayBox{ width: 350px; position: fixed;top: 5px;left: calc(50% - 490px);background-color: #fff;border: 5px solid #ccc;border-radius: 20px;}
</style>
