import { _firebase as $_firebase } from "@/model/firebase";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import { model as $model } from "@/model/model";
//import { getDocs } from "firebase/firestore";  
import config from '../etc/rosepetal.json';

//const functions = $_firebase.firebase().app().functions(); //local

//const functions = $_firebase.firebase().app().functions(config.firebaseRegion);

const functions = $_firebase.functions();


const datasets = {
    async get(datasetID = false, tags = false, automl = false) {
       
        if (datasetID) {
            let dataset = $_firebase.firestore().collection('dataset').doc(datasetID)
            let item = {}
            await dataset.get().then(async snapshot => {
                item = snapshot.data()
                item.id = snapshot.id;
            });
            //if (item.automl)item.annotationSet = await this.getAnnotationSetList(item.automl)
            return item;
        } else {
            let datalist = {}
            let dsets = $_firebase.firestore().collection('dataset').orderBy('createdAt', 'desc')
            await dsets.get().then(async snapshot => {
                await snapshot.forEach(async doc => {
                    let item = doc.data()
                    item.id = doc.id;
                    if (!item.deleted) datalist[doc.id] = item
                });
            });
            if (tags) {
                for (let index in datalist) {
                    datalist[index].tags = await this.getTags(index)
                    //if (datalist[index].automl)datalist[index].annotationSet = await this.getAnnotationSetList(datalist[index].automl)
                    datalist[index].imageCounter = await this.getTagsCounter(datalist[index].tags)
                }
            }
            if (automl) {
                for (let index in datalist) {
                    datalist[index].automldata = await this.getVertexDataset(datalist[index])
                }
            }
            return datalist
        }
    },
    async addDataset(displayName, type) {
        let NewDataset = {}
        let dataset = []
        let data = { name: displayName, type: type, automl: "", createdAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp(), description: "" }
        await $_firebase.firestore().collection("dataset").doc(displayName).set(data)
            .then(async function (docRef) {
                if (docRef) NewDataset.name = docRef.id;
                const action = functions.httpsCallable('api/model/create/dataset/' + displayName + '-' + type);
                await action({}).then(response => { dataset = response }).catch(async (error) => { console.log(error) });
            })
            .catch(function (error) { console.error("Error adding dataset: ", error); });
        return dataset;
    },
    async update(datasetID, data, msg = false) {
        if (datasetID) await $_firebase.firestore().collection("dataset").doc(datasetID).update(data).then(function () { if (msg) $h.Notification(msg) });
    },
    async getCSV(datasetID, set = false) {
        let csv = []
        let call = 'api/dataset/' + datasetID + '/csv'
        if (set.method && set.method == "auto") call = call + '?test=' + set.test + "&validation=" + set.validation;  //+ "&train=" + set.train
        const action = await functions.httpsCallable(call);
        await action({}).then(async response => {
            csv = response
            if (response.data && response.data.training && response.data.training.dataset)
                await $_firebase.firestore().collection("dataset").doc(datasetID.toString()).update({ importCsv: response.data.training.dataset });
        }).catch(async (error) => { console.log(error) });
        return csv
    },
    async importDatasetCsv(CsvPath, datasetID, type) {
        let importcsv = []
        let ApiCall = 'api/model/import/dataset/' + datasetID
        ApiCall += '-|-' + CsvPath.replace(/\//g, "!!-")
        ApiCall += '-|-' + type.replace(/\//g, "!!-")
        console.log("importDatasetCsv",ApiCall)
        const action = functions.httpsCallable(ApiCall)
        await action({}).then(response => { if (response && response.data) importcsv = response.data }).catch(async (error) => { console.log(error) });
        return importcsv;
    },
    async getVertexDataset(dataset = false, updateLocal = false, dataDivison = false) {
        let localModel = dataset.id ? await this.get(dataset.id) : false
        if (localModel && dataset.automldata && dataset.automldata.etag && !updateLocal && !dataDivison) { 
            return localModel.automldata  
        } else {
            if (dataset.automl) {
                let datasets = []
                if (updateLocal) {
                    console.log('consultando la api para total dataset')
                    const action = await functions.httpsCallable('api/model/datasets/dataset_id/' + dataset.automl);
                    await action({}).then(response => { if (response && response.data) datasets = response.data }).catch(async (error) => { console.log(error) });
                    if (datasets.length) {
                        let vertexDatasets = datasets
                        console.log('Update local datasets data from Vertex:')
                        for (let i = 0; i < vertexDatasets.length; i++) {
                            if (vertexDatasets[i] && vertexDatasets[i].id) {
                                if (vertexDatasets[i].dataItems) delete vertexDatasets[i].dataItems //no save vertex images
                                await $_firebase.firestore().collection("dataset").where("automl", "==", vertexDatasets[i].id).get()
                                    .then(function (querySnapshot) {
                                        querySnapshot.forEach(function (doc) {
                                            console.log("- " + doc.id)
                                            doc.ref.update({ automldata: vertexDatasets[i] })
                                        });
                                    })
                            }
                        }
                    }
                }else{
                    datasets[0] =  localModel?.automldata ? localModel.automldata : false
                }

                /*
                if (dataDivison && datasets.length && dataset.automl) {
                    console.log('bydataDivison!')
                    if (datasets[0].dataItems) {
                        datasets[0].divisionData = { total: 0, training: 0, validation: 0, test: 0, predetermined: 0, required: {} }
                        datasets[0].dataItemsAnnotations = { noannotation: 0, labels: [] }
                        for (let dx in datasets[0].dataItems) {
                            if (datasets[0].dataItems[dx] && datasets[0].dataItems[dx].length) {
                                for (let i = 0; i < datasets[0].dataItems[dx].length; i++) {
                                    console.log('Analizando imagen ' + datasets[0].divisionData.total + "  " + datasets[0].dataItems[dx][i].name)
                                    //data division
                                    if (datasets[0].dataItems[dx][i].labels && datasets[0].dataItems[dx][i].labels['aiplatform.googleapis.com/ml_use']) {
                                        switch (datasets[0].dataItems[dx][i].labels['aiplatform.googleapis.com/ml_use']) {
                                            case 'test': datasets[0].divisionData.test++; break;
                                            case 'validation': datasets[0].divisionData.validation++; break;
                                            case 'training': datasets[0].divisionData.training++; break;
                                            default: datasets[0].divisionData.predetermined++; break;
                                        }
                                    } else { datasets[0].divisionData.predetermined++; }
                                    datasets[0].divisionData.total++;
                                }
                            }
                        }
                        if (datasets[0].divisionData.total) {
                            datasets[0].divisionData.required["validate"] = true
                            datasets[0].divisionData.required["training"] = ((datasets[0].divisionData.training * 100) / datasets[0].divisionData.total).toFixed(2)
                            datasets[0].divisionData.required["validation"] = ((datasets[0].divisionData.validation * 100) / datasets[0].divisionData.total).toFixed(2)
                            datasets[0].divisionData.required["test"] = ((datasets[0].divisionData.test * 100) / datasets[0].divisionData.total).toFixed(2)
                            if ((!datasets[0].divisionData.required["training"] || datasets[0].divisionData.required["training"] < 80)) datasets[0].divisionData.required["validate"] = false
                            if ((!datasets[0].divisionData.required["validation"] || datasets[0].divisionData.required["validation"] < 10)) datasets[0].divisionData.required["validate"] = false
                            if ((!datasets[0].divisionData.required["test"] || datasets[0].divisionData.required["training"] < 10)) datasets[0].divisionData.required["validate"] = false
                        }
                    }
                }
                */
                if (dataset.automl && datasets[0]) return datasets[0];
                return datasets
            }
        }
    },
    async getVertexModel(modelID = false) {
        let model = []
        if (modelID) {
            let call = 'api/model/get/model_id/' + modelID.toString()
            const action = await functions.httpsCallable(call);
            await action({}).then(response => { if (response.data) model = response.data }).catch((error) => { console.log(error) });
        }
        return model
    },
    async getVertexDatasetDataItems(datasetID) {
        let dataItems = []
        if (datasetID) {
            let call = 'api/model/dataitems/dataset_id/' + datasetID.toString()
            const action = await functions.httpsCallable(call);
            await action({}).then(response => { if (response.data) dataItems = response.data }).catch((error) => { console.log(error) });
        }
        return dataItems
    },
    async getVertexAnnotation(dataiItem, dataItemsAnnotations = false) {
        console.log('- Analizando sus etiquetas')
        let annotations = []
        let apicall = 'api/model/annotations/dataitem/' + dataiItem.replace(/\//g, "_")
        const action = await functions.httpsCallable(apicall);
        await action({}).then(response => { if (response && response.data) annotations = response.data }).catch(async (error) => { console.log(error) });
        if (dataItemsAnnotations) {
            if (annotations.length) {
                for (let a = 0; a < annotations.length; a++) {
                    if (annotations[a].displayName.stringValue) {
                        let label = annotations[a].displayName.stringValue
                        console.log('-- Etiqueta ' + label)
                        let exists = false
                        dataItemsAnnotations.findIndex(function (lbl, index) {
                            if (lbl.name == label) { exists = true; dataItemsAnnotations[index].count++ }
                        });
                        if (!exists) dataItemsAnnotations.push({ name: label, count: 1 })
                    }
                }
            } //else { dataItemsAnnotations.noannotation++; }
            return dataItemsAnnotations
        }
        return annotations
    },
    async ImportReintent(datasetID) {
        let r = []
        //const action = await functions.httpsCallable('api/dataset/'+datasetID+'/reintent');

        const functionsUsApi = $_firebase.firebase().app().functions(config.functions.usapi)
        
        const action = await functionsUsApi.httpsCallable('dataset/' + datasetID + '/reintent');

        await action({}).then(response => { if (response) r = response }).catch(async (error) => { console.log(error) });
        
        return r
    },
    async ImportDiscard(datasetID) {
        await $_firebase.firestore().collection("dataset").doc(datasetID.toString()).set({ uploadStatus: "" }, { merge: true });
    },
    async CancelUploadZip(datasetID) {
        await $_firebase.firestore().collection("dataset").doc(datasetID.toString()).set({ uploadStatus: "error", uploadStatusMsg: "Upload canceled by user" }, { merge: true });
    },
    async getAnnotationSetList(datasetID) {
        let annotationSet = []
        let apicall = 'api/model/annotationset/dataset_id/' + datasetID
        const action = await functions.httpsCallable(apicall);
        await action({}).then(response => { if (response && response.data) annotationSet = response.data  }).catch(async (error) => { console.log(error) });
        return annotationSet;
    },
    async getTags(datasetID, unclassified = true) {
        let tags        = {}
        let dataset     = $_firebase.firestore().collection('dataset').doc(datasetID.toString()).collection('tag')
        await dataset.get().then(snapshot => {
            snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if (!item.color) {
                    item.color = $h.StringtoHex(doc.id);
                    await this.updTag({ id: doc.id, dataset: datasetID.toString(), data: { color: item.color }})
                }
                if (unclassified) { tags[doc.id] = item; } else { if (!item.unclassified) tags[doc.id] = item; }
            });
        });

        return tags;
    },
    async getTotalTagsCounter(tags) {
        let tagsCounter = 0
        if (Object.keys(tags).length) {
            for (const dstag of Object.values(tags)) {
                if (dstag.imageCounter) tagsCounter += dstag.imageCounter
            }
        }
        return tagsCounter;
    },
    async addTag(Tag) {
        let NewTagID = false
        Tag.data["createdAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
        if(useStore().state.main.User.uid)Tag.data["uid"] = useStore().state.main.User.uid
        await $_firebase.firestore().collection("dataset").doc(Tag.dataset.toString()).collection('tag').doc(Tag.id).set(Tag.data)
            .then(function (docRef) { if (docRef) NewTagID = docRef.id; })
            .catch(function (error) { console.error("Error adding tag: ", error); });
        return NewTagID
    },
    async updTag(Tag) {
        Tag.data["updatedAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("dataset").doc(Tag.dataset.toString()).collection('tag').doc(Tag.id).update(Tag.data);
    },
    async delTag(datasetID, TagID) {
        if (TagID) { await $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection('tag').doc(TagID).delete(); }
    },
    async getTagsCounter(tags) {
        let countImages = 0;
        for (let key in tags) { if (tags[key].imageCounter) countImages += tags[key].imageCounter }
        return countImages
    },

    async getTagsObjectDetection(datasetID) {
        let images = $_firebase.firestore().collection('image')
        let tagRef = await $_firebase.firestore().collection("dataset").doc(datasetID.toString())
        images = images.where('dataset', '==', tagRef)
        let snap = await images.get()
        let tagsCount = { total: 0, labeled: 0, nolabel: 0, tagslabeled: {}, colors: [] }
        snap.forEach(async (doc) => {
            let p = doc.data()
            p.id = doc.id
            if (p.tags && p.tags.length) {
                let tagged = false
                for (let key in p.tags) {
                    if (p.tags[key].tag) {
                        let tRef = p.tags[key].tag.path.toString().split('/')
                        let tagName = false
                        if (tRef[tRef.length - 1]) tagName = tRef[tRef.length - 1].replace('"', '');
                        if (tagName) {
                            tagged = true
                            if (!tagsCount.tagslabeled[tagName]) {
                                tagsCount.tagslabeled[tagName] = 1
                                tagsCount.colors.push($h.StringtoHex(tagName))
                            } else { tagsCount.tagslabeled[tagName]++; }
                        }
                    }
                }
                if (tagged) { tagsCount.labeled++; } else { tagsCount.nolabel++; }
            } else {
                tagsCount.nolabel++;
            }
            tagsCount.total++;
        });

        //required 10 images by tag
        if (tagsCount.tagslabeled) {
            tagsCount.tagsrequired = true
            for (let t in tagsCount.tagslabeled) {
                if (tagsCount.tagslabeled[t] && tagsCount.tagslabeled[t] < 10) tagsCount.tagsrequired = false
            }
        }

        return tagsCount
    },
    async updTagCounter(datasetID, TagID) {
        await $_firebase.firestore().collection("dataset").doc(datasetID.toString()).collection('tag').doc(TagID).update(
            { imageCounter: $_firebase.firebase().firestore.FieldValue.increment(-1) }
        );
    },

    async getSetCounter(datasetID, onlyLabeled = false, type = false) {
        let countSetImages = { total: 0, train: 0, test: 0, validation: 0, predetermined: 0, required: true }
        let images = $_firebase.firestore().collection('image')
        let tagRef = await $_firebase.firestore().collection("dataset").doc(datasetID.toString())
        images = images.where('dataset', '==', tagRef)
        let snap = await images.get()
        snap.forEach(async (doc) => {
            let p = doc.data()
            let check = true;
            if (type == 'imageObjectDetection' && onlyLabeled && (!p.tags || !p.tags.length)) check = false
            if ((type == 'MULTICLASS' || type == 'MULTILABEL')) {
                let tagStr = p.tag.path.toString().split('/').pop()
                if ((type == 'MULTICLASS' || type == 'MULTILABEL') && onlyLabeled && (!p.tag || tagStr == "0")) check = false
            }
            if (check) {
                if (p.set) {
                    countSetImages[p.set.toLowerCase()]++;
                } else { countSetImages.predetermined++; }
                countSetImages.total++;
            }
        });
        if ((((countSetImages.train * 100) / countSetImages.total).toFixed(2)) < 10) countSetImages.required = false
        if ((((countSetImages.test * 100) / countSetImages.total).toFixed(2)) < 1) countSetImages.required = false
        if ((((countSetImages.validation * 100) / countSetImages.total).toFixed(2)) < 1) countSetImages.required = false
        return countSetImages
    },


    async getImages(opt) {

        let media = { media: [], pagination: {} }
        let images = $_firebase.firestore().collection('image')
        //let imagesTags = false

        //console.log("getImages",opt)
        if (opt.datasetID) {
            let tagRef = await $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString())
            if (opt.tagID && opt.tagID != 'all') tagRef = await tagRef.collection('tag').doc(opt.tagID.toString())
            images = images.where('tag', '==', tagRef)
        }

        if (opt.objDivision && opt.objDivision != 'all') {
            let qrySet = opt.objDivision.toString().toUpperCase()
            if (qrySet == "PREDETERMINED") qrySet = ""
            images = images.where('set', '==', qrySet)
        }


        if (opt.objtagsType) {
            if (opt.objtagsType == 'labeled') {
                if (opt.objByLabel && opt.objByLabel != 'all') {
                    let stagRef = await $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString()).collection("tag").doc(opt.objByLabel.toString())
                    images = images.where('tagsContained', 'array-contains', stagRef).orderBy("tags").orderBy("date", "desc") 
                } else {
                    images = images.where('tags', '!=', []).orderBy("tags").orderBy("date", "desc") //.orderBy("tags") //.orderBy("tags", 'desc').orderBy("date", "desc")
                }
            }

            if (opt.objtagsType == 'nolabel')
                images = images.where('tags', '==', []).orderBy("date", "desc")
        } else {
            if (opt.qry && opt.qry.order && opt.qry.type) images = images.orderBy(opt.qry.order, opt.qry.type)
        }

        if (opt.action && opt.action == "init" && opt.pagination.init) {
            images = images.startAt(opt.pagination.init)
        } else if (opt.action && opt.action == "next" && opt.pagination.last) { images = images.startAfter(opt.pagination.last) }

        if (opt.action && opt.action == "prev" && opt.pagination.first) {
            images = images.endBefore(opt.pagination.first)
            if (opt.pagination.perPage) images = images.limitToLast(opt.pagination.perPage);
        } else if (opt.action && opt.action == "end" && opt.pagination.end) {
            images = images.endBefore(opt.pagination.end)
            if (opt.pagination.perPage) images = images.limitToLast((opt.pagination.perPage - 1));
        } else {
            if (opt.pagination && opt.pagination.perPage) images = images.limit(opt.pagination.perPage)
        }

        let snap = await images.get()

       
        snap.forEach(async (doc) => {
            let p = doc.data()
            //console.log('result image ->',p)
            p.id = doc.id
            p.tag = p.tag.path
            p.tagName = p.tag.toString().split('/')
            p.fileName = p.name.toString().split('/')
            p.img_base64_val = p.imageData && p.imageData._delegate._byteString.binaryString ? btoa(p.imageData._delegate._byteString.binaryString) : null
            let addMedia = true
            /*filter by tag
            if (opt.objByLabel && opt.objByLabel != 'all') {
                if ((opt.objtagsType && opt.objtagsType == 'labeled') && p.tags.length) {
                    addMedia = false
                    for (let i = 0; i < p.tags.length; i++) {
                        let tRef = p.tags[i].tag.path.toString().split('/')
                        if (tRef[3] && tRef[3] == opt.objByLabel) addMedia = true // console.log("objByLabel", tRef[3])
                    }
                }
            }*/
            if (addMedia)media.media.push(p)
        });


        /*PAGINATION*/
        if (opt.pagination) {
            opt.pagination.total = !opt.tagID && opt.curDataset ? opt.curDataset.imageCounter : opt.curDataset && opt.curDataset.tags && opt.curDataset.tags[opt.tagID] ? opt.curDataset.tags[opt.tagID].imageCounter : 0;
            if (opt.tagID == 'all') opt.pagination.total = opt.curDataset ? opt.curDataset.imageCounter : 0
            if (opt.objtagsTypeCount) opt.pagination.total = opt.objtagsTypeCount
            if (!opt.pagination.init) opt.pagination.init = snap.docs[0];
            opt.pagination.first = snap.docs[0];
            opt.pagination.last = snap.docs[snap.docs.length - 1];
            if (opt.action == 'init') {
                opt.pagination.currentPage = 0;
            } else if (opt.action == 'prev') {
                opt.pagination.prev = opt.pagination.next = false;
                opt.pagination.currentPage--;
            } else if (opt.action == 'next') {
                opt.pagination.prev = opt.pagination.next = false;
                opt.pagination.currentPage++;
            } else if (opt.action == 'end') {
                opt.pagination.currentPage = opt.pagination.pages - 1;
            }
            media.pagination = opt.pagination
        }
        /* END PAGINATION*/
        return media
    },
    async getOperation(operationName) {
        let status = {}
        let call = 'api/model/operation/status/' + operationName.replace(/\//g, "--")
        const action = await functions.httpsCallable(call);
        await action({}).then(response => { if (response) status = response }).catch((error) => { console.log(error) });
        return status
    },
    async getTrainingPipeline(pipelineName) {
        let status = {}
        let pipName = pipelineName.name ? pipelineName.name : pipelineName;
        let call = 'api/model/trainingpipeline/status/' + pipName.replace(/\//g, "--")
        const action = await functions.httpsCallable(call);
        await action({}).then(response => { if (response) status = response }).catch((error) => { console.log(error) });
        return status
    },
    getTypeName(o) {
        if (o.toUpperCase() == 'MULTICLASS') return 'Classification with a single label';
        if (o.toUpperCase() == 'MULTILABEL') return 'Classification with multiple labels';
        if (o.toUpperCase() == 'IMAGEOBJECTDETECTION') return 'Vision Object Detection';
    },
    async trainingDataset(trainingData) {
        let modelData = {
            automl: '',
            dataset: await $_firebase.firestore().collection("dataset").doc(trainingData.datasetName.toString()),
            description: "",
            savedModel: "",
            status: "undeployed",
            trainBudget: trainingData.trainBudget,
            annotationSetId: trainingData.annotationSetId,
            createdAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp(),
        }
        await $_firebase.firestore().collection("model").doc(trainingData.modelName).set(modelData)
            .then(async function (docRef) {
                if(docRef)console.log(docRef)
                let apiCall = 'api/model/create/model/' + trainingData.datasetID + '-' + trainingData.modelName + '-' + trainingData.trainBudget + '-' + trainingData.type
                if (trainingData.annotationSetId) apiCall += '-' + trainingData.annotationSetId
                //if (trainingData.baseModelId) apiCall += '-' + trainingData.baseModelId
                const action = functions.httpsCallable(apiCall);
                await action({}).then(response => { if (response) { console.log('response create model', response); modelData.operationID = response.data } }).catch(async (error) => { console.log(error) });
            })
            .catch(function (error) { console.error("Error create model: ", error); });
        return modelData;
    },
    async getModels(DatasetDisplayName, checkDbName = false) {
        let models = await $model.get();
        let DatasetModels = [];
        for (let i = 0; i < models.length; i++) {
            if (models[i].dataset && models[i].dataset.displayName == DatasetDisplayName) {
                if (checkDbName) {
                    models[i].db = await this.getDbModel(models[i].displayName)
                    let modelName = models[i].name.toString().split('/')
                    if (modelName[modelName.length - 1]) {
                        if (models[i].db && !models[i].db.automl) await this.modelUpdate(models[i].displayName, { automl: modelName[modelName.length - 1] })
                    }
                }
                DatasetModels.push(models[i])
            }
        }
        return DatasetModels
    },
    async exportModel(modelID) {
        let res = []
        let ApiCall = 'api/model/export/model_id/' + modelID
        if (config.modelBucket) ApiCall += '-|-' + config.modelBucket.replace(/\//g, "!!-")
        console.log(ApiCall)
        const action = functions.httpsCallable(ApiCall)
        await action({}).then(response => { console.log("exportModel", response.data); if (response.data) res = response.data }).catch(async (error) => { console.log(error) });
        return res;
    },
    async modelUpdate(modelID, data) { if (modelID) await $_firebase.firestore().collection("model").doc(modelID).update(data) },
    async getDbModel(modelID) {
        let model = $_firebase.firestore().collection('model').doc(modelID)
        const snapshot = await model.get();
        let item = false
        if (snapshot.data()) {
            item = snapshot.data();
            item.id = snapshot.id
        }
        return item
    },
    async getdDbModels(DatasetID) {
        let models = []
        let dsRef = $_firebase.firestore().collection('dataset').doc(DatasetID)
        let model = $_firebase.firestore().collection('model').where('dataset', '==', dsRef)
        await model.get().then(async snapshot => {
            await snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if (!item.deleted) models[models.length] = item
            });
        });
        return models
    },
};

const install = app => { app.config.globalProperties.$datasets = datasets; };

export { install as default, datasets as dataset };
