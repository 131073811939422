<template>
  <div></div>
</template>
<script>
import { defineComponent,onMounted, computed, onBeforeUnmount } from "vue";
import cash from "cash-dom";
import { useStore } from "@/store";
import config from '../../etc/rosepetal.json';

const nodeConfig = config.nodeConfig;

export default defineComponent({
  setup() {

    const store            = useStore();   
    const User             = computed(() => store.state.main.User);

    const loadNR = (url) => {  
        document.getElementById("uiFrame").style.display = 'block';
        document.getElementById("uiFrame").src=nodeConfig.host+"/nodered"+url+"?uid="+User.value.uid; 
    };

    onMounted( async () => { 
      cash(".content").addClass("hidden");
      cash("#topBarUiIframe").css("display", "block");
      cash(".dashboxItem,.side-nav").addClass("hidden");
      cash(".top-bar-node-menu").removeClass("hidden");
      cash(".top-bar-node-menu-side-menu-node").addClass("text-primary-4 top-bar-node-menu-current");
      if(cash("#uiFrame").attr("src")=="about:blank")loadNR('/ui/#!/0');
    });

    onBeforeUnmount( async () => { 
      cash(".dashboxItem,.side-nav").removeClass("hidden");
      cash(".top-bar-node-menu").addClass("hidden");
      cash(".content").removeClass("hidden");
      cash("#topBarUiIframe").css("display", "none");
      cash(".top-bar-node-menu-current").removeClass("text-primary-4 top-bar-node-menu-current");
    });

  }
});
</script>