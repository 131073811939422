import { _firebase as $_firebase } from "@/model/firebase";
import { useStore } from "@/store";

import JSZip from "jszip";
import { saveAs } from 'file-saver';

import { helper as $h } from "@/model/db/helper";
import { other as $other } from "@/model/db/other";
import { model as $model } from "@/model/db/model";
import { event as $event } from "@/model/db/event";
import { image as $image } from "@/model/db/image";
import { aws as $aws } from "@/model/db/aws";
import { vertex as $vertex } from "@/model/db/vertex";
import { project as $project } from "@/model/db/project";
import { op } from "@tensorflow/tfjs-core";

const datasets = {
    async list(opt = false) {
        let datasets = [];
        let dsets    = $_firebase.firestore().collection('dataset')
        if(opt.type)dsets = dsets.where('type', '==', opt.type)
        if(opt.trained)dsets = dsets.where('trained', '==', Boolean(true))
        if(opt.vertex){ dsets  = dsets.where('automl', '!=', ""); dsets = dsets.orderBy('createdAt', 'desc') }
        if(opt.search)dsets  = dsets.orderBy("name", "asc")
        if(opt.search)dsets  = dsets.where('name', '>=', opt.search.toString().toUpperCase()).where('name', '<=', opt.search.toString().toUpperCase() + '\uf8ff');
        if(opt.limit && !opt.search)dsets   = dsets.limit(opt.limit)
        else dsets = dsets.orderBy('createdAt', 'desc')
        await dsets.get().then(async snapshot => {
            snapshot.forEach(async doc => {
                let item     = doc.data()
                item.id      = doc.id;
                let pushItem = true
                item.createdDate        = $h.getTimestampDate(item.createdAt.toDate(),'full')
                if(item.name)item.name  = item.name.toUpperCase();
                if(item.name)item.name  = $h.capitalize(item.name)
                //if (opt.vertex && !item.automl)pushItem = false
                if (item.deleted)pushItem = false
                if (item.type)item.typeName = item.type=="MULTICLASS" ? item.type.toLowerCase() : item.type=="imageObjectDetection" ? "Object detection" : false
                //if (opt.type && item.type && opt.type!=item.type)pushItem = false
                if(pushItem)datasets.push(item)
            });
        });
        if(datasets.length && opt.preview){
            for (let index in datasets) {
                if(opt.preview)datasets[index].preview = await this.getImages({ datasetID: datasets[index].id, preview: true }); 
            }
        }  
        return datasets
    },
    async get(datasetID, opt = false) {
        let dataset = {};
        let dsets = $_firebase.firestore().collection('dataset').doc(datasetID)
        await dsets.get().then(async snapshot => {
            dataset    = snapshot.data()
            dataset.id = snapshot.id;
            if(dataset.name)dataset.name  = $h.capitalize(dataset.name)
            if(dataset.type)dataset.typeName   = dataset.type=="MULTICLASS" ? dataset.type.toLowerCase() : dataset.type=="imageObjectDetection" ? "Object detection" : false
            if(dataset.createdAt)dataset.createdDate = $h.getTimestampDate(dataset.createdAt.toDate(),'full')
        });
        if(Object.keys(dataset).length){ 
            if(opt.models){ let _models = await this.getModels(dataset.id); dataset.models = _models.models }
            if(opt.tagsCounter)dataset.tagsCounter   = await this.getTagsCounter(dataset.id)
            if(opt.dataDivision)dataset.dataDivision = await this.getDataDivision(dataset.id) 
            if(opt.preview)dataset.preview           = await this.getImages({ datasetID: dataset.id, preview: true });
            if(opt.ref)dataset.datsetRef = dsets
        }  
        return dataset
	},
    async getVertex(datasetID) {
        let resp = { status: "error", error: false }
        if (datasetID){
            let dataset  = await this.get(datasetID)
            //let config   = $project.getConfig()
            resp.dataset = datasetID 
            if (dataset.automl){
                resp.automl  = dataset.automl 
                let dsVertex = await $other.httpsCallable('api/model/datasets/dataset_id/' + dataset.automl )
                //console.log('call usapi method')
                //const functionsUsApi = $_firebase.firebase().app().functions(config.functions.usapi)
                //const dsVertex = await functionsUsApi.httpsCallable('model/datasets/dataset_id/' + dataset.automl );
                if(dsVertex.data){
                    resp.response = dsVertex.data
                    resp.status   = "success"
                }else{ resp.error = "dataset " + dataset.automl + " not found in Vertex AI" } 
            }else{ resp.error = "dataset is not created in Vertex AI" } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
	},
    async getAws(datasetID) {
        let resp = { status: "error", error: false }
        if (datasetID){
            let dataset  = await this.get(datasetID)
            resp.dataset = datasetID 
            if (dataset.aws && dataset.aws.length){
                resp.awsProjects  = {} 
                for(let i=0 ; i < dataset.aws.length ; i++){
                    let projectAws = await $aws.getProject(dataset.aws[i])
                    if(!projectAws.error){
                        let projectAwsDatasets = await $aws.getDataset(dataset.aws[i])
                        projectAws.response.ProjectDescription.Datasets = projectAwsDatasets.response
                        resp.awsProjects[dataset.aws[i]] = projectAws.response
                    }
                }  
            }else{ resp.error = "dataset project is not created in Lookout vision AI" } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
	},
    async update(datasetID, data) {
        let resp = ''
        if (datasetID){
            data["updatedAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
            await $_firebase.firestore().collection("dataset").doc(datasetID).update(data)
                .then( async function () { 
                    await $event.saveEvent('dataset.update',{ uid: useStore().state.main.User.uid, dataset: datasetID, data: data }, false);
                    resp = '{ result: "success", error: false }'
                })
                .catch(async () => { resp = '{ result: "error", error: "Dataset not found" }'});
        }
        return resp
    },
    async delete(datasetID) {
        await $event.saveEvent('dataset.delete',{ uid: useStore().state.main.User.uid, dataset: datasetID }, false);
        return await this.update(datasetID,{ deleted : Boolean(true) })
	},
    async getModels(datasetID) {
        let resp = { status: "error", error: false, count: 0, models: [] }
        let dsRef  = $_firebase.firestore().collection('dataset').doc(datasetID)
        let model  = $_firebase.firestore().collection('model').where('dataset', '==', dsRef)
        await model.get().then(async snapshot => {
            await snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if(item.createdAt)item.created = $h.getTimestampDate(item.createdAt.toDate(),'full')
                if (!item.deleted)resp.models[resp.models.length] = item
            });
        });
        resp.count  = resp.models.length
        if(resp.models.length)resp.status  = "success"
        else resp.error = "no found models linked to this dataset"
        return resp
    },
    async create(opt) {
        let saveData = { name: opt.name, type: opt.type, createdAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp(), description: "" }
        if(opt.description)saveData.description = opt.description
        let resp = { status: "error", error: false }
        await $_firebase.firestore().collection("dataset").doc(opt.name.toString()).set(saveData)
            .then( async function () {   
                resp.dataset = opt.name.toString() 
                resp.status  = "success"
            })
            .catch(function (error) { resp.error = error });  
        if(resp.status=="success"){
            await $event.saveEvent('dataset.create',{ uid: useStore().state.main.User.uid, dataset: opt.name.toString(), qry: saveData }, false);
            await this.createTag(opt.name.toString(), { tag: "0", name: "unclassified", unclassified: true })
        }
        return resp
	},
    async createVertex(datasetId) {
      let resp = { status: "error", error: false }
      if (datasetId){
        let dataset  = await this.get(datasetId)
        resp.dataset = datasetId 
        if (!dataset.automl){
            let valideTrain  = await this.validateToTrain(datasetId)
            if(valideTrain.validated){
                let createVertex = await $other.httpsCallable('api/model/create/dataset/' + dataset.name + '-' + dataset.type)
                if(createVertex.data){
                    await $event.saveEvent('dataset.create.vertex',{ uid: useStore().state.main.User.uid, dataset: dataset.name.toString(), response: JSON.stringify(createVertex.data) }, false);
                    resp.automl  = createVertex.data.name.toString().split('/').pop()
                    await this.update(resp.dataset, { automl : resp.automl })
                    resp.status  = "success" 
                }else{ resp.error = "failed to create dataset " + (createVertex.error ? createVertex.error : "") } 
            }else{ resp.error = "dataset is not validated for a training, review the errors."; resp["errors"] = valideTrain?.errors; resp["warnings"] = valideTrain?.warnings } 
        }else{ resp.error = "The dataset is already created in vertex with the identifier: " + dataset.automl } 
      }else{ resp.error = "dataset Id is required" } 
      return resp
    },    
    async createAws(datasetId) {
        let resp = { status: "error", error: false, response: {} }
        if (datasetId){
          let dataset    = await this.get(datasetId)
          resp.dataset   = datasetId 
          if(dataset.type=="MULTICLASS"){
            let valideTrain  = await this.validateToTrain(datasetId)
            if(valideTrain.validated){
                let awsConfig    = $aws.getConfig()
                var nowdate      = new Date()
                resp.projectName = awsConfig.projectId + "-" + dataset.name.toString().replace(/\s+/g, '_') + "-" + nowdate.getTime() 
                //let checkProject = await $aws.getProject(resp.projectName)
                //if(!checkProject.response?.ProjectDescription?.ProjectName){
                    let newProject = await $aws.createProject(resp.projectName)   
                    if(!newProject?.response?.ProjectMetadata){ resp.error = "failed to create project" 
                    }else{
                        let dsAws = dataset.aws ? dataset.aws : []
                        dsAws.push(resp.projectName)
                        await this.update(resp.dataset, { aws : dsAws })
                        await this.uploadS3(datasetId, resp.projectName)
                                .then(async function (r) { 
                                    if (!r.error){
                                        let newDatasetTest = await $aws.createDataset("test", resp.projectName, r.response.manifest.test) 
                                        if(newDatasetTest.error){ resp.error = newDatasetTest.error 
                                        }else{
                                            resp.response.test = newDatasetTest.response
                                            let newDatasetTrain = await $aws.createDataset("train", resp.projectName, r.response.manifest.train) 
                                            if(newDatasetTrain.error){ resp.error = newDatasetTrain.error 
                                            }else{   
                                                resp.response.train = newDatasetTrain.response
                                                resp.status   = "success"
                                            } 
                                        }     
                                    }else{ resp.error = r.error  } 
                                }).catch(function (error) { resp.status = "error"; resp.error = error  });
                    }
                //}else{ resp.error = resp.projectName + " dataset is already created" } 
            }else{ resp.error = "dataset is not validated for a training, review the errors."; resp["errors"] = valideTrain?.errors; resp["warnings"] = valideTrain?.warnings }     
          }else{ resp.error = "only for classification type datasets, is " + dataset.type } 
        }else{ resp.error = "dataset Id is required" } 
        return resp
    },   
    async createTag(datasetId, opt) {
        let resp = { dataset: datasetId };
        if(opt.tag && datasetId){
            let tag = {
                name:         opt.name ? opt.name : opt.tag,
                description:  "",
                imageCounter: Number(0),
                color:        opt.color ? opt.color : $h.StringtoHex(opt.tag),
                unclassified: opt.unclassified ? Boolean(true) : Boolean(false),
                createdAt:    $_firebase.firebase().firestore.FieldValue.serverTimestamp(),
                uid:          useStore().state.main.User.uid
            }
            await $_firebase.firestore().collection("dataset").doc(datasetId.toString()).collection('tag').doc(opt.tag).set(tag)
                   .then(async function () {  resp.status = "success";  await $event.saveEvent('dataset.tag.create',{ uid: useStore().state.main.User.uid, datasetId: datasetId, tag: tag }, false); })
                   .catch(function (error) { resp.status = "error"; resp.error = error });
        }else{ resp.status = "error"; resp.error = "new tag id and dataset id is required" }
        return resp
    },
    async updateTag(Tag) {
        Tag.data["updatedAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("dataset").doc(Tag.dataset.toString()).collection('tag').doc(Tag.id).update(Tag.data);
        await $event.saveEvent('dataset.tag.update',{ uid: useStore().state.main.User.uid, dataset: Tag.dataset.toString(), id: Tag.id, data: Tag.data }, false);
    },
    //create a function for count the images in a dataset
    async countImages(datasetId) {
        let resp = { dataset: datasetId };
        //convert resp to json
        resp = JSON.parse(JSON.stringify(resp))
        
        
        
        if(datasetId){
            let dataset = await this.get(datasetId)
            if(dataset){
                let images = await $_firebase.firestore().collection("dataset").doc(datasetId.toString()).collection('image').get()
                resp.count = images.size
                await this.update(datasetId, { imageCounter: resp.count })
            }else{ resp.error = "dataset not found" }
        }else{ resp.error = "dataset Id is required" }
        return resp
    },
    async getTags(datasetID, unclassified = true) {
        let tags        = {}
        let dataset     = $_firebase.firestore().collection('dataset').doc(datasetID.toString()).collection('tag').orderBy("name", "asc")
        await dataset.get().then(snapshot => {
            snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if(item.name)item.name = $h.capitalize(item.name)
                if (!item.color) {
                    item.color = $h.StringtoHex(doc.id);
                    this.updateTag({ id: doc.id, dataset: datasetID.toString(), data: { color: item.color }})
                }
                if (unclassified) { tags[doc.id] = item; } else { if (!item.unclassified) tags[doc.id] = item; }
            });
        });
        return tags;
    },
    async getTag(datasetID, tagId){
        let tag = {}
        let snapshot = await $_firebase.firestore().collection('dataset').doc(datasetID.toString()).collection('tag').doc(tagId).get(); 
        tag = snapshot.data(); 
        if(tag)tag.id = snapshot.id
        if(tag.createdAt)tag.created = $h.getTimestampDate(tag.createdAt.toDate(),'full')
        return tag;
    },
    async getTagsCounter(datasetID, unclassified = true , opt = false) {

        if(op.debug)console.log('------ Call getTagsCounter')
        var start1          = Date.now();
        let dataset         = opt.dataset ? opt.dataset : await this.get(datasetID, { ref: true })
        if(op.debug)console.log('------------ TC dataset: ' + (Date.now() - start1) + "ms")
        let tagsCounter     = { count: 0, tags: {}, labeled: 0, nolabel:0, names: {}, colors: {}, progessPercent: 0}
        if(dataset.type){
            var start2  = Date.now();
            let tags    = await this.getTags(datasetID, unclassified)
            if(op.debug)console.log('------------ TC tags: ' + (Date.now() - start2) + "ms")   
            if(Object.keys(tags).length){ 
                
                if(dataset.type=='MULTICLASS')tagsCounter.nolabel =  tags["0"] && tags["0"].imageCounter ? tags["0"].imageCounter : 0

                if(dataset.type=='imageObjectDetection'){
                    tagsCounter.tagslabeled = {}
                    if(tags["0"])tagsCounter.count = tags["0"].imageCounter
                }
                for (const k of Object.keys(tags)){
                    let _tagcount =  tags[k].imageCounter ? tags[k].imageCounter : 0
                    if(dataset.type=='MULTICLASS'){
                        tagsCounter.count       +=  _tagcount
                        if(!unclassified && k != '0')tagsCounter.tags[k] =  _tagcount
                        else tagsCounter.tags[k] =  _tagcount
                    }
                    if(k != '0'){
                        if(dataset.type=='imageObjectDetection')tagsCounter.tagslabeled[k] = _tagcount
                        tagsCounter.labeled += _tagcount 
                    }
                    tagsCounter.colors[k]  = (tags[k] && tags[k].color ? tags[k].color : $h.StringtoHex(k))
                    tagsCounter.names[k]   = tags[k].name
                    
                }
                if(dataset.type=='imageObjectDetection' && dataset.datsetRef){
                    let images          = $_firebase.firestore().collection('image').where('dataset', '==', dataset.datsetRef).where('tags', '==', [])
                    let snap            = await images.get()
                    tagsCounter.nolabel = snap.size 
                    tagsCounter.labeled = tagsCounter.count-tagsCounter.nolabel
                }
            } 
            if(op.debug)console.log('------------ TC counter by tags: ', tagsCounter)

            if(dataset.trained)tagsCounter.progessPercent = 100
            if(tagsCounter.labeled>1)tagsCounter.progessPercent  = 25
            if(tagsCounter.labeled>50)tagsCounter.progessPercent  = 50
            if(tagsCounter.labeled>100)tagsCounter.progessPercent = 75

            tagsCounter.chart = { labels: ["Labeled"], datasets: [{ data: [tagsCounter.labeled], backgroundColor: [$h.StringtoHex("LabeledGreen")], hoverBackgroundColor: [$h.StringtoHex("LabeledGreen")], borderWidth: 5, borderColor: "#fff"}]}
            if(tagsCounter.nolabel){
                tagsCounter.chart.labels.push("Unclassified")
                tagsCounter.chart.datasets[0].data.push(tagsCounter.nolabel)
                tagsCounter.chart.datasets[0].backgroundColor.push($h.StringtoHex("UnclassifiedRed"))
                tagsCounter.chart.datasets[0].hoverBackgroundColor.push($h.StringtoHex("UnclassifiedRed"))
            }
            /*
            if(dataset.type=='imageObjectDetection'){

                let images      = $_firebase.firestore().collection('image').where('dataset', '==', dataset.datsetRef)
                let snap        = await images.get()
                console.log('------------ Firebase images (OD): ' + (Date.now() - start3) + "ms")
                let tagsCount   = { 
                                    count:       snap.docs.length, 
                                    labeled:     0,
                                    nolabel:     0, 
                                    tagslabeled: {}, 
                                    colors:      { Unclassified: tags["Unclassified"] && tags["Unclassified"].color ? tags["Unclassified"].color : $h.StringtoHex("Unclassified") } 
                                    }
                var start4  = Date.now();
                snap.forEach(async (doc) => {
                    let p = doc.data()
                    p.id  = doc.id
                    if (p.tags && p.tags.length) {
                        let tagged = false
                        for (let key in p.tags) {
                            if (p.tags[key].tag) {
                                let tRef    = p.tags[key].tag.path.toString().split('/')
                                let tagName = false
                                if (tRef[tRef.length - 1]) tagName = tRef[tRef.length - 1].replace('"', '');
                                if (tagName) {
                                    tagged  = true
                                    if (!tagsCount.tagslabeled[tagName]) {
                                        tagsCount.tagslabeled[tagName] = 1
                                        tagsCount.colors[tagName] = (tags[tagName] && tags[tagName].color ? tags[tagName].color : $h.StringtoHex(tagName))
                                    } else { tagsCount.tagslabeled[tagName]++; }
                                }
                            }
                        }
                        if (tagged) { tagsCount.labeled++; } else { tagsCount.nolabel++; }
                    } else { tagsCount.nolabel++; }
                });
                console.log('------------ (OD) Firebase total: ' + tagsCount.count + " Process: " + (Date.now() - start4) + "ms")
                return tagsCount;
            }
            */
        }
        return tagsCounter;
    },
    async getImages(opt = false) {
        let media      = { media: [] }
        let images     = $_firebase.firestore().collection('image')
        let order      = { param: 'date', condition: '!=', val:  '', order:  "date", type: "desc"  }
        let pagination = { currentPage: 0, perPage: opt.perPage ? opt.perPage : 12, pages: 1, total: 0, init:  null, first: null, last:  null, prev:  false, next:  true, toend: false }
        if(opt.paginationQry)pagination = opt.paginationQry
        if(opt.perPage)opt.pagination = true
        if(opt.datasetID) {
            let dataset  = await this.get(opt.datasetID)
            media.type   = dataset.type
            if(opt.preview)opt.limit = 1
            if (dataset.type && dataset.type!='MULTICLASS')opt.pagination = false //pagination only MULTICLASS
            if (opt.objDivision && opt.objDivision != 'all' && opt.pagination)opt.pagination = opt.objDivision = false //pagination only whithout set
            let tagRef   = await $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString())
            if (dataset.type && dataset.type=='MULTICLASS' && opt.objByTag && opt.objByTag!= 'all'){
                tagRef = await tagRef.collection('tag').doc(opt.objByTag.toString())
                images = images.where('tag', '==', tagRef)
            }else{ images = images.where('dataset', '==', tagRef) }
            if (opt.objDivision && opt.objDivision != 'all')images = images.where('set', '==', opt.objDivision.toString().toUpperCase())
            if (dataset.type && opt.objtagsType) {
                let unclassRef   = await $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString()).collection('tag').doc("0")
                if (opt.objtagsType == 'labeled') {
                    if(dataset.type=='imageObjectDetection'){
                        if (opt.objByTag && opt.objByTag != 'all') {
                            let stagRef = await $_firebase.firestore().collection("dataset").doc(opt.datasetID.toString()).collection("tag").doc(opt.objByTag.toString())
                            images = images.where('tagsContained', 'array-contains', stagRef).orderBy("tags").orderBy("date", "desc") 
                        } else { images = images.where('tags', '!=', []).orderBy("tags").orderBy("date", "desc")}
                    }
                    if(dataset.type=='MULTICLASS')images = images.where('tag', '!=', unclassRef).orderBy("tag").orderBy("date", "desc")
                }
                if (opt.objtagsType == 'nolabel'){
                    if(dataset.type=='imageObjectDetection')images = images.where('tags', '==', []).orderBy("date", "desc")
                    if(dataset.type=='MULTICLASS')images = images.where('tag', '==', unclassRef).orderBy("tags").orderBy("date", "desc")
                }
            } else {
                if (order && order.order && order.type) images = images.orderBy(order.order, order.type)
            }
    
            if (opt.pagination){
                if (opt.action && opt.action == "init" && pagination.init) {
                    images = images.startAt(pagination.init)
                } else if (opt.action && opt.action == "next" && pagination.last){ images = images.startAfter(pagination.last) }
                if (opt.action && opt.action == "prev" && pagination.first) {
                    images = images.endBefore(pagination.first)
                    if (pagination.perPage) images = images.limitToLast(pagination.perPage);
                } else if (opt.action && opt.action == "end" && pagination.end) {
                    images = images.endBefore(pagination.end)
                    if (pagination.perPage) images = images.limitToLast((pagination.perPage - 1));
                } else { 
                    if (opt.pagination && pagination.perPage) images = images.limit(pagination.perPage)
                }
            }

            if(!opt.pagination && opt.limit)images = images.limit(opt.limit)

            let snap = await images.get()
            snap.forEach(async (doc) => {
                let p = doc.data()
                p.id = doc.id
                p.tag = p.tag.path
                p.tagName = p.tag.toString().split('/')
                p.fileName = p.name.toString().split('/')
                p.img_base64_val = p.imageData && p.imageData._delegate._byteString.binaryString ? btoa(p.imageData._delegate._byteString.binaryString) : null
                media.media.push(p)
            });
            media.count = media.media.length
    
            //PAGINATION
            if (opt.pagination) {
                let tags         = await this.getTagsCounter(opt.datasetID)
                pagination.total = !opt.objByTag || opt.objByTag == 'all' ? tags.count : tags.tags[opt.objByTag] ? tags.tags[opt.objByTag] : 0
                if (opt.objtagsType && opt.objtagsType == 'nolabel')pagination.total = tags.tags["Unclassified"]
                //if (opt.objtagsTypeCount)pagination.total = opt.objtagsTypeCount
                if (snap.docs && snap.docs[0]){
                    if(!pagination.init)pagination.init = snap.docs[0];
                    pagination.first = snap.docs[0];
                    pagination.last = snap.docs[snap.docs.length - 1];
                } 
                if (opt.action == 'init') {
                    pagination.currentPage = 0;
                } else if (opt.action == 'prev') {
                    pagination.prev = pagination.next = false;
                    pagination.currentPage--;
                } else if (opt.action == 'next') {
                    pagination.prev = pagination.next = false;
                    pagination.currentPage++;
                } else if (opt.action == 'end') {
                    pagination.currentPage = pagination.pages - 1;
                }
                pagination.pages = Math.ceil(pagination.total/pagination.perPage)
                if((pagination.currentPage+1)==pagination.pages){ pagination.next = false; }else{ pagination.next = true; }
                if(pagination.currentPage==0){ pagination.prev = false; }else{ pagination.prev = true;}
                if (media.media.length)media.pagination = pagination
            }
            //END PAGINATION

        }
        if(opt.resume){
            let resume = { count: 0, images: [] }
            for(let i=0;i<media.media.length;i++){ resume.images.push({ name: media.media[i].name, uri: media.media[i].uri, set: media.media[i].set}) }
            resume.count = resume.images.length
            return resume
        }
        if(opt.preview)return media.media[0] && media.media[0].imageData ? "data:image/png;base64,"+ (btoa(media.media[0].imageData._delegate._byteString.binaryString)) : false
        return media
    },
    async getDataDivision(datasetID, onlyLabeled = false) {
        let dataset         = await this.get(datasetID)
        let countSetImages  = { total: 0, train: 0, test: 0, validation: 0, predetermined: 0 }
        let images          = $_firebase.firestore().collection('image')
        let tagRef          = await $_firebase.firestore().collection("dataset").doc(datasetID.toString())
        images              = images.where('dataset', '==', tagRef)
        let snap            = await images.get()
        snap.forEach(async (doc) => {
            let p = doc.data()
            let check = true;
            if (dataset.type && dataset.type == 'imageObjectDetection' && onlyLabeled && (!p.tags || !p.tags.length)) check = false
            if (dataset.type && (dataset.type == 'MULTICLASS' || dataset.type == 'MULTILABEL')) {
                let tagStr = p.tag.path.toString().split('/').pop()
                if (dataset.type && (dataset.type == 'MULTICLASS' || dataset.type == 'MULTILABEL') && onlyLabeled && (!p.tag || tagStr == "0")) check = false
            }
            if (check) {
                if (p.set) {
                    countSetImages[p.set.toLowerCase()]++;
                } else { countSetImages.predetermined++; }
                countSetImages.total++;
            }
        });
        return countSetImages
	},
    async setRandDataDivision(datasetID, onlyLabeled = false) {
        let resp = { status: "error", error: false, dataset: false, options: {} }
        if(datasetID){
            resp.dataset                = datasetID
            resp.options.onlyLabeled    = onlyLabeled
            let qry                     = "Are you sure you want to assign the data division randomly?" + "\n" 
                                        + "Images: " + ( onlyLabeled ? "Only labeled" : "All" ) + "\n"
                                        + "Important: This action is irreversible"
            if (confirm(qry)){
                let dataset         = await this.get(datasetID)
                let newDivison      = { total: 0, train: 0, test: 0, validation: 0, predetermined: 0 }
                resp.oldDivision    = await this.getDataDivision(datasetID, onlyLabeled)
                let images          = $_firebase.firestore().collection('image')
                let tagRef          = await $_firebase.firestore().collection("dataset").doc(datasetID.toString())
                images              = images.where('dataset', '==', tagRef)
                let snap            = await images.get()
                var imageCount      = snap.docs.length
                resp.options.testPercentage          = 10 
                resp.options.validationPercentage    = 10 
                /* eslint-disable */
                let maskSet         = Array.from({length: imageCount }, (_, index) => "TRAIN");
                let train           = Array.from({length: imageCount }, (_, index) => index);
                for (let i = 0; i <= Math.round(imageCount * parseInt(resp.options.testPercentage) / 100); i++) {
                    let _rand               = Math.floor(Math.random() * train.length);
                    maskSet[train[_rand]]   = "TEST"
                    train                   = train.filter(function (item, idx) {return idx !== _rand });
                }
                for (let i = 0; i <= Math.round(imageCount * parseInt(resp.options.validationPercentage) / 100); i++) {
                    let _rand               = Math.floor(Math.random() * train.length);
                    maskSet[train[_rand]]   = "VALIDATION"
                    train = train.filter(function (item, idx) { return idx !== _rand });
                } 
                /* eslint-enable */
                var counterImg = 0
                snap.forEach(async (doc) => {
                    let p = doc.data()
                    p.id  = doc.id;
                    let check = true;
                    if (dataset.type && dataset.type == 'imageObjectDetection' && onlyLabeled && (!p.tags || !p.tags.length)) check = false
                    if (dataset.type && (dataset.type == 'MULTICLASS' || dataset.type == 'MULTILABEL')) {
                        let tagStr = p.tag.path.toString().split('/').pop()
                        if (dataset.type && (dataset.type == 'MULTICLASS' || dataset.type == 'MULTILABEL') && onlyLabeled && (!p.tag || tagStr == "0")) check = false
                    }
                    if (check) {
                        if (maskSet[counterImg]) {
                            if(p.id)$image.setSet(p.id, maskSet[counterImg].toUpperCase())
                            newDivison[maskSet[counterImg].toLowerCase()]++;
                        } else { 
                            if(p.id)$image.setSet(p.id, "PREDETERMINED")
                            newDivison.predetermined++; 
                        }
                        newDivison.total++;
                    }
                    counterImg++;
                });
                resp.newDivision  = newDivison
                resp.status       = "success"
                await $event.saveEvent('dataset.datadivision.random',{ uid: useStore().state.main.User.uid, dataset: datasetID, response: resp }, false);
            }else{
                resp.response         = "Randomly set assignement is canceled by user"
                resp.currentDivision  = await this.getDataDivision(datasetID, onlyLabeled)
            }
            resp.status    = "success"
        }else{ resp.error = "dataset Id is required" } 
        return resp 
	},
    async validateToTrain (datasetID) {
        let dataset  = await this.get(datasetID)
        let validation  = { 
                            dataset:   dataset.name,
                            type:      dataset.type,
                            tags:      await this.getTagsCounter(datasetID, false),
                            division:  await this.getDataDivision(datasetID,true),
                            validated: true,
                            errors:    [],
                            warnings:  [],
                            trainingImages: await this.getImages({ datasetID: datasetID, objtagsType: 'labeled', resume: true }),
                          }
        if(validation.trainingImages.count<20){ validation.validated = false; validation.errors.push("Must have at least 20 labeled images") }  
        if(validation.trainingImages.images.length){
            let repeatName = { imgNames: {} , validate: true }
            for(let i=0;i<validation.trainingImages.images.length;i++){
                if(repeatName.imgNames[validation.trainingImages.images[i].name]){ repeatName.validate = false; }else{ repeatName.imgNames[validation.trainingImages.images[i].name] = true }
            }
            if(!repeatName.validate){  validation.warnings.push("There are repeated image names") }  
        }
        if(validation.type=="MULTICLASS"){
            if(!validation.tags.tags || !Object.keys(validation.tags.tags).length){
                validation.errors.push("No defined required tags, must have at least 2 tags")
                validation.validated = false
            }else{
                if(Object.keys(validation.tags.tags).length<2){ validation.validated = false; validation.errors.push("Must have at least 2 tags") }
                for (let index in validation.tags.tags) {
                    if(validation.tags.tags[index]<10){ validation.validated = false; validation.errors.push("The "+index+" tag must have at least 10 images for the tag") }
                }
            }
        } 
        if(validation.type=="imageObjectDetection"){
            if(!validation.tags.tagslabeled || !Object.keys(validation.tags.tagslabeled).length){
                validation.errors.push("No defined required tags, must have at least 2 tags")
                validation.validated = false
            }else{
                for (let index in validation.tags.tagslabeled) {
                    if(validation.tags.tagslabeled[index]<10){ validation.validated = false; validation.errors.push("The "+index+" tag must have at least 10 images for the tag") }
                }
            }
        }
        if(!Object.keys(validation.division).length){ validation.validated = false; validation.errors.push("No defined data division")
        }else{
            validation.division.percentages = {
                train:  validation.division['train'] ? ((validation.division['train'] * 100) / validation.division['total']).toFixed(2): 0,
                test:   validation.division['test'] ? ((validation.division['test'] * 100) / validation.division['total']).toFixed(2): 0,
                validation: validation.division['validation'] ? ((validation.division['validation'] * 100) / validation.division['total']).toFixed(2): 0
            }
            if(validation.division.percentages.train< 70){ validation.validated = false; validation.errors.push("Must have at least 70% train") }
            if(validation.division.percentages.test< 10){ validation.validated = false; validation.errors.push("Must have at least 10% test") }
            if(validation.division.percentages.validation< 10){ validation.validated = false; validation.errors.push("Must have at least 10% validation") }

            if(validation.type=="MULTICLASS"){
                if(validation.division.test<10){ validation.validated = false; validation.errors.push("Must have at least 10 test images") }
                if(validation.division.validation<10){ validation.validated = false; validation.errors.push("Must have at least 10 validation images") }
                if(validation.division.train<10){ validation.validated = false; validation.errors.push("Must have at least 10 train images") }
            }

            validation.division.chart = { 
                    labels:                 ["test","validation","train"], 
                    datasets:               [{ data: [validation.division.test, validation.division.validation, validation.division.train], 
                    backgroundColor:        [$h.StringtoHex("test"),$h.StringtoHex("validation"),$h.StringtoHex("train")],
                    hoverBackgroundColor:   [$h.StringtoHex("test"),$h.StringtoHex("validation"),$h.StringtoHex("train")],
                    borderWidth:            5,
                    borderColor:            "#fff"}]}
         }

        return validation
	},
    async getStatus(datasetID) {

        let dataset           = await this.get(datasetID)
        let lastImportEvent   = dataset.automl ? await $event.get({ type: "dataset.import" , dataset: dataset.automl, last : true}) : {}
        let lastTrainingEvent = dataset.automl ? await $event.get({ type: "dataset.training" , dataset: dataset.automl, last : true}) : {}
        let lastImport        = { inProgress: false }
        let lastTraining      = { inProgress: false }
        let lastUploadZip     = { inProgress: false }

        if(dataset.uploadRef)lastUploadZip.uploadRef              = dataset.uploadRef
        if(dataset.uploadStatus)lastUploadZip.uploadStatus        = dataset.uploadStatus
        if(dataset.uploadStatusMsg)lastUploadZip.uploadStatusMsg  = dataset.uploadStatusMsg
        if(dataset.uploadStatus=="processing")lastUploadZip.inProgress = true

        if(Object.keys(lastImportEvent).length){
            if(lastImportEvent.name)lastImport.name                 = lastImportEvent.name
            if(lastImportEvent.payload.dataset)lastImport.datasetId = lastImportEvent.payload.dataset
            if(lastImportEvent.createdAt)lastImport.created         = $h.getTimestampDate(lastImportEvent.createdAt.toDate(),'full')
            if(lastImportEvent.payload.uid)lastImport.uid           = lastImportEvent.payload.uid
            if(lastImportEvent.payload && lastImportEvent.payload.operation){
                let operationName  = lastImportEvent.payload.operationName ? lastImportEvent.payload.operationName : lastImportEvent.payload.operation
                let lastImportOper = await $other.httpsCallable('api/model/operation/status/'+ operationName.replace(/\//g, "--"))
                if(lastImportOper.data)lastImport.operation         = { name: lastImportOper.data.name, result: lastImportOper.data.result, done: lastImportOper.data.done } 
                if(lastImport.operation && !lastImport.operation.done)lastImport.inProgress = true
            }
        }

        if(Object.keys(lastTrainingEvent).length){
            if(lastTrainingEvent.name)lastTraining.name                   = lastTrainingEvent.name
            if(lastTrainingEvent.payload.dataset)lastTraining.datasetId   = lastTrainingEvent.payload.dataset
            if(lastTrainingEvent.payload.displayname)lastTraining.model   = lastTrainingEvent.payload.displayname
            if(lastTrainingEvent.createdAt)lastTraining.created           = $h.getTimestampDate(lastTrainingEvent.createdAt.toDate(),'full')
            if(lastTrainingEvent.payload.uid)lastTraining.uid             = lastTrainingEvent.payload.uid
            if(lastTrainingEvent.payload && lastTrainingEvent.payload.operationID){
                let pipName          = lastTrainingEvent.payload.operationID.name ? lastTrainingEvent.payload.operationID.name : lastTrainingEvent.payload.operationID;
                let lastTrainingPip  = await $other.httpsCallable('api/model/trainingpipeline/status/' + pipName.replace(/\//g, "--"))
                if(lastTrainingPip.data){ 
                    lastTraining.pipeline  = { 
                        name:        lastTrainingPip.data.name, 
                        displayName: lastTrainingPip.data.displayName, 
                        startTime:   $h.getFbDate(lastTrainingPip.data.startTime),
                        trainBudget: lastTrainingEvent.payload.trainBudget,
                        state:       lastTrainingPip.data.state,
                        error:       lastTrainingPip.data.error,
                        modelToUpload: lastTrainingPip.data.modelToUpload ? lastTrainingPip.data.modelToUpload.name : false,
                        done:        lastTrainingEvent.status && lastTrainingEvent.status=="done" ? true : false
                    } 
                    if(lastTrainingPip.data.state=="PIPELINE_STATE_SUCCEEDED" || lastTrainingPip.data.state=="PIPELINE_STATE_FAILED")lastTraining.pipeline.done = true
                    if(lastTraining.model && lastTraining.pipeline.modelToUpload){
                        let trainedModel = await $model.get(lastTraining.model)
                        if(!trainedModel.automl)$model.update(lastTraining.model,{ automl : lastTraining.pipeline.modelToUpload.toString().split('/').pop() })
                    }
                }else{ lastTraining.pipeline  = { name: lastTrainingEvent.payload.operationID, error: "not found", trainBudget: lastTrainingEvent.payload.trainBudget, state: "PIPELINE_STATE_FAILED", done: true } }
                if(lastTraining.pipeline && !lastTraining.pipeline.done)lastTraining.inProgress = true
            }
            if(!dataset.trained)await this.update(dataset.id,{ trained : Boolean(true) })
        }   

        return {
            dataset:            datasetID,
            trained:            Object.keys(lastTrainingEvent).length ? true : false,
            inProgress:         lastImport.inProgress || lastTraining.inProgress || lastUploadZip.inProgress ? true : false,
            action:             lastImport.inProgress ? 'importing' : lastTraining.inProgress ? 'training' : lastUploadZip.inProgress ? 'Uploading' : false, 
            import:             lastImport,
            training:           lastTraining,
            uploadZip:          lastUploadZip,
        };
    },
    async getLastLog(datasetID, limit = 5, opt = {}) {
        let resp = { status: "error", error: false, log: false }
        if(datasetID){
            let optLog   = { dataset: datasetID, limit: limit }
            if(opt.preview)optLog.preview = true
            if(opt.byDate)optLog.byDate = true
            resp.log     = await $event.get(optLog)
            resp.status  = "success"
        }else{ resp.error = "dataset Id is required" } 
        return resp 
    },
    async downloadZip(datasetID) {
        let media   = await this.getImages({ datasetID: datasetID }) 
        let tags    = await this.getTags(datasetID)   
        let zip     = { name: "zip", count: 0, files: [], folders: [] }
        var nowDate = new Date()
        if(media.count){
            for (var i = 0; i < Object.keys(media.media).length; i++) {
                if(media.media[i].uri){
                    let storageUrl = await $image.getStorageUrl(media.media[i].uri)
                    if(storageUrl.url){
                        zip.files.push({ 
                            name:  media.media[i].name.substr(media.media[i].uri.lastIndexOf("/")+1).replace(/\s+/g, '_'),
                            tag:   media.media[i].tagName && media.media[i].tagName[3] ? media.media[i].tagName[3] : false,
                            blob:  fetch(storageUrl.url).then(response => response.blob()),
                        })
                        zip.count++
                    }
                }     
            }  
            var z    = new JSZip() 
            if(media.type && media.type=='MULTICLASS'){
                if(Object.keys(tags).length){ for (const k of Object.keys(tags)){  zip.folders[tags[k].id] = z.folder(tags[k].id); } } //create tags forlder
                for (let i = 0; i < zip.files.length; i++) { if(zip.files[i].tag)zip.folders[zip.files[i].tag].file(zip.files[i].name, zip.files[i].blob, { base64: true }) } //insert folder images
            }else{
                zip.folders[datasetID.replace(/\s+/g, '_')] = z.folder(datasetID.replace(/\s+/g, '_'));
                for (let i = 0; i < zip.files.length; i++) { zip.folders[datasetID.replace(/\s+/g, '_')].file(zip.files[i].name, zip.files[i].blob, { base64: true }) } //insert images
            }
            zip.name = datasetID.replace(/\s+/g, '_')+"_media_"+nowDate.getTime()+"_"+zip.count+".zip"
            await z.generateAsync({type:"blob"}).then(async function (blob) { saveAs(blob,zip.name); });
            await $event.saveEvent('dataset.download',{ uid: useStore().state.main.User.uid, dataset: datasetID, filename: zip.name, format: "zip", size: zip.files.length }, false);
            return { error: false, status: "success", name: zip.name, images: zip.count, message: "The download will start automatically" }
        }else{ return { error: "Dataset has no images", status: "error" } }
    },
    async uploadStorage(datasetID, opt = false) {
        let resp = { status: "error", error: false }
        if(datasetID){
        let tagsCounter = await this.getTagsCounter(datasetID)
        resp.dataset    = datasetID
        if(tagsCounter.count){
            let dataset = await this.get(datasetID)
            if(dataset.automl){
                resp.dataset = datasetID
                resp.api     = $project.getApiHost()
                resp.csvQry  = 'api/dataset/'+datasetID+'/csv'
                if(opt.test || opt.validation)resp.csvQry += "?" + (opt.test ? "test="+opt.test : "") + (opt.validation ?  opt.test ? "&" : "" + "validation="+opt.validation : "")
                let csv  = await $other.httpsCallable(resp.csvQry)
                resp.csv = csv.data?.training?.dataset
                if(csv.data && resp.csv){
                    resp.importQry = 'api/model/import/dataset/' + dataset.automl + '-|-' + resp.csv.replace(/\//g, "!!-") + '-|-' + dataset.type.replace(/\//g, "!!-")
                    let importResp = await $other.httpsCallable(resp.importQry)
                    if(importResp.data?.name){
                        resp.operationId    = importResp.data.name.toString().split('/').pop()
                        resp.operationName  = importResp.data.name.toString()
                        await $event.saveEvent('dataset.import', { uid: useStore().state.main.User.uid, dataset: dataset.automl, csv: resp.csv, operation: resp.operationId, operationName: resp.operationName }, false)
                        resp.status = "success"
                    }else{ resp.error = "failed to import to Vertex" } 
                }else{ resp.error = "failed to generate csv" } 
            }else{ resp.error = "dataset automl Id is required" }
         }else{ resp.error = "the dataset does not have images" }
       }else{ resp.error = "dataset Id is required" } 
       if(resp.error)await $event.saveEvent('dataset.import.vertex',{ uid: useStore().state.main.User.uid, dataset: datasetID, response: resp }, true );
       return resp 
    },
    async normalAnomaly(datasetID) {
        let resp = { status: "error", error: false }
        if(datasetID){
             let dataset   = await this.get(datasetID)
             if(dataset.type=="MULTICLASS"){
                let tags      = await this.getTags(datasetID)
                resp.dataset  = datasetID
                if(Object.keys(tags).length){ 
                    let confirmMap   = false
                    while (!confirmMap) {
                        resp.tagMap  = { normal: [], anomaly: [] }
                        for (const k of Object.keys(tags)){ 
                            let tagName =  tags[k].name.toString().toUpperCase()
                            if(!tags[k].unclassified){
                                if (confirm("Are " + tagName + " anomaly tag?")) resp.tagMap.anomaly.push(tagName)
                                else resp.tagMap.normal.push(tagName)
                            }
                        } 
                        if (confirm("Assignments:\n\n" + "Normal: " + JSON.stringify(resp.tagMap.normal) + "\n" + "Anomaly: " + JSON.stringify(resp.tagMap.anomaly) + "\n\nDo you wish to continue?")){
                            confirmMap   = true;
                            resp.status  = "success"
                        }
                    }
                }else{ resp.error = "dataset does not have tags" }     
            }else{ resp.error = "only for classification type datasets, is " + dataset.type } 
        }else{ resp.error = "dataset Id is required" } 
        return resp 
    },
    async uploadS3(datasetID, projectName = false) {
       let resp = { status: "error", error: false }
       if(datasetID){
            let tagMap     = await this.normalAnomaly(datasetID)
            resp.response  = await $aws.uploadS3(datasetID, tagMap.tagMap ? tagMap.tagMap : false, projectName)
            resp.status    = "success"
       }else{ resp.error = "dataset Id is required" } 
       return resp 
    },
    async uploadImage(opt) {
        let resp            = { status: "success", error: false }
        var nowDate         = new Date()
        let storageConfig   = opt.firebaseConfig ? opt.firebaseConfig : $vertex.getConfig()
        let fileName        = opt.name ? opt.name : nowDate.getTime() + opt.name ? "."+opt.name.toString().split('.').pop() : ".png"
        var storageRef      = $_firebase.firebase().app().storage("gs://" + storageConfig.projectId).ref(); 
        var datasetDir      = opt.datasetId.toString().replace(/\s+/g, "-")
        let uploadRef       = await storageRef.child('upload/manual/image/' + datasetDir + "/" + fileName);
        var reader          = new FileReader();
        reader.readAsDataURL(opt.image);
        reader.onloadend    = function() { resp.base64 = reader.result }
        const task          = uploadRef.put(opt.image)
        return new Promise((resolve, reject) => {
            task.on(
                "state_changed",
                () => { },
                async (error) => { 
                  resp.status = "error"; resp.error = error; reject
                  resolve(resp)
                },
                async () => {
                    let resizeImage = await this.resizeAndConvertImage(resp.base64)

                    let newImage    = {
                        date:      nowDate.getTime(), 
                        imageData: resizeImage,
                        uri:       "gs://"+storageConfig.projectId+"/upload/manual/image/" + datasetDir + "/" + fileName,
                        name:      datasetDir + "/" +fileName,
                        dataset:   $_firebase.firestore().collection("dataset").doc(opt.datasetId),
                        tag:       $_firebase.firestore().collection("dataset").doc(opt.datasetId).collection("tag").doc(opt.tagId ? opt.tagId : "0"),
                        tags:      [],
                        tagsContained: []
                        }
                    
                    if(opt.tag)newImage.tag = opt.tag
                    if(opt.tags)newImage.tags = opt.tags

                    let tagIdStr = newImage.tag.path.toString().split('/').pop()
                    let dsTags   = await this.getTags(opt.datasetId, false)
                    if(tagIdStr && !dsTags[tagIdStr])await this.createTag(opt.datasetId, { tag: tagIdStr, name: tagIdStr, unclassified: false })

                    $_firebase.firestore().collection("image").add(newImage).then(async (docRef)=>{
                                //get created image id
                                resp.status = "success" 
                                if(opt.comments)await $image.setComments(docRef.id, opt.comments)
                                await $event.saveEvent('dataset.upload.image',{ 
                                    uid:      opt.uid ? opt.uid : "", 
                                    status:   resp.status, 
                                    dataset:  opt.datasetId, 
                                    file:     fileName,
                                    comments: opt.comments ? opt.comments : "",  
                                    imageId:  docRef.id }, false);
                                    resolve(resp)  
                            }).catch( (error) => { resp.status = "error"; resp.error = error; reject; resolve(resp) } )
                }
              ); 
        }) 
    },
    async uploadZipReintent(datasetID) {
        let resp              = { status: "success", error: false }
        let projectConfig     = $project.getConfig()
        const functionsUsApi  = $_firebase.firebase().app().functions(projectConfig.functions.usapi)
        const action          = await functionsUsApi.httpsCallable('dataset/' + datasetID + '/reintent');
        await action({}).then(() => { }).catch(async (error) => { resp.status = "error"; resp.error = error });
        return resp
    },
    async resizeAndConvertImage(img) {
        const { createCanvas, loadImage } = require('canvas')
        const image         = await loadImage(img)
        const canvas        = createCanvas(image.width, image.height)
        const ctx           = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0)
        const resizedCanvas = createCanvas(300, Math.floor(300 * image.naturalHeight / image.width))
        const resizedCtx    = resizedCanvas.getContext('2d')
        resizedCtx.drawImage(canvas, 0, 0, 300, Math.floor(300 * image.naturalHeight / image.width))
        const webpData      = resizedCanvas.toDataURL('image/webp', 0.92)
        const base64String  = Buffer.from(webpData.substring(23), 'base64').toString('base64')
        const webpBlob      = $_firebase.firebase().firestore.Blob.fromBase64String(base64String);
        return webpBlob
    },
}
const install = app => { app.config.globalProperties.$datasets = datasets; };
export { install as default, datasets as dataset };