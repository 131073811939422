import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/model/db/helper";
import { event as $event } from "@/model/db/event";
import { other as $other } from "@/model/db/other";
import { project as $project } from "@/model/db/project";

const models = {
    async create(datasetID, modelName, modelData = false) {
      let resp = { status: "error", error: false }
      if (datasetID && modelName){
        var nowdate       = new Date()
        resp.modelId      = modelName.toString().toLowerCase().replace(/\s+/g, '-') + "-" + nowdate.getTime() 
        resp.modelCreated = {
          automl:           "",
          dataset:          await $_firebase.firestore().collection("dataset").doc(datasetID.toString()),
          description:      "",
          savedModel:       "",
          status:           "undeployed",
          trainBudget:      "",
          annotationSetId:  "",
          createdAt:        $_firebase.firebase().firestore.FieldValue.serverTimestamp(),
        }
        if(modelData)resp.modelCreated = {...resp.modelCreated, ...modelData}
        await $_firebase.firestore().collection("model").doc(resp.modelId).set(resp.modelCreated)
          .then(function () { resp.status = "success" })
          .catch(function (error) { resp.error = error });
          resp.modelCreated.dataset = datasetID
          resp.status = "success"
      }else{ resp.error = "dataset Id and model name is required" } 
      return resp
    },
    async list(opt = false) {
        let models = [];
        let m      = $_firebase.firestore().collection('model').orderBy('createdAt', 'desc')
        if(opt.limit)m = m.limit(opt.limit)
        await m.get().then(async snapshot => {
              snapshot.forEach(async doc => {
                let item = doc.data()
                item.id = doc.id;
                if(item.dataset)item.dataset = item.dataset.path.toString().split('/').pop()
                if(item.createdAt)item.created = $h.getTimestampDate(item.createdAt.toDate(),'full')
                let pushItem = true
                if (opt.export && !item.savedModel)pushItem = false
                if (opt.vertex && !item.automl)pushItem = false
                if (item.deleted)pushItem = false
                if(pushItem)models.push(item)
              });
        });
        return models
    },
    async get(modelId) {
      let model  = {};
      await $_firebase.firestore().collection('model').doc(modelId).get().then(async snapshot => {
        model    = snapshot.data()
        model.id = snapshot.id;
        if(model.dataset)model.dataset = model.dataset.path.toString().split('/').pop()
        if(model.createdAt)model.created = $h.getTimestampDate(model.createdAt.toDate(),'full')
      });
      return model
    },
    async update(modelId, data) {
      let resp = { status: "error", error: false }
      if (modelId){
        data["updatedAt"] = $_firebase.firebase().firestore.FieldValue.serverTimestamp()
        await $_firebase.firestore().collection("model").doc(modelId).update(data)
        await $event.saveEvent('model.update', JSON.stringify(data), false)
        resp.status = "success" 
      }else{ resp.error = "modelId is required" } 
      return resp
    },
    async getExportUrl(modelId) {
      let resp = { status: "error", error: false }
      if (modelId){
        resp.model    = modelId
        let item      = await this.get(modelId)
        resp.api      = $project.getApiHost()
        if (item.automl){
          resp.automlId   = item.automl
          resp.api_url    = 'api/model/export/model_id/' + item.automl
          let config      = $project.getConfig()
          if (config.modelBucket){
            resp.bucket   = config.modelBucket
            resp.api_url += '-|-' + config.modelBucket.replace(/\//g, "!!-")
          }
          resp.status   = "success" 
        }else{ resp.error = "model does not have automl Id" }   
      }else{ resp.error = "modelId is required" } 
      return resp
    },
    async export(modelId) {
      let resp = { status: "error", error: false }
      if (modelId){
        let exportUrl = await this.getExportUrl(modelId)
        if(exportUrl.api_url){
          let req   = await $other.httpsCallable(exportUrl.api_url)
          if(!req.error){
              let savedModel     = req.data.metadata.outputInfo.artifactOutputUri + "/saved_model.pb"
              let _rq            = await this.update(modelId, { savedModel: savedModel  } ) 
              resp.exportStorage = { model: exportUrl.automlId,  name: exportUrl.model, bucket: exportUrl.bucket, url : exportUrl.api_url } 
              if(!_rq.error){ 
                resp.savedModel = savedModel
                resp.status     = "success" 
              }else{ resp.error = _rq.error }
          }else{ resp.error = req.error } 
        }else{ resp.error = exportUrl.error } 
      }else{ resp.error = "modelId is required" } 
      return resp
    },
    async getEvaluations(modelId) {
      let resp = { status: "error", error: false }
      if (modelId){
        let item      = await this.get(modelId)
        resp.model    = modelId
        if(item.automl){
          resp.automl      = item.automl
          let vertexModel  = await $other.httpsCallable('api/model/get/model_id/' + item.automl)
          let req          = await $other.httpsCallable('api/model/evaluations/model_id/' + item.automl)
          resp.count       = req.data ? req.data.length : 0
          resp.evaluations = []
          if(resp.count){
            for (var i = 0; i < resp.count; i++) {
              let vertexModelSlices = await $other.httpsCallable('api/model/evaluationslices/evaluation_id/' + req.data[i].name.toString().replace(/\//g, "_"))
              //evaluation
              let eva = { 
                        evaluationId : req.data[i].name.toString().split('/').pop(), 
                        name         : req.data[i].name.toString(),
                        typeObjects  : req.data[i].metrics?.structValue?.fields?.boundingBoxMetrics ? true : false,
                        created      : $h.getFbDate(req.data[i].createTime),
                        explanations : req.data[i].explanationSpecs.length ? { count: req.data[i].explanationSpecs.length, types: [] } : false,
                        model        : { id: item.automl }, 
                        slices       : { ALL: await this.parserVertexMetrics(req.data[i].metrics) }, 
                      } 

              //explanations
              for (var ex = 0; ex < req.data[i].explanationSpecs.length; ex++) { eva.explanations.types.push(req.data[i].explanationSpecs[ex].explanationType)} 

              //slices          
              if(vertexModelSlices?.data){        
                for (let _s = 0; _s < vertexModelSlices.data.length; _s++) {
                  eva.slices[vertexModelSlices.data[_s].slice.value] = await this.parserVertexMetrics(vertexModelSlices.data[_s].metrics) 
                }
              }

              //model data
              if(vertexModel.data)eva.model = await this.parserVertexModel(vertexModel.data, eva.model) 
              
              //add evaluation
              resp.evaluations.push(eva)
            }
            resp.status     = "success" 
          }else{ resp.error = "could not get the vertex evaluation" } 
        }else{ resp.error = "model does not have automl Id" } 
      }else{ resp.error = "modelId is required" } 
      return resp
    },
    async parserVertexModel(model , modelObj = false) {
      let resp = { status: "error", error: false }
      if (model){
        let m = modelObj ? modelObj : {}
        m.displayName                 = model?.displayName
        m.type                        = model?.metadata?.structValue?.fields?.modelType?.stringValue
        m.versionId                   = model?.versionId 
        m.trainingDataItemsCount      = model?.metadata?.structValue?.fields?.trainingDataItemsCount?.stringValue 
        m.trainingAnnotationsCount    = model?.metadata?.structValue?.fields?.trainingAnnotationsCount?.stringValue  
        m.validationDataItemsCount    = model?.metadata?.structValue?.fields?.validationDataItemsCount?.stringValue   
        m.validationAnnotationsCount  = model?.metadata?.structValue?.fields?.validationAnnotationsCount?.stringValue   
        m.eligibleAsBaseModel         = model?.metadata?.structValue?.fields?.eligibleAsBaseModel?.boolValue
        let trainingPipeline          = await $other.httpsCallable('api/model/trainingpipeline/status/' + model.trainingPipeline.replace(/\//g, "--")) 
        let training                  = trainingPipeline?.data?.trainingTaskMetadata?.structValue?.fields
        if(training)m.training        = { 
                                          budgetMilliNodeHours: trainingPipeline?.data?.trainingTaskInputs?.structValue?.fields?.budgetMilliNodeHours.stringValue, 
                                          costMilliNodeHours: training.costMilliNodeHours.stringValue, 
                                          stopReason: training.successfulStopReason.stringValue 
                                        }  
        return m
      }else{ resp.error = "model data is required" } 
      return resp
    },
    async parserVertexMetrics(metrics) {
      let resp = { status: "error", error: false }
      if (metrics){
        let m = {}
        //metrics confidence
        m.auPrc    = metrics?.structValue?.fields?.auPrc?.numberValue
        m.logLoss  = metrics?.structValue?.fields?.logLoss?.numberValue 
        m.boundingBoxMeanAveragePrecision  = metrics?.structValue?.fields?.boundingBoxMeanAveragePrecision?.numberValue 
        m.evaluatedBoundingBoxCount        = metrics?.structValue?.fields?.evaluatedBoundingBoxCount?.numberValue 
        let meItem = metrics?.structValue?.fields?.confidenceMetrics?.listValue.values        
        if(metrics?.structValue?.fields?.boundingBoxMetrics)meItem   = metrics?.structValue?.fields?.boundingBoxMetrics?.listValue.values
        if(meItem){
          let _mt   = { count: meItem.length, metrics: [] /*{}*/ }
          for (let _i   = 0; _i < meItem.length; _i++) {
            let bItem   = meItem[_i]
            let m       = { metricId: _i+1 }
            //let index   = 0
            for (const _b of Object.keys(bItem.structValue.fields)){ 
              let _bitem = bItem.structValue.fields[_b]; 
              m[_b]      = _bitem.numberValue 
              if(_b =='recall' || _b =='precision')m[_b+"Percent"] = (_bitem.numberValue * 100).toFixed(1) 

              //if(_b =='confidenceThreshold')index = _bitem.numberValue

              if(_bitem.listValue){
                let _bitemList = bItem.structValue.fields[_b].listValue.values
                m[_b]          = { count: _bitemList.length, confidence: [] }
                for (const _bl of Object.keys(_bitemList)){ 
                  let _bitemListFields = _bitemList[_bl].structValue.fields; 
                  let v                = {}
                  for (const _bf of Object.keys(_bitemListFields)){ 
                    v[_bf] = _bitemListFields[_bf].numberValue 
                    if(_bf =='recall' || _bf =='precision' || _bf =='f1Score')v[_bf+"Percent"] = (_bitemListFields[_bf].numberValue * 100).toFixed(1) 
                  }
                  m[_b].confidence.push(v)
                }
              }
            }
            //_mt.metrics[index] = m
            _mt.metrics.push(m)
          }
          m.confidenceMetrics = _mt
        }
        //metrics confusion matrix
        let confusionMatrix = metrics?.structValue?.fields?.confusionMatrix?.structValue?.fields 
        if(confusionMatrix){
          let matrix    = {}
          for (const con of Object.keys(confusionMatrix)){  
            matrix[con] = [] 
            let cm      = confusionMatrix[con].listValue.values
            for (let _i  = 0; _i < cm.length; _i++) {
              let m       = false
              if(cm[_i].structValue){
                m       = {}
                let _cm = cm[_i].structValue.fields
                for (const _c of Object.keys(_cm)){ m[_c] = _cm[_c].stringValue }
              }
              if(cm[_i].listValue){
                m       = []
                let _cm =  cm[_i].listValue.values
                for (let _c  = 0; _c < _cm.length; _c++) { m.push(_cm[_c].numberValue) }
              }
              matrix[con].push(m)
            }
          }
          if(matrix.rows){
            matrix.rowsPercent = []
            for (let _cr  = 0; _cr < matrix.rows.length; _cr++) { 
              let countRows  = 0
              let rowVals    = []
              for (let _r = 0; _r < matrix.rows[_cr].length; _r++) { 
                countRows = countRows + matrix.rows[_cr][_r]
                rowVals.push(matrix.rows[_cr][_r])
              }
              let rowAdd = []
              for (let _rv = 0; _rv < rowVals.length; _rv++) { 
                rowAdd.push(Math.round((rowVals[_rv]/countRows) * 100, 2))
              }
              matrix.rowsPercent.push(rowAdd)
            }
          }
          m.confusionMatrix = matrix
        }
        return m
      }else{ resp.error = "metrics are required" }
      return resp
    },
    async renderEvaluations(modelId) {
      let resp = { status: "error", error: false, render: false }
      if (modelId){
        let modelEvaluations = await this.getEvaluations(modelId)
        if (modelEvaluations.evaluations && Object.keys(modelEvaluations.evaluations).length){
          resp.evaluations = modelEvaluations.evaluations
          resp.render      = "<div id='evaluationBox' style='background-color:#1a202c'>"
          for (let _c = 0; _c < resp.evaluations.length; _c++) {
            //Evaluation
            resp.render += "<table style='width: 100%;margin: 0; border: 1px solid #ccc; font-size: 12px;font-weight: 300; background-color: #1a202c'>"
            resp.render += "<tr style='background-color: #ccc; color: #333;'><td style='padding:5px;font-weight: 500; font-size: 14px' colspan='2'>Evaluation</td></tr>"
            if(resp.evaluations[_c].evaluationId)resp.render    += "<tr><td style='padding:5px'>ID</td><td style='padding:5px'>"+resp.evaluations[_c].evaluationId+"</td></tr>"
            if(resp.evaluations[_c].created)resp.render         += "<tr><td style='padding:5px'>Created</td><td style='padding:5px'>"+resp.evaluations[_c].created+"</td></tr>"
            if(resp.evaluations[_c].model.id)resp.render        += "<tr><td style='padding:5px'>Model</td><td style='padding:5px'>"+ resp.evaluations[_c].model.displayName +"</td></tr>"
            if(resp.evaluations[_c].model.versionId)resp.render += "<tr><td style='padding:5px'>Version</td><td style='padding:5px'>"+ resp.evaluations[_c].model.versionId + "</td></tr>"
            resp.render    += "</table>"
            //Training
            resp.render  += "<table style='width: 100%;margin: 0; border: 1px solid #ccc; font-size: 12px;font-weight: 300; background-color: #1a202c'>"
            if(resp.evaluations[_c].model.training){
              resp.render  += "<tr style='background-color: #ccc; color: #333;'><td style='padding:3px 5px;font-weight: 500' colspan='2'>Training</td></tr>"
              if(resp.evaluations[_c].model.training.budgetMilliNodeHours)  
                resp.render  += "<tr><td style='padding:5px; width: 200px'>budget</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.budgetMilliNodeHours + " milliNodeHours" + "</td></tr>"
              if(resp.evaluations[_c].model.training.costMilliNodeHours)  
                resp.render  += "<tr><td style='padding:5px'>cost</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.costMilliNodeHours + " milliNodeHours" + "</td></tr>"
                if(resp.evaluations[_c].model.training.stopReason)    
              resp.render  += "<tr><td style='padding:5px'>stopReason</td><td style='padding:5px'>" + resp.evaluations[_c].model.training.stopReason + "</td></tr>"
            }
            if(resp.evaluations[_c].model){
              let modelItems   = ["trainingDataItemsCount", "trainingAnnotationsCount", "validationDataItemsCount", "validationAnnotationsCount"]
              for (let _m   = 0; _m < modelItems.length; _m++) {
                if(resp.evaluations[_c].model[modelItems[_m]]){
                  resp.render  += "<tr>" + "<td style='padding:5px'>"+modelItems[_m] + "</td>" + "<td style='padding:5px'>"+resp.evaluations[_c].model[modelItems[_m]] + "</td>" + "</tr>"
                }
              }
            }
            if(resp.evaluations[_c].metrics){
              let metrics   = ["auPrc", "logLoss", "boundingBoxMeanAveragePrecision", "evaluatedBoundingBoxCount"]
              for (let _m   = 0; _m < metrics.length; _m++) {
                if(resp.evaluations[_c].metrics[metrics[_m]]){
                  resp.render  += "<tr>" + "<td style='padding:5px'>"+metrics[_m] + "</td>" + "<td style='padding:5px'>" + resp.evaluations[_c].metrics[metrics[_m]] + "</td>" + "</tr>"
                }
              }
            }
            resp.render  += "</table>"
            //confusionMatrix
            if(resp.evaluations[_c]?.slices["ALL"]?.confusionMatrix){
              resp.render  += "<div style='width: 100%; margin: 20px 0 0 0; font-size: 12px; background-color: #ccc; color: #333;padding:3px 5px;font-weight: 500'>Confusion matrix</div>"
              resp.render  += "<table style='width: 100%; max-width: 800px; font-size: 12px;font-weight: 300; background-color: #1a202c; margin: 30px 0;'>"
              resp.render  += "<tr style='border-bottom: 1px solid #ccc;'>"
              resp.render  += "<td style='padding:3px 5px'></td>"
              for (let _cm  = 0; _cm < resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs.length; _cm++) {
                resp.render += "<td style='padding:3px 20px;transform: translateX(-5%) translateY(-180%) rotate(-25deg) !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'>" 
                              + resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs[_cm].displayName 
                              + "</td>"
              }
              resp.render  += "</tr>"
              for (let _cm     = 0; _cm < resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs.length; _cm++) {
                resp.render   += "<tr style='border-bottom: 1px solid #ccc;line-height: 35px'>"
                resp.render   += "<td style='padding: 3px 0 0 10px'>" + resp.evaluations[_c].slices["ALL"].confusionMatrix.annotationSpecs[_cm].displayName + "</td>"
                for (let _r    = 0; _r < resp.evaluations[_c].slices["ALL"].confusionMatrix.rows[_cm].length; _r++) {
                  resp.render += "<td style='padding:3px 5px'>"
                  resp.render += "<div style='padding: 1px 5px; width: 50px; display: inline-block; text-align: center'>"+resp.evaluations[_c].slices["ALL"].confusionMatrix.rows[_cm][_r] + "</div>"
                  resp.render += "<div style='padding: 0 5px; background-color: #b7d3fa; color: #333; display: inline-block; line-height: 25px;'>"+resp.evaluations[_c].slices["ALL"].confusionMatrix.rowsPercent[_cm][_r] + "%</div>"
                  resp.render += "</td>"
                }
                resp.render  += "</tr>"
              }
              resp.render  += "</table>"
            }
            //slices metrics
            if(resp.evaluations[_c].slices){
              resp.render += "<table style='width: 100%; margin: 0; border: 1px solid #ccc; font-size: 12px;font-weight: 300; background-color: #1a202c'>"
              resp.render  += "<tr style='background-color: #ccc; color: #333;'>"
              resp.render  += "<td style='width:140px; padding:3px 5px;font-weight: 500'>Tag</td>"
              if(resp.evaluations[_c].slices["ALL"]["auPrc"])resp.render  += "<td style='width:60px; padding:3px 0'>auPrc</td>"
              if(resp.evaluations[_c].slices["ALL"]["logLoss"])resp.render  += "<td style='width:60px; padding:3px 0'>logLoss</td>"
              if(resp.evaluations[_c].slices["ALL"]["boundingBoxMeanAveragePrecision"])resp.render  += "<td style='width:120px; padding:3px 0'>Bounding Box average precision</td>"
              if(resp.evaluations[_c].slices["ALL"]["evaluatedBoundingBoxCount"])resp.render  += "<td style='width:120px; padding:3px 0'>Evaluated BoundingBox</td>"
              if(!resp.evaluations[_c].typeObjects && resp.evaluations[_c].slices["ALL"]["confidenceMetrics"])resp.render  += "<td style='padding:3px 5px'>Confidence / Precision / Recovery</td>"
              resp.render  += "</tr>"
              for (const _s of Object.keys(resp.evaluations[_c].slices)){  
                resp.render  += "<tr style='border-bottom: 1px solid #ccc;'>"
                resp.render  += "<td style='padding-left: 10px; width:140px;font-weight: 500'>"+_s+"</td>"
                if(resp.evaluations[_c].slices[_s]["auPrc"])resp.render    += "<td style='width:60px'><div style='background-color: #ccc; color: #333; text-align: center; padding: 5px 1px;width: 40px;'>"+resp.evaluations[_c].slices[_s]["auPrc"].toFixed(3)+"</div></td>"
                if(resp.evaluations[_c].slices[_s]["logLoss"])resp.render  += "<td style='width:60px'>"+resp.evaluations[_c].slices[_s]["logLoss"].toFixed(3)+"</td>"
                if(resp.evaluations[_c].slices[_s]["boundingBoxMeanAveragePrecision"])resp.render += "<td style='width:120px; padding: 5px 0'><div style='background-color: #ccc; color: #333; text-align: center; padding: 2px 1px;width: 40px;'>"+resp.evaluations[_c].slices[_s]["boundingBoxMeanAveragePrecision"].toFixed(3)+"</div></td>"
                if(resp.evaluations[_c].slices[_s]["evaluatedBoundingBoxCount"])resp.render  += "<td style='width:120px'>"+resp.evaluations[_c].slices[_s]["evaluatedBoundingBoxCount"]+"</td>"
                if(!resp.evaluations[_c].typeObjects && resp.evaluations[_c].slices[_s].confidenceMetrics){
                  resp.render  += "<td style='padding: 5px 0'>"
                  resp.render  += "<table style='margin: 2px 0; font-size: 11px; line-height: 11px;'>"
                  let confidenceSections = { "0": true, "0.1": true, "0.3": true, "0.6": true, "0.8": true, "0.9": true, }  
                  resp.render  += "<tr style='padding: 0; margin:0'>"
                  let countConfidence = 0
                  for (let _m   = 0; _m < resp.evaluations[_c].slices[_s].confidenceMetrics.metrics.length; _m++) {
                    let _confidence = resp.evaluations[_c].slices[_s].confidenceMetrics.metrics[_m]
                    if(!_confidence.confidenceThreshold)_confidence.confidenceThreshold = 0
                    if(countConfidence==2 || countConfidence==4 || countConfidence==6) resp.render  += "</tr><tr style='padding: 0; margin:0'>"
                    if(confidenceSections[_confidence.confidenceThreshold]){ countConfidence++;
                      resp.render  += "<td style='padding: 0 5px 3px 10px; width: 30px;'><div style='border: 1px solid #ccc; text-align: center; padding: 1px;width: 30px;'>"+_confidence.confidenceThreshold+"</div></td>"
                      resp.render  += "<td style='padding: 0 5px 3px 0; width:50px; text-align: center;" + (_confidence.precision=="1" ? "color: #3CB371" : "" )+ "'>"+_confidence.precisionPercent+"%</td>"
                      resp.render  += "<td style='padding: 0 10px 3px 0; width: 60px; text-align: center;" + (_confidence.recall=="1" ? "color: #3CB371" : "" )+ "'>"+ (_confidence.recallPercent ? _confidence.recallPercent+"%" : "-") +"</td>"
                    }
                  }
                  resp.render  += "</tr>"
                  resp.render  += "</table>"
                  resp.render  += "</td>"
                }
                resp.render  += "</tr>"
              }
              resp.render    += "</table>"
            }
          }
          resp.render     += "</div>"
        }else{ resp.error = "model does not have evaluations" }   
      }else{ resp.error = "modelId is required" } 
      return resp
    }

}
const install = app => { app.config.globalProperties.$models = models; };

export { install as default, models as model };