<template>
  <div></div>
</template>
<script>
import { defineComponent,onMounted, computed, onUnmounted} from "vue";
import cash from "cash-dom";
import { useStore } from "@/store";
import config from '../../etc/rosepetal.json';

const nodeConfig = config.nodeConfig;

export default defineComponent({

  setup() {

    const store            = useStore();   
    const User             = computed(() => store.state.main.User);

    /*const loadCP = (url) => {  
        var auth = "root:2323po23";
        var xhr = new XMLHttpRequest();
        xhr.open("GET", nodeConfig.host+"/cockpitp/cockpit/login", false);
        xhr.setRequestHeader("Authorization", "Basic " + btoa(auth));  
        xhr.send();
        document.getElementById("cockpit").style.display = 'block';
        document.getElementById("cockpit").src=nodeConfig.host+"/cockpitp/cockpit/@localhost"+url+"?uid="+User.value.uid; 
    };*/

    const loadNR = (url) => {  
        document.getElementById("flowFrame").style.display = 'block';
        document.getElementById("flowFrame").src=nodeConfig.host+"/nodered"+url+"?uid="+User.value.uid; 
    };

    onMounted( async () => { 
      cash(".content").addClass("hidden");
      cash("#topBarFlowIframe").css("display", "block");
      cash(".dashboxItem,.side-nav").addClass("hidden");
      cash(".top-bar-node-menu").removeClass("hidden");
      cash(".top-bar-node-menu-side-menu-flow").addClass("text-primary-4 top-bar-node-menu-current");
      if(cash("#flowFrame").attr("src")=="about:blank")loadNR('/');
    });

    onUnmounted( async () => { 
     cash(".content").removeClass("hidden");
     cash("#topBarFlowIframe").css("display", "none");
     cash(".dashboxItem,.side-nav").removeClass("hidden");
     cash(".top-bar-node-menu").addClass("hidden");
     cash(".top-bar-node-menu-current").removeClass("text-primary-4 top-bar-node-menu-current");
    });

  }

});
</script>