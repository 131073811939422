<template>
  <div class="w-full text-gray-900" v-if="layout.dataset">
    <div class="grid chat grid-cols-12" :class="layout.fullScreen ? 'fullScreenMode overflow-y-scroll' : ''">
      <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-20" v-if="layout.loading">
          <img class="w-24 h-auto" :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" />
          <LoadingIcon icon="three-dots" class="w-12 h-12" />
      </div>
      <div class="col-span-12 w-full text-left text-xs" v-else>
        <div class="grid chat grid-cols-12" v-if="layout.fullScreen && props.action!='labeling'">
          <div class="col-span-12 w-full">
            <!-- full screen actions -->
            <div class="input-form p-5">
              <div class="flex justify-left text-xs mt-5 text-center">
                <div class="px-4 py-2 zoom-in border bg-gray-800 text-white cursor-pointer mr-1" @click="back()"><CornerUpLeftIcon class="w-8 h-8 pb-1" /> {{ $t('Back') }}</div>
                <div class="px-4 py-2 zoom-in border bg-gray-300 hover:bg-blue-500 hover:text-white cursor-pointer mr-1" @click="action('copy')" :class="layout?.action=='copy' ? 'bg-blue-500 text-white' : 'text-gray-700'">
                    <CopyIcon class="w-8 h-8 pb-1" /> {{ $t('Create tag') }}
                </div>
              </div>
            </div>
            <!-- /full screen actions -->
          </div>
        </div>
        <div class="col-span-12 pl-5">
          <div :class="layout.center ? 'flex flex-col justify-center items-center' : ''">

            <!-- upload -->
            <div v-if="props.action=='upload'">
              <div class="intro-y flex items-center h-10 my-3">
                <h2 class="text-lg font-medium truncate mr-5">{{$t('Upload images')}}</h2>
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                  <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                </Tippy>
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Dataset labeling" :options="{ theme: 'light'}" @click="goAction('labeling')">
                  <span class="w-5 h-5 flex items-center justify-center"><ImageIcon class="w-6 h-6" /></span>
                </Tippy> 
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                  <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                </Tippy>   
              </div>
              <DropzoneComponent :dataset="layout.dataset.id" ref="DropzoneComponent" class="mt-5" />
            </div>
            <!-- /upload -->

            <!-- models -->
            <div v-if="props.action=='models'">
              <div>
                <div class="intro-y flex items-center h-10 mt-3">
                  <h2 class="text-lg font-medium truncate mr-5">{{$t('Model registration')}}</h2>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                  <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                  </Tippy>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                    <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                  </Tippy>  
                  <button class="btn box bg-blue-500 text-white hover:bg-green-600 px-5 py-3 font-normal ml-5" @click="goAction('train')" v-if="layout.action!='train'">{{$t('TRAIN A NEW MODEL')}}</button>
                </div>
                <div class="mt-5" v-if="layout.dataset?.models?.models?.length">
                  <div v-for="(m) in layout.dataset.models.models" :key="m" class="intro-y" @click="goAction('models')">
                    <div class="box px-2 py-4 mb-3 flex items-center zoom-in">
                      <div class="w-16 h-16 flex-none image-fit rounded-md overflow-hidden">
                        <img :src="require(`@/assets/images/rosepetal/icon/logoLoadingGray.png`)" />
                      </div>
                      <div class="ml-2 mr-auto">
                        <div class="truncate font-medium text-sm">{{m.id.slice(0, 80)}} <span v-if="(m.id.length>80)">...</span></div>
                        <div class="text-gray-600 text-xs mt-0.5">{{ m.created }}</div>
                      </div>
                      <div class="py-1 px-2 rounded-full text-xs bg-theme-10 text-white cursor-pointer font-medium px-5 mr-5">Ready</div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-center justify-center mt-10 text-gray-600"><h2 class="text-sm truncate">{{ $t('This dataset has not been trained') }}</h2></div>
              </div>
            </div>
            <!-- /models -->

            <!-- train -->
            <div v-if="props.action=='train'"><!-- :style="`background-image: url(${require(`@/assets/images/rosepetal/icon/net2.png`)}); background-position: bottom; background-size: contain; background-repeat: no-repeat; height: 100%; height: 80vh;`" -->
              <div>
                <div class="intro-y flex items-center h-10 mt-3">
                  <h2 class="text-lg font-medium truncate mr-5">{{$t('Validate to train')}}</h2>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                  <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                  </Tippy>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Model registration" :options="{ theme: 'light'}" @click="goAction('models')">
                    <span class="w-5 h-5 flex items-center justify-center"><CodesandboxIcon class="w-5 h-5" /></span>
                  </Tippy> 
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                    <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                  </Tippy> 
                </div>
                <div class="mt-2 text-sm text-center font-medium mt-5">
                  <div v-if="layout.dataset?.validation?.validated">{{$t('Now the dataset is ready to be trained')}}.</div> 
                  <div v-else class="mt-5">{{$t('Now the dataset cannot be training, check requirements and warnings for train')}}.</div> 
                </div>
                <div class="tab-content text-xs text-gray-700 mt-5">
                  <div v-if="layout.dataset.validation?.validated">
                    
                    
                    <div class="box text-center p-5 w-80 display-inline"> 
                      <div class="text-normal font-medium text-lg text-center">{{ $t('Supervised') }}</div>
                      <div class="text-center"> <ImageIcon class="w-14 h-14" /><div class="mt-2">{{$t('Supervised')}}</div></div>
                        <div>{{layout.dataset.validation?.trainingImages?.count}} {{$t('images for training')}}</div>
                      <div>
                      </div>
                      <button class="btn box bg-blue-500 text-white hover:bg-green-600 px-5 font-normal mt-5">{{$t('TRAIN A NEW MODEL')}}</button>
                    </div>
                    


                  </div>
                  <div class="mt-5" v-else>
                      <div class="mb-5" v-if="layout.dataset.validation?.errors.length">
                        <div class="mb-2">{{$t('Requirements')}}:</div>
                        <div v-for="(e) in layout.dataset.validation?.errors" :key="e" class="pl-2">{{e}}</div>
                      </div> 
                      <div v-if="layout.dataset.validation?.warnings.length">
                        <div class="mb-2">{{$t('Warnings')}}:</div>
                        <div v-for="(w) in layout.dataset.validation?.warnings" :key="w" class="pl-2">{{w}}</div>
                      </div>  
                  </div>
                </div>
              </div>
            </div>
            <!-- /train -->

            <!-- log -->
            <div v-if="props.action=='log'">  
              <div class="intro-y flex items-center h-10 mt-3">
                <h2 class="text-lg font-medium truncate mr-5">{{$t(' Recent Activities')}}</h2>
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                </Tippy>
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                  <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                </Tippy>
                <span class="ml-auto mt-2 bg-white h-8 pt-2 px-3">
                  <input type="checkbox" class="form-check-input border-gray-700 mr-1" style="vertical-align: top;" v-model="layout.logOptions.preview" @change="load()" /> {{ $t('Previews') }}
                </span>
                <select class="w-20 flex-col form-select p-2 cursor-pointer mt-2 ml-2 text-gray-600" v-model="layout.logLimit" @change="load()" v-if="layout.dataset.log?.log">
                  <option value="5">5</option>
                  <option value="12">12</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select> 
              </div>
              <div class="report-timeline mt-5 relative" v-if="layout.dataset.log?.log">
                <div v-for="(e,i) in layout.dataset.log.log" :key="i">
                  <div class="intro-x text-gray-500 text-xs text-center my-4">
                    {{i}}
                  </div>
                  <div v-if="e?.events?.length">
                    <div class="intro-x relative flex items-center mb-3" v-for="(e) in e.events" :key="e.id">
                      <div class="report-timeline__image">
                        <div class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden text-center" 
                          :class="e?.isCritical ? 'border border-red-900 bg-red-200 text-red-900' : 'bg-white text-gray-500'">
                          <component :is="e?.type?.icon ? e?.type?.icon : 'ActicityIcon'" class="w-6 h-6 mt-2" />
                        </div>
                      </div>
                      <div class="box px-5 py-3 ml-4 flex-1 zoom-in cursor-default">
                        <div class="flex items-center">
                          <div class="font-medium" :class="e?.isCritical ? 'text-red-900' : ''">
                            <span class="mt-1 text-center font-normal text-gray-600 border px-1" v-if="e?.source"><span class="font-medium">{{ e.source.toUpperCase() }}</span></span>
                            {{ e.type?.name ? e.type.name : e.name }} 
                          </div>
                          <div class="text-xs text-gray-500 ml-auto">{{ e.created.toString().split(" ")[1] }}</div>
                        </div>
                        <div class="text-gray-600 mt-1">
                          <div class="bg-red-200 p-2 my-2" v-if="e?.isCritical && e?.payload?.response?.error">
                            <div class="text-slate-500 mb-1 text-gray-900">{{ e.payload.response.error }}</div>
                          </div>
                          <div v-if="e?.payload" class="pt-1 mb-1">
                            <div v-if="e?.payload?.id">{{ $t('Id') }}: <span class="font-medium">{{ e.payload.id }}</span></div>
                            <div v-if="e?.payload?.zip">{{ $t('File') }}: <span class="font-medium">{{ e.payload.zip }}</span></div>
                            <div v-if="e?.payload?.filename">
                                <span v-if="e?.user" class="pr-0.5">{{ e?.user?.displayName }}</span>{{ $t('has downloaded dataset in zip file') }}: <span class="font-medium">{{ e.payload.filename }}</span>
                            </div>
                            <div v-if="e?.payload?.uploadedFiles?.count">
                              <span v-if="e?.user" class="pr-0.5">{{ e?.user?.displayName }}</span>{{ $t('added') }} {{ e.payload.uploadedFiles.count }} {{ $t('new images to dataset for') }} <a class="text-theme-12" @click="goAction('labeling')">{{ $t('Labeling') }}</a>
                            </div>
                            <div v-if="e?.payload?.data?.description"><span v-if="e?.user" class="pr-0.5">{{ e?.user?.displayName }}</span>{{ $t('has changed dataset description') }}.</div>
                            <div v-if="e?.payload?.data?.aws"><span v-if="e?.user" class="pr-0.5">{{ e?.user?.displayName }}</span>{{ $t('has issued an IA storage action') }}.</div>
                            <div v-if="e?.type?.id=='dataset.datadivision.random'">
                              <span v-if="e?.user" class="pr-0.5">{{ e?.user?.displayName }}</span>{{ $t('has generated a random data division') }}<span v-if="e?.payload?.response?.onlyLabeled" class="pl-0.5">{{ $t('for tagged images only') }}</span>.
                              <div v-if="e?.payload?.response?.oldDivision" class="flex w-full pt-1">
                                <span class="pr-1 font-medium">{{ $t('Old') }}:</span> <span v-for="(value, index) in e.payload.response.oldDivision" :key="index" class="pr-1">{{ index }} ({{ value }})</span>
                              </div>
                              <div v-if="e?.payload?.response?.newDivision" class="flex w-full pt-1">
                                <span class="pr-1 font-medium">{{ $t('New') }}:</span> <span v-for="(value, index) in e.payload.response.newDivision" :key="index" class="pr-1">{{ index }} ({{ value }})</span>
                              </div>
                            </div>
                            <div v-if="e?.payload?.status=='success' || e?.payload?.response?.status=='success'" class="flex w-full pt-1">{{ $t('Completed successfully') }}</div>
                            <div v-if="e?.payload?.status=='error' || e?.payload?.response?.status=='error'" class="flex w-full pt-1">{{ $t('Unexpected error has ocurred') }}</div>
                          </div>
                          <div v-if="e?.type?.id=='dataset.create'" class="mb-1">
                            <span v-if="e?.user" class="pr-0.5">{{ e?.user?.displayName }}</span>{{ $t('has created the new dataset') }}. {{ $t("It's starting to take shape!") }}
                          </div>
                          <div v-if="e?.preview" class="flex w-full pt-1">
                            <Tippy tag="div" class="w-8 h-8 image-fit mr-1 zoom-in" :content="img.name" v-for="(img) in e.preview" :key="img">
                              <img class="rounded-md border border-white" :src="img.src" />
                            </Tippy>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
              <div v-else class="text-center flex flex-col justify-center items-center mt-20 text-gray-600">
                <ActivityIcon class="w-20 h-20 mb-5" />
                <h2 class="text-base truncate">{{ $t('No activity has been recorded in this dataset') }}</h2>
              </div> 
            </div>
            <!-- /log -->

            <!-- description -->
            <div v-if="props.action=='description'">
              <div class="intro-y flex items-center h-10 mt-3">
                <h2 class="text-lg font-medium truncate mr-5">{{$t('A brief description of the goal')}}</h2>
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                  <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                </Tippy>
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                  <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                </Tippy> 
                <button class="btn box bg-green-600 text-white hover:bg-green-800 px-5 py-2.5 font-normal ml-2" 
                  @click="confirmQry({ qry: 'Want to continue with the dataset description update?', description: true })">{{$t('SAVE')}}</button>
              </div>
              <div class="mt-2 text-sm mt-8 text-center bg-white p-5 rounded-2">
                <textarea v-model="layout.dataset.description" class="form-control textAreaObs rounded border text-gray-900 p-5 'bg-white" :placeholder="$t('add a description of the dataset goal...')"></textarea>
              </div>
            </div>
            <!-- /description -->

            <!-- Copy -->
            <div v-if="props.action=='copy'" class="w-2/4">
              <div class="text-xl mb-3">{{ $t('Copy dataset') }}</div>
              <div class="intro-y box col-span-12 lg:col-span-6">
                <div class="flex items-center px-5 py-5 sm:py-0 border-b border-gray-200 dark:border-dark-5" >
                  <h2 class="font-medium text-base mr-auto">Work In Progress</h2>
                  <div class="dropdown ml-auto sm:hidden">
                    <a
                      class="dropdown-toggle w-5 h-5 block"
                      href="javascript:;"
                      aria-expanded="false"
                    >
                      <MoreHorizontalIcon
                        class="w-5 h-5 text-gray-600 dark:text-gray-300"
                      />
                    </a>
                    <div class="nav nav-tabs dropdown-menu w-40" role="tablist">
                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                        <a
                          id="work-in-progress-new-tab"
                          href="javascript:;"
                          data-toggle="tab"
                          data-target="#work-in-progress-new"
                          class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          role="tab"
                          aria-controls="work-in-progress-new"
                          aria-selected="true"
                          >New</a
                        >
                        <a
                          id="work-in-progress-last-week-tab"
                          href="javascript:;"
                          data-toggle="tab"
                          data-target="#work-in-progress-last-week"
                          class="block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                          role="tab"
                          aria-selected="false"
                          >Last Week</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="nav nav-tabs ml-auto hidden sm:flex" role="tablist">
                    <a
                      id="work-in-progress-mobile-new-tab"
                      data-toggle="tab"
                      data-target="#work-in-progress-new"
                      href="javascript:;"
                      class="py-5 ml-6 active"
                      role="tab"
                      aria-selected="true"
                      >New</a
                    >
                    <a
                      id="week-work-in-progress-mobile-last-week-tab"
                      data-toggle="tab"
                      data-target="#work-in-progress-last-week"
                      href="javascript:;"
                      class="py-5 ml-6"
                      role="tab"
                      aria-selected="false"
                      >Last Week</a
                    >
                  </div>
                </div>
                <div class="p-5">
                  <div class="tab-content">
                    <div
                      id="work-in-progress-new"
                      class="tab-pane active"
                      role="tabpanel"
                      aria-labelledby="work-in-progress-new-tab"
                    >
                      <div>
                        <div class="flex">
                          <div class="mr-auto">Pending Tasks</div>
                          <div>20%</div>
                        </div>
                        <div class="progress h-1 mt-2">
                          <div
                            class="progress-bar w-1/2 bg-theme-17"
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="mt-5">
                        <div class="flex">
                          <div class="mr-auto">Completed Tasks</div>
                          <div>2 / 20</div>
                        </div>
                        <div class="progress h-1 mt-2">
                          <div
                            class="progress-bar w-1/4 bg-theme-17"
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="mt-5">
                        <div class="flex">
                          <div class="mr-auto">Tasks In Progress</div>
                          <div>42</div>
                        </div>
                        <div class="progress h-1 mt-2">
                          <div
                            class="progress-bar w-3/4 bg-theme-17"
                            role="progressbar"
                            aria-valuenow="0"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <a href="" class="btn btn-secondary block w-40 mx-auto mt-5"
                        >View More Details</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
             <!-- /Copy -->

            <!-- tags -->
            <div v-if="props.action=='tags' && layout.dataset.id" class="w-full">
              <div>
                <div class="intro-y flex items-center h-10 mt-3">
                  <h2 class="text-lg font-medium truncate mr-5">{{$t('Classification Tags')}}</h2>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                    <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                  </Tippy>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                    <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                  </Tippy> 
                </div>
                <div class="mt-2 text-sm"><TagsComponent :dataset="layout.dataset.id" ref="TagsComponent" /></div>
              </div>
            </div>
            <!-- /tags -->

            <!-- labeling -->
            <div v-if="props.action=='labeling' && layout.dataset.id">
              <LabelingComponent :dataset="layout.dataset.id" ref="LabelingComponent" />
            </div>
            <!-- /labeling -->
            
            <!-- download -->
            <div v-if="props.action=='download'">
              <div class="px-1 w-1/2">
                <div class="intro-y flex items-center h-10 mt-3">
                  <h2 class="text-lg font-medium truncate mr-5">{{$t('Download dataset')}}</h2>
                  <button class="btn px-2 box text-gray-700 ml-1 hover:bg-blue-500 hover:text-white" @click="back()">
                    <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                  </button>
                  <button class="btn px-2 box text-gray-700 ml-1 hover:bg-blue-500 hover:text-white" @click="reload()">
                    <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                  </button>
                </div>
                <div class="mt-2 text-sm mt-10 text-center">
                  {{$t('Descarga')}}...
                </div>
              </div>
            </div>
            <!-- /download -->

            <!-- random division -->
            <div v-if="props.action=='random-data-division'">
              <div>
                <div class="intro-y flex items-center h-10 mt-3">
                  <h2 class="text-lg font-medium truncate mr-5">{{$t('Data division')}}</h2>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Back" :options="{ theme: 'light'}" @click="back()">
                    <span class="w-5 h-5 flex items-center justify-center"><CornerUpLeftIcon class="w-4 h-4" /></span>
                  </Tippy>
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Dataset labeling" :options="{ theme: 'light'}" @click="goAction('labeling')">
                    <span class="w-5 h-5 flex items-center justify-center"><ImageIcon class="w-6 h-6" /></span>
                  </Tippy> 
                  <Tippy tag="a" href="javascript:;" class="tooltip btn px-2 box ml-1 text-gray-700 hover:bg-blue-500 hover:text-white py-2" content="Refresh" :options="{ theme: 'light'}" @click="reload()">
                    <span class="w-5 h-5 flex items-center justify-center"><RefreshCcwIcon class="w-4 h-4" /></span>
                  </Tippy> 
                  <button class="btn box bg-blue-500 text-white hover:bg-blue-800 px-5 py-2.5 font-normal ml-2" 
                  @click="goAction('train')">{{$t('VALIDATE TO TRAIN')}}</button>
                </div>
                <div class="mt-2 text-xs mt-5 text-left">
                  {{$t('The division of data in a set of images refers to the separation of that set of images into different subsets in order to use one of them to train an artificial intelligence model')}}.
                  {{$t('This is done to avoid overfitting, which is when a model tends to fit the training data too well and is unable to generalize well to new or unknown data')}}.
                  <div class="mt-5">
                    <div><span class="mx-1">•</span> {{$t('The training set teaches the model to recognize patterns and features in the images')}}.</div>
                    <div><span class="mx-1">•</span> {{$t('The validation set is used to evaluate the model during training and to fine-tune the model')}}.</div>  
                    <div><span class="mx-1">•</span> {{$t('The test set is used to evaluate the model after training and to determine how well the model will generalize to new data')}}.</div>
                  </div>
                  <div class="intro-y grid grid-cols-12 gap-6 mt-8">
                    <div class="intro-y col-span-4">
                      <div class="text-base font-medium">{{$t('Current dataset data division')}}</div>
                      <div v-if="layout.dataset?.validation" class="p-5">
                        <div class="relative mb-3" v-if="layout.dataset.validation?.division?.chart && (layout.dataset.dataDivision.total!=layout.dataset.dataDivision.predetermined)">
                          <ReportPieChart :height="200" :data="layout.dataset.validation?.division?.chart" /> 
                        </div>
                        <div class="mt-2 bg-white rounded px-5 py-3 mt-2">
                          <div class="flex items-center mt-1" v-for="(d,i) in layout.dataset.validation?.division?.percentages" :key="d">
                            <div class="w-4 h-4 rounded-full mr-3" :style="'background-color: '+this.helper.StringtoHex(i)"></div>
                            <span class="truncate text-sm">{{i}} <span v-if="layout.dataset?.dataDivision[i]" class="text-xs"> ({{layout.dataset.dataDivision[i]}})</span></span>
                            <div class="h-px flex-1 border border-r border-dashed border-gray-300 mx-3 xl:hidden"></div>
                            <span class="font-medium xl:ml-auto">
                              <span>{{d}}%</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="intro-y col-span-12 lg:col-span-12 flex flex-col items-center mt-10"><LoadingIcon icon="three-dots" class="w-12 h-12 mt-5" /></div>
                      </div>
                    </div>  
                    <div class="intro-y col-span-4">
                      <div class="box rounded p-5 border border-gray-300 text-xs h-60">
                        <div class="text-base text-medium mb-2">{{$t('Manual')}} <span class="text-green-600 pl-1 text-xs">{{$t('recommended')}}</span></div>
                        <div class="mt-3 h-24">{{$t('Manually assigning sets (test, validation or train) of images for training an AI model is the process of separating the total set of images into different groups called sets for use at different stages of the training process')}}.</div>
                        <button class="btn box bg-green-600 text-white hover:bg-green-800 px-5 py-2.5 font-normal mt-5" @click="goAction('labeling')">{{$t('LABELING')}}</button>
                      </div>
                    </div>
                    <div class="intro-y col-span-4">
                      <div class="box rounded p-5 border border-gray-300 text-xs h-60">
                        <div class="text-base text-medium mb-2">{{$t('Random')}}</div>
                        <div class="mt-3 h-24">{{$t('Random assignment is used to evaluate the performance of a machine learning model and helps prevent overfitting of the model and ensures that the model generalizes well to new and unknown data')}}.</div>
                        <button class="btn box bg-green-600 text-white hover:bg-green-800 px-5 py-2.5 font-normal mt-5" 
                          @click="confirmQry({ qry: 'Are you sure you want to apply a random data allocation to the dataset?', randomDivision: true })">
                          {{$t('RANDOM DIVISION')}}
                        </button>
                      </div>
                    </div>  
                  </div>
                </div>
              </div>
            </div>
            <!-- /random division -->
            
            <!-- Confirm -->
            <div class="rpoverlayDialog" v-if="layout.confirm"></div>
            <div id="rpDialogBox" v-if="layout.confirm">
              <div class="box">
                <div class="px-6 py-8 pb-3">
                    <div class="text-normal font-MEDIUM text-lg text-center">{{ layout.confirm.qry }}</div>
                    <div class="flex flex-col sm:flex-row mt-2 p-5 items-center justify-center text-xs">
                      <button class="btn bg-gray-100 w-1/3 mr-2 py-3 bg-white" @click="layout.confirm=false">{{$t('CANCEL')}}</button>
                      <button class="bg-green-600 text-white w-1/3 py-3 rounded font-normal hover:bg-green-800" @click="save(layout.confirm)">{{$t('CONFIRM')}}</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Confirm -->

          </div>
        </div>
      </div>  
    </div>
  </div>
</template>
<script>

import { defineComponent, onMounted, ref, watch} from "vue"; 
import * as rosepetalModel from "rosepetal-model";
import DropzoneComponent  from "@/components/dropzone/Main.vue";
import TagsComponent  from "@/components/dataset-tags/Main.vue";
import LabelingComponent  from "@/components/dataset-labeling/Main.vue";
import ReportPieChart from "@/components/report-pie-chart/Main.vue";

export default defineComponent({
  props: {
      dataset: {
          type: String,
          required: true
      },
      action: {
          type: String,
          required: true
      },
      options: {
          type: Object,
          required: false
      },
      timestamp: {
          type: Object,
          required: false
      }
  },
  components: {
    DropzoneComponent,
    TagsComponent,
    LabelingComponent,
    ReportPieChart
  },
  created () {
    this.helper = rosepetalModel.helper,
    this.image  = rosepetalModel.image
  },
  setup(props) {

      const layout     = ref({
                           dataset:     false,
                           loading:     true,
                           fullScreen:  false,
                           logLimit:    5,
                           confirm:     false,
                           preview:     {},
                           logOptions:  { preview: false, byDate: true },
                          });

      const fullScreen = async (mode) => { layout.value.fullScreen = mode; }

      const destroy    = async () => { layout.value.dataset = false; fullScreen(false); }

      const load       = async () => { 
        layout.value.loading = true;  
        layout.value.center  = layout.value.confirm = false;
        layout.value.dataset = await rosepetalModel.dataset.get(props.dataset, { preview: true, ref: true })
        switch(props.action){
          case 'description': /*await rosepetalModel.dataset.update(props.dataset,{ description: props.options.description });*/ break
          case 'download': /*fullScreen(true); layout.value.dataset.download = await rosepetalModel.dataset.downloadZip(layout.value.dataset.id); */break
          case 'models':   
            layout.value.dataset.models   = await rosepetalModel.dataset.getModels(props.dataset)
            break
          case 'log': 
            layout.value.dataset.log      = await rosepetalModel.dataset.getLastLog(props.dataset, layout.value?.logLimit, layout.value?.logOptions)
            break 
          case 'labeling': 
            fullScreen(true);  
            layout.value.center           = true
            break 
          case 'train':
            layout.value.dataset.validation    = await rosepetalModel.dataset.validateToTrain(props.dataset)
            break
          case 'random-data-division':
            layout.value.dataset.validation    = await rosepetalModel.dataset.validateToTrain(props.dataset)
            layout.value.dataset.dataDivision  = await rosepetalModel.dataset.getDataDivision(props.dataset, true)
            break
          default: break
        }
        layout.value.loading = false
      }

      const reload = async () => { 
        if(props.action)await load()
      }

      const confirmQry = async (opt) => { layout.value.confirm = opt }

      const save   = async (opt = false) => { 
        layout.value.loading = true
        if(opt.description)await rosepetalModel.dataset.update(props.dataset, { description: layout.value.dataset.description })
        if(opt.randomDivision){
          await rosepetalModel.dataset.setRandDataDivision(props.dataset)
          await reload()
        }
        layout.value.confirm = false;
      }

      onMounted( async () => {
        if(props.action)await load()
      });

      watch(() => props.timestamp, async () => { await load() })

      return {
          layout,
          props,
          fullScreen,
          load,
          reload,
          confirmQry,
          save,
          destroy,
      };
  },
  methods: {
    back: async function () { this.$parent.layout.action = false },
    goDatasets: async function () { this.$parent.back() },
    goAction: async function (a) { this.$parent.action(a) },
  }
});
</script>
<style>
.fullScreenMode{ position: fixed; top: 0; left: 70px; height: 100%; background-color: #f3f3f3; width: calc( 100% - 70px ) !important; z-index: 999 !important;}
@media screen and (max-width:767px) { .fullScreenMode{ left: 0; width: 100% !important; } }
.textAreaObs{ width: 100%;height: 250px;resize: none; padding: 10px; font-size: 13px; line-height: 16px; margin:0 }
.txtMinSize{ font-size: 10px !important}
.table-rp-tags td{ padding: 3px 5px !important;}
.rp-box-log{ background-color: #f9f9fb }
.labelingBox{  position: fixed; top: 0; left: 0; min-height: 100%; width: 100% !important; z-index: 9999 !important; }
</style>