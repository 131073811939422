import { _firebase as $_firebase } from "@/model/firebase";
import { helper as $h } from "@/utils/helper";
import { dataset as $dataset } from "@/model/dataset";
import config from '../etc/rosepetal.json';
import { event as $event } from "@/model/events";

const functions = $_firebase.firebase().app().functions(config.firebaseRegion);

const models = {
  async get(){
      let models = []
      const action = functions.httpsCallable('api/model/list');
      await action({}).then( async response => { 
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i]) { 
            let m = response.data[i]
            if (m.length) {
              for (let x = 0; x < m.length; x++) {
                if (m[x]) {
                  let modelPipeline = await $dataset.getTrainingPipeline(m[x].trainingPipeline)
                  if (modelPipeline.data && modelPipeline.data.inputDataConfig.datasetId) m[x].dataset = await this.getDataset(modelPipeline.data.inputDataConfig.datasetId)
                  models.push(m[x]) 
                } 
              }
            }
          }
        }
      }).catch( async (error) => {  console.log(error) });
      return models;
  },
  async update(modelID, data, msg = false) {
    if (modelID){
      await $_firebase.firestore().collection("model").doc(modelID).update(data).then(function () { if (msg) $h.Notification(msg) });
      await $event.saveEvent('model.update', JSON.stringify(data), false)
    }
  },
  async getEvaluation(modelID){
    let evaluation = []
    const action = functions.httpsCallable('api/model/evaluation/model_id/'+modelID);
    await action({}).then( async response => {  
      if(response.data){
        for (let i = 0; i < response.data.length; i++) {
           let e = response.data[i];
           evaluation.push(e) 
        }
      }  
    }).catch( async (error) => {  console.log(error) });
    if(evaluation.length)evaluation = $h.sortObject(evaluation,'displayName') 
    return evaluation
  },
  async getEvaluationItems(data){
    let items = {
      truePositive:  [],
      falseNegative: [],
      falsePositive: []
    }
    let images  = $_firebase.firestore().collection('image')
    let tagRef  = await $_firebase.firestore().collection("dataset").doc(data.dataset.toString())
    if(data.tag.displayName)tagRef = await tagRef.collection('tag').doc(data.tag.displayName.toString())
    images = images.where('tag','==',tagRef)
    images = images.orderBy("date", "desc")
    let snap  = await images.get()

    snap.forEach(async (doc) => {
        let p  = doc.data()
        if(p.prediction && p.testTag){
          p.id              = doc.id
          p.tag             = p.tag.path
          p.tagName         = p.tag.toString().split('/')
          p.fileName        = p.name.toString().split('/')
          p.testTag         = p.testTag.path
          p.testTagName     = p.testTag.toString().split('/')
          p.img_base64_val  = p.imageData && p.imageData._delegate._byteString.binaryString ? btoa(p.imageData._delegate._byteString.binaryString) : null
          p.scores          = {}
          if(p.prediction.scores && p.prediction.labels){
            for (const [key, item] of Object.entries(p.prediction.labels)) {
              p.scores[item] = p.prediction.scores[key]
              if(p.testTagName[3] && item==p.testTagName[3])p.testTagScore = p.prediction.scores[key]
            }
          }
          if(p.tag==p.testTag){
            p.type = 'truePositive'
            if(p.testTagScore && p.testTagScore>=data.confidenceLimit)items.truePositive.push(p)
          }else{
            p.type = 'falseNegative'
            if(p.testTagScore && p.testTagScore<=data.confidenceLimit)items.falseNegative.push(p)
          }
        }
      });

      //get falsePositive
      images  = $_firebase.firestore().collection('image')
      tagRef  = await $_firebase.firestore().collection("dataset").doc(data.dataset.toString())
      if(data.tag.displayName)tagRef = await tagRef.collection('tag').doc(data.tag.displayName.toString())
      images = images.where('testTag','==',tagRef)
      images = images.orderBy("date", "desc")
      snap   = await images.get()
      snap.forEach(async (doc) => {
        let p  = doc.data()
        if(p.prediction && p.testTag){
          p.id              = doc.id
          p.tag             = p.tag.path
          p.tagName         = p.tag.toString().split('/')
          p.fileName        = p.name.toString().split('/')
          p.testTag         = p.testTag.path
          p.testTagName     = p.testTag.toString().split('/')
          p.img_base64_val  = p.imageData && p.imageData._delegate._byteString.binaryString ? btoa(p.imageData._delegate._byteString.binaryString) : null
          p.scores          = {}
          if(p.prediction.scores && p.prediction.labels){
            for (const [key, item] of Object.entries(p.prediction.labels)) {
              p.scores[item] = p.prediction.scores[key]
              if(p.testTagName[3] && item==p.testTagName[3])p.testTagScore = p.prediction.scores[key]
            }
          }
          if(p.tag!=p.testTag){ p.type = 'falsePositive'; if(p.testTagScore && p.testTagScore>=data.confidenceLimit)items.falsePositive.push(p); }
        }
      });

    return items;
  },
  async getEvaluationDatamatrix(modelEvaluation){
    let datamatrix = null
    for (let i = 0; i < modelEvaluation.length; i++) {
     if(modelEvaluation[i].metrics && modelEvaluation[i].metrics.confusionMatrix){
       let dm = modelEvaluation[i].metrics.confusionMatrix
       if(dm.row){
        let rowsCount = [] 
        dm.row.forEach (function(num){
           let count = 0
           for (let n = 0; n < num.exampleCount.length; n++) { count += num.exampleCount[n];  }
           rowsCount.push(count)
        });
        dm.rowsCount = rowsCount;
        datamatrix = dm;
       }
     }
    } 
    return datamatrix
  },
  async getDataset(datasetID=false){
    let datasets = []
    let ApiCall = 'api/model/datasets'
    if(datasetID)ApiCall += '/dataset_id/'+datasetID
    const action = functions.httpsCallable(ApiCall);
    await action({}).then( response => { datasets = response.data  }).catch( async (error) => {  console.log(error) });
    if(datasetID && datasets[0])return datasets[0];
    return datasets
  },
  async addDataset(displayName,type){
    let dataset = []
    const action = functions.httpsCallable('api/model/create/dataset/'+displayName+'-'+type);
    await action({}).then( response => { dataset = response.data  }).catch( async (error) => {  console.log(error) });
    return dataset;
  },
  /*
  async importDatasetCsv(CsvPath,datasetID){
   let importcsv = []
   let ApiCall = 'api/model/import/dataset/'+datasetID
   if(CsvPath)ApiCall += '-|-'+CsvPath.replace(/\//g,"!!-")
   const action = functions.httpsCallable(ApiCall)
   await action({}).then( response => { importcsv = response.data  }).catch( async (error) => {  console.log(error) });
   return importcsv;
  },
  */
  async generateDataset(){
    let csv = []
    let ApiCall = 'api/model/training/dataset'
    const action = functions.httpsCallable(ApiCall)
    await action({}).then( response => { csv = response.data }).catch( async (error) => {  console.log(error) });
    return csv;
  },
  /*async trainingDataset(datasetID,ModelName,trainBudget){
    let modelData   = { 
                        automl: '', 
                        dataset: await $_firebase.firestore().collection("dataset").doc(datasetID.toString()), 
                        description: "", 
                        savedModel: "", 
                        status: "undeployed", 
                        trainBudget: trainBudget, 
                        createdAt: $_firebase.firebase().firestore.FieldValue.serverTimestamp(), 
                      }
    await $_firebase.firestore().collection("model").doc(ModelName).set(modelData)
        .then(async function(docRef) { 
            modelData.modelID = docRef.id
            //const action = functions.httpsCallable('api/model/create/model/'+datasetID+'-'+ModelName+'-'+trainBudget);
            //await action({}).then( response => { model = response.data  }).catch( async (error) => {  console.log(error) });
          })
        .catch(function(error) { console.error("Error adding dataset: ", error);  });
        
    return modelData;
  }*/
};

const install = app => {
  app.config.globalProperties.$models = models;
};

export { install as default, models as model };
