<template>
  <div class="w-full text-gray-900 " v-if="layout.dataset">
    <div class="grid chat grid-cols-12 gap-6">
      <div class="intro-y col-span-12 flex flex-col items-center mt-5" v-if="layout.loading">
        <LoadingIcon icon="three-dots" class="w-12 h-12" />
      </div>
      <div class="col-span-12" v-else>
        <div class="grid grid-cols-12 gap-0">
          <div class="col-span-12" v-if="layout.dataset">
            <div class="text-left float-right">
               <!-- 
              <div class="text-lg font-medium" v-if="layout.dataset.tagsCounter">
                {{$t('Tags')}}
                <span v-if="layout.dataset.tagsCounter.tags && Object.keys(layout.dataset.tagsCounter.tags).length" class="pl-1">({{Object.keys(layout.dataset.tagsCounter.tags).length}})</span>
                <span v-if="layout.dataset.tagsCounter.tagslabeled && Object.keys(layout.dataset.tagsCounter.tagslabeled).length" class="pl-1">({{Object.keys(layout.dataset.tagsCounter.tagslabeled).length}})</span>
                
                <Tippy tag="a" href="javascript:;" class="tooltip btn px-1 box text-white bg-green-500 py-1 ml-2 align-middle cursor-pointer" content="Create a new tag" :options="{ theme: 'light'}" 
                  @click="layout.create='normal'">
                  <span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-4 h-4" /></span>
                </Tippy>
              </div>
              -->
            </div>
          </div>
          <div class="col-span-12">
            <div class="flex flex-col">
              <div class="box pb-3 shadow-none">
                <div class="flex">
                  <button type="button" class="btn border bg-green-600 text-white py-1 px-2 zoom-in font-normal float-right ml-auto mr-2" @click="layout.create=true">
                    <span class="w-5 h-5 flex items-center justify-center"><PlusIcon class="w-5 h-5" /></span>
                  </button> 
                </div>
              </div>    
            </div>
            <div id="tabulator" ref="tableRef" class="table-report table-report--tabulator bg-white tabulatorTags"></div>
          </div> 
        </div>
      </div>
    </div>

    <div class="rpoverlayDialog" v-if="layout.edit || layout.create"></div>

    <!-- Edit -->
    <div id="rpDialogBox" v-if="layout.edit">
      <div class="box">
        <div class="p-8 pb-0">
          <div class="intro-y flex items-center h-6">
            <h2 class="text-xl font-medium truncate">{{ $t('Edit Tag') }}</h2>
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" @click="layout.edit=false"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
          </div>
          <div class="mt-5">
            <div class="font-medium text-sm">{{ $t('Name') }} *</div>
            <input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700 mt-2" :disabled="layout.item.id=='0'"
              :placeholder="'Tag name'" v-model="layout.item.name" required />
          </div>
          <div class="mt-5">
            <div class="font-medium text-sm flex">
              {{ $t('RGB Color') }} *
              <div class="text-xs w-5 h-5 rounded-full px-2 py-1 text-center text-gray-800 ml-auto" 
              :style="'background-color: ' + (layout.item.color && layout.item.color.hex ? layout.item.color.hex : layout.item.color ? layout.item.color : '') + ' !important'"></div>
              <span class="text-xs pl-2 pt-0.5 text-gray-500">{{layout.item.color && layout.item.color.hex ? layout.item.color.hex : layout.item.color ? layout.item.color : ''}}</span>
            </div>
            <input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700 hidden" 
                   :value="layout.item.color && layout.item.color.hex ? layout.item.color.hex : layout.item.color ? layout.item.color : ''" />
            <div v-if="layout.item.color" class="w-full sliderBox mt-5">
              <Slider v-model="layout.item.color" class="mt-3 sliderColor" :size="'small'" />
            </div>
          </div>
          <div class="mt-5">
            <div class="font-medium text-sm">{{ $t('A brief description') }}</div>
            <textarea class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700 h-20 mt-2" 
              :placeholder="'Description'" v-model="layout.item.description" required></textarea>
          </div>
          <div class="mt-3 text-xs text-gray-700" v-if="(layout.item.imageCounter && layout.item.imageCounter>0) && layout.item.id!='0'">
            <div v-if="layout.dataset?.type && layout.dataset?.type=='MULTICLASS'">{{ $t('Has') }} <span class="font-medium" v-if="layout.item.imageCounter">{{layout.item.imageCounter}} {{ $t('images') }}</span> {{ $t('tagged as') }} <span class="font-medium">{{ layout.item.name.toUpperCase() }}</span>.</div>
            <div v-else>{{ $t('Has') }} {{ $t('images tagged as') }} <span class="font-medium">{{ layout.item.name.toUpperCase() }}</span>.</div>
            <div class="mt-1">{{ $t('To remove this tag from the dataset you must unlink  or remove all associated images') }}.</div>
          </div>
          <div class="text-xs mt-3 text-gray-600 font-italic" v-if="layout.item.created"><ClockIcon class="w-4 h-4 align-top" /> {{ $t('Created on') }} {{layout.item.created}}</div>
          <div class="flex flex-col sm:flex-row p-5 pt-0 items-center justify-center mt-3">
            <button class="bg-red-500 text-white w-24 py-3 rounded font-normal mr-2" v-if="(!layout.item.imageCounter || layout.item.imageCounter<0) && layout.item.id!='0'" @click="deleteTag()">{{$t('Delete')}}</button>
            <button class="bg-green-600 text-white py-3 px-14 text-center rounded font-normal" @click="save()">{{$t('Save')}}</button>
          </div>
        </div>
        <!--
        <div id="mosaicTag" v-if="layout.item?.imageCounter && layout.item.imageCounter>0 && Object.keys(layout.editor.options).length">
          <imageEditor :options="layout.editor.options" ref="imageEditor" />
        </div>
        --> 
      </div>
    </div>
    <!-- /Edit --> 

    <!-- Create -->
    <div id="rpDialogBox" v-if="layout.create">
      <div class="box">
        <div class="px-6 py-8 pb-3">
          <div class="intro-y flex items-center h-6">
            <h2 class="text-xl font-medium truncate">{{ $t('Create Tag') }}</h2>
            <label class="form-label float-right ml-auto :hover:text-blue-500 cursor-pointer pt-2 pr-1" @click="layout.create=false"><XIcon class="w-8 h-8 text-gray-600 hover:text-gray-900" /></label>
          </div>
          <div class="mt-5"><input type="text" class="form-control py-3 px-4 border-transparent pr-10 bg-gray-200 text-gray-700" :placeholder="'Tag name'" v-model="layout.newTagName" required /></div>
          <div class="flex flex-col sm:flex-row mt-2 p-5 items-center justify-center">
            <button class="btn bg-gray-100 w-1/3 mr-2 py-3 bg-white" @click="layout.create=false">{{$t('Cancel')}}</button>
            <button class="bg-green-600 text-white w-1/3 py-3 rounded font-normal" @click="create()">{{$t('Create')}}</button>
          </div>
        </div>
      </div>
    </div>
    <!-- /Create -->  

  </div>
</template>
<script>

import { defineComponent, onMounted, ref, watch} from "vue"; 
import { dataset as $dataset } from "@/model/db/dataset";
import { helper as $h } from "@/model/db/helper";
import { table as $table } from "@/model/db/table";
import { Slider } from '@ckpack/vue-color';
import cash from "cash-dom";
import * as rosepetalModel from "rosepetal-model";
//import imageEditor  from "@/components/manage-image/Main.vue";

export default defineComponent({
  props: {
      dataset: {
          type: String,
          required: true
      },
  },
  components: { Slider,  }, //imageEditor
  created () {
    this.helper = $h
  },
  setup(props) {

    const tableRef = ref()
    const layout = ref({
          dataset:     false,
          loading:     true,
          tag:         false,
          tabulator:   false,
          create:      false,
          edit:        false,
          item:        {},
          newTagName:  '',
          editor:  { options: {} }
    });

    const table = async () => { 
      layout.value.tableCol   = [{ formatter: "responsiveCollapse", width: 40, minWidth: 30, hozAlign: "left", resizable: false, headerSort: false, noDataMessage: "Custom message"  } ]
      let tableData           = [] 
      if(layout.value.dataset.tagsCounter?.tags)
        for(let idx in layout.value.dataset.tagsCounter.tags)
          tableData.push({  
                          id:           idx, 
                          name:         layout.value.dataset.tagsCounter.names[idx], 
                          images:       layout.value.dataset.tagsCounter.tags[idx], 
                          color:        layout.value.dataset.tagsCounter.colors[idx], 
                        })  
      if(layout.value.dataset.tagsCounter?.tagslabeled)
        for(let idx in layout.value.dataset.tagsCounter.tagslabeled)
          tableData.push({  
                          id:           idx, 
                          name:         layout.value.dataset.tagsCounter.names[idx], 
                          annotations:  layout.value.dataset.tagsCounter.tagslabeled[idx], 
                          color:        layout.value.dataset.tagsCounter.colors[idx], 
                        })     
      if(tableData.length)
        for(let d in (tableData[0])){ 
            layout.value.tableCol.push({ 
              title:    d, 
              field:    d, 
              formatter(cell) { 
                let a = []
                if(d=="color")a = cash( `<div class="text-xs w-6 h-6 rounded-full px-2 mx-10 text-center text-gray-800 py-0" style="background-color: ${cell.getData().color} !important"></div>`);
                else if(d=="name")a = cash( `<div class="text-xs text-gray-600 text-xs whitespace-nowrap">${cell.getData()[d]==0 ? 'Unclassified' : cell.getData()[d].toUpperCase()}</div>`)
                else if(d=="images" || d=='annotations')a = cash( `<div class="text-gray-600 text-xs whitespace-nowrap text-center text-xs">${cell.getData()[d]}</div>`)
                else if(d=="id")a = cash( `<div class="text-gray-500 text-xs whitespace-nowrap text-left text-xs">${cell.getData()[d]}</div>`)
                else a= cash( `<div class="text-sm text-gray-700 text-xs whitespace-nowrap">${cell.getData()[d]}</div>`)
                cash(a).on("click", function() { layout.value.edit=cell.getData().id;   })
                return a[0]
              }
            })
        }        
      layout.value.tabulator  = await $table.init(tableRef.value, layout.value.tableCol, { placeholder: "No tags have been registered" , paginationSize: 5, pagination: tableData.length>5 ? 'local' : false})
      await $table.setData(layout.value.tabulator, tableData)
    }

    const create = async () => { 
      layout.value.create  = false
      layout.value.loading = true;  
      if(layout.value.newTagName && layout.value.newTagName!="0")
        await rosepetalModel.dataset.createTag(layout.value.dataset.id, { tag: layout.value.newTagName.toString().replace(/\//g, "_"), name: layout.value.newTagName.toString(), unclassified: false })
        layout.value.newTagName = ''     
      await reload()    
    }  
    
    const load = async () => { 
      layout.value.loading = true; 
      layout.value.center  = false;
      layout.value.dataset = await $dataset.get(props.dataset)
      layout.value.dataset.tagsCounter  = await rosepetalModel.dataset.getTagsCounter(props.dataset, true) //{ dataset: layout.value.dataset }
      layout.value.loading = false
    }

    const deleteTag = async () => { 
      layout.value.edit    = false
      layout.value.loading = true; 
      if(layout.value.item)await rosepetalModel.dataset.deleteTag({ dataset: layout.value.dataset.id, id: layout.value.item.id })
      await reload() 
    }

    const save = async () => { 
      layout.value.edit    = false
      layout.value.loading = true;  
      if(layout.value.item)await rosepetalModel.dataset.updateTag({ id: layout.value.item.id, dataset: layout.value.dataset.id, data: { color: layout.value.item.color.hex ? layout.value.item.color.hex : layout.value.item.color, name: layout.value.item.name, description: layout.value.item.description ? layout.value.item.description : '' }})
      await reload()
    }
    
    const reload = async () => { 
      if(props.dataset){
        await load()
        await table()
      }
    }

    onMounted( async () => {
      console.log('mounted tags',props)
      await reload()
    });

    watch(() => props.dataset, async () => { await load() })

    watch(() => layout.value.edit, async () => { 
      if(layout.value.edit)layout.value.item = await $dataset.getTag(props.dataset,layout.value.edit)
      else layout.value.item = {}
      layout.value.editor.options = { 'format': 'mosaicTag', 'dataset': props.dataset, 'limit': 5 }
      if(layout.value.item.id)layout.value.editor.options.tagId = layout.value.item.id
    })

    return {
        layout,
        props,
        tableRef,
        load,
        reload,
        create,
        deleteTag,
        save
    };
  }
});
</script>
<style>
.table-rp-tags td{ padding: 3px 5px !important;}
.tabulatorTags{ width: 98%; margin: 0 2%;}
.tabulatorTags .tabulator-header{ border-top-left-radius: 5px; border-top-right-radius: 5px;border-right: 3px solid #f7f8f9 !important; border-left: 3px solid #f7f8f9 !important;}
.tabulatorTags .tabulator-header .tabulator-headers .tabulator-col{ background-color: #fff !important; color: #333 !important; }
.tabulatorTags .tabulator-header .tabulator-headers .tabulator-col:hover{background-color: #3b82f6 !important; color: #fff !important;}
.tabulatorTags .tabulator-footer .tabulator-paginator > label{ display:none !important}
.tabulatorTags .tabulator-footer .tabulator-paginator .tabulator-page-size{ margin-left: 0 !important; }
.tabulatorTags .tabulator-row .tabulator-cell{ padding: 10px 15px !important; height: 40px !important; text-align: left;}
.tabulatorTags .tabulator-row.tabulator-row-even { --tw-bg-opacity: 1; background-color: #f7fafc !important;}
.tabulatorTags .tabulator-header{ border-right: 1px solid #f7f8f9 !important; border-left: 1px solid #f7f8f9 !important;width: 100% !important; overflow: hidden !important;}
.sliderColor{ max-width: 350px !important;}
.sliderBox .vc-slider-swatches{margin-top: 8px !important;}
#rpDialogBox{position: fixed;top: 20px;left: calc( (100% - 430px) /2);z-index: 999;width: 430px;}
.rpoverlayDialog {position: fixed;top: 0;left: 0;right: 0;bottom: 0;z-index: 999;background-color: rgba(0, 0, 0, 0.8);}
</style>


        
        <!-- 
        <div class="col-span-12 lg:col-span-6 flex flex-col">
          <table class="table table--sm text-xs mb-5 w-full table-layout mt-5" 
            :class="layout.dataset?.tagsCounter?.names && Object.keys(layout.dataset?.tagsCounter?.names).length > 10 ? 'table-rp-tags' : ''">
            <tr class="bg-gray-100 h-8">
              <td class="border w-5"></td>
              <td class="border w-52">{{ $t('Tag') }}</td>
              <td class="border w-20 text-center">{{ Object.keys(layout.dataset?.tagsCounter?.tags).length ? $t('Images') :  $t('Annotations') }}</td>
              <td class="border w-10 text-center">{{ $t('Color') }}</td>
            </tr>
            <tr v-for="(t,i,c) in layout.dataset?.tagsCounter?.tags" :key="t" class="bg-white hover:bg-blue-100 cursor-pointer" @click="layout.tag=i">
              <td class="border text-center" v-if="i!='0'">{{(c)}}</td>
              <td class="border" v-if="i!='0'">{{layout.dataset.tagsCounter.names[i]}}</td>
              <td class="border text-center" v-if="i!='0'">{{t}}</td>
              <td class="border" v-if="i!='0'"><div :style="layout.dataset.tagsCounter.colors[i] ? 'background-color: ' + layout.dataset.tagsCounter.colors[i] : ''" class="w-full h-4 rounded"></div></td>
            </tr>
            <tr v-for="(t,i,c) in layout.dataset?.tagsCounter?.tagslabeled" :key="t" class="bg-white hover:bg-blue-100 cursor-pointer">
              <td class="border text-center">{{(c+1)}}</td>
              <td class="border">
                {{layout.dataset.tagsCounter.names[i]}}
              </td>
              <td class="border text-center">{{t}}</td>
              <td class="border">
                <div :style="layout.dataset.tagsCounter.colors[i] ? 'background-color: ' + layout.dataset.tagsCounter.colors[i] : ''" class="w-2/3 h-4 rounded ml-2" :title="layout.dataset.tagsCounter.colors[i]"></div>
              </td>
            </tr>
          </table>
        </div>
        -->