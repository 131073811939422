
import Tabulator from "tabulator-tables";
import feather from "feather-icons";

const table = {
    async init(tableRef, columns, opt=false){
        let table = new Tabulator(tableRef, {
            data:                    [],
            printHeader:             opt.printHeader ? opt.printHeader : '', 
            pagination:              opt.pagination, //"local"
            paginationSize:          opt.paginationSize ? opt.paginationSize : 5, 
            paginationSizeSelector:  [5, 10],
            layout:                  "fitColumns",
            responsiveLayout:        "collapse",
            placeholder:             opt.placeholder ? opt.placeholder : "No matching records found",
            columns:                 columns,
            responsiveLayoutCollapseStartOpen:  false,
            locale:                  false,
            langs:{
                "es-es":{
                    "columns":      { "name":"Nombre",},
                    "ajax":         { "loading":"Cargando", "error":"Error",},
                    "groups":       { "item":"item", "items":"items",},
                    "pagination":   { "page_size":"", "first":"First", "first_title":"Primera", "last":"Last", "last_title":"Última", "prev":"Prev", "prev_title":"Anterior", "next":"Next", "next_title":"Siguiente", },
                    "headerFilters":{ "default":"Filtrar columna...", "columns":{ "name":"Filtrar nombre...", }}
                }
            },
            renderComplete() { 
                feather.replace({ "stroke-width": 1.5 });
            }
        });
        this.InitOnResizeWindow(table);  
        return table;
    },
    async InitOnResizeWindow(table){  window.addEventListener("resize", () => { table.redraw(); feather.replace({ "stroke-width": 1.5 }); }); },
    async setData(table,data){ await table.setData(data) },
};

const install = app => { app.config.globalProperties.$table = table; };

export { install as default, table as table };
