import { _firebase as $_firebase } from "@/model/firebase";
import { dataset as $dataset } from "@/model/db/dataset";
import { event as $event } from "@/model/db/event";
import config from '@/etc/rosepetal.json';
import { Response } from 'node-fetch'


var S3 = require('aws-sdk/clients/s3');
const s3Config = { 
    region:       config.awsConfig.region, 
    credentials:  { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey },
    s3Uri:        "s3://"+ config.awsConfig.bucket + "/projects/" + config.awsConfig.projectId + "/upload" + "/manual/"
}
var s3 = new S3(s3Config);

var LookoutVisionClient = require('aws-sdk/clients/lookoutvision');
const lookoutConfig = { region: "us-east-1", credentials: { accessKeyId: config.awsConfig.accessKeyId, secretAccessKey: config.awsConfig.secretAccessKey } }
var lookoutvision = new LookoutVisionClient(lookoutConfig);

/*
var SagemakerClient = require('aws-sdk/clients/sagemaker');
var sagemaker = new SagemakerClient(s3Config);
*/

const aws = {
    getConfig() { return config.awsConfig },
    async getBucketCors() {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: config.awsConfig.bucket }
            s3.getBucketCors( { Bucket: resp.bucket },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data.CORSRules
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getBucketAcl() {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: config.awsConfig.bucket }
            s3.getBucketAcl( { Bucket: resp.bucket },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getBucketObjects() {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: config.awsConfig.bucket }
            s3.listObjects( { Bucket: resp.bucket },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getObject(key , renderHtml = false) {
        let p = new Promise((resolve, reject) => {
                    let resp  = { status: "error", error: false, bucket: config.awsConfig.bucket, key: key } //, render: "TEST"
                    if(key){
                        s3.getObject( { Bucket: resp.bucket, Key: key },async function(err, data) {
                            if (!err){
                                resp.status   = "success"
                                resp.response = data
                                resp.extension = key.toString().split('.').pop()
                                resolve(resp)
                            }
                            resp.error = err; reject
                            resolve(resp)
                        })
                    }else{
                        resp.error = "key object is required"; reject
                        resolve(resp)
                    }
                }) 
        let obj = await p 
        if(renderHtml && obj?.response?.Body){
            let acceptedRender = ['jpg', 'gif', 'png', 'bmp'];
            if (obj.extension && acceptedRender.includes(obj.extension)) {
                let res        = new Response(obj.response.Body) 
                let buffer     = await res.arrayBuffer()
                if(renderHtml=='64') obj.render = "data:image/png;base64," + Buffer.from(buffer, 'binary').toString('base64')
                else obj.render     = '<img width="250" height="250" src="' + "data:image/png;base64,"+Buffer.from(buffer, 'binary').toString('base64') + '" />'
            }
            delete obj.response.Body
        }
        return obj                         
    },
    async getObjectAttributes(key) {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: config.awsConfig.bucket, key: key }
            if(key){
                s3.getObjectAttributes( { Bucket: resp.bucket, Key: key, ObjectAttributes: ["ETag", "ObjectSize", "Checksum", "StorageClass", "ObjectParts"] },function(err, data) {
                    if (!err){
                        resp.status   = "success"
                        resp.response = data
                        resolve(resp)
                    }
                    resp.error = err; reject
                    resolve(resp)
                })
            }else{
                resp.error = "key object is required"; reject
                resolve(resp)
            }
        }) 
    },
    async deleteObject(objKey) {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false, bucket: config.awsConfig.bucket }
            if (objKey){
                resp.objKey   = objKey
                let delParams = {
                    Bucket: resp.bucket,
                    Key:    objKey,
                };
                s3.deleteObject( delParams ,function(err, data) {
                    if (!err){
                        resp.status   = "success"
                        resp.response = data
                        resolve(resp)
                    }
                    resp.error = err; reject
                    resolve(resp)
                })
            }else{ resp.error = "object Key is required"; resolve(resp) }
        }) 
    },
    async uploadS3(datasetID, tagMap = false, projectName = false) {
        let resp     = { status: "error", error: false, bucket: config.awsConfig.bucket }
        resp.dataset = datasetID  
        let images   = await $dataset.getImages({ datasetID: resp.dataset }) 
        if(tagMap)resp.tagMap = tagMap
        if(images.media && images.media.length){
            var nowdate          = new Date()
            let configS3         = this.getConfig()
            let dataset          = await $dataset.get(datasetID)
            let datasetTags      = await $dataset.getTags(datasetID)
            resp.projectName     = configS3.projectId 
            resp.datasetPathName = projectName ? projectName : dataset.name.toString() + "_" + nowdate.getTime()
            resp.path            = "projects/" + resp.projectName  + '/datasets/' + resp.datasetPathName
            resp.uploadedFiles   = { count: 0, normal: 0, anomaly: 0, files: [] };
            resp.manifest        = { test: "", train: ""}
            for (var i = 0; i < images.media.length; i++) { 
                let imgRef   = await $_firebase.storage().refFromURL(images.media[i].uri).getDownloadURL(); 
                let uploadFile = true
                if(resp.tagMap && images.media[i].tag){
                    let tagId     = images.media[i].tag.toString().split('/').pop()
                    var imagePath = resp.path
                    if(!datasetTags[tagId].unclassified){
                        var tagName  = datasetTags[tagId].name.toString().toUpperCase()  
                        if (resp.tagMap.normal.includes(tagName)){ tagName = "normal"; resp.uploadedFiles.normal++ }
                        if (resp.tagMap.anomaly.includes(tagName)){ tagName = "anomaly"; resp.uploadedFiles.anomaly++ }
                        imagePath +=  '/' + tagName
                    }else{  uploadFile = false }
                }
                if(uploadFile){
                    let fileKey = imagePath + "/" + images.media[i].uri.split("/").pop()
                    resp.uploadedFiles.files.push(fileKey)
                    let bucketParams = {
                        Bucket: resp.bucket,
                        Key:    fileKey,
                        Body:   await fetch(imgRef).then(response => response.blob())
                    };
                    s3.putObject(bucketParams,function(err){ if (err)resp.error = err  })
                    resp.uploadedFiles.count++;

                    let imgSet = "test"
                    if(images.media[i].set=="TRAIN")imgSet = "train"
                    resp.manifest[imgSet] += '{ '
                                            + '"source-ref" : "s3://' + config.awsConfig.bucket + "/" + fileKey + '",'
                                            + '"anomaly-label": ' + (tagName == "anomaly" ? '1' : '0') + ','  
                                            + '"anomaly-label-metadata": {"job-name": "anomaly-label", "class-name": "' + tagName +'", "human-annotated": "yes", "creation-date": "' + nowdate.toISOString() + '", "type": "groundtruth/image-classification" }'
                                            + ' }\n'
                }
            }
            resp.manifest = await this.createManifest(resp)
            resp.status   = "success";
            await $event.saveEvent('dataset.upload.s3', resp, false)
            return resp
        }else{ resp.error = "The dataset " + resp.dataset + " does not have images"; return resp }
    },
    async createManifest(opt) {
        return new Promise((resolve, reject) => {
            let resp          = { status: "error", error: false, manifest: { test: false, train: false } }
            if(opt.manifest){
                let datasetTypes  = ["test", "train"]
                for (var t = 0; t < datasetTypes.length; t++) {
                    if(opt.manifest[datasetTypes[t]]){
                        resp.manifest[datasetTypes[t]] = opt.path + '/manifests/' + opt.datasetPathName + "-" + datasetTypes[t] + ".manifest"
                        s3.putObject({
                                        Bucket: config.awsConfig.bucket,
                                        Key:    resp.manifest[datasetTypes[t]],
                                        Body:   new Blob([opt.manifest[datasetTypes[t]]], {type: "application/json"})
                                    }
                                    ,function(err){ if (err)resp.error = err  })
                    } 
                }
                resolve(resp.manifest) 
            }else{ resp.error = "manifest content is required"; reject; resolve(resp) }
        }) 
    },
    async listProjects() {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false }
            resp.region = lookoutConfig.region
            lookoutvision.listProjects({},function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async createProject(projectName) {
        return new Promise((resolve, reject) => {
            let resp = { status: "error", error: false }
            lookoutvision.createProject( { ProjectName: projectName, },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async createDataset(datasetType, projectName, manifest = false) {
        return new Promise((resolve, reject) => {
            let resp            = { status: "error", error: false, bucket: config.awsConfig.bucket }
            let datasetParams   = { DatasetType: datasetType, ProjectName: projectName };
            if(manifest){
                datasetParams.DatasetSource = {
                    GroundTruthManifest:  {
                        S3Object: {
                            Bucket: resp.bucket,
                            Key:    manifest,
                        }
                    }
                }
            }
            lookoutvision.createDataset( datasetParams,function(err, data) {
                if (!err && err === null){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getDataset(projectName) {
        let resp          = { status: "error", error: false, projectName: projectName, response: { dataset: {} } }
        let datasetTypes  = ["test", "train"]
        for (var t = 0; t < datasetTypes.length; t++) {
            if(datasetTypes[t]){
                let describe = await this.describeDataset({ DatasetType: datasetTypes[t], ProjectName: projectName })
                if(!describe.error)resp.response.dataset[datasetTypes[t]] = describe.response
                else resp.error = describe.error;
            } 
        }
        if(!resp.error)resp.status = "success"
        return resp
    },
    async getDatasetEntries(projectName) {
        let resp          = { status: "error", error: false, projectName: projectName, response: { counter: { test: { count: 0, normal: 0, anomaly: 0, nolabeled: 0 }, train: { count: 0, normal: 0, anomaly: 0, nolabeled: 0 } } } }
        let datasetTypes  = ["test", "train"]
        for (var t = 0; t < datasetTypes.length; t++) {
            if(datasetTypes[t]){
                let endList     = false
                let entries     = { normal: [], anomaly: [], nolabeled: []}
                var lastToken   = false
                while(!endList){
                    let qry  = { DatasetType: datasetTypes[t], ProjectName: projectName }
                    if(lastToken)qry.NextToken = lastToken
                    let list = await this.listDatasetEntries(qry)
                    if(!list.error){
                        if(list.response.NextToken)lastToken = list.response.NextToken
                        else endList = true
                        for(let e in list.response.DatasetEntries){ 
                            let entry = JSON.parse(list.response.DatasetEntries[e])
                            if(!("anomaly-label" in entry)){ entries.nolabeled.push(entry); resp.response.counter[datasetTypes[t]].nolabeled++  
                            }else{
                                if(entry["anomaly-label"]){ entries.anomaly.push(entry); resp.response.counter[datasetTypes[t]].anomaly++ 
                                }else{ entries.normal.push(entry); resp.response.counter[datasetTypes[t]].normal++ }
                            }
                            resp.response.counter[datasetTypes[t]].count++
                        }  
                        resp.response[datasetTypes[t]]       = entries
                    }else{ resp.error = list.error; endList = true }
                }
            } 
        }
        if(!resp.error)resp.status = "success"
        return resp
    },
    async listDatasetEntries(datasetParams) {
        return new Promise((resolve, reject) => {
            let resp       = { status: "error", error: false }
            lookoutvision.listDatasetEntries(datasetParams,function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async describeDataset(datasetParams) {
        return new Promise((resolve, reject) => {
            let resp       = { status: "error", error: false }
            lookoutvision.describeDataset(datasetParams,function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getProject(projectName) {
        return new Promise((resolve, reject) => {
            let resp       = { status: "error", error: false, projectName: projectName}
            lookoutvision.describeProject({ ProjectName: projectName },function(err, data) {
                if (!err){
                    resp.status   = "success"
                    resp.response = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async listModels(projectName) {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName }
            resp.region = lookoutConfig.region
            lookoutvision.listModels({ ProjectName: projectName },function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resp.response.count = data.Models ? data.Models.length : 0
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getModel(projectName, modelVersion = false) {
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName, modelVersion: "latest" }
            if(modelVersion)resp.modelVersion = modelVersion
            resp.region = lookoutConfig.region
            lookoutvision.describeModel({ ProjectName: projectName, ModelVersion: resp.modelVersion },function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    if(resp.response.ModelDescription){
                        resp.response.evaluation = {
                            resultKey:   resp.response?.ModelDescription?.EvaluationResult?.Key,
                            manifestKey: resp.response?.ModelDescription?.EvaluationManifest?.Key
                        }
                    }   
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getEvaluation(projectName, modelVersion = false) {
        let resp   = { status: "error", error: false, projectName: projectName, modelVersion: modelVersion ? modelVersion : "latest" }
        var model  = await this.getModel(resp.projectName, resp.modelVersion)
        if(model.response && model.response.evaluation){
            let resultEvaluation = await this.getObject(model.response.evaluation.resultKey)
            if(resultEvaluation.response && resultEvaluation.response.Body) {
                let res         = new Response(resultEvaluation.response.Body) 
                resp.results    = await res.json()
            }else{ resp.error   = "model evaluation results not found" } 
            var manifestEvaluation = await this.getObject(model.response.evaluation.manifestKey)
            if(manifestEvaluation.response && manifestEvaluation.response.Body) {
                let res         = new Response(manifestEvaluation.response.Body) 
                let manifest    = await res.text()
                manifest        = JSON.parse("[" + manifest.replace(/\n/g, ",").slice(0, -1) + "]")
                if(Object.keys(manifest).length){
                    resp.manifestResume   = { 
                                              totalTestImages:   Object.keys(manifest).length,
                                              normal:           { total: 0, trueNegative:  0, falseNegative: 0 },
                                              anomaly:          { total: 0, truePositive:  0, falsePositive: 0 }  
                                            }
                    resp.manifest         = manifest   
                    for (var i = 0; i < Object.keys(resp.manifest).length; i++) {

                        let objKey              = resp.manifest[i]["source-ref"].replace(/s3:/g, "").replace(/\/\//g, "").replace(new RegExp(config.awsConfig.bucket+"/", "g"), "");
                        resp.manifest[i]["key"] = objKey 

                        if(resp.manifest[i]["anomaly-label"]){
                            if(resp.manifest[i]["anomaly-label"])resp.manifestResume.anomaly.truePositive++
                            else resp.manifestResume.anomaly.falsePositive++
                            resp.manifestResume.anomaly.total++
                        }else{
                            if(!resp.manifest[i]["anomaly-label"])resp.manifestResume.normal.trueNegative++
                            else resp.manifestResume.normal.falsePositive++
                            resp.manifestResume.normal.total++
                        }
                    }
                }
            }
        }else{ resp.error = "model evaluation results not found" }
        return new Promise((resolve, reject) => {
            if(resp.error)reject 
            else resp.status = "success"
            resolve(resp)
        }) 
    },
    async getModelPackagingJobs(projectName){
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName }
            resp.region = lookoutConfig.region
            lookoutvision.listModelPackagingJobs({ ProjectName: projectName }, function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async getModelPackagingJob(projectName, jobName){
        return new Promise((resolve, reject) => {
            let resp    = { status: "error", error: false, projectName: projectName, jobName: jobName  }
            resp.region = lookoutConfig.region
            lookoutvision.describeModelPackagingJob({ ProjectName: projectName, JobName: jobName }, function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async createModel(projectName){
        return new Promise((resolve, reject) => {
            let resp     = { status: "error", error: false, projectName: projectName }
            resp.region  = lookoutConfig.region
            let model    = {
                            ProjectName: projectName,
                            OutputConfig: {
                                S3Location: {  
                                    Bucket: config.awsConfig.bucket,
                                    Prefix: "components/" + projectName.toString().replace(/\s+/g, '_') 
                                }
                            }
                          }
            lookoutvision.createModel(model, function(err, data) {
                if (!err){
                    resp.status         = "success"
                    resp.response       = data
                    resolve(resp)
                }
                resp.error = err; reject
                resolve(resp)
            })
        }) 
    },
    async UpdateDatasetEntries(datasetID) {
        let resp     = { status: "error", error: false, bucket: config.awsConfig.bucket }
        resp.dataset = datasetID  
        return resp
    },
}
const install = app => { app.config.globalProperties.$aws = aws; };
export { install as default, aws as aws };